import * as IconSelector from './icons';
import * as constants from './constants';
import * as helpers from 'core/helpers';
import { DateTime } from 'luxon';

/* DEPRECATED
export const getEnum = (api, field, icon, color) => {
    const newEnum = [];
    const data = helpers.apiGet(api).then(data => {
        data.forEach(item =>
            newEnum.push({
                value: item[field],
                label: item[field],
                icon: icon,
                color: color
            })
        );
    });

    return newEnum;
};
*/
export const getItem = (options, key, prop) => {
    const item = options.find(option => option.value === key);
    if (prop && item) return item[prop];

    return item;
};

export const filterValues = (options, values) => {
    return options.filter(option => values.includes(option.value));
};

const getCampañas = () => {
    const inicio = 2019;
    const fin = DateTime.now().year;
    let newEnum = [];

    for (let i = fin; i >= inicio; i--) {
        newEnum.push({
            value: i,
            label: i,
            description: 'Campaña',
            icon: IconSelector.CampañaIcon,
            color: 'black'
        });
    }

    return newEnum;
};

// Refactorizados
export const campañas = getCampañas();
export const scopes = [
    {
        value: constants.SCOPE_MIEMBRO,
        label: 'Miembro',
        description: 'Miembro',
        icon: IconSelector.PointIcon,
        color: 'Black'
    },
    {
        value: constants.SCOPE_ELABORACION,
        label: 'Elaboración',
        description: 'Elaboración',
        icon: IconSelector.PointIcon,
        color: 'Black'
    },
    {
        value: constants.SCOPE_ALMACENAMIENTO,
        label: 'Almacenamiento',
        description: 'Almacenamiento',
        icon: IconSelector.PointIcon,
        color: 'Black'
    },
    {
        value: constants.SCOPE_CRIANZA,
        label: 'Crianza',
        description: 'Crianza',
        icon: IconSelector.PointIcon,
        color: 'Black'
    },
    {
        value: constants.SCOPE_EMBOTELLADO,
        label: 'Embotellado',
        description: 'Embotellado',
        icon: IconSelector.PointIcon,
        color: 'Black'
    },
    {
        value: constants.SCOPE_PRODUCCION,
        label: 'Produccion',
        description: 'Produccion',
        icon: IconSelector.PointIcon,
        color: 'Black'
    }
];
export const changeLogs = [
    {
        value: constants.LOG_STATE,
        label: 'Estado',
        description: 'Estado',
        icon: IconSelector.ChangeLogStateIcon,
        color: 'Orange'
    },
    {
        value: constants.LOG_DATA,
        label: 'Cambios',
        description: 'Cambios',
        icon: IconSelector.ChangeLogDataIcon,
        color: 'Green'
    }
];
export const contactType = [
    /*{
        value: null,
        label: 'Sin definir',
        description: 'Sin tipo definido',
        icon: IconSelector.PointIcon,
        color: 'DarkGrey'
    },*/
    {
        value: constants.CONTACT_TYPE_REPRESENTANTE,
        label: 'Representante',
        description: 'Representante',
        icon: IconSelector.RepresentativePersonIcon,
        color: 'Green'
    },
    /*{
        value: constants.CONTACT_TYPE_DECLARANTE,
        label: 'Declarante',
        description: 'Declarante',
        icon: IconSelector.DeclarantIcon,
        color: 'Blue'
    },*/
    {
        value: constants.CONTACT_TYPE_AUTORIZADO,
        label: 'Autorizado',
        description: 'Autorizado',
        icon: IconSelector.AuthorizedPersonIcon,
        color: 'Red'
    },
    {
        value: constants.CONTACT_TYPE_CONTACTO,
        label: 'Contacto',
        description: 'Comunidad de bienes',
        icon: IconSelector.ContactIcon,
        color: 'Blue'
    },
    {
        value: constants.CONTACT_TYPE_DIRECCION,
        label: 'Dirección Envío',
        description: 'Direccion de Envio',
        icon: IconSelector.LogisticIcon,
        color: 'DarkOrange'
    }
];
export const locksParcel = [
    {
        value: null,
        label: 'Sin definir',
        description: 'No se ha definido un bloqueo',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: constants.LOCK_PARCEL_COSECHA_EN_VERDE,
        label: 'Cosecha en Verde',
        description: 'Se ha bloqueado porque ha realizado consecha en verde',
        icon: IconSelector.RecolectionIcon,
        color: 'red'
    },
    {
        value: constants.LOCK_PARCEL_EXCESO_RENDIMIENTO,
        label: 'Exceso Rendimiento',
        description: 'Se ha bloqueado porque tiene exceso de rendimiento',
        icon: IconSelector.ExceedIcon,
        color: 'DarkRed'
    }
];
export const incidenceParcel = [
    {
        value: null,
        label: 'Sin definir',
        description: 'No se ha definido una incidencia',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: constants.INCIDENCE_PARCEL_COSECHA_EN_VERDE,
        label: 'Cosecha en Verde',
        description: 'Se ha bloqueado porque ha realizado consecha en verde',
        icon: IconSelector.RecolectionIcon,
        color: 'green'
    },
    {
        value: constants.INCIDENCE_PARCEL_EXCESO_RENDIMIENTO_CAMPO,
        label: 'Exceso Rendimiento Campo',
        description:
            'Se ha bloqueado porque tiene exceso de rendimiento detectado en campo',
        icon: IconSelector.InspeccionesIcon,
        color: 'red'
    },
    {
        value: constants.INCIDENCE_PARCEL_EXCESO_RENDIMIENTO_DECLARADO,
        label: 'Exceso Rendimiento Declarado',
        description: 'Tiene exceso de rendimiento en la declaración',
        icon: IconSelector.ExceedIcon,
        color: 'darkred'
    },
    {
        value: constants.INCIDENCE_PARCEL_NO_GRADO_MINIMO,
        label: 'No grado minimo',
        description: 'No cumple con el grado definido en el pliego',
        icon: IconSelector.BaumeIcon,
        color: 'darkorange'
    }
];
export const statesEntity = [
    {
        value: null,
        label: 'Sin definir',
        description: 'No se ha definido un estado',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: constants.STATE_ACTIVO,
        label: 'Activo',
        description: 'Estado Activo',
        icon: IconSelector.DoIcon,
        color: 'Green'
    },
    {
        value: constants.STATE_BAJA,
        label: 'Baja',
        description: 'Estado Baja',
        icon: IconSelector.DoneIcon,
        color: 'DarkRed'
    },
    {
        value: constants.STATE_TRASPASADO,
        label: 'Traspasado',
        description: 'Estado Traspasado',
        icon: IconSelector.TransferredIcon,
        color: 'Black'
    },
    {
        value: constants.STATE_BORRRADOR,
        label: 'Borrador',
        description: 'Estado Borrador',
        icon: IconSelector.DraftIcon,
        color: 'DarkOrange'
    },
    {
        value: constants.STATE_SOLICITUD,
        label: 'Solicitud',
        description: 'Estado Solicitud',
        icon: IconSelector.SolicitudesIcon,
        color: 'Blue'
    }
];
export const statesExecution = [
    {
        value: null,
        label: 'Sin definir',
        description: 'No se ha definido un estado para la tarea',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: constants.STATE_EXECUTION_PENDIENTE,
        label: 'Pendiente',
        description: 'Pendiente de Realizar',
        icon: IconSelector.PendingIcon,
        color: 'DarkRed'
    },
    {
        value: constants.STATE_EXECUTION_EN_CURSO,
        label: 'En Curso',
        description: 'Haciendose actualmente',
        icon: IconSelector.DoIcon,
        color: 'DarkOrange'
    },
    {
        value: constants.STATE_EXECUTION_FINALIZADO,
        label: 'Finalizado',
        description: 'Terminado',
        icon: IconSelector.DoneIcon,
        color: 'DarkGreen'
    }
];
export const statesResolution = [
    {
        value: null,
        label: 'Sin definir',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: constants.STATE_RESOLUTION_POR_RESOLVER,
        label: 'Por Resolver',
        description: 'Pendiente de Resolución',
        icon: IconSelector.ToVerifiedIcon,
        color: 'Blue'
    },
    {
        value: constants.STATE_RESOLUTION_EN_CURSO,
        label: 'En Curso',
        description: 'Realizandose atualmente',
        icon: IconSelector.DoIcon,
        color: 'DarkOrange'
    },
    {
        value: constants.STATE_RESOLUTION_RESUELTO,
        label: 'Resuelto',
        description: 'Resolución favorable',
        icon: IconSelector.VerifiedIcon,
        color: 'Green'
    },
    {
        value: constants.STATE_RESOLUTION_RECHAZADO,
        label: 'Rechazado',
        description: 'Resolución no favorable',
        icon: IconSelector.RejectIcon,
        color: 'DarkRed'
    }
];
export const statesLiquidation = [
    {
        value: constants.STATE_LIQUIDATION_NO_LIQUIDABLE,
        label: 'No Liquidable',
        description: 'No se puede liquidar',
        icon: IconSelector.NotDefinedIcon,
        color: 'DarkOrange'
    },
    {
        value: constants.STATE_LIQUIDATION_POR_LIQUIDAR,
        label: 'Por Liquidar',
        description: 'Pendiente de Liquidar',
        icon: IconSelector.PendingIcon,
        color: 'DarkRed'
    },
    {
        value: constants.STATE_LIQUIDATION_LIQUIDADO,
        label: 'Liquidado',
        description: 'Se ha generado la liquidación',
        icon: IconSelector.LiquidacionesIcon,
        color: 'DarkGreen'
    }
];
export const resultsResolution = [
    {
        value: null,
        label: 'Sin definir',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: constants.RESULT_RESOLUTION_FAVORABLE,
        label: 'Favorable',
        description: 'Resultado favorable',
        icon: IconSelector.VerifiedIcon,
        color: 'Green'
    },
    {
        value: constants.RESULT_RESOLUTION_NO_FAVORABLE,
        label: 'No Favorable',
        description: 'Resolución desfavorable',
        icon: IconSelector.RejectIcon,
        color: 'DarkRed'
    },
    {
        value: constants.RESULT_RESOLUTION_PENDIENTE,
        label: 'Pendiente',
        description: 'Resultado pendiente',
        icon: IconSelector.PendingIcon,
        color: 'DarkOrange'
    }
];
export const usesForm = [
    {
        value: null,
        label: 'Sin definir',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: constants.USE_FORM_SOLICITUD,
        label: 'Solicitud',
        description: 'Formulario de solicitud',
        icon: IconSelector.SolicitudesIcon,
        color: 'Blue'
    },
    {
        value: constants.USE_FORM_ACTA,
        label: 'Acta',
        description: 'Formulario de acta',
        icon: IconSelector.ActasIcon,
        color: 'Orange'
    },
    {
        value: constants.USE_FORM_RESOLUTION,
        label: 'Resolucion',
        description: 'Resultado inspeccion',
        icon: IconSelector.VerifiedIcon,
        color: 'Green'
    }
];
export const statesTask = [
    {
        value: null,
        label: 'Sin definir',
        description: 'No se ha definido un estado para la tarea',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: constants.STATE_TASK_PENDIENTE,
        label: 'Pendiente',
        description: 'tareas asignadas pendientes de planificar',
        icon: IconSelector.PendingIcon,
        color: 'Blue'
    },
    {
        value: constants.STATE_TASK_POR_HACER,
        label: 'Por Hacer',
        description:
            'tareas asignadas planificadas y listas para su desarrollo',
        icon: IconSelector.TodoIcon,
        color: 'DarkRed'
    },
    {
        value: constants.STATE_TASK_EN_CURSO,
        label: 'En Curso',
        description: 'tareas asignadas que estan siendo realizadas',
        icon: IconSelector.DoIcon,
        color: 'DarkOrange'
    },
    {
        value: constants.STATE_TASK_FINALIZADA,
        label: 'Finalizada',
        description: 'tareas asignadas finalizdas y a la espera de validación',
        icon: IconSelector.DoneIcon,
        color: 'Green'
    }
];
export const validationsTask = [
    {
        value: null,
        label: 'Sin definir',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: constants.VALIDATION_TASK_POR_VALIDAR,
        label: 'Por Validar',
        description: 'tareas pendientes de validar',
        icon: IconSelector.ToVerifiedIcon,
        color: 'DarkOrange'
    },
    {
        value: constants.VALIDATION_TASK_VALIDADA,
        label: 'Validada',
        description: 'tareas asignadas finalizadas y validadas',
        icon: IconSelector.VerifiedIcon,
        color: 'Green'
    },
    {
        value: constants.VALIDATION_TASK_RECHAZADA,
        label: 'Rechazada',
        description: 'tareas asignadas finalizadas pero rechazadas',
        icon: IconSelector.RejectIcon,
        color: 'DarkRed'
    }
];
export const representationRol = [
    {
        value: null,
        label: 'Sin definir',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: constants.REPRESENTATION_ROL_INSPECTOR,
        label: 'Inspector',
        description: 'Hace de Inspector',
        icon: IconSelector.PointIcon,
        color: 'BLue'
    },
    {
        value: constants.REPRESENTATION_ROL_AUDITOR,
        label: 'Auditor',
        description: 'Hace de Auditor',
        icon: IconSelector.PointIcon,
        color: 'DarkRed'
    },
    {
        value: constants.REPRESENTATION_ROL_FORMADOR,
        label: 'Formador',
        description: 'Hace de Formador',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: constants.REPRESENTATION_ROL_OBSERVADOR,
        label: 'Observador',
        description: 'Hace de Observador',
        icon: IconSelector.PointIcon,
        color: 'DarkOrange'
    }
];
export const authDocument = [
    {
        value: constants.AUTH_DOCUMENT_AUTORIZACION,
        label: 'Autorización',
        description: 'Existe una autorización expresa',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: constants.AUTH_DOCUMENT_DNI,
        label: 'DNI',
        description: 'Se ha aportado el DNI como autorizacion',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: constants.AUTH_DOCUMENT_PODERES,
        label: 'Poderes',
        description: 'Se han emitido poderes',
        icon: IconSelector.PointIcon,
        color: 'DarkOrange'
    },
    {
        value: constants.AUTH_DOCUMENT_ESCRITURA,
        label: 'Escritura',
        description: 'Se ha presentado la escritura',
        icon: IconSelector.PointIcon,
        color: 'Red'
    }
];
export const authAction = [
    {
        value: constants.AUTH_ACTION_SOLICITUDES,
        label: 'Solicitudes',
        description: 'Puede hacer solicitudes',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: constants.AUTH_ACTION_CONSULTAS,
        label: 'Consultas',
        description: 'Puede realizar consultas',
        icon: IconSelector.PointIcon,
        color: 'DarkOrange'
    },
    {
        value: constants.AUTH_ACTION_AUTORIZACIONES,
        label: 'Autorizaciones',
        description: 'Puede autorizar a otros',
        icon: IconSelector.PointIcon,
        color: 'Red'
    },
    {
        value: constants.AUTH_ACTION_TODO,
        label: 'Todo',
        description: 'Puede realizar cualquier tramite',
        icon: IconSelector.PointIcon,
        color: 'Black'
    }
];
export const typeQuota = [
    {
        value: null,
        label: 'Sin definir',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: constants.TYPE_QUOTA_COMPLETA,
        label: 'Cuota completa',
        description: 'Se genera la cuota completa',
        icon: IconSelector.FullIcon,
        color: 'Green'
    },
    {
        value: constants.TYPE_QUOTA_PARCIAL,
        label: 'Cuota parcial',
        description: 'Se genera la cuota por la declaración',
        icon: IconSelector.PartialIcon,
        color: 'DarkOrange'
    },
    {
        value: constants.TYPE_QUOTA_YA_EMITIDA,
        label: 'Cuota ya emitida',
        description: 'La cuota se emitió en una declaración anterior',
        icon: IconSelector.DoneIcon,
        color: 'Red'
    }
];

export const typeRepresentative = [
    {
        value: null,
        label: 'Sin definir',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: constants.TYPE_REPRESENTATIVE_CLIENTE,
        label: 'Cliente',
        description: 'Representante de un cliente',
        icon: IconSelector.BodegaIcon,
        color: 'Blue'
    },
    {
        value: constants.TYPE_REPRESENTATIVE_SOCIO,
        label: 'Socio',
        description: 'Representante de un socio',
        icon: IconSelector.ViticultorIcon,
        color: 'Green'
    }
];

// Pendientes de refactorizar

export const prioridades = [
    {
        value: 'Alta',
        label: 'Alta',
        description: 'Prioridad Alta',
        icon: IconSelector.PriorityHighIcon,
        color: 'DarkRed'
    },
    {
        value: 'Media',
        label: 'Media',
        description: 'Prioridad Media',
        icon: IconSelector.PriorityNormalIcon,
        color: 'DarkOrange'
    },
    {
        value: 'Baja',
        label: 'Baja',
        description: 'Prioridad baja',
        icon: IconSelector.PriorityDownIcon,
        color: 'Green'
    }
];

export const archivo = [
    {
        value: 'Archivado',
        label: 'Archivado',
        description: 'Elemento Archivado',
        icon: IconSelector.ArchiveIcon,
        color: 'Black'
    },
    {
        value: 'Disponible',
        label: 'Disponible',
        description: 'Elemento no Archivado',
        icon: IconSelector.UnarchiveIcon,
        color: 'Green'
    }
];

export const censos = [
    {
        value: null,
        label: 'Sin definir',
        description: 'Sin censo definido',
        icon: IconSelector.PointIcon,
        color: 'Black'
    },
    {
        value: 'JUMILLA',
        label: 'JUMILLA',
        description: 'JUMILLA',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'ALBATANA',
        label: 'ALBATANA',
        description: 'ALBATANA',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'ONTUR',
        label: 'ONTUR',
        description: 'ONTUR',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'HELLIN',
        label: 'HELLIN',
        description: 'HELLIN',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'TOBARRA',
        label: 'TOBARRA',
        description: 'TOBARRA',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'FUENTEALAMO',
        label: 'FUENTEALAMO',
        description: 'FUENTEALAMO',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'MONTEALEGRE',
        label: 'MONTEALEGRE',
        description: 'MONTEALEGRE',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'No Inscrita',
        label: 'No Inscrita',
        description: 'Bodega no inscrita en la DOP',
        icon: IconSelector.PointIcon,
        color: 'Red'
    }
];

export const tiposEmpresa = [
    {
        value: null,
        label: 'Sin definir',
        description: 'Sin tipo definido',
        icon: IconSelector.PointIcon,
        color: 'Black'
    },
    {
        value: 'Persona Fisica',
        label: 'Persona Fisica',
        description: 'Persona Fisica',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'Particular',
        label: 'Particular',
        description: 'Persona Fisica',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'Comunidad de bienes',
        label: 'Comunidad de bienes',
        description: 'Comunidad de bienes',
        icon: IconSelector.PointIcon,
        color: 'DarkGrey'
    },
    {
        value: 'Sociedad Limitada',
        label: 'Sociedad Limitada',
        description: 'Sociedad Limitada',
        icon: IconSelector.PointIcon,
        color: 'DarkRed'
    },
    {
        value: 'Sociedad Anonima',
        label: 'Sociedad Anonima',
        description: 'Sociedad Anonima',
        icon: IconSelector.PointIcon,
        color: 'DarkRed'
    },
    {
        value: 'SAT',
        label: 'SAT',
        description: 'Sociedad Agraria de Transformación',
        icon: IconSelector.PointIcon,
        color: 'DarkOrange'
    },
    {
        value: 'Cooperativa',
        label: 'Cooperativa',
        description: 'Cooperativa',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: 'Coop Grado 1',
        label: 'Coop Grado 1',
        description: 'Cooperativa',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: 'Sdad Mercantil',
        label: 'Sdad Mercantil',
        description: 'Sdad Mercantil',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: 'Otros',
        label: 'Otros',
        description: 'Otros',
        icon: IconSelector.PointIcon,
        color: 'Green'
    }
];

export const sistemasExplotacion = [
    {
        value: null,
        label: 'Sin definir',
        description: 'Sin sistema definido',
        icon: IconSelector.PointIcon,
        color: 'Black'
    },
    {
        value: 'Propiedad',
        label: 'Propiedad',
        description: 'Propiedad',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'Arrendamiento',
        label: 'Arrendamiento',
        description: 'Arrendamiento',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: 'Aparceria',
        label: 'Aparcería',
        description: 'Aparceria',
        icon: IconSelector.PointIcon,
        color: 'Orange'
    }
];

export const tiposExpedicion = [
    {
        value: 'Expedicion',
        label: 'Expedicion',
        description: 'Expedicion',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: 'Cambio Uso',
        label: 'Cambio Uso',
        description: 'Cambio de Uso',
        icon: IconSelector.PointIcon,
        color: 'DarkOrange'
    },
    {
        value: 'Devolucion',
        label: 'Devolucion',
        description: 'Devolucion',
        icon: IconSelector.PointIcon,
        color: 'DarkRed'
    }
];

export const tiposVino = [
    {
        value: 'Tinto',
        label: 'Tinto',
        description: 'Tinto',
        icon: IconSelector.PointIcon,
        color: 'DarkRed'
    },
    {
        value: 'Roble',
        label: 'Roble',
        description: 'Roble',
        icon: IconSelector.PointIcon,
        color: 'DarkRed'
    },
    {
        value: 'Barrica',
        label: 'Barrica',
        description: 'Barrica',
        icon: IconSelector.PointIcon,
        color: 'DarkRed'
    },
    {
        value: 'Crianza',
        label: 'Crianza',
        description: 'Crianza',
        icon: IconSelector.PointIcon,
        color: 'DarkRed'
    },
    {
        value: 'Reserva',
        label: 'Reserva',
        description: 'Reserva',
        icon: IconSelector.PointIcon,
        color: 'DarkRed'
    },
    {
        value: 'Gran Reserva',
        label: 'Gran Reserva',
        description: 'Gran Reserva',
        icon: IconSelector.PointIcon,
        color: 'DarkRed'
    },
    {
        value: 'Rosado',
        label: 'Rosado',
        description: 'Rosado',
        icon: IconSelector.PointIcon,
        color: 'LightRed'
    },
    {
        value: 'Blanco',
        label: 'Blanco',
        description: 'Blanco',
        icon: IconSelector.PointIcon,
        color: 'Gold'
    },
    {
        value: 'Dulce',
        label: 'Dulce',
        description: 'Dulce',
        icon: IconSelector.PointIcon,
        color: 'BLack'
    },
    {
        value: 'Vino Licor',
        label: 'Vino Licor',
        description: 'Vino Licor',
        icon: IconSelector.PointIcon,
        color: 'Grey'
    }
];

export const tipoEnvase = [
    {
        value: 'Botella',
        label: 'Botella',
        description: 'Auxiliar',
        icon: IconSelector.PointIcon,
        color: 'DarkRed'
    },
    {
        value: 'Bag in Box',
        label: 'Bag in Box',
        description: 'Bag in Box',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    }
];

export const tipoHomologacion = [
    {
        value: 'Auxiliar',
        label: 'Auxiliar',
        description: 'Auxiliar',
        icon: IconSelector.PointIcon,
        color: 'DarkRed'
    },
    {
        value: 'Normalizado',
        label: 'Normalizado',
        description: 'Normalizado',
        icon: IconSelector.PointIcon,
        color: 'LightRed'
    }
];

export const tiposGasto = [
    {
        value: 'Normal',
        label: 'Normal',
        description: 'Normal',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: 'Rectificativo',
        label: 'Rectificativo',
        description: 'Rectificativo',
        icon: IconSelector.PointIcon,
        color: 'Red'
    },
    {
        value: 'Intracomunitario',
        label: 'Intracomunitario',
        description: 'Intracomunitario',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'Exento',
        label: 'Exento',
        description: 'Exento',
        icon: IconSelector.PointIcon,
        color: 'Orange'
    },
    {
        value: 'Inversion Sujeto Pasivo',
        label: 'Inversion Sujeto Pasivo',
        description: 'Inversion Sujeto Pasivo',
        icon: IconSelector.PointIcon,
        color: 'Black'
    }
];

export const tiposPago = [
    {
        value: null,
        label: 'Sin definir',
        description: 'No se ha definido',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: 'Efectivo',
        label: 'Efectivo',
        description: 'Uso Metalico',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: 'Transferencia',
        label: 'Transferencia',
        description: 'Transferencia Bancaria',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'Tarjeta',
        label: 'Tarjeta',
        description: 'Tarjeta',
        icon: IconSelector.PointIcon,
        color: 'Brown'
    },
    {
        value: 'Domiciliacion',
        label: 'Domiciliación',
        description: 'Domiciliación Bancaria',
        icon: IconSelector.PointIcon,
        color: 'Orange'
    },
    {
        value: 'Pagare',
        label: 'Pagaré',
        description: 'Emisión de efectos',
        icon: IconSelector.PointIcon,
        color: 'Red'
    }
];

export const mediosPago = [
    {
        value: 'ccc',
        label: 'Cuenta Corriente',
        description: 'Cuenta Corrinte',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: 'Tarjeta',
        label: 'Tarjeta',
        description: 'Tarjeta Debito/Credito',
        icon: IconSelector.PointIcon,
        color: 'Black'
    },
    {
        value: 'Poliza',
        label: 'Póliza',
        description: 'Póliza',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'Credito',
        label: 'Crédito',
        description: 'Crédito',
        icon: IconSelector.PointIcon,
        color: 'Red'
    },
    {
        value: 'Adeudos',
        label: 'Adeudos',
        description: 'Linea de Adeudos',
        icon: IconSelector.PointIcon,
        color: 'Orange'
    }
];

export const unidadVencimientos = [
    {
        value: 'Dias',
        label: 'Dias',
        description: 'Días',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: 'Meses',
        label: 'Meses',
        description: 'Meses',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    }
];

export const ajusteVencimientos = [
    {
        value: 'Sin Ajuste',
        label: 'Sin Ajuste',
        description: 'No ajustamos la fecha del vencimiento',
        icon: IconSelector.PointIcon,
        color: 'Green'
    },
    {
        value: 'Fin de mes',
        label: 'Fin de mes',
        description:
            'No ajustamos la fecha del vencimiento al ultimo día de mes en curso',
        icon: IconSelector.PointIcon,
        color: 'Blue'
    },
    {
        value: 'Dia especifico',
        label: 'Dia especifico',
        description:
            'Ajustamos al  dia especidicado por el proveedor o cliente',
        icon: IconSelector.PointIcon,
        color: 'Orange'
    }
];

export const tipoPartida = [
    {
        value: 'Gasto',
        label: 'Gasto',
        description: 'Es un gasto',
        icon: IconSelector.PointIcon,
        color: 'Red'
    },
    {
        value: 'Ingreso',
        label: 'Ingreso',
        description: 'Es un Ingreso',
        icon: IconSelector.PointIcon,
        color: 'Green'
    }
];

export const tiposDocumento = [
    {
        value: null,
        label: 'Sin definir',
        description: 'No se ha definido',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: 'Gasto',
        label: 'Gasto',
        description: 'Gasto',
        icon: IconSelector.GastosIcon,
        color: 'Red',
        entrada: false
    },
    {
        value: 'Compra',
        label: 'Compra',
        description: 'Compra',
        icon: IconSelector.PointIcon,
        color: 'Orange',
        entrada: false
    },
    {
        value: 'Nomina',
        label: 'Nomina',
        description: 'Nomina',
        icon: IconSelector.PointIcon,
        color: 'Blue',
        entrada: false
    },
    {
        value: 'Liquidacion',
        label: 'Liquidación',
        description: 'Liquidación',
        icon: IconSelector.LiquidacionesIcon,
        color: 'Green',
        entrada: true
    }
];

export const tiposApunte = [
    {
        value: 'Gasto',
        label: 'Gasto',
        description: 'Gasto',
        icon: IconSelector.GastosIcon,
        color: 'Red',
        entrada: false
    },
    {
        value: 'Compra',
        label: 'Compra',
        description: 'Compra',
        icon: IconSelector.PointIcon,
        color: 'Orange',
        entrada: false
    },
    {
        value: 'Nomina',
        label: 'Nomina',
        description: 'Nomina',
        icon: IconSelector.PointIcon,
        color: 'Blue',
        entrada: false
    },
    {
        value: 'Liquidacion',
        label: 'Liquidación',
        description: 'Liquidación',
        icon: IconSelector.LiquidacionesIcon,
        color: 'Green',
        entrada: true
    },
    {
        value: 'Tesoreria',
        label: 'Tesorería',
        description: 'Cobros, pagos, remesas',
        icon: IconSelector.CobrosPagosIcon,
        color: 'Black',
        entrada: true
    }
];

export const sector = [
    {
        value: null,
        label: 'Sin definir',
        description: 'No se ha definido un sector',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: 'Agricultura',
        label: 'Agricultura',
        description: 'Sector Agricola',
        icon: IconSelector.ParcelIcon,
        color: 'Green'
    },
    {
        value: 'Ganaderia',
        label: 'Ganadería',
        description: 'Sector Ganadero',
        icon: IconSelector.AnimalIcon,
        color: 'LightSalmon'
    },
    {
        value: 'Ambos',
        label: 'Ambos',
        description: 'Sector Agricola y Ganadero',
        icon: IconSelector.AllIcon,
        color: 'Blue'
    }
];

export const tipoSocio = [
    {
        value: null,
        label: 'Sin definir',
        description: 'No se ha definido un tipo',
        icon: IconSelector.NotDefinedIcon,
        color: 'Black'
    },
    {
        value: 'Aficionado',
        label: 'Aficionado',
        description: 'Socio Normal',
        icon: IconSelector.AmateurIcon,
        color: 'Orange'
    },
    {
        value: 'Profesional',
        label: 'Profesional',
        description: 'Socio considerado como profesional',
        icon: IconSelector.ProfesionalIcon,
        color: 'Blue'
    },
    {
        value: 'Joven',
        label: 'Joven',
        description: 'Incorporacion de Joven',
        icon: IconSelector.NewIcon,
        color: 'Green'
    }
];

export const claseTramite = [
    {
        value: 'Solicitud',
        label: 'Solicitud',
        description: 'Solicitud',
        icon: IconSelector.SolicitudesIcon,
        color: 'Blue'
    },
    {
        value: 'Declaracion',
        label: 'Declaración',
        description: 'Declaración',
        icon: IconSelector.DeclaracionesIcon,
        color: 'Green'
    },
    {
        value: 'Auditoria',
        label: 'Auditoría',
        description: 'Auditoría',
        icon: IconSelector.AuditoriasIcon,
        color: 'Orange'
    },
    {
        value: 'Inspeccion',
        label: 'Inspección',
        description: 'Inspección',
        icon: IconSelector.InspeccionesIcon,
        color: 'Red'
    },
    {
        value: 'General',
        label: 'General',
        description: 'General',
        icon: IconSelector.TramitesIcon,
        color: 'Black'
    }
];
