import React, { useEffect } from 'react';

import { Divider, Grid } from 'core/components/library';

import { Text, Select, Tag, Tabs, Documents } from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';

import Detalles from './Tabs/TabDetalles';
import Marcas from './Tabs/TabMarcas';
import Explotaciones from './Tabs/TabExplotaciones';
import Contactos from './Tabs/TabContactos';
import DetailVencimiento from '../../../tesoreria/vencimientos/Detalle';
import { statesEntity } from 'core/enums';

const Detalle = props => {
    const { values, editing, handleChange } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={3}>
                <Grid item xs>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <Text
                                fullWidth
                                name='nif'
                                label='NIF'
                                value={values.nif}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                fullWidth
                                name='censo'
                                label='Censo'
                                value={values.censo}
                                onChange={handleChange}
                                editing={editing}
                                options={enums.censos}
                            />
                        </Grid>
                        <Grid item xs>
                            <Tag
                                align={'center'}
                                size={'medium'}
                                sx={{
                                    color: enums.getItem(
                                        statesEntity,
                                        values.estado,
                                        'color'
                                    )
                                }}
                                Icon={enums.getItem(
                                    statesEntity,
                                    values.estado,
                                    'icon'
                                )}
                                value={values.estado}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <Text
                                fullWidth
                                name='nombre'
                                label='Razón Social / Nombre'
                                value={values.nombre}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item xs>
                            <Text
                                fullWidth
                                name='nombreComercial'
                                label='Denominación / Nombre Comercial'
                                value={values.nombreComercial}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <Grid container direction='column' spacing={1}>
                                <Divider
                                    style={{ margin: '12px 12px 0px 12px' }}
                                />
                                <Grid item>
                                    <Text
                                        fullWidth
                                        multiline
                                        rows={2}
                                        name='direccion'
                                        label='Dirección'
                                        value={values.direccion}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <Text
                                                fullWidth
                                                name='cp'
                                                label='Cód. Postal'
                                                value={values.cp}
                                                onChange={handleChange}
                                                editing={editing}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Text
                                                fullWidth
                                                name='poblacion'
                                                label='Población'
                                                value={values.poblacion}
                                                onChange={handleChange}
                                                editing={editing}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        name='provincia'
                                        label='Provincia'
                                        value={values.provincia}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs>
                            <Grid container direction='column' spacing={1}>
                                <Divider
                                    style={{ margin: '12px 12px 0px 12px' }}
                                />
                                <Grid item>
                                    <Grid container spacing={1}>
                                        <Grid item xs>
                                            <Text
                                                fullWidth
                                                multiline
                                                rows={2}
                                                name='tlf'
                                                label='Tlfno.'
                                                value={values.tlf}
                                                onChange={handleChange}
                                                editing={editing}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Text
                                                fullWidth
                                                multiline
                                                rows={2}
                                                name='fax'
                                                label='Fax'
                                                value={values.fax}
                                                onChange={handleChange}
                                                editing={editing}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        name='email'
                                        label='@'
                                        value={values.email}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        name='web'
                                        label='web'
                                        value={values.web}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Tabs
                        tabs={[
                            {
                                label: 'Detalles',
                                icon: icons.DetailIcon,
                                component: <Detalles {...props} />
                            },
                            {
                                label: 'Centros',
                                icon: icons.ResourcesIcon,
                                component: <Explotaciones {...props} />,
                                disabled: false
                            },
                            {
                                label: 'Contactos',
                                icon: icons.ContactsIcon,
                                component: <Contactos {...props} />,
                                disabled: false
                            },
                            {
                                label: 'Marcas',
                                icon: icons.BrandsIcon,
                                component: <Marcas {...props} />,
                                disabled: false
                            },
                            {
                                label: 'Documentos',
                                icon: icons.DocumentIcon,
                                component: (
                                    <Documents
                                        name='documentacion'
                                        editing={editing}
                                        onChange={handleChange}
                                        rows={
                                            values.documentacion
                                                ? values.documentacion
                                                : []
                                        }
                                        maxHeight={456}
                                    />
                                ),
                                disabled: false
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Detalle;
