import React, { useEffect } from 'react';
import { DateTime } from 'luxon';

import { Divider, Grid } from 'core/components/library';

import { Text, Select, Tabs, Documents, Combo } from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import * as helpers from 'core/helpers';

import Apuntes from './Tabs/Apuntes';
import Partidas from './Tabs/Partidas';
import Vencimientos from './Tabs/Vencimientos';
import List from '../../../../store/List';

import DetailProveedor from 'views/compras/Proveedores/Detalle';
import DetailSubcuenta from 'views/contabilidad/subcuentas/Detalle';
import DetailFormaPago from 'views/tesoreria/formas-pago/Detalle';

const ComboProveedores = List(Combo, 'options', 'lstProveedores');
const ComboSubcuentas = List(Combo, 'options', 'lstSubcuentas');
const ComboFormaPago = List(Combo, 'options', 'lstFormasPago');

let timeBoxApuntes = 0;
let timeBoxVencimientos = 0;
let timeBoxRegistro = 0;

const Detalle = props => {
    const {
        values,
        editing,
        handleChange,
        refresh,
        reload,
        execAction,
        adding
    } = props;

    useEffect(() => {
        if (editing) {
            const changes = [];

            if (values.proveedor) {
                changes.push(
                    helpers.getValueControl(
                        'combo',
                        'subcuenta',
                        values.proveedor.subcuenta
                    )
                );

                changes.push(
                    helpers.getValueControl(
                        'combo',
                        'formaPago',
                        values.proveedor.formaPago
                    )
                );
            }

            changes.push(
                helpers.getValueControl(
                    'text',
                    'concepto',
                    buildConcepto(values)
                )
            );

            handleChange(changes);
        }
    }, [JSON.stringify(values.proveedor)]);

    useEffect(() => {
        if (editing) {
            handleChange([
                helpers.getValueControl(
                    'text',
                    'concepto',
                    buildConcepto(values)
                )
            ]);
            //actualizaVencimientos('Documento Proveedor')
        }
    }, [values.fechaDocumento, values.documento]);

    useEffect(() => {
        actualizaApuntes('Concepto');
        actualizaVencimientos('Concepto');
    }, [values.concepto]);

    useEffect(() => {
        actualizaApuntes('Total');
        actualizaVencimientos('Total');
    }, [values.total]);

    useEffect(() => {
        actualizaApuntes('Subcuenta');
        actualizaVencimientos('Subcuenta');
    }, [JSON.stringify(values.subcuenta)]);

    useEffect(() => {
        actualizaApuntes('Fecha');
        actualizaVencimientos('Fecha');
    }, [values.fecha]);

    useEffect(() => {
        actualizaVencimientos('Registro');
    }, [values.registro]);

    useEffect(() => {
        actualizaApuntes('Partidas');
    }, [JSON.stringify(values.partidas)]);

    useEffect(() => {
        actualizaVencimientos('FormaPago');
    }, [JSON.stringify(values.formaPago)]);

    const buildConcepto = () => {
        let concepto = 'Fra.';

        if (values.proveedor)
            concepto = concepto + ' ' + values.proveedor.nombre;
        if (values.documento)
            concepto = concepto + ' número ' + values.documento;
        if (values.fechaDocumento)
            concepto =
                concepto +
                ' del ' +
                DateTime.fromISO(values.fechaDocumento).toFormat('dd/MM/yyyy');

        return concepto;
    };

    const actualizaRegistro = () => {
        if (editing) {
            const action = () => {
                const accion = {
                    api: 'gastos/nexts',
                    metodo: 'post',
                    data: {
                        fields: ['registro'],
                        values: { ...values }
                    }
                };
                execAction(accion, [reload]);
            };

            timeBoxRegistro > 0 && clearTimeout(timeBoxRegistro);
            timeBoxRegistro = setTimeout(action, 250);
        }
    };

    const isReadyApuntes = () => {
        if (!values.concepto) return false;
        if (!values.fecha) return false;
        if (!values.subcuenta) return false;
        if (!values.total) return false;
        if (!values.partidas) return false;

        return true;
    };

    const actualizaApuntes = emisor => {
        if (editing && isReadyApuntes()) {
            const action = () => {
                //console.log('TabApuntes',emisor)
                const accion = {
                    api: 'gastos/asiento',
                    metodo: 'post',
                    data: { ...values }
                };
                execAction(accion, [reload]);
            };

            timeBoxApuntes > 0 && clearTimeout(timeBoxApuntes);
            timeBoxApuntes = setTimeout(action, 800);
        }
    };

    const isReadyVencimientos = () => {
        if (!values.concepto) return false;
        if (!values.fecha) return false;
        if (!values.registro) return false;
        if (!values.subcuenta) return false;
        if (!values.total) return false;
        if (!values.fechaDocumento) return false;
        if (!values.documento) return false;
        if (!values.formaPago) return false;

        return true;
    };

    const actualizaVencimientos = emisor => {
        if (editing && isReadyVencimientos()) {
            const action = () => {
                //console.log('TabVencimientos',emisor)
                const accion = {
                    api: 'gastos/vencimientos',
                    metodo: 'post',
                    data: { ...values }
                };
                execAction(accion, [reload]);
            };

            timeBoxVencimientos > 0 && clearTimeout(timeBoxVencimientos);
            timeBoxVencimientos = setTimeout(action, 800);
        }
    };

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='fecha'
                        label='Fecha'
                        value={values.fecha}
                        type='date'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='registro'
                        label='Número Registro'
                        value={values.registro}
                        onChange={handleChange}
                        editing={editing}
                        type={`y${DateTime.fromISO(values.fecha).year}.5`}
                        nextValue={adding && actualizaRegistro}
                    />
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs>
                    <Select
                        fullWidth
                        name='tipo'
                        label='Tipo'
                        value={values.tipo}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.tiposGasto}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='fechaDocumento'
                        label='Fecha Documento'
                        value={values.fechaDocumento}
                        type='date'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='documento'
                        label='Documento'
                        value={values.documento}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboProveedores
                        fullWidth
                        api='proveedores'
                        name='proveedor'
                        nameKey='proveedorid'
                        label='Proveedor'
                        relacionado={{
                            Detail: DetailProveedor,
                            resume: ['nombre'],
                            readOnly: false
                        }}
                        refresh={refresh}
                        Icon={icons.ProveedoresIcon}
                        value={values.proveedor}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `[${option.nif}]-${option.nombre}`
                        }
                    />
                </Grid>
                <Grid item xs>
                    <ComboSubcuentas
                        fullWidth
                        api='subcuentas/activas'
                        name='subcuenta'
                        nameKey='subcuentaid'
                        label='Cuenta Contable'
                        relacionado={{
                            Detail: DetailSubcuenta,
                            resume: ['descripcion'],
                            readOnly: false
                        }}
                        refresh={refresh}
                        Icon={icons.CuentasIcon}
                        value={values.subcuenta}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `${option.id} ${option.descripcion}`
                        }
                    />
                </Grid>
                <Grid item xs>
                    <ComboFormaPago
                        fullWidth
                        api='formaspago'
                        name='formaPago'
                        nameKey='formaPagoid'
                        label='Forma de Cobro'
                        relacionado={{
                            maxWidth: 'md',
                            Detail: DetailFormaPago,
                            resume: ['descripcion'],
                            readOnly: false
                        }}
                        refresh={refresh}
                        Icon={icons.FormasPagoIcon}
                        value={values.formaPago}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option.descripcion}`}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='concepto'
                        label='Concepto'
                        value={values.concepto}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Partidas',
                        icon: icons.LaboralIcon,
                        component: <Partidas {...props} />,
                        disabled: false
                    },
                    {
                        label: 'Apuntes',
                        icon: icons.AsientosIcon,
                        component: <Apuntes {...props} />,
                        disabled: false
                    },
                    {
                        label: 'Vencimientos',
                        icon: icons.VencimientosIcon,
                        component: <Vencimientos {...props} />,
                        disabled: false
                    },
                    {
                        label: 'Documentos',
                        icon: icons.DocumentIcon,
                        component: (
                            <Documents
                                name='documentacion'
                                editing={editing}
                                onChange={handleChange}
                                rows={
                                    values.documentacion
                                        ? values.documentacion
                                        : []
                                }
                                maxHeight={456}
                            />
                        ),
                        disabled: false
                    }
                ]}
            />
        </form>
    );
};

export default Detalle;
