import React, { useEffect } from 'react';

import { Grid } from '@mui/material';

import {
    Switch,
    DataGridPro as DataGrid,
    Tag,
    ViewEntity
} from 'core/components';

import * as icons from 'core/icons';
import * as helpers from 'core/helpers';

import DetailParcela from '../../../Parcela';
import { useSelector } from 'react-redux';
import { ENTITY_PARCELA_ACTA } from '../../../../../../store/entities/constants';

const cols = (row, entityData) => [
    {
        key: 'parcela',
        title: 'Parcela',
        align: 'left',
        width: '35%',
        component:
            row &&
            `${row.municipio.descripcion}-${row['poligono']}-${row['parcela']}-${row['recinto']}-${row.variedad.descripcion}-${row.año}`
    },
    {
        key: 'superficie',
        title: 'Superficie',
        align: 'right',
        width: '5%',
        component: row && helpers.getValueFormatted('d.4', row.superficie)
    },
    {
        key: 'marcox',
        title: 'x (m)',
        align: 'right',
        width: '4%',
        component: row && helpers.getValueFormatted('d.2', row.marcox)
    },
    {
        key: 'marcoy',
        title: 'y (m)',
        align: 'right',
        width: '4%',
        component: row && helpers.getValueFormatted('d.2', row.marcoy)
    },
    {
        key: 'densidad',
        title: 'Densidad',
        align: 'right',
        width: '6%',
        component: row && helpers.getValueFormatted('entero', row.densidad)
    },
    {
        key: 'sistemaRiego',
        title: 'S. Riego',
        align: 'center',
        width: '17%',
        component: row && row.sistemaRiego && row.sistemaRiego.descripcion
    },
    {
        key: 'conduccion',
        title: 'Conducción',
        align: 'center',
        width: '12%',
        component: row && row.conduccion && <>{row.conduccion.descripcion}</>
    },
    {
        key: 'modoCultivo',
        title: 'Cultivo',
        align: 'center',
        width: '12%',
        component: row && row.modoCultivo && <>{row.modoCultivo.descripcion}</>
    },
    {
        key: 'observaciones',
        title: 'Incidencias',
        align: 'center',
        width: '5%',
        component: row && (
            <Switch
                value={
                    (row.notas && row.notas !== '') ||
                    (row.incidencias && row.incidencias.length !== 0)
                }
                name='incidencias'
                editing={false}
            />
        )
    }
];

const Parcelas = props => {
    const { values, editing, adding, handleChange, refresh, execAction } =
        props;
    const entityData = useSelector(
        state => state.entities[ENTITY_PARCELA_ACTA]
    );

    return (
        <form autoComplete='off' noValidate>
            <Grid sx={{ marginTop: '10px' }} container spacing={2}>
                <Grid item xs>
                    <DataGrid
                        name='parcelas'
                        editing={editing}
                        rows={values.parcelas ? values.parcelas : []}
                        cols={(name, row, index) => cols(row, entityData)}
                        onDelete={(name, index, row) => {
                            execAction(
                                {
                                    api: entityData.api,
                                    metodo: 'delete',
                                    data: [row.id]
                                },
                                [() => refresh(false)]
                            );
                        }}
                        //maxHeight={456}
                    >
                        <ViewEntity
                            key={ENTITY_PARCELA_ACTA}
                            refresh={refresh}
                            entity={entityData}
                            initValue={{
                                acta: values
                            }}
                        >
                            {DetailParcela}
                        </ViewEntity>
                    </DataGrid>
                </Grid>
            </Grid>
        </form>
    );
};

export default Parcelas;
