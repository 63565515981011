import React, { useEffect } from 'react';
import List from '../../store/List';
import Auth from '../../store/Auth';
import { Emit, Experiment, ViewEntity } from '../../core/components';
import { ENTITY_BODEGA } from 'store/entities/constants';
import { useSelector } from 'react-redux';
import Detalle from 'views/maestros/Bodegas/Detalle';

const EtiquetaImpresion = props => {
    const { viticultores } = props;
    useEffect(() => {
        viticultores.load('viticultores');

        return viticultores.unload;
    }, []);

    const entityData = useSelector(state => state.entities[ENTITY_BODEGA]);

    if (!viticultores.isReady) return null;

    return (
        <div>
            <ViewEntity
                id={'new'}
                open={false}
                entity={entityData}
                initValue={{ nombre: 'periquito' }}
            >
                {Detalle}
            </ViewEntity>
            <Emit
                disabled={false}
                hidden={false}
                toolTipText={'Emitir Etiquetas Viticultores'}
                formularios={[{ formulario: 'Apli' }]}
                values={viticultores.list}
            />
            <Emit
                disabled={false}
                hidden={false}
                toolTipText={'Emitir Cartas Viticultores'}
                formularios={[{ formulario: 'Carta' }]}
                values={viticultores.list}
            />
        </div>
    );
};

const EtiquetaImpresionContainer = List(
    Auth(EtiquetaImpresion, 'auth'),
    'viticultores',
    'viticultores'
);
export default EtiquetaImpresionContainer;
