import React, { useState } from 'react';

import { makeStyles } from 'core/components/library';
import { Divider, Grid, Typography } from 'core/components/library';

import * as icons from 'core/icons';
import * as enums from 'core/enums';

import { getAvatar, now } from 'core/helpers';
import List from 'store/List';
import {
    Combo,
    Select,
    Tag,
    Text,
    Switch,
    Documents,
    Tabs
} from 'core/components';

import DetailTramite from 'views/tramites/Tramites/Detalle';
import DetailUsuario from 'views/usuarios/Detalle';
import * as hlprs from '../../../../core/components/helpers';
import Detalles from './Tabs/TabDetalles';
import Diario from './Tabs/TabDiario';

const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '15px'
    }
}));

const ComboTramites = List(Combo, 'options', 'lsttramites');

const Detalle = props => {
    const { auth, values, initial, editing, adding, handleChange, refresh } =
        props;
    const classes = useStyles();

    return (
        <form autoComplete='on' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Tag
                        //variant="outlined"
                        //size={'small'}
                        sx={{ marginTop: '10px' }}
                        hidden={!values.propietario}
                        value={values.propietario && values.propietario.nombre}
                        id={values.propietario && values.propietario.id}
                        //helperText="Creador de la Tarea"
                        avatar={
                            values.propietario &&
                            getAvatar(
                                values.propietario.id,
                                values.propietario.avatar
                            )
                        }
                        relacionado={{
                            Detail: DetailUsuario,
                            resume: ['nombre'],
                            api: 'usuarios',
                            readOnly: true
                        }}
                        refresh={refresh}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='codigo'
                        label='Código'
                        //helperText="Fecha de alta en la Organización"
                        value={values.codigo}
                        type='text'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Select
                        fullWidth
                        name='estado'
                        label='Estado'
                        //helperText="Estado de la Tarea"
                        value={values.estado}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.statesTask}
                    />
                </Grid>
                <Grid item xs>
                    <Select
                        fullWidth
                        name='validacion'
                        label='Validación'
                        //helperText="Estado de validación de la Tarea"
                        value={values.validacion}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.validationsTask}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Text
                        fullWidth
                        name='fecha'
                        label='Fecha'
                        //helperText="Fecha de alta en la Organización"
                        value={hlprs.getValueFormatted(
                            'datetime-local',
                            values.fecha
                        )}
                        type='datetime-local'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <ComboTramites
                        fullWidth
                        api={'tramites'}
                        name='tramite'
                        nameKey='tramiteid'
                        label='Tramite'
                        relacionado={{
                            Detail: DetailTramite,
                            resume: ['codigo'],
                            readOnly: true
                        }}
                        refresh={refresh}
                        Icon={icons.TramitesIcon}
                        value={values.tramite}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `[${option.codigo}]-${option.concepto}`
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <Select
                        fullWidth
                        name='prioridad'
                        label='Prioridad'
                        //helperText="Prioridad de la Tarea"
                        value={values.prioridad}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.prioridades}
                    />
                </Grid>
                <Grid item xs>
                    <div className={classes.wrapper}>
                        <Switch
                            value={values.urgente}
                            onChange={handleChange}
                            name='urgente'
                            label='Urgente'
                            editing={editing}
                        />
                        <Switch
                            value={values.archivada}
                            onChange={handleChange}
                            name='archivada'
                            label='Archivada'
                            editing={editing}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='resumen'
                        label='Resumen'
                        //helperText="Resumen o titulo"
                        value={values.resumen}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Detalles',
                        icon: icons.DetailIcon,
                        component: <Detalles {...props} />
                    },
                    {
                        label: 'Diario',
                        icon: icons.TableroIcon,
                        component: <Diario {...props} />,
                        disabled: false
                    }
                ]}
            />
        </form>
    );
};

export default Detalle;
