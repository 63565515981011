import React from 'react';

import { makeStyles } from '../library';
import {
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemIcon,
    ListItemText
} from '../library';

const useStyles = makeStyles(theme => ({
    root: {}
}));

const applyFormat = (value, format) => {
    if (format) return format(value);

    return value;
};

const ListBox = props => {
    const classes = useStyles();
    const {
        primary,
        secondary,
        label,
        hidden,
        options,
        action,
        Icon,
        IconAction,
        format
    } = props;

    if (hidden || !options) return null;

    if (options.length === 0) return null;

    return (
        <div>
            <Typography variant='h6' className={classes.title}>
                {label}
            </Typography>

            <List dense>
                {options.map(item => (
                    <ListItem>
                        {Icon && (
                            <ListItemIcon>
                                <Icon fontSize='small' />
                            </ListItemIcon>
                        )}
                        <ListItemText
                            primary={
                                primary
                                    ? applyFormat(item[primary], format)
                                    : applyFormat(item, format)
                            }
                            secondary={secondary ? item[secondary] : null}
                        />
                        {action && (
                            <ListItemSecondaryAction>
                                <IconButton edge='end' onClick={action}>
                                    <IconAction />
                                </IconButton>
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default ListBox;
