//PRUEBA INICIAL
//DEPRECATED
//MATENEMOS POR COMPATIBILIDAD CON LOS REPORTES EXISTENTES
//QUE VIENEN DESDE EL SERVIDOR

import React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    Image,
    Font,
    Svg,
    Line,
    StyleSheet
} from '@react-pdf/renderer';
import config from '../../settings/environment';
import { DateTime } from 'luxon';
import { getValueFormatted } from '../../core/helpers';
import { Typography } from '../../core/components/library';

let acumulados = null;
let acumuladosGrupo = null;

const Report = props => {
    const { report, data, groups } = props;

    if (!data)
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}
            >
                <Typography variant={'h3'}>
                    Cargando Informe. Espere por favor ........
                </Typography>
            </div>
        );

    return (
        <Document>
            {/*
                {data.list.map((row, index) => {
                    anterior= haber;
                    debe = debe + Number(row.debe);
                    haber = haber + Number(row.haber);
                    saldo = saldo + (Number(row.debe) - Number(row.haber));
                    return (
                        <>
                            <Text style={[styles.acumulado]}>
                                {anterior}
                            </Text>


                            <Text style={[styles.suma]}>
                                {haber}
                            </Text>
                            <View break></View>

                        </>
                    );
                })}

                */}
            {getPages(report.pages, data, groups)}
        </Document>
    );
};

export default Report;

const getPages = (pages, data, groups) => {
    return pages.map(page => (
        <Page
            wrap={page.wrap}
            style={[
                getStyle('page'),
                { padding: page.margin, paddingBottom: page.bottom }
            ]}
            size={page.size}
            orientation={page.orientation}
        >
            <View fixed>
                {getSections(page.headers)}
                {getLine()}
            </View>

            <View fixed>{getFilters(data.filters)}</View>

            {page.body.map(body => getColHeaders(body))}

            {!data ? (
                getSections(page.body)
            ) : groups.length === 0 ? (
                <>
                    {data.list.map((record, index) => (
                        <>{getSections(page.body, undefined, record)}</>
                    ))}
                    {getLine()}
                    {acumuladosGrupo && page.resume && getSections(page.resume)}
                    {getLine()}
                </>
            ) : (
                <>
                    {groups.map((group, index) =>
                        group.values.map((value, index) => (
                            <>
                                <View
                                    style={[
                                        getStyle(group.style),
                                        {
                                            display: 'flex',
                                            flexDirection: group.direction,
                                            width: group.width
                                                ? group.width
                                                : '100%',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center'
                                        }
                                    ]}
                                >
                                    {getSections(
                                        group.sections,
                                        { group: group },
                                        data.list.filter(
                                            item => item.subcuenta.id === value
                                        )[0]
                                    )}
                                </View>
                                {getLine()}
                                {data.list
                                    .filter(item => item.subcuenta.id === value)
                                    .map((record, index) => (
                                        <>
                                            {getSections(
                                                page.body,
                                                { group: group },
                                                record
                                            )}
                                        </>
                                    ))}
                                {getLine()}
                                {acumuladosGrupo &&
                                    page.resume &&
                                    getSections(page.resume, {
                                        group: group
                                    })}
                                {(acumuladosGrupo = null)}
                                {getLine()}
                                {group.newPage && <View break />}
                            </>
                        ))
                    )}
                    {getLine()}
                    {acumulados && page.resume && getSections(page.resume)}
                    {getLine()}
                </>
            )}

            <View
                fixed
                style={[
                    {
                        padding: page.margin,
                        paddingBottom: 15,
                        position: 'absolute',
                        bottom: 10,
                        left: 0,
                        right: 0
                    }
                ]}
            >
                {getLine()}
                {getSections(page.footers)}
            </View>

            <Text
                style={[styles.pageNumber, { right: page.margin }]}
                render={({ pageNumber, totalPages }) =>
                    `Página ${pageNumber}/${totalPages}`
                }
                fixed
            />
        </Page>
    ));
};

const getSections = (sections, props, data, style) => {
    return sections.map(item => {
        return (
            <View
                style={[
                    getStyle(style ? style : item.style),
                    {
                        display: 'flex',
                        flexDirection: item.direction,
                        width: item.width ? item.width : '100%',
                        justifyContent: 'space-evenly',
                        alignItems: 'center'
                    }
                ]}
            >
                {item.sections
                    ? getSections(item.sections, props, data)
                    : getSection(false, item, props, data)}
            </View>
        );
    });
};

const getColHeaders = cols => {
    return [
        <View
            fixed
            style={[
                getStyle(`${cols.style}.cols`),
                {
                    display: 'flex',
                    flexDirection: cols.direction,
                    width: cols.width ? cols.width : '100%',
                    justifyContent: 'space-evenly',
                    alignItems: 'center'
                }
            ]}
        >
            {cols.sections.map(item => (
                <View
                    style={[
                        getStyle(item.style),
                        {
                            display: 'flex',
                            flexDirection: item.direction,
                            width: item.width ? item.width : '100%',
                            justifyContent: 'space-evenly',
                            alignItems: 'center'
                        }
                    ]}
                >
                    {getSection(true, item)}
                </View>
            ))}
        </View>,
        <View fixed>{getLine()}</View>
    ];
};

const getSection = (label, section, props, data) => {
    if (label) {
        return (
            <Text
                style={{
                    margin: 1,
                    textAlign: section.align,
                    width: '100%'
                }}
            >
                {section.label}
            </Text>
        );
    }

    if (section.img) {
        return (
            <Image
                style={getStyle(section.img.style)}
                src={`${config.servidor}:${config.puerto}/api/reports/images/${section.img.src}`}
            />
        );
    }

    return (
        <Text
            style={{
                margin: 1,
                textAlign: section.align,
                width: '100%',
                border: 0,
                borderColor: 'red'
            }}
        >
            {getValue(section, props, data)}
        </Text>
    );
};

const getValue = (section, props, data) => {
    if (section.acumulate) {
        if (props && props.group) {
            return applyFormat(
                acumuladosGrupo[section.acumulate].value,
                section.format
            );
        }
        return applyFormat(acumulados[section.acumulate].value, section.format);
    }

    if (section.formula) {
        return applyFormat(
            applyFormula(
                section.field,
                section.formula,
                props,
                data,
                section.resume
            ),
            section.format
        );
    }

    if (section.field) {
        return applyFormat(
            getField(section.field, data, section.resume),
            section.format
        );
    }

    let texto = section.text;
    if (props && props.group) texto = texto + ' ' + props.group.key;
    return applyFormat(texto, section.format);
};

const getField = (field, data, resume) => {
    let value = data;
    const properties = field.toString().split('.');

    properties.forEach(property => {
        try {
            value = value[property];
        } catch (e) {
            console.log(data, field);
        }
    });

    if (resume) setResume(field, value);

    return value;
};

const applyFormat = (value, format) => {
    if (!format) return value;

    return getValueFormatted(format, value);
};

const applyFormula = (field, formula, props, data, resume) => {
    const partsFormula = formula.substring(0, formula.length - 1).split('(');
    const functionName = partsFormula[0];
    const parametros = partsFormula[1]
        ? partsFormula[1].toString().split(',')
        : [];

    switch (functionName.toLowerCase()) {
        case 'function.now':
            return DateTime.now().toString();

        case 'function.numpagina':
            if (props)
                return `Página ${props.pageNumber} de ${props.totalPages}`;
            else return 'Pagina';

        case 'function.sum':
            let sum = 0;

            parametros.forEach(op => {
                sum = sum + Number(getOperator(op, data, false));
            });

            if (resume) setResume(field, sum);

            return sum;

        case 'function.accumulatesum':
            let accumulatesum = 0;

            parametros.forEach(op => {
                accumulatesum =
                    accumulatesum + Number(getOperator(op, data, false));
            });

            if (resume) setResume(field, accumulatesum);

            if (props && props.group) {
                return accumulatesum + acumuladosGrupo[field].anterior;
            }

            return accumulatesum + acumulados[field].anterior;

        default:
            return '#error#';
    }
};

const getOperator = (op, data, resume) => {
    let signo = 1;
    let operador = op;
    let value = '';

    if (operador.startsWith('-')) {
        signo = -1;
        operador = op.substring(1);
    }

    if (operador.startsWith('field')) {
        value = getField(operador.substring(6), data, resume);
    } else value = operador;

    return signo * Number(value);
};

const getLine = () => {
    const w = 900;

    return (
        <Svg
            height='5px'
            width='100%'
            style={{ marginTop: 5, border: 0, borderColor: 'red' }}
        >
            <Line x1='0' y1='0' x2={w} y2='0' strokeWidth={5} stroke='grey' />
        </Svg>
    );
};

const setResume = (field, value) => {
    const oldAcumulados = acumulados ? acumulados : {};
    const newResume = {
        ...oldAcumulados,
        [field]: {
            value: !oldAcumulados[field]
                ? Number(value)
                : Number(oldAcumulados[field].value) + Number(value),
            anterior: !oldAcumulados[field]
                ? 0
                : Number(oldAcumulados[field].value)
        }
    };

    acumulados = newResume;

    const oldAcumuladosGrupo = acumuladosGrupo ? acumuladosGrupo : {};
    const newResumeGrupo = {
        ...oldAcumuladosGrupo,
        [field]: {
            value: !oldAcumuladosGrupo[field]
                ? Number(value)
                : Number(oldAcumuladosGrupo[field].value) + Number(value),
            anterior: !oldAcumuladosGrupo[field]
                ? 0
                : Number(oldAcumuladosGrupo[field].value)
        }
    };

    acumuladosGrupo = newResumeGrupo;
};

const getStyle = style => {
    if (!style) return null;

    let newStyle = styles;
    const properties = style.toString().split('.');

    properties.forEach(property => {
        newStyle = newStyle[property];
    });

    return newStyle;
};

const getFilters = filters => {
    const keys = Object.keys(filters);

    if (keys.length === 0) return null;

    return (
        <>
            <View
                style={[
                    getStyle('header.filter'),
                    {
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }
                ]}
            >
                {keys.map(key => {
                    let campos = getFilterFields(filters[key]);
                    let operador = getFilterOperator(
                        filters[key][0].op,
                        campos.length
                    );
                    const rango = filters[key][0].valor.split('*');
                    let valor = '';
                    if (rango.length === 1) {
                        valor =
                            '"' +
                            filters[key][0].valor +
                            /*
                            getValueFormatted(
                                filters[key][0].tipo,
                                filters[key][0].valor
                            )*/ '"';
                    } else {
                        valor = '[';

                        rango.forEach((v, index) => {
                            if (index > 0) valor = valor + ' - ';

                            if (v !== '') {
                                valor = valor + '"' + v + '"';
                            }
                        });

                        valor = valor + ']';
                    }

                    campos = campos.toString();

                    /*
                    if (filters[key][0].tipo === 'date') {
                        campos = '';
                    }*/

                    if (filters[key][0].tipo === 'boolean') {
                        if (valor === false) campos = 'no ' + campos;
                        operador = '';
                        valor = '';
                    }
                    return (
                        <Text
                            style={{
                                borderRadius: 4,
                                marginTop: 2,
                                maxWidth: getFilterWidth(keys.length),
                                padding: 3,
                                marginRight: 4,
                                textAlign: 'center',
                                backgroundColor: 'lightgrey'
                            }}
                        >
                            {campos + operador + valor}
                        </Text>
                    );
                })}
            </View>
            {getLine()}
        </>
    );
};

const getFilterWidth = num => {
    if (num === 1) return '100%';

    if (num === 2) return '75%';

    if (num === 3) return '50%';

    return '35%';
};

const getFilterOperator = (operator, num) => {
    console.log(operator);
    switch (operator) {
        //case '=':
        //    return `${num === 1 ? 'es' : 'son'} igual a `;

        case '<=':
            return 'hasta: ';

        case '>=':
            return 'desde: ';

        //case 'in':
        default:
            return `: `;

        //return ` ${num === 1 ? 'contiene' : 'contienen'} `;
    }
};

const getFilterFields = fields => {
    let campos = [];

    fields.forEach(f => {
        if (Array.isArray(f.campo)) {
            //console.log(campos,f.campo[0])
            if (!campos.some(i => i === f.campo[0])) {
                campos.push(f.campo[0]);
            }
        } else {
            if (!campos.some(i => i === f.campo)) {
                campos.push(f.campo);
            }
        }
    });
    console.log(campos);
    return campos;
};

Font.register({
    family: 'Lucida Console, Courier, monospace',
    src: 'https://fonts.cdnfonts.com/s/56356/lucon.woff'
});

const styles = StyleSheet.create({
    page: {
        //fontFamily: 'Lucida Console, Courier, monospace',
        //padding:15,
        //paddingBottom:'1.8cm',
    },
    header: {
        border: 0,
        //padding:2,
        textAlign: 'left',
        title: {
            padding: 2,
            border: 1,
            borderRadius: 2,
            borderColor: 'grey',
            fontWeight: 'bold',
            fontSize: 18
        },
        subtitle: {
            marginTop: 5,
            padding: 2,
            border: 1,
            borderColor: 'grey',
            borderRadius: 2,
            color: 'black',
            fontWeight: 'bold',
            fontSize: 10
        },
        filter: {
            //padding:2,
            //border:1,
            //color:'black',
            //fontWeight: 'bold',
            fontSize: 8
        },
        image: {
            maxWidth: '80px',
            maxHeight: '80px'
        }
    },
    body: {
        fontSize: 8,
        paddingTop: 2,
        paddingBottom: 2,
        cols: {
            fontSize: 9,
            fontWeight: 900
        }
    },
    resume: {
        padding: 2,
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'right'
    },
    group: {
        padding: 1,
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'left'
    },
    footer: {
        fontWeight: 'bold',
        fontSize: 10,
        textAlign: 'center'
    },
    text: {
        margin: 12,
        fontSize: 7,
        textAlign: 'justify'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 25,
        left: 0,
        right: 10,
        textAlign: 'right'
        //color: 'grey'
    },
    cumulativeSum: {
        position: 'absolute',
        fontSize: 9,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey'
    },
    previousValues: {
        //position: 'absolute',
        fontSize: 9,
        //top: 150,
        //bottom: 0,
        left: 150,
        textAlign: 'center',
        color: 'red',
        opacity: 1
    }
});
