import React, { useEffect } from 'react';

import { Divider, Grid } from '@mui/material';

import {
    Text,
    Button,
    Select,
    Switch,
    Tabs,
    Documents,
    Tag,
    Combo
} from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';

import Detalles from './Tabs/TabDetalles';
//import Estado from './Tabs/Estado';
import Etiquetas from './Tabs/TabEtiquetas';
import { statesEntity } from 'core/enums';
import DetailBodega from '../../Bodegas/Detalle';
import List from '../../../../store/List';
import { ENTITY_BODEGA } from '../../../../store/entities/constants';

const ComboBodegas = List(Combo, 'options', 'lstbodegas');

const Explotacion = props => {
    const { values, editing, handleChange, refresh, related } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs={1}>
                    <Text
                        fullWidth
                        name='codigo'
                        label='Código Operador'
                        value={values.codigo}
                        onChange={handleChange}
                        editing={editing}
                        type='0.5'
                    />
                </Grid>
                <Grid item xs>
                    {!related && (
                        <ComboBodegas
                            fullWidth
                            api='bodegas'
                            name='bodega'
                            nameKey='bodegaid'
                            label='Bodega'
                            relacionado={{
                                type: ENTITY_BODEGA,
                                Detail: DetailBodega,
                                resume: ['nombre'],
                                readOnly: false
                            }}
                            refresh={refresh}
                            Icon={icons.CellarIcon}
                            value={values.bodega}
                            onChange={handleChange}
                            editing={editing}
                            propertyValue='id'
                            getOptionLabel={option => `${option.nombre}`}
                            searchKeys={['nombre']}
                        />
                    )}
                </Grid>
                <Grid item xs>
                    <Select
                        fullWidth
                        name='sistemaExplotacion'
                        label='Sistema Explotación'
                        value={values.sistemaExplotacion}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.sistemasExplotacion}
                    />
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs>
                    <Tag
                        variant={'outline'}
                        align={'center'}
                        size={'medium'}
                        sx={{
                            color: enums.getItem(
                                statesEntity,
                                values.estado,
                                'color'
                            )
                        }}
                        Icon={enums.getItem(
                            statesEntity,
                            values.estado,
                            'icon'
                        )}
                        value={values.estado}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='nombre'
                        label='Nombre'
                        value={values.nombre}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Divider style={{ margin: '12px 12px 0px 12px' }} />
                        <Grid item>
                            <Text
                                fullWidth
                                multiline
                                rows={2}
                                name='direccion'
                                label='Dirección'
                                value={values.direccion}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Text
                                        fullWidth
                                        name='cp'
                                        label='Cód. Postal'
                                        value={values.cp}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        name='poblacion'
                                        label='Población'
                                        value={values.poblacion}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Text
                                fullWidth
                                name='provincia'
                                label='Provincia'
                                value={values.provincia}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Divider style={{ margin: '12px 12px 0px 12px' }} />
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        multiline
                                        rows={2}
                                        name='tlf'
                                        label='Tlfno.'
                                        value={values.tlf}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        multiline
                                        rows={2}
                                        name='fax'
                                        label='Fax'
                                        value={values.fax}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Text
                                fullWidth
                                name='email'
                                label='@'
                                value={values.email}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item>
                            <Text
                                fullWidth
                                name='web'
                                label='web'
                                value={values.web}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Detalles',
                        icon: icons.DetailIcon,
                        component: <Detalles {...props} />
                    },
                    /*
                    {
                        label: 'Estado',
                        icon: icons.LaboralIcon,
                        component: <Estado {...props} />,
                        disabled: false
                    },*/
                    {
                        label: 'Etiquetas',
                        icon: icons.LabelsIcon,
                        component: <Etiquetas {...props} />,
                        disabled: false
                    },
                    {
                        label: 'Documentos',
                        icon: icons.DocumentIcon,
                        component: (
                            <Documents
                                name='documentacion'
                                editing={editing}
                                onChange={handleChange}
                                rows={
                                    values.documentacion
                                        ? values.documentacion
                                        : []
                                }
                                maxHeight={456}
                            />
                        ),
                        disabled: false
                    }
                ]}
            />
        </form>
    );
};

export default Explotacion;
