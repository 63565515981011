import * as icons from 'core/icons';

const secretaria = {
    id: 'secretaria',
    title: 'Secretaria',
    type: 'group',
    collapse: true,
    locked: true,
    children: [
        {
            visible: false,
            id: 'registro',
            title: 'Registro',
            type: 'collapse',
            icon: icons.RegistroIcon,
            children: []
        }
    ]
};

export default secretaria;
