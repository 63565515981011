import React from 'react';
import { useSelector } from 'react-redux';

import { Crud } from 'core/components/Crud';
import Detalle from './Detalle';
import List from 'store/List';
import Auth from 'store/Auth';
import { Button } from '@mui/material';

import * as enums from 'core/enums';
import { ENTITY_BODEGA } from '../../../store/entities/constants';

const columns = items => [
    {
        titleFilter: 'Buscar',
        type: 'text',
        width: '3',
        filter: valor =>
            items.filter(
                ['nombre', 'nombreComercial', 'nif', 'direccion', 'poblacion'],
                valor,
                '',
                'text'
            )
    },
    {
        titleFilter: 'Censo',
        type: 'text',
        width: 3,
        options: enums.censos.filter(c => c.value),
        filter: valor => items.filter(['censo'], valor, 'in', 'text')
    },
    {
        titleFilter: 'Tipo',
        type: 'text',
        width: 3,
        options: enums.tiposEmpresa.filter(c => c.value),
        filter: valor => items.filter(['tipoEmpresa'], valor, 'in', 'text')
    },
    {
        titleFilter: 'Estado',
        type: 'text',
        width: 2,
        options: enums.statesEntity.filter(c => c.value),
        filter: valor => items.filter(['estado'], valor, 'in', 'text')
    },
    {
        column: 'nombre',
        title: 'Razón Social',
        type: 'text',
        width: '20%'
    },
    {
        column: 'nombreComercial',
        title: 'Denominación',
        type: 'text',
        width: '20%'
    },
    {
        column: 'nif',
        title: 'NIF',
        type: 'text',
        width: '10%'
    },
    {
        column: 'direccion',
        title: 'Direccion',
        type: 'text',
        width: '30%'
    },
    {
        column: 'poblacion',
        title: 'Poblacion',
        type: 'text',
        width: '20%'
    }
];

const CrudContainer = List(Auth(Crud, 'auth'), 'entities', 'bodegas');

const Bodegas = props => {
    const { ...rest } = props;
    const entityData = useSelector(state => state.entities[ENTITY_BODEGA]);

    return (
        <CrudContainer
            single
            entityData={entityData}
            columns={columns}
            Detalle={Detalle}
            {...rest}
        />
    );
};

export default Bodegas;
