import React, { useEffect } from 'react';

import { Divider, Grid } from '@mui/material';

import {
    Text,
    Button,
    Select,
    Switch,
    Tabs,
    Documents,
    Combo
} from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import List from '../../../../../../store/List';

import DetailFormato from 'views/maestros/Etiquetas/Detalle';
import DetailMarca from '../../../../../maestros/Marcas/Detalle';
import { statesEntity } from 'core/enums';
const ComboEtiquetas = List(Combo, 'options', 'lstetiquetas');

const getNewValue = values => {
    if (values.estado === 'Finalizado') return values.newValue;

    if (!values.explotacion) return '';

    return JSON.stringify(values.explotacion, null, 4).replaceAll('null,', '');
};

const getOldValue = values => {
    return JSON.stringify(values.oldValue, null, 4);
};

const Formato = props => {
    const { values, editing, handleChange, refresh } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Grid container direction='column' spacing={2}>
                        <Grid item xs={12}>
                            <Select
                                fullWidth
                                name='estadoBodega'
                                label='Bodega'
                                value={values.estadoBodega}
                                onChange={handleChange}
                                editing={editing}
                                options={enums.filterValues(statesEntity, [
                                    null,
                                    'Activo',
                                    'Baja'
                                ])}
                            />
                        </Grid>
                        <Grid item xs>
                            <Select
                                fullWidth
                                name='estadoExplotacion'
                                label='Centro Explotación'
                                value={values.estadoExplotacion}
                                onChange={handleChange}
                                editing={editing}
                                options={enums.filterValues(statesEntity, [
                                    null,
                                    'Activo',
                                    'Baja'
                                ])}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid container direction='column' spacing={2}>
                        <Grid item xs>
                            <Select
                                fullWidth
                                name='elaboracion'
                                label='Elaboración'
                                value={values.elaboracion}
                                onChange={handleChange}
                                editing={editing}
                                options={enums.filterValues(statesEntity, [
                                    null,
                                    'Activo',
                                    'Baja'
                                ])}
                            />
                        </Grid>
                        <Grid item xs>
                            <Select
                                fullWidth
                                name='almacenamiento'
                                label='Almacenamiento'
                                value={values.almacenamiento}
                                onChange={handleChange}
                                editing={editing}
                                options={enums.filterValues(statesEntity, [
                                    null,
                                    'Activo',
                                    'Baja'
                                ])}
                            />
                        </Grid>
                        <Grid item xs>
                            <Select
                                fullWidth
                                name='crianza'
                                label='Crianza'
                                value={values.crianza}
                                onChange={handleChange}
                                editing={editing}
                                options={enums.filterValues(statesEntity, [
                                    null,
                                    'Activo',
                                    'Baja'
                                ])}
                            />
                        </Grid>
                        <Grid item xs>
                            <Select
                                fullWidth
                                name='embotellado'
                                label='embotellado'
                                value={values.embotellado}
                                onChange={handleChange}
                                editing={editing}
                                options={enums.filterValues(statesEntity, [
                                    null,
                                    'Activo',
                                    'Baja'
                                ])}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {values.tipo.modificacion && (
                    <Grid item xs>
                        <Text
                            fullWidth
                            multiline
                            rows={15}
                            name='newValue'
                            label='Datos actuales'
                            value={getOldValue(values)}
                        />
                    </Grid>
                )}
                <Grid item xs>
                    <Text
                        fullWidth
                        multiline
                        rows={15}
                        name='newValue'
                        label='Datos a registrar'
                        value={getNewValue(values)}
                        //onChange={handleChange}
                        //editing={false}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Formato;
