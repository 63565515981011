import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

//import { TemplateBerry as Template, layouts } from './templates/Berry';
import { Template, layouts } from './templates/GreenApple';
import { createRoutes } from './routes';

import Auth from 'store/Auth';

import CircularProgress from '@mui/material/CircularProgress';
import { Error } from './core/components';

function App(props: any) {
    const { auth } = props;

    useEffect(() => {
        auth.doInit();
    }, []);

    const state: any = useSelector(state => state);

    if (auth.isReady) {
        return (
            <Template
                routes={createRoutes(auth, layouts)}
                customization={state.customization}
            />
        );
    }

    if (!auth.errors) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}
            >
                <CircularProgress disableShrink={true} size={150} />
            </div>
        );
    } else {
        return <Error entities={[auth]} />;
    }
}

export const AppContainer = Auth(App, 'auth');

export default AppContainer;
