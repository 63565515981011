import React, { useEffect, useState } from 'react';

import { Divider, Grid } from 'core/components/library';
import { Text, Tabs, Combo, Documents, Links, Tag } from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import * as helpers from 'core/helpers';
import List from '../../../../store/List';
import { Chip } from '../../../../core/components/library';

import Detalles from './Tabs/TabDetalles';
import { locksParcel, statesEntity } from 'core/enums';
import DetailSocio from 'views/maestros/Socios/Detalle';
import { ENTITY_SOCIO } from '../../../../store/entities/constants';
import { LINK_SIGPAC } from '../../../../core/constants';

const ComboMunicipios = List(Combo, 'options', 'lstmunicipios');
const ComboDestinos = List(Combo, 'options', 'lstdestinosproduccion');
const ComboMarcos = List(Combo, 'options', 'lstmarcosplantacion');
const ComboPortaInjertos = List(Combo, 'options', 'lstportainjertos');
const ComboDerechos = List(Combo, 'options', 'lstderechos');
const ComboConducciones = List(Combo, 'options', 'lstconducciones');
const ComboCultivos = List(Combo, 'options', 'lstcultivos');
const ComboSistemasExplotacion = List(
    Combo,
    'options',
    'lstsistemasexplotacion'
);
const ComboRiegos = List(Combo, 'options', 'lstsistemasriego');
const ComboVariedades = List(Combo, 'options', 'lstvariedades');
const ComboSocios = List(Combo, 'options', 'lstsocios');

const getDesidad = values => {
    let newDensidad = 0;

    if (
        values.marcox &&
        values.marcoy &&
        Number(values.marcox) !== 0 &&
        Number(values.marcoy) !== 0
    ) {
        newDensidad = Math.round(
            10000 / (Number(values.marcox) * Number(values.marcoy))
        );
    }

    return newDensidad;
};

const getModoCultivo = values => {
    let newModoCultivo = null;

    if (values.densidad < 1500 && values.densidad >= 1100) {
        newModoCultivo = { id: 2, descripcion: 'EXTENSIVO', clave: 'E' };
    }

    if (values.densidad > 1900 && values.densidad <= 3350) {
        newModoCultivo = { id: 1, descripcion: 'INTENSIVO', clave: 'I' };
    }

    if (values.densidad < 1100 || values.densidad > 3350) {
        newModoCultivo = { id: 5, descripcion: 'NO DOP', clave: 'N' };
    }

    return newModoCultivo;
};

const Parcela = props => {
    const {
        values,
        editing,
        handleChange,
        handleError,
        refresh,
        reload,
        execAction,
        adding
    } = props;

    const [linkSigPac, setLinkSigPac] = useState([]);
    const [superficie] = useState(
        values.superficie ? Number(values.superficie.replace(',', '.')) : 0
    );

    useEffect(() => {
        if (editing) {
            handleChange([
                helpers.getValueControl('text', 'densidad', getDesidad(values))
            ]);
        }
    }, [values.marcox, values.marcoy]);

    useEffect(() => {
        if (editing) {
            handleChange([
                helpers.getValueControl(
                    'combo',
                    'modoCultivo',
                    getModoCultivo(values)
                )
            ]);
        }
    }, [values.densidad]);

    useEffect(() => {
        let link = '';

        if (values.municipio && values.poligono && values.parcela) {
            link = `${LINK_SIGPAC}?provincia=${values.municipio.ineProv}&municipio=${values.municipio.ine}&poligono=${values.poligono}&parcela=${values.parcela}`;

            if (values.recinto) {
                link = `${link}&recinto=${values.recinto}`;
            }

            setLinkSigPac([link]);
        } else {
            setLinkSigPac([]);
        }
    }, [
        JSON.stringify(values.municipio),
        values.poligono,
        values.parcela,
        values.recinto
    ]);

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboMunicipios
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Municipio'
                        api='auxiliares/municipios'
                        name='municipio'
                        nameKey='municipioId'
                        refresh={refresh}
                        Icon={icons.MunicipioIcon}
                        value={values.municipio}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        editing={editing}
                        name='poligono'
                        label='Poligono'
                        //variant='standard'
                        //margin='none'
                        type='entero'
                        value={values.poligono}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        editing={editing}
                        name='parcela'
                        label='Parcela'
                        //variant='standard'
                        //margin='none'
                        type='entero'
                        value={values.parcela}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        editing={editing}
                        name='recinto'
                        label='Recinto / Subparcela'
                        //variant='standard'
                        //margin='none'
                        //type='0.3'
                        value={values.recinto}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        required
                        fullWidth
                        /*error={
                            values.superficie &&
                            helpers.getNumero(values.superficie) !== 0 &&
                            Math.abs(
                                helpers.getNumero(values.superficie) -
                                    superficie
                            ) > 0.1 &&
                            'Cambio excesivo'
                        }*/
                        editing={editing}
                        name='superficie'
                        label='Superficie'
                        type='d.4'
                        value={values.superficie}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                        onError={handleError}
                    />
                </Grid>
                <Grid item xs></Grid>
                <Grid item>
                    <Links
                        toolTipText={'Abrir SIGPAC'}
                        icon={<icons.SigPacIcon />}
                        hidden={false}
                        disabled={linkSigPac.length === 0}
                        links={linkSigPac}
                    />
                </Grid>
                <Grid item>
                    <Tag
                        align={'center'}
                        size={'medium'}
                        sx={{
                            color: enums.getItem(
                                statesEntity,
                                values.estado,
                                'color'
                            )
                        }}
                        Icon={enums.getItem(
                            statesEntity,
                            values.estado,
                            'icon'
                        )}
                        value={`${values.estado}`}
                    />
                </Grid>
                {values.bloqueo && (
                    <Grid item>
                        <Tag
                            align={'center'}
                            size={'medium'}
                            sx={{
                                color: enums.getItem(
                                    locksParcel,
                                    values.bloqueo,
                                    'color'
                                )
                            }}
                            Icon={enums.getItem(
                                locksParcel,
                                values.bloqueo,
                                'icon'
                            )}
                            value={`${values.bloqueo}`}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboSocios
                        fullWidth
                        api='maestros/socios'
                        name='socio'
                        nameKey='socioid'
                        label='Socio'
                        relacionado={{
                            type: ENTITY_SOCIO,
                            Detail: DetailSocio,
                            resume: ['nombre'],
                            readOnly: false
                        }}
                        refresh={refresh}
                        Icon={icons.SociosIcon}
                        value={values.socio}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `${option.nombre}-[${option.codigo}]-[${option.nif}]`
                        }
                        searchKeys={['codigo', 'nombre', 'nif']}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        editing={editing}
                        name='paraje'
                        label='Paraje'
                        value={values.paraje}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Divider style={{ margin: '12px 12px 12px 12px' }}>
                <Chip label={'Plantación'} />
            </Divider>
            <Grid container spacing={1}>
                {/*<Grid item>
                    <Text
                        fullWidth
                        editing={false}
                        name='año'
                        label='Año'
                        type='entero'
                        value={values.año}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>*/}
                <Grid item xs>
                    <ComboCultivos
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Cultivo'
                        api='auxiliares/modoscultivo'
                        name='modoCultivo'
                        nameKey='modoCultivoId'
                        refresh={refresh}
                        value={values.modoCultivo}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item xs>
                    <ComboVariedades
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Variedad'
                        api='auxiliares/variedadesuva'
                        name='variedad'
                        nameKey='variedadId'
                        Icon={icons.VarietyIcon}
                        refresh={refresh}
                        value={values.variedad}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item xs>
                    <ComboRiegos
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Sistema Riego'
                        api='auxiliares/sistemasriego'
                        name='sistemaRiego'
                        nameKey='sistemaRiegoId'
                        refresh={refresh}
                        value={values.sistemaRiego}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item xs>
                    {/*<Text
                        fullWidth
                        editing={false}
                        name='fechaAlta'
                        label='Fecha Alta DOP'
                        type='date'
                        value={values.fechaAlta}
                        onChange={handleChange}
                    />*/}
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs>
                    {/*<Text
                        fullWidth
                        editing={false}
                        name='fechaBaja'
                        label='Fecha Baja DOP'
                        type='date'
                        value={values.fechaBaja}
                        onChange={handleChange}
                    />*/}
                </Grid>
                <Grid item>
                    {/*<Text
                        fullWidth
                        editing={false}
                        name='añoArranque'
                        label='Año Arranque'
                        type='entero'
                        value={values.añoArranque}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />*/}
                </Grid>
            </Grid>
            {/*<Grid container spacing={1}>
                <Grid item xs>
                    <ComboMarcos
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Tipo Marco'
                        api='auxiliares/marcoplantacion'
                        name='marcoPlantacion'
                        nameKey='marcoPlantacionId'
                        refresh={refresh}
                        value={values.marcoPlantacion}
                        onChange={handleChange}
                        editing={false}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        editing={false}
                        name='marcox'
                        label='x (m)'
                        type='d.2'
                        value={values.marcox}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        editing={false}
                        name='marcoy'
                        label='y (m)'
                        type='d.2'
                        value={values.marcoy}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        editing={false}
                        name='densidad'
                        label='Cepas / ha'
                        type='entero'
                        value={values.densidad}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>

            </Grid>*/}
            {/*<Grid container spacing={1}>
                <Grid item xs>
                    <ComboPortaInjertos
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Porta Injerto'
                        api='auxiliares/portainjertos'
                        name='portaInjertos'
                        nameKey='portaInjertosId'
                        refresh={refresh}
                        value={values.portaInjertos}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item xs>
                    <ComboConducciones
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Conducción'
                        api='auxiliares/formasconduccion'
                        name='conduccion'
                        nameKey='conduccionId'
                        refresh={refresh}
                        value={values.conduccion}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>

            </Grid>*/}
            <Divider style={{ margin: '12px 12px 12px 12px' }}>
                <Chip label={'Detalles'} />
            </Divider>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboSistemasExplotacion
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Sistemas Explotación'
                        api='auxiliares/sistemasexplotacion'
                        name='sistemaExplotacion'
                        nameKey='sistemaExplotacionId'
                        refresh={refresh}
                        value={values.sistemaExplotacion}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Text
                        fullWidth
                        editing={editing}
                        name='arrendatario'
                        label='Arrendatario / Agricultor'
                        //variant='standard'
                        //margin='none'
                        type='text'
                        value={values.arrendatario}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs>
                    <ComboDestinos
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Destino Producción'
                        api='auxiliares/destinoproduccion'
                        name='destinoProduccion'
                        nameKey='destinoProduccionId'
                        refresh={refresh}
                        value={values.destinoProduccion}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item xs>
                    <ComboDerechos
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Estado Derechos'
                        api='auxiliares/derechosviñedo'
                        name='derechosViñedo'
                        nameKey='derechosViñedoId'
                        refresh={refresh}
                        value={values.derechosViñedo}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Detalles',
                        icon: icons.DetailIcon,
                        component: <Detalles {...props} />,
                        hide: false
                    },
                    {
                        label: 'Documentos',
                        icon: icons.DocumentIcon,
                        component: (
                            <Documents
                                name='documentacion'
                                editing={editing}
                                onChange={handleChange}
                                rows={
                                    values.documentacion
                                        ? values.documentacion
                                        : []
                                }
                                minHeight={456}
                                maxHeight={456}
                            />
                        ),
                        hide: false
                    }
                ]}
            />
        </form>
    );
};

export default Parcela;
