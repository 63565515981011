import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Stack, Typography } from '@mui/material';

// project imports
import config from 'settings/environment';
//import Logo from '../../../ui-component/Logo';
import React from 'react';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={'/'}>
        <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={2}
        >
            <img
                alt='Logo'
                src={`${config.rutaImagenes}/logos/logo.png`}
                height='52'
                width='52'
            />
            <Typography variant={'h5'} color={'primary'}>
                IOPAS v.1.0.0
            </Typography>
        </Stack>
    </ButtonBase>
);

export default LogoSection;
