import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import Dropzone from 'react-dropzone';
import { Typography } from '@mui/material';

// Función para convertir el formato de la imagen capturada a Blob
const dataURItoBlob = dataURI => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    const file = new File([new Blob([ab], { type: mimeString })], 'image.png', {
        type: mimeString
    });
    return file;
};

const CameraCapture = props => {
    const { getFile } = props;
    const webcamRef = useRef(null);

    const capturePhoto = () => {
        const imageSrc = webcamRef.current.getScreenshot();

        getFile(dataURItoBlob(imageSrc), true);
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                height: '300px',
                padding: '10px',
                marginTop: '10px',
                border: 'dashed',
                cursor: 'copy',
                flexDirection: 'column'
            }}
        >
            <Webcam
                style={{
                    width: '100%',
                    height: '90%'
                }}
                audio={false}
                ref={webcamRef}
                screenshotFormat='image/jpeg'
            />
            <button onClick={capturePhoto}>Capturar foto</button>
        </div>
    );
};

const FileUpload = props => {
    const { getFile } = props;

    const handleDrop = acceptedFiles => {
        // Aquí puedes manejar los archivos cargados
        getFile(acceptedFiles[0], true);
    };

    return (
        <div>
            <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps }) => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '300px',
                            padding: '10px',
                            marginTop: '10px',
                            border: 'dashed',
                            cursor: 'copy'
                        }}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <Typography variant={'h5'}>
                            Arrastra y suelta aquí, o haz clic para capturar o
                            seleccionar archivos
                        </Typography>
                    </div>
                )}
            </Dropzone>
        </div>
    );
};

const Upload = props => {
    const { getFile } = props;
    const [camera, setCamera] = useState(false);

    if (camera) return <CameraCapture getFile={getFile} />;

    return <FileUpload getFile={getFile} />;
};

export default Upload;
