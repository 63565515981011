import React, { useState, useEffect } from 'react';

import { makeStyles } from '../library';
import {
    Auth,
    Autocomplete,
    CircularProgress,
    Entity,
    IconButton,
    TextField,
    Tooltip,
    Chip,
    createFilterOptions
} from '../library';
import { matchSorter } from 'match-sorter';

import { Detail, Element } from '../Crud';

import * as hlprs from '../helpers';
import { isConsole } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    root: {}
}));

const cargaOpciones = (lista, api, filters) => {
    if (lista.isInit !== undefined) {
        // Quiza deberiamos refrescar no ersta nada claro
        //console.log('Refresco?')
        return;
    }

    if (filters && filters.length === 0) return;

    lista.load(
        api,
        undefined,
        () => filters && setFilters(lista, filters) //lista.filter(filtro[0], filtro[1], 'in', 'text')
    );
};

const setFilters = (options, filters) => {
    filters.forEach(filter => {
        options.filter(
            filter.fields,
            filter.values,
            filter.operator,
            filter.type
        );
    });
};

const Combo = props => {
    const classes = useStyles();
    const {
        api,
        options,
        name,
        nameKey,
        //nameItem,
        label,
        helperText,
        value,
        error,
        required,
        onChange,
        onError,
        editing,
        multiple,
        propertyValue,
        getOptionLabel,
        getItem,
        Icon,
        filters,
        lockOption,
        colorOptionLocked,
        relacionado,
        refresh,
        fullWidth,
        rows,
        variant,
        margin,
        searchKeys,
        matchExact,
        limit
    } = props;

    //const fixedOptions = (multiple && lockProperty) ? value.filter(e => e[lockProperty]):[]

    useEffect(() => {
        return api ? options.unload : () => {};
    }, []);

    useEffect(() => {
        // Aplico el filtro
        if (!options.isInit && options.isReady)
            if (filters) {
                //options.filter(filtro[0], filtro[1], 'in', 'text');
                setFilters(options, filters);
            }
    }, [JSON.stringify(filters)]);

    const handleChange = (event, value) => {
        if (!onChange) return;

        let newValue;

        if (multiple) {
            if (!editing) return;

            newValue = [];

            value.forEach(item =>
                newValue.push(getItem ? getItem(item) : item)
            );
        } else {
            newValue = value !== null ? value[propertyValue] : null;
        }

        onChange([
            {
                field: name,
                value: {
                    selected: multiple ? null : value,
                    name: name,
                    nameKey: nameKey,
                    value: newValue
                }
            }
        ]);
    };

    const handleError = () => {
        if (error) {
            onError && onError(name, error);
            return true;
        }

        if (!value && required) {
            onError && onError(name, 'Valor Requerido');
            return true;
        }

        onError && onError(name, null);
        return false;
    };

    const reviewOptions = (event, value) => {
        if (fixedOptions.length === 0) return handleChange(event, value);

        return handleChange(event, [
            ...fixedOptions,
            ...value.filter((option, index) => !lockOption(option, index))
        ]);
    };

    const [fixedOptions] = useState(
        multiple && lockOption
            ? value.filter((e, index) => lockOption(e, index))
            : []
    );
    const [detail, setDetail] = useState(null);

    const lockRelacionado = () => {
        const alta = !multiple
            ? value === null || value === undefined
                ? true
                : false
            : true;

        if (!relacionado) return true;

        if (alta && !editing) return true;

        if (alta && relacionado.readOnly) return true;

        return false;
    };

    const openDetail = id => {
        const key = Date.now();
        const newId = !multiple
            ? value === null || value === undefined
                ? 'new'
                : value[propertyValue]
            : id === null || id === undefined
              ? 'new'
              : id;

        setDetail({
            type: relacionado.type,
            key: key,
            id: newId,
            title: relacionado.title ? relacionado.title : label,
            resume: relacionado.resume,
            api: (relacionado.api ? relacionado.api : api) + '/' + newId,
            container: Entity(Auth(Element, 'auth'), 'entity', key),
            element: relacionado.Detail,
            dfault: newId === 'new' ? relacionado.dfault : undefined,
            list: options
        });
    };

    const editDetail = (key, cancel, id, isEditable, entity) => {
        if (!isEditable) {
            if (!cancel) {
                // Refresco la lista o la cargo si estoy editando
                if (editing) {
                    // Refresco la lista de opciones
                    if (options.isInit === undefined)
                        cargaOpciones(options, api, filters);
                    else detail.list.refresh();

                    if (!multiple) {
                        if (value !== null && value !== undefined) {
                            // Modificacion: refresco solo al padre
                            refresh && refresh(true);
                        }

                        // Meto los cambios en el control
                        handleChange(undefined, entity);
                    } else {
                        let newArray = Array.isArray(value)
                            ? hlprs.clone(value)
                            : [];
                        if (
                            !newArray.some(item => item[propertyValue] === id)
                        ) {
                            // Es un alta añado
                            newArray.push(entity);

                            // Y si estoy filtrado???????????????
                        } else {
                            // Es una modificacion actualizo
                            newArray = newArray.map(listItem =>
                                listItem[propertyValue] === id
                                    ? { ...listItem, ...entity }
                                    : listItem
                            );

                            // Modificacion: refresco solo al padre
                            refresh && refresh(true);
                        }

                        handleChange(undefined, newArray);
                    }
                }
                // Refresco el elemento al que pertenezco y su padre
                else refresh && refresh(false);

                // Cierro el dialogo
                setDetail(null);
            }
        }
    };

    const deleteDetail = id => {
        if (editing) {
            let newArray = Array.isArray(value) ? hlprs.clone(value) : [];
            newArray = newArray.filter(
                listItem => listItem[propertyValue] !== id
            );

            handleChange(undefined, newArray);
        } else {
            refresh && refresh(false);
        }

        // Refresco la lista de opciones si es necesario
        if (options.isInit !== undefined) detail.list.refresh();

        // Cierro el diálogo
        setDetail(null);
    };

    /*
    const filterOptions = createFilterOptions({
        matchFrom: matchExact ? 'start' : 'any',
        limit: limit ? limit : undefined,
        //stringify: (option) => console.log(option)
    });
*/

    const isSimpleOptions = () => {
        if (options.length && options.length !== 0) {
            if (!options[0]) return true;
            if (typeof options[0] === 'string') return true;
        }

        return false;
    };

    const filterOptions = (options, { inputValue }) => {
        let newInputValue = inputValue;
        const keys = options.length === 0 ? [] : Object.keys(options[0]);

        if (newInputValue.includes('.') && !isNaN(Number(newInputValue))) {
            newInputValue = procesaSubcuenta(inputValue);
        }

        const optionsSearch = {
            keys: searchKeys ? searchKeys : keys,
            threshold: matchSorter.rankings.CONTAINS,
            sorter: rankItems => rankItems
        };

        //console.log(options.length!==0 ? getOptionLabel(): null)
        return matchSorter(options, newInputValue, optionsSearch);
    };

    const procesaSubcuenta = value => {
        console.log('llego alguna vez');
        const partes = value.split('.');

        if (partes.length > 1) {
            if (partes[1] !== '') {
                const sufijo = partes[1].split(' ')[0];
                const cadena = `${partes[0]}${'0'.repeat(
                    10 - partes[0].length - sufijo.length
                )}${sufijo}`;

                return cadena;
            }
        }

        return value;
    };

    return [
        <Autocomplete
            filterSelectedOptions
            filterOptions={isSimpleOptions() ? undefined : filterOptions}
            readOnly={!editing}
            multiple={multiple}
            value={
                value === undefined || value === null
                    ? multiple
                        ? []
                        : null
                    : value
            }
            onChange={reviewOptions}
            onOpen={event =>
                editing && api && cargaOpciones(options, api, filters)
            }
            options={api ? options.list : options}
            getOptionLabel={getOptionLabel}
            getOptionSelected={(option, value) => {
                return option[propertyValue] === value[propertyValue];
            }}
            forcePopupIcon={editing}
            disableClearable={!editing}
            clearOnEscape={editing}
            clearText='Borrar'
            closeText='Cerrar'
            openText='Abrir'
            loadingText='Cargando'
            noOptionsText={editing ? 'No encontrado' : ''}
            loading={api ? options.isInit : false}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        style={
                            colorOptionLocked &&
                            lockOption &&
                            (lockOption(option, index)
                                ? { backgroundColor: colorOptionLocked }
                                : {})
                        }
                        //variant='outlined'
                        color='primary'
                        size='small'
                        onClick={
                            relacionado &&
                            (event =>
                                openDetail(
                                    value[
                                        event.currentTarget.getAttribute(
                                            'data-tag-index'
                                        )
                                    ][propertyValue]
                                ))
                        }
                        label={getOptionLabel(option)}
                        {...getTagProps({ index })}
                        disabled={
                            lockOption && editing
                                ? lockOption(option, index)
                                : false
                        }
                    />
                ))
            }
            renderInput={params => (
                <TextField
                    {...params}
                    required={required ? true : false}
                    error={handleError()}
                    rows={rows && rows}
                    multiline={rows ? true : false}
                    label={label}
                    helperText={helperText}
                    variant={variant ? variant : 'outlined'}
                    size='small'
                    fullWidth={fullWidth}
                    margin={margin ? margin : 'dense'}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        ...params.InputProps,
                        //readOnly: !editing,
                        startAdornment: (
                            <React.Fragment>
                                <Tooltip
                                    title={
                                        value === null ||
                                        value === undefined ||
                                        multiple
                                            ? `Nuevo ${label}`
                                            : `Abrir ${label}`
                                    }
                                >
                                    <IconButton
                                        disabled={lockRelacionado()}
                                        style={{ padding: 0, marginRight: 2 }}
                                        size='small'
                                        onClick={event => openDetail()}
                                    >
                                        {Icon && <Icon color='primary' />}
                                    </IconButton>
                                </Tooltip>
                                {params.InputProps.startAdornment}
                            </React.Fragment>
                        ),
                        endAdornment: (
                            <React.Fragment>
                                {options.isInit ? (
                                    <CircularProgress
                                        disableShrink={true}
                                        color='inherit'
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />,
        <Detail
            maxWidth={
                relacionado && relacionado.maxWidth && relacionado.maxWidth
            }
            lite={relacionado && relacionado.lite && relacionado.lite}
            item={detail}
            open={detail !== null}
            close={() => setDetail(null)}
            edit={editDetail}
            del={deleteDetail}
            readOnly={relacionado && relacionado.readOnly}
        />
    ];
};

const procesaValor = value => {
    const partes = value.split('.');

    if (partes.length > 1) {
        if (partes[1] !== '') {
            const sufijo = partes[1].split(' ')[0];
            const cadena = `${partes[0]}${'0'.repeat(
                10 - partes[0].length - sufijo.length
            )}${sufijo}`;

            return cadena;
        }
    }

    return value;
};

export default Combo;
