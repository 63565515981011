import React from 'react';

import { Grid } from 'core/components/library';

import {
    Select,
    DataGridPro as DataGrid,
    Text,
    Tag,
    Switch,
    ViewEntity,
    Literal
} from 'core/components';

import * as enums from 'core/enums';

import * as helpers from '../../../../../../core/helpers';
import { ENTITY_RESOLUCIONES } from '../../../../../../store/entities/constants';
import DetailResolucion from '../../../../Resoluciones/Detalle';
import { useSelector } from 'react-redux';
import { resultsResolution, contactType } from '../../../../../../core/enums';

const cols = (row, entityData) => [
    {
        key: 'fecha',
        title: 'Fecha',
        align: 'left',
        width: '15%',
        component: row && (
            <>{helpers.getValueFormatted('datetime-base', row.fecha)}</>
        )
    },
    {
        key: 'concepto',
        title: 'Concepto',
        align: 'left',
        width: '60%',
        component: row && (
            <Literal text={row['concepto']}>
                <entityData.icon fontSize={'small'} />
            </Literal>
        )
    },
    {
        key: 'resultado',
        title: 'Resultado',
        align: 'center',
        width: '15%',
        component: row && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(
                        resultsResolution,
                        row.resultado,
                        'color'
                    )
                }}
                Icon={enums.getItem(resultsResolution, row.resultado, 'icon')}
                value={row.resultado}
            />
        )
    },
    {
        key: 'liquidar',
        title: 'Liquidar',
        align: 'center',
        width: '10%',
        component: row && (
            <Switch align={'center'} value={row.liquidar} name='liquidar' />
        )
    }
];

const Resoluciones = props => {
    const { values, editing, execAction, refresh, auth } = props;
    const entityData = useSelector(
        state => state.entities[ENTITY_RESOLUCIONES]
    );

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <DataGrid
                    readOnly={values.resolucion === 'Resuelto'}
                    name='resoluciones'
                    editing={editing}
                    rows={values.resoluciones ? values.resoluciones : []}
                    cols={(name, row, index) => cols(row, entityData)}
                    onDelete={(name, index, row) => {
                        execAction(
                            {
                                api: entityData.api,
                                metodo: 'delete',
                                data: [row.id]
                            },
                            [() => refresh(false)]
                        );
                    }}
                    //maxHeight={456}
                >
                    <ViewEntity
                        key={ENTITY_RESOLUCIONES}
                        refresh={refresh}
                        entity={entityData}
                        initValue={{
                            concepto: `Resolucion de ${values.tipo.descripcion} ${values.viticultor ? values.viticultor.nombre : ''}`,
                            tramite: {
                                id: values.id,
                                tipo: values.tipo,
                                viticultor: values.viticultor
                            }
                        }}
                    >
                        {DetailResolucion}
                    </ViewEntity>
                </DataGrid>
            </Grid>
        </Grid>
    );
};

export default Resoluciones;
