import * as icons from 'core/icons';

const seguimiento = {
    id: 'seguimiento',
    title: 'Seguimiento',
    //caption:'Este es el caption',
    type: 'group',
    visible: true,
    children: [
        {
            id: 'dashboard',
            title: 'Cuadro Mandos',
            type: 'item',
            url: '/seguimiento/dashboard',
            visible: true,
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'informes',
            title: 'Informes',
            type: 'item', //'collapse',
            url: '/seguimiento/informes',
            icon: icons.InformesIcon,
            visible: false,
            breadcrumbs: false
        },
        {
            id: 'tablero',
            title: 'tablero',
            type: 'item',
            url: '/seguimiento/tablero',
            visible: true,
            icon: icons.TableroIcon,
            breadcrumbs: false
        },
        {
            id: 'tareas',
            title: 'Tareas',
            type: 'item',
            url: '/seguimiento/tareas',
            visible: true,
            icon: icons.TareasIcon,
            breadcrumbs: false
        }
    ]
};

export default seguimiento;
