import React from 'react';

import { Grid } from 'core/components/library';
import { Text } from 'core/components';

const Detalle = props => {
    const { values, editing, handleChange } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='id'
                        label='Mención'
                        value={values.id}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Detalle;
