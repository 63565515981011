import React, { useState } from 'react';
import { Grid, Typography, Divider } from 'core/components/library';

import * as icons from 'core/icons';
import * as helpers from 'core/helpers';
import * as enums from 'core/enums';

import {
    DataGridPro as DataGrid,
    Text,
    Tag,
    Select,
    Combo,
    Document
} from 'core/components';

import DetailTramite from 'views/tramites/Tramites/Detalle';
import * as hlprs from '../../../../../../core/components/helpers';

const cols = (name, row, index, editing, handleChange) => [
    {
        key: 'enlace',
        title: 'Etiqueta',
        align: 'center',
        width: '10%',
        component: row && (
            <Document
                name='enlace'
                readOnly={true}
                width='50px'
                height='50px'
                value={row.etiqueta}
            />
        )
    },
    {
        key: 'version',
        title: 'Version',
        align: 'left',
        width: '15%',
        component: row && (
            <Tag
                size={'small'}
                sx={{ color: 'green' }}
                Icon={icons.SolicitudesIcon}
                value={row.codigo} //{hlprs.getValueFormatted('datetime-base', row.fecha)}
                id={row.id}
                relacionado={{
                    Detail: DetailTramite,
                    resume: ['codigo'],
                    api: 'tramites/solicitud',
                    readOnly: true
                }}
            />
        )
    },
    {
        key: 'resumen',
        title: 'Resumen',
        align: 'left',
        width: '40',
        component: row && row.resumenVersion
    },
    {
        key: 'idioma',
        title: 'Idioma',
        align: 'left',
        width: '15%',
        component: row && row.idiomaVersion
    },
    {
        key: 'destino',
        title: 'Destino',
        align: 'left',
        width: '20%',
        component: row && row.destinoVersion
    }
];

const Detalles = props => {
    const {
        values,
        editing,
        adding,
        initial,
        handleChange,
        refresh,
        estableceFiltro
    } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <DataGrid
                    //title={'Versiones de la Etiqueta'}
                    name={'versiones'}
                    editing={false}
                    rows={
                        values.versiones
                            ? values.versiones.filter(v => v.etiqueta)
                            : []
                    }
                    cols={(name, row, index) =>
                        cols(name, row, index, false, handleChange)
                    }
                    onAdd={name =>
                        handleChange({ version: helpers.now() }, name)
                    }
                    onDelete={(name, index) =>
                        handleChange(undefined, name, (index + 1) * -1)
                    }
                    maxHeight={415}
                />
            </Grid>
            <Grid item xs>
                <Text
                    fullWidth
                    multiline
                    rows={15}
                    name='notas'
                    label='Notas'
                    value={values.notas}
                    onChange={handleChange}
                    editing={editing}
                />
            </Grid>
        </Grid>
    );
};

export default Detalles;
