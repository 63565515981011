import React, { useState } from 'react';

import { Chip, Divider, makeStyles } from '../../../library';
import {
    Badge,
    Checkbox,
    TableCell,
    TableFooter,
    TableRow
} from '../../../library';

import { SumIcon } from '../../../icons';
import { getValueFormatted } from '../../../helpers';
import { Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
    actions: {
        width: '50px'
    },
    checkbox: {
        width: '50px',
        padding: 0,
        margin: 0
    }
}));

const getSpanCols = cols => {
    return cols.findIndex(item => item.aggregate) + 2;
};

const Foot = props => {
    const { columns, entities, labels } = props;
    const classes = useStyles();

    const [firstIndex] = useState(
        columns.findIndex(item => item.aggregate || item.resume)
    );
    const [tipoValor, setTipoValor] = useState(1);

    const getValue = (aggregate, resume) => {
        let newAggregate;

        if (resume) newAggregate = resume(entities.aggregates);
        else
            newAggregate = entities.aggregates.filter(
                item => item.field === aggregate
            )[0];

        if (tipoValor === 1) return newAggregate.valueAcumulado;
        if (tipoValor === 2) return newAggregate.valuePaginado;

        return newAggregate.value;
    };

    const getLabel = () => {
        if (tipoValor === 1) return 'Total Acumulado';
        if (tipoValor === 2) return 'Total Página';

        return 'Total';
    };

    if (entities.aggregates.length === 0) return null;

    return (
        <TableFooter>
            {labels && (
                <TableRow>
                    <TableCell
                        align={'right'}
                        colSpan={firstIndex + 2}
                    ></TableCell>

                    {columns.map((column, index) => {
                        if (index >= firstIndex) {
                            if (!column.aggregate && !column.resume)
                                return <TableCell></TableCell>;
                            else
                                return (
                                    <TableCell align={column.align}>
                                        <Typography variant='h6' noWrap>
                                            {column.title}
                                        </Typography>
                                    </TableCell>
                                );
                        }

                        return null;
                    })}
                </TableRow>
            )}
            <TableRow>
                <TableCell align={'right'} colSpan={firstIndex + 2}>
                    <Chip
                        icon={<SumIcon />}
                        label={getLabel()}
                        //variant="outlined"
                        onClick={() => setTipoValor((tipoValor + 1) % 3)}
                    />
                </TableCell>

                {columns.map((column, index) => {
                    if (index >= firstIndex) {
                        if (!column.aggregate && !column.resume)
                            return <TableCell></TableCell>;
                        else
                            return (
                                <TableCell align={column.align}>
                                    <Typography
                                        variant='h6'
                                        noWrap
                                        color={
                                            getValue(
                                                column.column,
                                                column.resume
                                            ) < 0
                                                ? 'red'
                                                : 'green'
                                        }
                                    >
                                        {getValueFormatted(
                                            column.type,
                                            getValue(
                                                column.column,
                                                column.resume
                                            )
                                        )}
                                    </Typography>
                                </TableCell>
                            );
                    }

                    return null;
                })}
            </TableRow>
        </TableFooter>
    );
};

export default Foot;
