import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Grid, Typography, Divider } from '@mui/material';

import * as icons from 'core/icons';
import * as hlprs from 'core/helpers';
import * as enums from 'core/enums';

import { DataGrid, Text, Switch, Select, Combo, Tag } from 'core/components';
import DetailSubcuenta from '../../../../../contabilidad/subcuentas/Detalle';
import DetailFormaPago from '../../../../../tesoreria/formas-pago/Detalle';
import List from '../../../../../../store/List';
import { statesEntity } from 'core/enums';

const ComboSubcuentas = List(Combo, 'options', 'lstSubcuentas');
const ComboFormaPago = List(Combo, 'options', 'lstFormasPago');

const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const Detalles = props => {
    const {
        values,
        editing,
        adding,
        initial,
        handleChange,
        refresh,
        estableceFiltro
    } = props;
    const classes = useStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs={7}>
                <Grid container direction='column' spacing={1}>
                    <Grid item>
                        <Grid container direction='row' spacing={1}>
                            <Grid item xs>
                                <Tag
                                    align={'center'}
                                    size={'small'}
                                    sx={{
                                        color: enums.getItem(
                                            statesEntity,
                                            values.elaboracion,
                                            'color'
                                        )
                                    }}
                                    Icon={enums.getItem(
                                        statesEntity,
                                        values.elaboracion,
                                        'icon'
                                    )}
                                    value={'Elaboración'}
                                    helperText={values.elaboracion}
                                />
                            </Grid>
                            <Grid item xs>
                                <Tag
                                    align={'center'}
                                    size={'small'}
                                    sx={{
                                        color: enums.getItem(
                                            statesEntity,
                                            values.almacenamiento,
                                            'color'
                                        )
                                    }}
                                    Icon={enums.getItem(
                                        statesEntity,
                                        values.almacenamiento,
                                        'icon'
                                    )}
                                    value={'Almacenamiento'}
                                    helperText={values.almacenamiento}
                                />
                            </Grid>
                            <Grid item xs>
                                <Tag
                                    align={'center'}
                                    size={'small'}
                                    sx={{
                                        color: enums.getItem(
                                            statesEntity,
                                            values.crianza,
                                            'color'
                                        )
                                    }}
                                    Icon={enums.getItem(
                                        statesEntity,
                                        values.crianza,
                                        'icon'
                                    )}
                                    value={'Crianza'}
                                    helperText={values.crianza}
                                />
                            </Grid>
                            <Grid item xs>
                                <Tag
                                    align={'center'}
                                    size={'small'}
                                    sx={{
                                        color: enums.getItem(
                                            statesEntity,
                                            values.embotellado,
                                            'color'
                                        )
                                    }}
                                    Icon={enums.getItem(
                                        statesEntity,
                                        values.embotellado,
                                        'icon'
                                    )}
                                    value={'Embotellado'}
                                    helperText={values.embotellado}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' spacing={1}>
                            <Grid item xs>
                                <Text
                                    fullWidth
                                    name='regIndustria'
                                    label='Registro Industria'
                                    value={values.regIndustria}
                                    onChange={handleChange}
                                    editing={editing}
                                />
                            </Grid>
                            <Grid item xs>
                                <Text
                                    fullWidth
                                    name='regEmbotellado'
                                    label='Registro Embotellado'
                                    value={values.regEmbotellado}
                                    onChange={handleChange}
                                    editing={editing}
                                />
                            </Grid>
                            <Grid item xs>
                                <Text
                                    fullWidth
                                    name='regSanitario'
                                    label='Registro Sanitario'
                                    value={values.regSanitario}
                                    onChange={handleChange}
                                    editing={editing}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' spacing={1}>
                            <Grid item>
                                <Text
                                    fullWidth
                                    name='volumen'
                                    label='Volumen Elaboración'
                                    value={values.volumen}
                                    onChange={handleChange}
                                    editing={editing}
                                    type='entero'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Text
                    fullWidth
                    multiline
                    rows={10}
                    name='notas'
                    label='Notas'
                    value={values.notas}
                    onChange={handleChange}
                    editing={editing}
                />
            </Grid>
        </Grid>
    );
};

export default Detalles;
