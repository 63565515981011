import React, { createContext, useState } from 'react';

const ReportContext = createContext();
let initialReport = {};
let initialValues = {};

const ReportProvider = ({ children }) => {
    const [report, setReport] = useState(initialReport);
    const [values, setValues] = useState(initialValues);

    const openReport = (report, values) => {
        setReport(report);
        setValues(values);

        const key = `/form/${report.id}`;
        window.open(key);
    };

    const data = { report, values, openReport };

    return (
        <ReportContext.Provider value={data}>{children}</ReportContext.Provider>
    );
};

export { ReportProvider };
export default ReportContext;
