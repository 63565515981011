import React, { useState } from 'react';

import { makeStyles } from '../../library';

import { IconButton, Link, Tooltip, TableCell, TableRow } from '../../library';
import { getValueFormatted, getValue } from '../../helpers';
import * as icons from '../../icons';

import { Alert } from '../../';

const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-star'
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const getProps = (column, item, onChange, id) => {
    let newProps = {};

    // Proceso los parametros
    if (column.component.parameters)
        Object.entries(column.component.parameters).forEach(
            ([key, parameter]) => {
                newProps = { ...newProps, [key]: parameter(item) };
            }
        );

    // Añado la propiedad para value
    if (column.component.propValue)
        newProps = {
            ...newProps,
            [column.component.propValue]: column.type
                ? getValueFormatted(column.type, getValue(item, column.column))
                : item[column.column]
        };

    // Anado el evento onChange
    if (column.component.eventChange)
        newProps = {
            ...newProps,
            [column.component.eventChange]:
                onChange && (chngs => onChange(chngs, id))
        };

    newProps = { ...column.component.props, ...newProps };

    return newProps;
};

const Cell = props => {
    const { id, index, column, item, onChange } = props;
    const classes = useStyles();

    if (column.column === '') return null;

    /*
        if (getValue(item, column.column)===null)
        return (
            <TableCell
                key={index}
                align={column.align}
                width= {column.width}
                style={{padding:'0px',paddingRight:'4px',paddingTop:'4px',paddingBottom:'4px'}}
            />
        )
*/
    return (
        <TableCell
            key={index}
            align={column.align}
            width={column.width}
            style={{
                padding: '0px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px'
            }}
        >
            {column.component ? (
                column.component.detail ? (
                    <div className={classes.wrapper}>
                        <column.component.Container
                            {...{
                                ...column.component.props,
                                [column.component.propValue]:
                                    item[column.column],
                                [column.component.eventChange]:
                                    onChange && (chngs => onChange(chngs, id))
                            }}
                        >
                            {column.component.propValue === undefined &&
                                getValue(item, column.column)}
                        </column.component.Container>

                        {column.component.detail &&
                            getValue(item, column.component.detail)}
                    </div>
                ) : (
                    <column.component.Container
                        {...getProps(column, item, onChange, id)}
                    />
                )
            ) : column.onClick ? (
                <Link
                    component='button'
                    variant='button'
                    onClick={() =>
                        column.onClick(getValue(item, column.column))
                    }
                >
                    {getValueFormatted(
                        column.type,
                        getValue(item, column.column)
                    )}
                </Link>
            ) : (
                getValueFormatted(column.type, getValue(item, column.column))
            )}
        </TableCell>
    );
};

const Row = props => {
    const {
        id,
        columns,
        item,
        edit,
        erase,
        select,
        height,
        onChange,
        lockRow
    } = props;
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const locked = lockRow ? lockRow(item) : false;

    return [
        <TableRow
            height={height}
            style={{ cursor: select ? 'pointer' : 'default' }}
            className={classes.row}
            hover
            key={item.id}
            selected={false}
            onClick={select && (() => select(item))}
        >
            {columns.map((column, index) => {
                return (
                    <Cell
                        id={id}
                        index={index}
                        column={column}
                        item={item}
                        onChange={onChange}
                    />
                );
            })}
            {!locked && (edit || erase || onChange) && (
                <TableCell style={{ padding: '0px' }} align={'right'}>
                    <div className={classes.actions}>
                        {edit && !onChange && (
                            <Tooltip title={'Editar'}>
                                <IconButton
                                    size='small'
                                    onClick={() => edit(item)}
                                >
                                    <icons.EditIcon />
                                </IconButton>
                            </Tooltip>
                        )}

                        {(erase || onChange) && (
                            <div>
                                <Tooltip title={'Borrar'}>
                                    <IconButton
                                        size='small'
                                        onClick={
                                            onChange
                                                ? () =>
                                                      onChange(
                                                          undefined,
                                                          (id + 1) * -1
                                                      )
                                                : () => setOpenDialog(true)
                                        }
                                    >
                                        <icons.DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                                <Alert
                                    open={openDialog}
                                    title={
                                        '¿Esta seguro de eliminar el elemento?'
                                    }
                                    text={`Si "Acepta" borrará definitivamente el elemento. Tenga en cuenta que esta acción es irreversible.`}
                                    cancel={() => setOpenDialog(false)}
                                    accept={() => {
                                        setOpenDialog(false);
                                        erase(item.id);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </TableCell>
            )}
        </TableRow>
    ];
};

export default Row;

/* <div className={classes.wrapper}>
                                            <column.component.Container
                                                style={column.component.style}
                                                onClick={column.onClick && (
                                                    () => column.onClick(getValue(item, column.column))
                                                )}
                                            >
                                                {getValue(item, column.column)}
                                            </column.component.Container>
                                            {column.component.detail && getValue(item, column.component.detail)}
                                        </div> */

/* <div className={classes.nameContainer}>
<Avatar className={classes.avatar}>
    {evento.idEventoInicial}
</Avatar>
<Typography color="textSecondary" variant="h6">{evento.eventoInicial.descripcion}</Typography>
</div> */
