import React, { useEffect, useState } from 'react';

import { Grid } from 'core/components/library';

import {
    Text,
    DataGridPro as DataGrid,
    ViewEntity,
    Literal,
    Tag,
    Button,
    Links,
    Combo,
    Select,
    Alert
} from 'core/components';

import * as icons from 'core/icons';
import DetailParcela from 'views/maestros/Parcelas/Detalle';
import Declaracion from '../../../Declaracion';
import {
    ENTITY_COSECHA,
    ENTITY_PARCELA
} from '../../../../../../store/entities/constants';
import { useSelector } from 'react-redux';
import * as helpers from '../../../../../../core/helpers';
import List from '../../../../../../store/List';
import * as enums from '../../../../../../core/enums';
import { DateTime } from 'luxon';
import { Typography } from '../../../../../../core/components/library';

const ComboMunicipios = List(Combo, 'options', 'lstmunicipios');

const cols = (row, entityData, tipo) => [
    {
        key: 'Vendimia',
        title: 'Vend.',
        align: 'center',
        //width: '5%',
        component: row && row.vendimia
    },
    {
        key: 'municipio',
        title: 'Municipio',
        align: 'left',
        width: '7%',
        component:
            row && row.parcela.municipio && row.parcela.municipio.descripcion
    },
    {
        key: 'poligono',
        title: 'Pol.',
        align: 'center',
        component: row && row.parcela.poligono
    },
    {
        key: 'parcela',
        title: 'Par.',
        align: 'center',
        component: row && row.parcela.parcela
    },
    {
        key: 'recinto',
        title: 'Rec.',
        align: 'center',
        component: row && row.parcela.recinto
    },
    {
        key: 'variedad',
        title: 'Variedad',
        align: 'left',
        width: '7%',
        component: row && row.variedad && row.variedad.descripcion
    },
    {
        key: 'uso',
        title: '% Uso',
        align: 'right',
        component: row && helpers.getValueFormatted('d.4', row.uso)
    },
    {
        key: 'superficieUso',
        title: 'Superf.',
        align: 'right',
        component: row && helpers.getValueFormatted('d.4', row.superficieUso)
    },
    {
        key: 'baume',
        title: 'ºBme.',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.baume)
    },
    {
        key: 'recolectado',
        title: 'Recolec.',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsRecolectados)
    },
    {
        key: 'kgsMaxUso',
        title: 'Máx.',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsMaxUso)
    },
    {
        key: 'rendimiento',
        title: '% Rend.',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.rendimiento)
    },
    {
        key: 'kgsExcedidos',
        title: 'Exceso',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsExcedidos)
    },
    {
        key: 'exceso',
        title: '% Exc.',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.exceso)
    },
    {
        key: 'kgsBloqueados',
        title: 'Bloqueado',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsBloqueados)
    },
    {
        key: 'kgsNoAptos',
        title: 'No Apto',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsNoAptos)
    },
    {
        key: 'kgsNoDop',
        title: 'No DOP',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsNoDop)
    },
    {
        key: 'kgsdeclarado',
        title: 'Declarado',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsDeclarados)
    },
    {
        key: 'incidencia',
        title: 'Incidencia',
        width: '10%',
        align: 'center',
        component: row && row.incidencia
    }
];

const Cosecha = props => {
    const { values, editing, execAction, refresh } = props;

    const [isBusy, setIsBusy] = useState(false);
    const [dialog, setDialog] = useState('');
    const [localState, setLocalState] = useState({
        vendimia: values.campaña,
        tramite: values.id,
        fecha: DateTime.fromISO(values.fecha).toISODate(),
        viticultor: values.viticultor ? values.viticultor.id : null,
        bodega: values.bodega ? values.bodega.id : null,
        bloqueo: null,
        municipio: null,
        poligono: null,
        parcela: null,
        recinto: null,
        kgs: 0,
        baume: 0
    });

    const entityData = useSelector(state => state.entities[ENTITY_COSECHA]);

    const entityQuery = useSelector(state => state.entities[ENTITY_PARCELA]);

    const handleChange = value => {
        const field = value[0].field;
        const newValue =
            value[0].value && value[0].value.name
                ? value[0].value.selected
                : value[0].value === ''
                  ? null
                  : value[0].value;

        setLocalState({
            ...localState,
            [field]: newValue
        });
    };

    const addParcel = () => {
        document.getElementById('poligono').focus();
        setIsBusy(true);
        execAction(
            {
                api: 'cosecha/addparcelas',
                metodo: 'post',
                data: {
                    ...localState,
                    vendimia: values.campaña,
                    tramite: values.id
                }
            },
            [
                data => {
                    setIsBusy(false);
                    if (data.length === 0) {
                        setDialog(
                            `No se han incluido nuevas parcelas con el criterio de selección especificado.
                            Puede ser que no existan o bien ya se han declarado`
                        );
                    } else {
                        setLocalState({
                            ...localState,
                            baume: null,
                            recinto: null,
                            kgs: null
                        });
                        refresh(false);
                    }
                }
            ],
            true
        );
    };

    const getTotal = field => {
        let valor = 0;
        if (values && values.cosecha) {
            values.cosecha.forEach(parcela => {
                valor += Number(parcela[field]);
            });
        }

        return helpers.getValueFormatted('d.2', valor);
    };

    const getSuperficie = () => {
        let valor = 0;
        if (values && values.cosecha) {
            values.cosecha.forEach(parcela => {
                valor = valor + Number(parcela.superficieUso);
            });
        }

        return helpers.getValueFormatted('d.4', valor);
    };

    return (
        <>
            {/*<Grid container spacing={2}>
                <Grid item>
                    <Button
                        //busy={isBusy}
                        toolTipText={'Seleccionar parcelas'}
                        icon={<icons.BuscarIcon />}
                        hidden={editing}
                        float={true}
                        title={values.concepto}
                        relacionado={
                            <Declaracion
                                values={values}
                                execAction={execAction}
                                refresh={refresh}
                                getSuperficie={getSuperficie}
                                getTotal={getTotal}
                            />
                        }
                        maxWidth={'xl'}
                        fullWidth
                        //fullScreen
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        variant='standard'
                        editing={true}
                        name='vendimia'
                        label='Vendimia'
                        type='entero'
                        value={localState.vendimia}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs>
                    <ComboMunicipios
                        fullWidth
                        variant='standard'
                        label='Municipio'
                        api='auxiliares/municipios'
                        name='municipio'
                        nameKey='municipioId'
                        refresh={refresh}
                        Icon={icons.MunicipioIcon}
                        value={localState.municipio}
                        onChange={handleChange}
                        editing={true}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item>
                    <Text
                        id={'poligono'}
                        fullWidth
                        variant='standard'
                        editing={true}
                        name='poligono'
                        label='Poligono'
                        type='entero'
                        value={localState.poligono}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        editing={true}
                        name='parcela'
                        label='Parcela'
                        variant='standard'
                        type='entero'
                        value={localState.parcela}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        editing={true}
                        name='recinto'
                        label='Recinto'
                        variant='standard'
                        value={localState.recinto}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        variant='standard'
                        editing={true}
                        name='kgs'
                        label='Kilos'
                        type='d.2'
                        value={localState.kgs}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        variant='standard'
                        editing={true}
                        name='baume'
                        label='º Baume'
                        type='d.2'
                        value={localState.baume}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Button
                        busy={isBusy}
                        toolTipText={'Añadir Kilos Parcela'}
                        icon={<icons.AddIcon />}
                        hidden={
                            editing ||
                            values.liquidacion === 'Liquidado' ||
                            !values.bodega ||
                            !localState.vendimia ||
                            !values.viticultor ||
                            !localState.municipio ||
                            !localState.poligono ||
                            !localState.parcela ||
                            !localState.kgs ||
                            !localState.baume
                        }
                        onClick={() => addParcel()}
                        float={true}
                    />
                </Grid>
            </Grid>*/}
            <Grid container alignItems={'center'} spacing={2}>
                <Grid item>
                    <Button
                        //busy={isBusy}
                        toolTipText={'Declarar cosecha de parcelas'}
                        icon={<icons.RecolectionIcon />}
                        hidden={editing}
                        float={true}
                        title={values.concepto}
                        relacionado={
                            <Declaracion
                                values={values}
                                execAction={execAction}
                                refresh={refresh}
                                handleChange={handleChange}
                                //getSuperficie={getSuperficie}
                                //getTotal={getTotal}
                            />
                        }
                        maxWidth={'xl'}
                        fullWidth
                        //fullScreen
                    />
                </Grid>
                <Grid item>
                    <Tag
                        align={'center'}
                        size={'small'}
                        sx={{
                            color: enums.getItem(
                                enums.typeQuota,
                                values.cuota,
                                'color'
                            )
                        }}
                        Icon={enums.getItem(
                            enums.typeQuota,
                            values.cuota,
                            'icon'
                        )}
                        value={values.cuota}
                    />
                </Grid>
                <Grid item xs>
                    <Grid
                        sx={{
                            marginTop: '10px',
                            marginBottom: '10px',
                            backgroundColor: 'lightgray',
                            height: 25,
                            borderRadius: 1
                        }}
                        container
                        justifyContent={'space-around'}
                        alignItems={'center'}
                    >
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Superficie Total: ${getSuperficie()}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Kgs. Recolectados: ${getTotal('kgsRecolectados')}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Kgs. Excedidos: ${getTotal('kgsExcedidos')}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Kgs. Bloqueados: ${getTotal('kgsBloqueados')}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Kgs. Declarados: ${getTotal('kgsDeclarados')}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs>
                    <DataGrid
                        //title={'Parcelas Seleccionadas'}
                        allowSelect={'multiple'}
                        name='parcelas'
                        editing={false}
                        readOnly={true}
                        rows={values.cosecha ? values.cosecha : []}
                        cols={(name, row, index) =>
                            cols(row, entityData, values.tipo)
                        }
                        getId={row => row.id}
                        notAdd={true}
                        /*onDelete={(name, index, row) => {
                            execAction(
                                {
                                    api: entityData.api,
                                    metodo: 'delete',
                                    data: [row.id]
                                },
                                [() => refresh(false)]
                            );
                        }}*/
                    >
                        {/*<ViewEntity
                            readOnly
                            type={ENTITY_PARCELA}
                            refresh={refresh}
                            entity={entityQuery}
                        >
                            {DetailParcela}
                        </ViewEntity>*/}
                    </DataGrid>
                </Grid>
            </Grid>
            <Alert
                open={dialog !== ''}
                title='Parcelas seleccionadas'
                text={dialog}
                cancel={() => {
                    setDialog('');
                }}
            />
        </>
    );
};

export default Cosecha;
