import moment from 'moment';
import { DateTime } from 'luxon';

let timeBoxId = 0;

export const getNumero = num => {
    return parseFloat(num.replace(/\./g, '').replace(',', '.'));
};

export const getValue = (obj, property) => {
    const prop = Array.isArray(property) ? property[0] : property;
    const newProp = Array.isArray(property) ? property[1] : null;
    let newObj = null;

    if (Array.isArray(obj)) {
        newObj = [];
        obj.forEach(itemObj => {
            const value = getValueProperty(itemObj, prop);
            if (value !== null && !newObj.includes(value)) newObj.push(value);
        });
    } else {
        newObj = getValueProperty(obj, prop);
    }

    if (newProp !== null && newObj !== null) {
        return getValue(newObj, newProp);
    }

    return newObj;
};

const getValueProperty = (obj, property) => {
    let value = '';

    // Compruebo si son varias propiedades concatenadas con  '+'
    // Si encuentra [] lo de dentro es un literal que tienen que concatenar
    const properties = property.split('+');

    if (properties.length === 1) {
        // Lo separo en dos casos para evitar que me concatene valores y me los cambie de tipo a string
        if (obj) value = obj[property];
        else value = null;
    } else {
        properties.forEach(e => {
            if (e.startsWith('['))
                // Es un literal lo concateno
                value = value + e.substring(1, e.length - 1);
            else {
                value =
                    !isObject(obj[e]) && obj[e] !== null
                        ? value + obj[e]
                        : obj[e];
            }
        });
    }

    return value;
};

export const getValueFormatted = (type, value) => {
    if (typeof type === 'function') return type(value);

    let newValue = '';
    const tp = type === undefined ? 'text' : type.toLowerCase();

    switch (tp) {
        case 'text':
            newValue = value;
            break;

        case 'number':
            newValue = value;
            break;

        case 'date':
            newValue = value === null ? '' : moment(value).format('DD/MM/YYYY');
            break;

        case 'time':
            newValue = value === null ? '' : moment(value).format('HH:mm:ss');
            break;

        case 'datetime':
            newValue =
                value === null
                    ? ''
                    : moment(value).format('DD/MM/YYYY HH:mm:ss');
            break;

        case 'datetime-base':
            newValue =
                value === null ? '' : moment(value).format('DD/MM/YYYY HH:mm');
            break;

        case 'datetime-local':
            newValue =
                value === null
                    ? ''
                    : moment(value).format(moment.HTML5_FMT.DATETIME_LOCAL);
            break;

        case 'day':
            newValue =
                value === null ? '' : moment(value).format('dddd DD MMMM YYYY');
            break;

        case 'week':
            newValue =
                value === null
                    ? ''
                    : `${moment(value).format('WW')} [${moment(value)
                          .startOf('week')
                          .format('dddd DD')} - ${moment(value)
                          .endOf('week')
                          .format('dddd DD')}] ${moment(value).format('MMMM')}`;
            break;

        case 'month':
            newValue = value === null ? '' : moment(value).format('MMMM YYYY');
            break;

        case 'timecounter':
            newValue = contadorTemporal(value);
            break;

        default:
            if (type.startsWith('d.'))
                newValue = getNumberFormatted(value, Number(type.substring(2)));
            else newValue = value;
    }

    return newValue;
};

export const getInitials = (name = '') => {
    if (!name) return '';

    return name
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map(v => v && v[0].toUpperCase())
        .join('');
};

export const contadorTemporal = tiempo => {
    if (tiempo < 0) return '000:00:00';

    if (tiempo === undefined || tiempo === null || tiempo === '') return null;

    // Obtengo las horas
    const horas = Math.trunc(tiempo / 3600);

    // obtengo los minutos
    const minutos = Math.trunc((tiempo - horas * 3600) / 60);

    // obtengo los segundos
    const segundos = tiempo - horas * 3600 - minutos * 60;

    return (
        horas.toString().padStart(3, '0') +
        ':' +
        minutos.toString().padStart(2, '0') +
        ':' +
        segundos.toString().padStart(2, '0')
    );
};

export const segundosContador = contador => {
    if (contador === undefined || contador === null || contador === '')
        return 0;

    const elementos = contador.split(':');

    let horas = 0;
    let minutos = 0;
    let segundos = 0;

    // Obtengo las horas
    elementos.length > 0 &&
        (horas = parseInt(elementos[0] === '' ? 0 : elementos[0]));

    // obtengo los minutos
    elementos.length > 1 &&
        (minutos = parseInt(elementos[1] === '' ? 0 : elementos[1]));

    // obtengo los segundos
    elementos.length > 2 &&
        (segundos = parseInt(elementos[2] === '' ? 0 : elementos[2]));

    return horas * 3600 + minutos * 60 + segundos;
};

export const singular = word => {
    return word.slice(0, word.length - 1);
};

export const clone = obj => {
    return JSON.parse(JSON.stringify(obj));
};

export const dateFormat = period => {
    switch (period) {
        case 'days':
            return 'yyyy-MM-DD';

        case 'weeks':
            return 'GGGG-[W]WW';

        case 'months':
            return 'YYYY-MM';

        case 'years':
            return 'YYYY';

        default:
            return '';
    }
};

export const isObject = obj => {
    return (
        (typeof obj === 'object' || typeof obj === 'function') && obj !== null
    );
};

export const empty = obj => {
    if (isObject(obj)) return Object.keys(obj).length === 0;

    return true;
};

export const now = () => {
    return moment().format();
};

export const period = (start, end) => {
    const desde = DateTime.fromISO(start);
    const hasta = end ? DateTime.fromISO(end) : DateTime.now();

    const diferencia = hasta.diff(desde, [
        'years',
        'months',
        'days',
        'hours',
        'minutes'
    ]);

    let formato = '';

    if (Math.trunc(diferencia.years) !== 0) formato += "y'a' ";

    if (Math.trunc(diferencia.months) !== 0) formato += "M'm' ";

    if (
        Math.trunc(diferencia.days) !== 0 &&
        diferencia.years === 0 &&
        diferencia.months === 0
    )
        formato += "d'd' ";
    if (
        Math.trunc(diferencia.hours) !== 0 &&
        diferencia.years === 0 &&
        diferencia.months === 0
    )
        formato += "h'h' ";
    if (
        Math.trunc(diferencia.minutes) !== 0 &&
        diferencia.years === 0 &&
        diferencia.months === 0 &&
        diferencia.days === 0 &&
        Math.trunc(diferencia.hours) <= 1
    )
        formato += "m'm' ";

    return formato === '' ? '' : `( ${diferencia.toFormat(formato)})`;
};

export const timeBox = (action, time) => {
    timeBoxId > 0 && clearTimeout(timeBoxId);

    timeBoxId = setTimeout(action, time);
};

export const contadorNumerico = (value, max) => {
    const number = Number(value).toString();

    if (number.length > max) return value;

    const contador = `${'0'.repeat(max - number.length)}${number}`;
    return contador === '0'.repeat(max) ? '' : contador;
};

export const contadorAño = (value, max, año) => {
    const number =
        value.length < 4 ? value : Number(value.substring(4)).toString();

    if (number.length > max) return value;

    const contador = `${año}${'0'.repeat(max - number.length)}${number}`;

    return contador === `${año}${'0'.repeat(max)}` ? '' : contador;
};

export const getNumberFormatted = (value, max) => {
    let decimales = 0;
    try {
        decimales = max
            ? max
            : value.split('.')[1]
              ? value.split('.')[1].length
              : 0;
    } catch {
        return '0';
    }

    if (
        value.toString().substring(value.length - 1) !== '.' &&
        value.toString().substring(value.length - 1) !== '-'
    ) {
        const number = Number(value); //Number(value.replace(',', '.'));
        if (!Number.isNaN(number)) {
            const newValue = new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: decimales
            }).format(number);

            return newValue;
        }
    }

    return value.replace('.', ',');
};

export const getNumber = (value, decimales) => {
    const number = value.split(',');

    let newValue = number[0].replaceAll('.', '');

    if (number.length > 1) newValue = newValue + '.' + number[1];

    return newValue;
};

export const getSubcuenta = (value, max) => {
    const parts = value.split('.');

    if (parts.length === 1 || !max) return value;

    const cuenta = Number(parts[0]).toString();
    const num = Number(parts[1]).toString();

    return cuenta + '0'.repeat(max - cuenta.length - num.length) + num;
};
