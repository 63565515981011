import * as icons from 'core/icons';

const maestros = {
    id: 'maestros',
    title: 'Maestros',
    type: 'group',
    collapse: false,
    visible: true,
    children: [
        {
            id: 'groupBodegas',
            title: 'Bodegas',
            type: 'collapse',
            icon: icons.BodegasIcon,
            visible: false,
            children: [
                {
                    id: 'bodegas',
                    title: 'Bodegas',
                    type: 'item',
                    visible: true,
                    url: '/gestion/maestros/bodegas',
                    icon: icons.BodegasIcon,
                    breadcrumbs: false
                },
                {
                    id: 'explotaciones',
                    title: 'Centros',
                    type: 'item',
                    visible: true,
                    url: '/gestion/maestros/centros',
                    icon: icons.SitesIcon,
                    breadcrumbs: false
                },
                {
                    id: 'marcas',
                    title: 'Marcas',
                    type: 'item',
                    visible: true,
                    url: '/gestion/maestros/marcas',
                    icon: icons.BrandsIcon,
                    breadcrumbs: false
                },
                {
                    id: 'etiquetasBodega',
                    title: 'Etiquetas',
                    type: 'item',
                    visible: true,
                    url: '/gestion/maestros/etiquetasbodega',
                    icon: icons.LabelsIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'groupViticultores',
            title: 'Viticultores',
            type: 'collapse',
            visible: false,
            icon: icons.ViticultoresIcon,
            children: [
                {
                    id: 'viticultores',
                    title: 'Viticultores',
                    type: 'item',
                    visible: true,
                    url: '/gestion/maestros/viticultores',
                    icon: icons.ViticultoresIcon,
                    breadcrumbs: false
                },
                {
                    id: 'parcelas',
                    title: 'Parcelas',
                    type: 'item',
                    visible: true,
                    url: '/gestion/maestros/parcelas',
                    icon: icons.ParcelIcon,
                    breadcrumbs: false
                },
                {
                    id: 'cosecha',
                    title: 'Cosecha',
                    type: 'item',
                    visible: true,
                    url: '/gestion/cosecha',
                    icon: icons.RecolectionIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'socios',
            title: 'Socios',
            type: 'item',
            visible: true,
            url: '/gestion/maestros/socios',
            icon: icons.SociosIcon,
            breadcrumbs: false
        },
        {
            id: 'parcelas',
            title: 'Parcelas',
            type: 'item',
            visible: true,
            url: '/gestion/maestros/parcelas',
            icon: icons.ParcelIcon,
            breadcrumbs: false
        },
        {
            id: 'clientes',
            title: 'Clientes',
            type: 'item',
            visible: true,
            url: '/gestion/maestros/clientes',
            icon: icons.ClientesIcon,
            breadcrumbs: false
        },
        {
            id: 'proveedores',
            title: 'Proveedores',
            type: 'item',
            visible: true,
            url: '/gestion/maestros/Proveedores',
            icon: icons.ProveedoresIcon,
            breadcrumbs: false
        },
        {
            id: 'productos',
            title: 'Productos',
            type: 'item',
            url: '/gestion/maestros/productos',
            icon: icons.ProductosIcon,
            breadcrumbs: false
        },
        {
            id: 'usuarios',
            title: 'Usuarios',
            type: 'item',
            visible: true,
            url: '/gestion/maestros/usuarios',
            icon: icons.UsuariosIcon,
            breadcrumbs: false
        }
    ]
};

export default maestros;
