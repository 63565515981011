import React from 'react';

import { Grid } from 'core/components/library';
import { Text } from 'core/components';

const Detalles = props => {
    const { values, editing, handleChange, refresh } = props;

    return (
        <Grid container spacing={3}>
            <Grid item xs>
                <Text
                    fullWidth
                    multiline
                    rows={15}
                    name='notas'
                    label='Observaciones'
                    value={values.notas}
                    onChange={handleChange}
                    editing={editing}
                />
            </Grid>
        </Grid>
    );
};

export default Detalles;
