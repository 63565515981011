import React, { useEffect } from 'react';

import { Divider, Grid } from 'core/components/library';

import {
    Text,
    Button,
    Select,
    Switch,
    Tabs,
    Documents,
    Tag,
    Combo
} from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';

import Detalles from './Tabs/TabDetalles';
import Etiquetas from './Tabs/TabEtiquetas';
import { statesEntity } from 'core/enums';
import DetailBodega from '../../Bodegas/Detalle';
import List from '../../../../store/List';
import { ENTITY_BODEGA } from '../../../../store/entities/constants';

const ComboBodegas = List(Combo, 'options', 'lstbodegas');

const Marca = props => {
    const { values, editing, handleChange, related, refresh } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='nombre'
                        label='Nombre'
                        value={values.nombre}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        name='acronimo'
                        label='Acronimo'
                        value={values.acronimo}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Tag
                        align={'center'}
                        size={'medium'}
                        sx={{
                            color: enums.getItem(
                                statesEntity,
                                values.estado,
                                'color'
                            )
                        }}
                        Icon={enums.getItem(
                            statesEntity,
                            values.estado,
                            'icon'
                        )}
                        value={values.estado}
                    />
                </Grid>
            </Grid>
            {!related && (
                <Grid container spacing={1}>
                    <Grid item xs>
                        <ComboBodegas
                            fullWidth
                            api='bodegas'
                            name='bodega'
                            nameKey='bodegaid'
                            label='Bodega'
                            relacionado={{
                                type: ENTITY_BODEGA,
                                Detail: DetailBodega,
                                resume: ['nombre'],
                                readOnly: false
                            }}
                            refresh={refresh}
                            Icon={icons.CellarIcon}
                            value={values.bodega}
                            onChange={handleChange}
                            editing={editing}
                            propertyValue='id'
                            getOptionLabel={option => `${option.nombre}`}
                            searchKeys={['nombre']}
                        />
                    </Grid>
                </Grid>
            )}

            <Tabs
                tabs={[
                    {
                        label: 'Detalles',
                        icon: icons.DetailIcon,
                        component: <Detalles {...props} />
                    },
                    {
                        label: 'Etiquetas',
                        icon: icons.LabelsIcon,
                        component: <Etiquetas {...props} />
                    }
                ]}
            />
        </form>
    );
};

export default Marca;
