import React, { useState, useEffect } from 'react';

import { makeStyles, gridSpacing } from '../../library';
import { Auth, Entity, Grid, Container } from '../../library';

import { Actions, Index, Toolbar, Element, Elements } from '../';
import { Error, FloatAction } from '../../';

import { print } from 'reports';

const useStyles = makeStyles(theme => ({
    root: {
        //padding: theme.spacing(2)
    }
}));

const getAggregates = cols => {
    let aggregates = [];
    cols.forEach(
        item =>
            item.column &&
            item.aggregate &&
            aggregates.push({ field: item.column, op: item.aggregate })
    );
    return aggregates;
};
const getReports = (reports, entities) => {
    if (!reports || !entities) return null;

    if (reports.list.length === 0) return null;

    return {
        title: 'Informes disponibles',
        list: reports.list,
        action: report => print(report, entities),
        columns: [
            {
                column: 'name',
                title: 'Titulo',
                align: 'left',
                type: 'text'
            },
            {
                column: 'description',
                title: 'Resumen',
                align: 'left',
                type: 'text'
            }
        ]
    };
};

const Crud = props => {
    const {
        autoRefresh,
        readOnly,
        single,
        initialSize,
        auth,
        entities,
        reports,
        entityData,
        title,
        columns,
        resume,
        id,
        api,
        Detalle,
        children,
        ...rest
    } = props;

    const classes = useStyles();

    const myApi = entityData ? entityData.api : api;
    const myId = entityData ? entityData.id : id;

    const [editing, setEditing] = useState([]);
    const [elements, setElements] = useState([]);
    const [selectedElement, selectElement] = useState(0);
    const [filters, setFilters] = useState([]);
    const [size, setSize] = useState(
        single ? 0 : initialSize !== undefined ? initialSize : 6
    );

    const openElements = (element, nuevo) => {
        let newElements = [];

        if (entities.selected.length === 0 || nuevo)
            newElements = addElement([element[myId]]);
        else newElements = addElement(entities.selected);

        setElements(newElements.elements);
        selectElement(
            newElements.index === -1
                ? newElements.elements.length
                : newElements.index
        );
    };

    const addElement = ids => {
        let newElements = [];
        let key = Date.now();
        let index = -1;

        ids.forEach(id => {
            index = elements.findIndex(item => item.id === id);

            if (index === -1) {
                newElements = newElements.concat({
                    key: key,
                    id: id,
                    readOnly: readOnly,
                    entityData: entityData,
                    title: entityData ? entityData.title : title,
                    resume: entityData ? entityData.resume : resume,
                    api: myApi + id,
                    container: Entity(Auth(Element, 'auth'), 'entity', key),
                    element: Detalle
                });

                key = key + 1;
            }
        });

        return {
            index: index,
            elements: elements.concat(newElements)
        };
    };

    const closeElement = key => {
        if (key === undefined) {
            setElements([]);
            setEditing([]);
        } else {
            setElements(elements.filter(item => item.key !== key));
            editing.includes(key) &&
                setEditing(editing.filter(item => item !== key));
        }

        if (autoRefresh === undefined || autoRefresh) entities.refresh();
    };

    const newElement = toCopy => {
        const api = toCopy === '' ? 'new' : 'new/' + toCopy;

        openElements(
            {
                [myId]: api
            },
            true
        );
    };

    const editElement = (key, cancel, id, isEditable) => {
        if (!isEditable) {
            setEditing(editing.filter(item => item !== key));

            if (true) {
                //if (!cancel) { lo he quitado para probar edicion continua
                entities.refresh();
                checkElement(key, id);
            }
        } else {
            setEditing(editing.concat(key));
        }
    };

    const checkElement = (key, id) => {
        const index = elements.findIndex(item => item.key === key);

        if (index !== -1) {
            if (elements[index].id !== id) {
                const newElements = elements.map(item =>
                    item.key === key
                        ? { ...item, ...{ id: id, api: myApi + id } }
                        : item
                );

                setElements(newElements);
            }
        }
    };

    const resize = () => {
        const newSize = size - 2 < 0 ? 8 : size - 2;
        setSize(newSize);
    };

    useEffect(() => {
        entities.load(
            myApi,
            undefined,
            undefined,
            getAggregates(columns(entities))
        );

        if (reports) reports.load('reports/' + myApi);

        setFilters(getFilters(columns(entities)));

        return () => {
            entities.unload();
            if (reports) reports.unload();
        };
    }, [JSON.stringify(entityData)]);

    if (!entities.isReady) return null;

    return (
        <Container
            disableGutters={entityData && entityData.maxWidth}
            maxWidth={
                elements.length === 0 || size === 0
                    ? entityData && entityData.maxWidth
                        ? true
                        : 'xl'
                    : false
            }
        >
            <div className={classes.root}>
                <Grid container spacing={gridSpacing}>
                    {elements.length > 0 && size === 0 && !single && (
                        <FloatAction
                            title={`Mostrar lista de ${
                                entityData ? entityData.title : title
                            }`}
                            Icon={entityData ? entityData.icon : null}
                            handleAction={resize}
                        />
                    )}

                    {(elements.length === 0 || size !== 0) && (
                        <Grid item xs={elements.length === 0 ? true : size}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Toolbar
                                        {...rest}
                                        Icon={
                                            entityData ? entityData.icon : null
                                        }
                                        title={
                                            entityData
                                                ? entityData.title
                                                : title
                                        }
                                        disabled={editing.length !== 0}
                                        filters={filters}
                                        resize={elements.length > 0 && resize}
                                        menu={
                                            <Actions
                                                readOnly={
                                                    !Detalle || readOnly
                                                        ? true
                                                        : false
                                                }
                                                add={newElement}
                                                print={getReports(
                                                    reports,
                                                    entities
                                                )}
                                                entities={entities}
                                                float={true}
                                                optionals={
                                                    children &&
                                                    children(entities)
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Index
                                        readOnly={readOnly}
                                        view={openElements}
                                        disabled={
                                            editing.length !== 0 || !Detalle
                                        }
                                        entities={entities}
                                        columns={columns(entities)}
                                        {...rest}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {elements.length > 0 && (
                        <Grid item xs>
                            <Elements
                                title={
                                    'Detalles ' +
                                    (entityData ? entityData.title : title)
                                }
                                Icon={entityData ? entityData.icon : null}
                                single={single}
                                items={elements}
                                selectedItem={selectedElement}
                                selectItem={selectElement}
                                close={closeElement}
                                edit={editElement}
                                editing={editing.length !== 0}
                                refresh={entities.refresh}
                                {...rest}
                            />
                        </Grid>
                    )}
                </Grid>
                <Error entities={[entities]} />
            </div>
        </Container>
    );
};

const getFilters = columns => {
    let filters = [];

    columns.forEach(column => {
        if (column.filter) {
            filters.push({
                ...column,
                value: column.defaultValue ? column.defaultValue : null,
                setValue: (filter, newValue) => {
                    filter.value = newValue;
                },
                modificador: column.modificador ? column.modificador : null,
                setModificador: (filter, newModificador) => {
                    filter.modificador = newModificador;
                }
            });
        }
    });

    return filters;
};

export default Crud;
