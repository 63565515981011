import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { Divider, Grid } from 'core/components/library';
import { Text, Tabs, Emit } from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import * as helpers from 'core/helpers';

import Parcelas from './Tabs/Parcelas';
import Firmas from './Tabs/Firmas';

const Detalle = props => {
    const {
        values,
        editing,
        handleChange,
        refresh,
        reload,
        execAction,
        adding
    } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item>
                    <Text
                        fullWidth
                        name='fecha'
                        label='Fecha'
                        value={values.fecha}
                        type='date'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item>
                    <Emit
                        disabled={false}
                        hidden={editing}
                        toolTipText={'Emitir Acta'}
                        forms={
                            values.tramite &&
                            values.tramite.tipo &&
                            values.tramite.tipo.formularios
                                ? helpers.getForms(
                                      values.tramite.tipo.formularios,
                                      'Acta'
                                  )
                                : []
                        }
                        values={values}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='concepto'
                        label='Concepto'
                        value={values.concepto}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                {/*<Grid item>
                    <Text
                        fullWidth
                        name='fechaRegistro'
                        label='Fecha RV'
                        value={values.fechaRegistro}
                        type='date'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>*/}
                <Grid item xs>
                    <Text
                        fullWidth
                        multiline
                        rows={5}
                        name='notas'
                        label='Observaciones'
                        value={values.notas}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            {!adding && (
                <Tabs
                    tabs={[
                        {
                            label: 'Parcelas',
                            icon: icons.ParcelIcon,
                            component: <Parcelas {...props} />
                        },
                        {
                            label: 'Firmas',
                            icon: icons.FirmasIcon,
                            component: <Firmas {...props} />,
                            disabled: false
                        }
                    ]}
                />
            )}
        </form>
    );
};

export default Detalle;
