import React, { useState } from 'react';

import { makeStyles, Grid } from '../../../library';
import {
    IconButton as Button,
    Checkbox,
    TableCell,
    TableRow,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Stack
} from '../../../library';

import { DeleteIcon, ViewIcon } from '../../../icons';

import { Alert } from '../../../';

import { getValueFormatted, getValue } from '../../../helpers';
import { Typography } from '@mui/material';

const useStyles = disabled =>
    makeStyles(theme => ({
        root: {},
        button: {
            margin: 0,
            padding: 5
        },
        actions: {
            width: '50px',
            padding: 0,
            margin: 0
        },
        wrapper: {
            display: 'flex',
            justifyContent: 'center',
            visibility: 'collapse'
        },
        row: {
            padding: 0,
            '&:hover $wrapper': {
                visibility: disabled ? false : 'visible'
            }
        },
        listIcon: {
            padding: '0px',
            margin: '0px',
            marginRight: '-30px',
            border: '0px'
        },
        listItem: {
            padding: '2px',
            margin: '0px',
            border: '0px'
        }
    }));

const getAlign = align => {
    if (!align) return 'flex-start';

    if (align.toLowerCase() === 'center') return 'center';

    if (align.toLowerCase() === 'right') return 'flex-end';

    return 'flex-start';
};

const MultiValueList = props => {
    const { value, classes, column } = props;

    return (
        <List dense disablePadding className={classes.listItem}>
            {value.map((v, index) => (
                <ListItem className={classes.listItem} disableGutters>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent={getAlign(column.align)}
                        spacing={2}
                    >
                        {column.icon && (
                            <ListItemIcon className={classes.listIcon}>
                                <column.icon
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            </ListItemIcon>
                        )}
                        <ListItemText
                            color={column.color && column.color}
                            className={classes.listItem}
                            //disableTypography={true}
                            primary={getValueFormatted(column.type, v)}
                        />
                    </Stack>
                </ListItem>
            ))}
        </List>
    );
};

const MultiValue = props => {
    const { value, classes, column } = props;

    return (
        <Grid
            container
            spacing={1}
            alignItems='center'
            justifyContent={'flex-start'}
        >
            {value.map((v, index) => (
                <Grid item>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent={'center'}
                        spacing={0.2}
                    >
                        {column.icon && (
                            <column.icon
                                style={{
                                    fontSize: 15
                                }}
                            />
                        )}
                        <Typography
                            variant={'caption'}
                            color={column.color && column.color}
                        >
                            {getValueFormatted(column.type, v)}
                        </Typography>
                    </Stack>
                </Grid>
            ))}
        </Grid>
    );
};

const Row = props => {
    const { view, disabled, columns, entities, item, rowNum, readOnly } = props;
    const classes = useStyles(disabled)();

    const [openDialog, setOpenDialog] = useState(false);

    return (
        <TableRow
            className={classes.row}
            hover
            key={item.id}
            selected={entities.selected.indexOf(item.id) !== -1}
        >
            {!disabled && (
                <TableCell className={classes.actions}>
                    <div className={classes.wrapper}>
                        <Button
                            className={classes.button}
                            disabled={
                                entities.isUpdating ||
                                entities.isRefreshing ||
                                disabled
                            }
                            onClick={event => view(item)}
                        >
                            <ViewIcon color='primary' fontSize='small' />
                        </Button>
                        {!readOnly && (
                            <Button
                                className={classes.button}
                                disabled={
                                    entities.isUpdating ||
                                    entities.isRefreshing ||
                                    disabled
                                }
                                onClick={() => setOpenDialog(true)}
                            >
                                <DeleteIcon
                                    color='secondary'
                                    fontSize='small'
                                />
                            </Button>
                        )}
                        <Alert
                            open={openDialog}
                            title='¿Esta seguro de eliminar el elemento?'
                            text={`Si "Acepta" eliminara el elemento de la lista actual. Tenga en cuenta que esta acción es irreversible.`}
                            cancel={() => setOpenDialog(false)}
                            accept={() => {
                                setOpenDialog(false);
                                entities.delete(item.id);
                            }}
                        />
                    </div>
                </TableCell>
            )}

            <TableCell className={classes.actions}>
                <Checkbox
                    size={'small'}
                    checked={entities.selected.indexOf(item.id) !== -1}
                    color='primary'
                    onChange={event => entities.select(item.id, true)}
                />
            </TableCell>

            {columns.map((column, index) => {
                if (column.column && column.column !== '') {
                    const value = getValue(item, column.column);

                    if (Array.isArray(value)) {
                        return (
                            <TableCell key={index} size='small'>
                                <MultiValue
                                    {...props}
                                    value={value}
                                    classes={classes}
                                    column={column}
                                />
                            </TableCell>
                        );
                    } else {
                        return (
                            <TableCell
                                key={index}
                                size='small'
                                align={column.align && column.align}
                            >
                                {(value !== null ||
                                    (!Array.isArray(column.column) &&
                                        column.column.startsWith('@'))) && (
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        justifyContent={getAlign(column.align)}
                                        spacing={1}
                                    >
                                        {column.component &&
                                            column.component(item, rowNum)}
                                        {column.icon && (
                                            <column.icon fontSize={'small'} />
                                        )}
                                        {!column.hideValue && (
                                            <Typography
                                                color={
                                                    column.color && column.color
                                                }
                                                variant='normal'
                                                // noWrap
                                            >
                                                {getValueFormatted(
                                                    column.type,
                                                    value
                                                )}
                                            </Typography>
                                        )}
                                    </Stack>
                                )}
                            </TableCell>
                        );
                    }
                } else return null;
            })}
        </TableRow>
    );
};

export default Row;
