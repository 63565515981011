import React, { useState } from 'react';
import { Typography, makeStyles } from '../library';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent,
    TimelineDot
} from '../library';
import { DateTime } from 'luxon';

import * as helpers from '../helpers';
import * as icons from '../icons';

const sizeIcon = 'small';

const Event = props => {
    const {
        idField,
        event,
        options,
        onClick,
        principal,
        secondary,
        date,
        period
    } = props;

    const option = !options
        ? null
        : options.find(item => item.value === event[principal]);
    const bgcolor = option ? option.color : 'white';
    const color = option ? undefined : 'primary';
    const variant = option ? undefined : 'outlined';

    return (
        <TimelineItem key={event[idField]}>
            {date && (
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    variant='caption'
                    color='text.secondary'
                >
                    {helpers.getValueFormatted('datetime-base', event[date])}
                </TimelineOppositeContent>
            )}
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                    sx={{
                        bgcolor: bgcolor,
                        cursor: onClick ? 'pointer' : 'default'
                    }}
                    variant={variant}
                    color={color}
                    onClick={onClick}
                >
                    {option ? (
                        <option.icon fontSize={sizeIcon} />
                    ) : (
                        <icons.EventIcon fontSize={sizeIcon} />
                    )}
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant='h6'>{`${principal ? event[principal] : ''} ${period ? period : ''}`}</Typography>
                <Typography variant='caption'>{event[secondary]}</Typography>
            </TimelineContent>
        </TimelineItem>
    );
};

const isCaducate = events => {
    if (!events) return true;
    if (events.length === 0) return true;

    const event = events[0];
    if (!event.hasta) return false;

    return DateTime.fromISO(event.hasta) < DateTime.now();
};

const StateLine = props => {
    const {
        idField,
        events,
        principal,
        secondary,
        date,
        options,
        children,
        period,
        onClick
    } = props;

    const [id, setId] = useState(null);

    const open = (key, id) => {
        setId({ key: key, id: id });
    };

    return (
        <>
            <Timeline position='alternate'>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color={'secondary'}>
                            <icons.ToDayIcon fontSize={sizeIcon} />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '20px', px: 2 }}>
                        <Typography variant='h6' component='span'>
                            Hoy
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
                {isCaducate(events) && (
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color={'primary'}>
                                <icons.ReadyIcon fontSize={sizeIcon} />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '20px', px: 2 }}>
                            <Typography variant='h6' component='span'>
                                Disponible
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                )}
                {events &&
                    events.map((event, index) => (
                        <Event
                            key={event[idField ? idField : 'id']}
                            idField={idField ? idField : 'id'}
                            onClick={
                                onClick
                                    ? () => {
                                          onClick(event, index, open);
                                      }
                                    : () =>
                                          open(
                                              null,
                                              event[idField ? idField : 'id']
                                          )
                            }
                            event={event}
                            principal={principal}
                            secondary={secondary}
                            date={date}
                            options={options}
                            period={
                                period
                                    ? helpers.period(
                                          event[date],
                                          index !== 0
                                              ? events[index - 1][date]
                                              : undefined
                                      )
                                    : undefined
                            }
                        />
                    ))}
            </Timeline>
            {id &&
                React.Children.map(children, (child, index) => {
                    if (id.key === child.key || (!id.key && index === 0)) {
                        return React.cloneElement(child, {
                            open: id.id ? true : false,
                            id: id.id,
                            onClose: () => setId(null)
                        });
                    }
                })}
        </>
    );
};

export default StateLine;
