import React, { useEffect } from 'react';

import { Divider, Grid } from 'core/components/library';

import { Text, Switch, Select, Combo } from 'core/components';
import * as enums from '../../../../core/enums';
import * as icons from '../../../../core/icons';

import List from '../../../../store/List';

const ComboMedios = List(Combo, 'options', 'lstMediosPago');

const Detalle = props => {
    const { values, editing, handleChange, refresh } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='descripcion'
                        label='Descripción'
                        value={values.descripcion}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Select
                        fullWidth
                        editing={editing}
                        label='Tipo'
                        name='tipo'
                        options={enums.tiposPago}
                        value={values.tipo}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Select
                        fullWidth
                        editing={editing}
                        label='Calcular por'
                        name='unidad'
                        options={enums.unidadVencimientos}
                        value={values.unidad}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs>
                    <Select
                        fullWidth
                        editing={editing}
                        label='Ajustar fecha a'
                        name='ajuste'
                        options={enums.ajusteVencimientos}
                        value={values.ajuste}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        type='entero'
                        name='vencimientos'
                        label='Número de vencimientos'
                        value={values.vencimientos}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        type='entero'
                        name='cadenciaInicial'
                        label='Cadencia Inicial'
                        value={values.cadenciaInicial}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item>
                    {values.vencimientos && values.vencimientos > 1 && (
                        <Text
                            fullWidth
                            type='entero'
                            name='cadencia'
                            label='Cadencia'
                            value={values.cadencia}
                            onChange={handleChange}
                            editing={editing}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboMedios
                        fullWidth
                        api='bancos/mediospago'
                        name='medio'
                        nameKey='medioid'
                        label='Medio de Cobro/Pago'
                        refresh={refresh}
                        Icon={icons.BancosIcon}
                        value={values.medio}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `${option.banco.nombre}-[${option.alias}]-[${option.subcuenta.id}]`
                        }
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Detalle;
