import * as icons from 'core/icons';

const gestion = {
    id: 'gestion',
    title: 'Gestión',
    type: 'group',
    collapse: false,
    visible: true,
    children: [
        {
            id: 'tramites',
            title: 'Tramites',
            type: 'collapse',
            visible: true,
            icon: icons.TramitesIcon,
            children: [
                {
                    id: 'tiposTramite',
                    title: 'Definicion',
                    type: 'item',
                    visible: true,
                    url: '/gestion/tramites/definicion',
                    icon: icons.DefinicionIcon,
                    breadcrumbs: false
                },
                {
                    id: 'tramites',
                    title: 'Tramites',
                    type: 'item',
                    visible: true,
                    url: '/gestion/tramites/tramites',
                    icon: icons.TramitesIcon,
                    breadcrumbs: false
                },
                {
                    id: 'actas',
                    title: 'Actas',
                    type: 'item',
                    visible: false,
                    url: '/gestion/tramites/actas',
                    icon: icons.ActasIcon,
                    breadcrumbs: false
                },
                {
                    id: 'resoluciones',
                    title: 'Resoluciones',
                    type: 'item',
                    visible: false,
                    url: '/gestion/tramites/resoluciones',
                    icon: icons.ResolucionesIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'control',
            title: 'Organo Control',
            type: 'collapse',
            icon: icons.OrganoControlIcon,
            children: [
                {
                    id: 'normas',
                    title: 'Normas',
                    type: 'item',
                    url: '/gestion/control/normas',
                    icon: icons.NormasIcon,
                    breadcrumbs: false
                },
                {
                    id: 'auditorias',
                    title: 'Auditorias',
                    type: 'item',
                    url: '/gestion/control/auditorias',
                    icon: icons.AuditoriasIcon,
                    breadcrumbs: false
                },
                {
                    id: 'inspecciones',
                    title: 'Inspecciones',
                    type: 'item',
                    url: '/gestion/control/inspecciones',
                    icon: icons.InspeccionesIcon,
                    breadcrumbs: false
                },
                {
                    id: 'catas',
                    title: 'Catas',
                    type: 'item',
                    url: '/gestion/control/catas',
                    icon: icons.CatasIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'contraetiquetas',
            title: 'Contraetiquetas',
            type: 'collapse',
            visible: false,
            icon: icons.ContraetiquetasIcon,
            children: [
                {
                    id: 'adquisicion',
                    title: 'Adquisición',
                    type: 'item',
                    url: '/gestion/contraetiquetas/adquisiciones',
                    icon: icons.EntradasIcon,
                    visible: true,
                    breadcrumbs: false
                },
                {
                    id: 'expedicion',
                    title: 'Expedición',
                    type: 'item',
                    url: '/gestion/contraetiquetas/expediciones',
                    icon: icons.ExpedicionIcon,
                    visible: true,
                    breadcrumbs: false
                },
                {
                    id: 'inventario',
                    title: 'Inventario',
                    type: 'item',
                    url: '/gestion/contraetiquetas/inventario',
                    visible: true,
                    icon: icons.InventarioIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'liquidaciones',
            title: 'Liquidaciones',
            type: 'item',
            visible: true,
            url: '/gestion/liquidaciones/liquidaciones',
            icon: icons.LiquidacionesIcon,
            breadcrumbs: false
        },
        {
            id: 'registro',
            title: 'Registro',
            type: 'item',
            visible: false,
            url: '/gestion/secretaria/registro',
            icon: icons.RegistroIcon,
            breadcrumbs: false
        },
        {
            id: 'stock',
            title: 'Stock',
            type: 'item',
            url: '/gestion/stock',
            visible: false,
            icon: icons.StockIcon,
            breadcrumbs: false
        }
    ]
};

export default gestion;
