import * as constState from './constants';
import {
    SCOPE_PRODUCCION,
    SCOPE_ALMACENAMIENTO,
    SCOPE_ELABORACION,
    SCOPE_CRIANZA,
    SCOPE_EMBOTELLADO,
    SCOPE_MIEMBRO,
    LOG_DATA,
    LOG_STATE
} from 'core/constants';
import * as icons from '../../core/icons';
import { IconGlassFull as BodegasIcon } from '@tabler/icons';
import { changeLogs, scopes } from '../../core/enums';

const getLogs = selectedLogs => {
    if (selectedLogs.length === 0) return changeLogs;

    return changeLogs.filter(changeLog =>
        selectedLogs.includes(changeLog.value)
    );
};

const getScopes = selectedScopes => {
    if (selectedScopes.length === 0) return scopes;

    return scopes.filter(scope => selectedScopes.includes(scope.value));
};

const initialEntities = {
    [constState.ENTITY_CONTACTO]: {
        entity: constState.ENTITY_CONTACTO,
        title: 'Contactos',
        id: 'id',
        resume: 'nombre',
        api: 'contactos/',
        icon: icons.ContactIcon,
        //scopes: getScopes([]),
        //changeLogs: getLogs([]),
        message: () => alert(constState.ENTITY_CONTACTO)
    },
    [constState.ENTITY_SOCIO]: {
        entity: constState.ENTITY_SOCIO,
        title: 'Socios',
        maxWidth: false,
        id: 'id',
        resume: 'nombre',
        api: 'maestros/socios/',
        icon: icons.SociosIcon,
        scopes: getScopes([SCOPE_MIEMBRO]),
        changeLogs: getLogs([LOG_STATE]),
        message: () => alert('Este es el Socio')
    },
    [constState.ENTITY_PROVEEDOR]: {
        entity: constState.ENTITY_PROVEEDOR,
        title: 'Proveedores',
        maxWidth: false,
        id: 'id',
        resume: 'nombre',
        api: 'proveedores/',
        icon: icons.ProveedoresIcon,
        message: () => alert('Este es el Proveedor')
    },
    [constState.ENTITY_CLIENTE]: {
        entity: constState.ENTITY_CLIENTE,
        title: 'Clientes',
        maxWidth: false,
        id: 'id',
        resume: 'nombre',
        api: 'clientes/',
        icon: icons.ClientesIcon,
        message: () => alert('Este es el Cliente')
    },
    [constState.ENTITY_VITICULTOR]: {
        entity: constState.ENTITY_VITICULTOR,
        title: 'Viticultores',
        maxWidth: false,
        id: 'id',
        resume: 'nombre',
        api: 'viticultores/',
        icon: icons.ViticultoresIcon,
        scopes: getScopes([SCOPE_MIEMBRO]),
        changeLogs: getLogs([LOG_STATE]),
        message: () => alert('Este es el Viticultor')
    },
    [constState.ENTITY_COSECHA]: {
        entity: constState.ENTITY_COSECHA,
        title: 'Cosecha',
        maxWidth: true,
        id: 'id',
        resume: [
            'vendimia',
            ['parcela', ['municipio', 'descripcion']],
            ['parcela', 'poligono'],
            ['parcela', 'parcela'],
            ['parcela', 'recinto'],
            ['variedad', 'descripcion']
        ],
        api: 'cosecha/',
        icon: icons.RecolectionIcon,
        //scopes: getScopes([SCOPE_MIEMBRO, SCOPE_PRODUCCION]),
        //changeLogs: getLogs([LOG_STATE]),
        message: () => alert('Este es la Cosecha')
    },
    [constState.ENTITY_PARCELA]: {
        entity: constState.ENTITY_PARCELA,
        title: 'Parcelas',
        id: 'id',
        resume: [
            ['municipio', 'descripcion'],
            'poligono',
            'parcela',
            'recinto',
            ['variedad', 'descripcion'],
            'año'
        ],
        api: 'parcelas/',
        icon: icons.ParcelIcon,
        scopes: getScopes([SCOPE_MIEMBRO, SCOPE_PRODUCCION]),
        changeLogs: getLogs([LOG_STATE]),
        message: () => alert('Este es la Parcela')
    },
    [constState.ENTITY_PARCELA_TRAMITE]: {
        entity: constState.ENTITY_PARCELA_TRAMITE,
        title: 'Parcelas Tramites',
        id: 'id',
        resume: [
            ['municipio', 'descripcion'],
            'poligono',
            'parcela',
            'recinto',
            ['variedad', 'descripcion'],
            'año'
        ],
        api: 'parcelas-tramites/',
        icon: icons.ParcelIcon,
        //scopes: getScopes([SCOPE_MIEMBRO, SCOPE_PRODUCCION]),
        //changeLogs: getLogs([LOG_STATE]),
        message: () => alert('Este es la Parcela de un tramite')
    },
    [constState.ENTITY_PARCELA_ACTA]: {
        entity: constState.ENTITY_PARCELA_ACTA,
        title: 'Parcelas Actas',
        id: 'id',
        resume: [
            ['municipio', 'descripcion'],
            'poligono',
            'parcela',
            'recinto',
            ['variedad', 'descripcion'],
            'año'
        ],
        api: 'parcelas-actas/',
        icon: icons.ParcelIcon,
        //scopes: getScopes([SCOPE_MIEMBRO, SCOPE_PRODUCCION]),
        //changeLogs: getLogs([LOG_STATE]),
        message: () => alert('Este es la Parcela de un acta')
    },
    [constState.ENTITY_BODEGA]: {
        entity: constState.ENTITY_BODEGA,
        title: 'Bodegas',
        id: 'id',
        resume: 'nombre',
        api: 'bodegas/',
        icon: BodegasIcon,
        scopes: getScopes([SCOPE_MIEMBRO]),
        changeLogs: getLogs([LOG_STATE]),
        message: () => alert(constState.ENTITY_BODEGA)
    },
    [constState.ENTITY_EXPLOTACION_BODEGA]: {
        entity: constState.ENTITY_EXPLOTACION_BODEGA,
        title: 'Centros',
        id: 'id',
        resume: 'nombre',
        api: 'explotaciones/',
        icon: icons.ResourcesIcon,
        scopes: getScopes([
            SCOPE_MIEMBRO,
            SCOPE_CRIANZA,
            SCOPE_ELABORACION,
            SCOPE_EMBOTELLADO,
            SCOPE_ALMACENAMIENTO
        ]),
        changeLogs: getLogs([LOG_STATE]),
        message: () => alert(constState.ENTITY_EXPLOTACION_BODEGA)
    },
    [constState.ENTITY_MARCA]: {
        entity: constState.ENTITY_MARCA,
        title: 'Marcas',
        id: 'id',
        resume: 'nombre',
        api: 'marcasbodega/',
        icon: icons.BrandsIcon,
        scopes: getScopes([SCOPE_MIEMBRO]),
        changeLogs: getLogs([LOG_STATE]),
        message: () => alert(constState.ENTITY_MARCA)
    },
    [constState.ENTITY_ETIQUETA_MARCA]: {
        entity: constState.ENTITY_ETIQUETA_MARCA,
        title: 'Etiquetas',
        id: 'id',
        resume: 'nombre',
        api: 'etiquetasmarca/',
        icon: icons.LabelsIcon,
        scopes: getScopes([SCOPE_MIEMBRO]),
        changeLogs: getLogs([LOG_STATE]),
        message: () => alert(constState.ENTITY_ETIQUETA_MARCA)
    },
    [constState.ENTITY_SUBCUENTA]: {
        entity: constState.ENTITY_SUBCUENTA,
        title: 'Cuentas',
        id: 'id',
        resume: 'descripcion',
        api: 'subcuentas/',
        icon: icons.CuentasIcon,
        //scopes: getScopes([]),
        //changeLogs: getLogs([]),
        message: () => alert(constState.ENTITY_SUBCUENTA)
    },
    [constState.ENTITY_TRAMITES]: {
        entity: constState.ENTITY_TRAMITES,
        title: 'Tramites',
        id: 'id',
        resume: 'codigo',
        api: 'tramites/solicitud/',
        icon: icons.TramitesIcon,
        //scopes: getScopes([SCOPE_MIEMBRO]),
        //changeLogs: getLogs([]),
        message: () => alert(constState.ENTITY_TRAMITES)
    },
    [constState.ENTITY_ACTAS]: {
        entity: constState.ENTITY_ACTAS,
        title: 'Actas',
        id: 'id',
        resume: 'concepto',
        api: 'actas/',
        icon: icons.ActaIcon,
        //scopes: getScopes([SCOPE_MIEMBRO]),
        //changeLogs: getLogs([]),
        message: () => alert(constState.ENTITY_ACTAS)
    },
    [constState.ENTITY_RESOLUCIONES]: {
        entity: constState.ENTITY_RESOLUCIONES,
        title: 'Resoluciones',
        id: 'id',
        resume: 'concepto',
        api: 'resoluciones/',
        icon: icons.ResolucionesIcon,
        //scopes: getScopes([SCOPE_MIEMBRO]),
        //changeLogs: getLogs([]),
        message: () => alert(constState.ENTITY_RESOLUCIONES)
    },
    [constState.BANCOS]: {
        entity: constState.BANCOS,
        title: 'Bancos',
        id: 'id',
        resume: 'nombre',
        api: 'bancos/',
        icon: icons.BancosIcon,
        message: () => alert('Este es el Banco')
    },
    [constState.VENCIMIENTOS]: {
        entity: constState.VENCIMIENTOS,
        title: 'Vencimientos',
        id: 'id',
        resume: 'nombre',
        api: 'vencimientos/',
        icon: icons.VencimientosIcon,
        message: () => alert('Este es el Vencimiento')
    },
    [constState.DIARIO]: {
        entity: constState.DIARIO,
        title: 'Diario/Mayor',
        id: 'asiento',
        resume: 'concepto',
        api: 'contabilidad/diario/',
        icon: icons.DiarioIcon,
        message: () => alert('Este es el Diario')
    },
    [constState.FORMASPAGO]: {
        entity: constState.FORMASPAGO,
        title: 'Formas de Pago',
        id: 'id',
        resume: 'descripcion',
        api: 'formaspago/',
        icon: icons.FormasPagoIcon,
        message: () => alert('Este es la Forma de Pago')
    },

    [constState.PARTIDAS_PRESUPUESTO]: {
        entity: constState.PARTIDAS_PRESUPUESTO,
        title: 'Conceptos Presupuesto',
        id: 'id',
        resume: 'descripcion',
        api: 'partidaspresupuesto/',
        icon: icons.PartidasPresupuestoIcon,
        message: () => alert('Este es una partida del presupuesto')
    },
    [constState.PRESUPUESTO_CONTABLE]: {
        entity: constState.PRESUPUESTO_CONTABLE,
        title: 'Presupuesto Contable',
        id: 'ejercicio',
        resume: 'descripcion',
        api: 'presupuestocontable/',
        icon: icons.PresupuestoContableIcon,
        message: () => alert('Este es un presupuesto contable')
    },
    [constState.GASTOS]: {
        entity: constState.GASTOS,
        title: 'Gastos',
        id: 'id',
        resume: 'concepto',
        api: 'gastos/',
        icon: icons.GastosIcon,
        message: () => alert('Este es un gasto')
    },
    [constState.ENTITY_LIQUIDACIONES]: {
        entity: constState.ENTITY_LIQUIDACIONES,
        title: 'Liquidaciones',
        id: 'id',
        resume: 'concepto',
        api: 'liquidaciones/',
        icon: icons.LiquidacionesIcon,
        message: () => alert('Este es una liquidacion')
    },
    [constState.TIPOS_TRAMITE]: {
        entity: constState.TIPOS_TRAMITE,
        title: 'Definición Tramites',
        id: 'id',
        resume: 'descripcion',
        api: 'tipostramite/',
        icon: icons.DefinicionIcon,
        message: () => alert('Este es una definicion de tramites')
    },

    [constState.TAREAS]: {
        entity: constState.TAREAS,
        title: 'Tareas',
        id: 'id',
        resume: 'codigo',
        api: 'tareas/',
        icon: icons.TareasIcon,
        message: () => alert('Este es la tarea')
    },
    [constState.MOVIMIENTOS_TESORERIA]: {
        entity: constState.MOVIMIENTOS_TESORERIA,
        title: 'Movimientos Tesorería',
        id: 'id',
        resume: 'concepto',
        api: 'tesoreria/movimientos/',
        icon: icons.CobrosPagosIcon,
        message: () => alert('Este es un moviento de tesoreria')
    },
    [constState.IMPUESTO_347_PROVEEDORES]: {
        entity: constState.IMPUESTO_347_PROVEEDORES,
        title: 'Modelo 347 Proveedores',
        id: 'asiento',
        resume: 'concepto',
        api: 'contabilidad/declaraciones/m347?tipo=proveedores',
        icon: icons.TaxIcon,
        message: () => alert('Este es el Modelo 347 de Proveedores')
    },
    [constState.IMPUESTO_347_CLIENTES]: {
        entity: constState.IMPUESTO_347_CLIENTES,
        title: 'Modelo 347 Clientes',
        id: 'asiento',
        resume: 'concepto',
        api: 'contabilidad/declaraciones/m347?tipo=clientes',
        icon: icons.TaxIcon,
        message: () => alert('Este es el Modelo 347 de Clientes')
    }
};

export default initialEntities;
