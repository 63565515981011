import React, { useEffect, useState } from 'react';

import { Grid } from 'core/components/library';

import {
    Text,
    DataGridPro as DataGrid,
    ViewEntity,
    Literal,
    Tag,
    Button,
    Links,
    Combo,
    Select,
    Alert,
    Switch
} from 'core/components';

import * as icons from 'core/icons';
import DetailParcela from 'views/maestros/Parcelas/Detalle';
import {
    ENTITY_PARCELA,
    ENTITY_PARCELA_TRAMITE
} from '../../../../../../store/entities/constants';
import { useSelector } from 'react-redux';
import * as helpers from '../../../../../../core/helpers';
import List from '../../../../../../store/List';
import * as enums from '../../../../../../core/enums';
import { Typography } from '../../../../../../core/components/library';

const ComboMunicipios = List(Combo, 'options', 'lstmunicipios');

/*
const getLiteral = value => {
    if (!value) return '#';
    if (value.descripcion) return value.descripcion;
    return value;
};*/

const cols = (row, entityData, tipo) => [
    {
        key: 'viticultor',
        title: 'Viticultor',
        align: 'left',
        width: '29%',
        component: row && row.parcelaActual.viticultor.nombre
    },
    {
        key: 'municipio',
        title: 'Municipio',
        align: 'left',
        width: '17%',
        component:
            row &&
            row.parcelaActual.municipio &&
            row.parcelaActual.municipio.descripcion
    },
    {
        key: 'poligono',
        title: 'Polígono',
        align: 'center',
        width: '8%',
        component: row && row.parcelaActual.poligono
    },
    {
        key: 'parcela',
        title: 'Parcela',
        align: 'center',
        width: '8%',
        component: row && row.parcelaActual.parcela
    },
    {
        key: 'recinto',
        title: 'Recinto',
        align: 'center',
        width: '8%',
        component: row && row.parcelaActual.recinto
    },
    {
        key: 'variedad',
        title: 'Variedad',
        align: 'left',
        width: '17%',
        component:
            row &&
            row.parcelaActual.variedad &&
            row.parcelaActual.variedad.descripcion
    },
    {
        key: 'año',
        title: 'Año',
        align: 'center',
        width: '5%',
        component: row && row.parcelaActual.año
    },
    {
        key: 'superficie',
        title: 'Superficie',
        align: 'right',
        width: '8%',
        component:
            row &&
            helpers.getValueFormatted('d.4', row.parcelaActual.superficie)
    }
    /*
    {
        key: 'marcox',
        title: 'x (m)',
        align: 'right',
        width: '4%',
        component: row && helpers.getValueFormatted('d.2', row.marcox)
    },
    {
        key: 'marcoy',
        title: 'y (m)',
        align: 'right',
        width: '4%',
        component: row && helpers.getValueFormatted('d.2', row.marcoy)
    },
    {
        key: 'densidad',
        title: 'Densidad',
        align: 'right',
        width: '6%',
        component: row && helpers.getValueFormatted('entero', row.densidad)
    },
    {
        key: 'sistemaRiego',
        title: 'S. Riego',
        align: 'center',
        width: !tipo.traspaso ? '17%' : '12%',
        component: row && row.sistemaRiego && row.sistemaRiego.descripcion
    },
    {
        key: 'conduccion',
        title: 'Conducción',
        align: 'center',
        width: !tipo.traspaso ? '15%' : '12%',
        component: row && row.conduccion && row.conduccion.descripcion
    },
    {
        key: 'modoCultivo',
        title: 'Cultivo',
        align: 'center',
        width: !tipo.traspaso ? '14%' : '12%',
        component: row && row.modoCultivo && row.modoCultivo.descripcion
    },*/
];

const Bloqueos = props => {
    const { values, editing, execAction, refresh } = props;

    const [isBusy, setIsBusy] = useState(false);
    const [dialog, setDialog] = useState('');
    const [localState, setLocalState] = useState({
        tramite: values.id,
        fecha: values.fecha,
        viticultor: values.viticultor ? values.viticultor.id : null,
        bloqueo: null,
        municipio: null,
        poligono: null,
        parcela: null,
        recinto: null
    });
    const [punteo, setPunteo] = useState(false);
    const [punteados, setPunteados] = useState([]);
    const [totales, setTotales] = useState({
        items: 0,
        itemsPunteados: 0,
        superficie: 0,
        superficiePunteada: 0
        //rendimiento: 0,
        //rendimientoPunteado: 0
    });

    const entityData = useSelector(
        state => state.entities[ENTITY_PARCELA_TRAMITE]
    );

    const entityQuery = useSelector(state => state.entities[ENTITY_PARCELA]);

    useEffect(() => {
        calculaTotales();
    }, [JSON.stringify(punteados), JSON.stringify(values.parcelas)]);

    const calculaTotales = () => {
        let newTotales = {
            items: 0,
            itemsPunteados: 0,
            superficie: 0,
            superficiePunteada: 0
            //rendimiento: 0,
            //rendimientoPunteado: 0
        };

        if (values && values.parcelas) {
            values.parcelas.forEach(parcela => {
                newTotales.items += 1;
                newTotales.superficie += Number(
                    parcela.parcelaActual.superficie
                );
                if (punteados.some(item => item.id === parcela.id)) {
                    newTotales.itemsPunteados += 1;
                    newTotales.superficiePunteada += Number(
                        parcela.parcelaActual.superficie
                    );
                }
            });
        }

        setTotales(newTotales);
    };

    const handleChange = value => {
        const field = value[0].field;
        const newValue =
            value[0].value && value[0].value.name
                ? value[0].value.selected
                : value[0].value === ''
                  ? null
                  : value[0].value;

        setLocalState({
            ...localState,
            [field]: newValue
        });
    };

    const findParcelas = () => {
        document.getElementById('poligono').focus();
        setIsBusy(true);
        execAction(
            {
                api: 'tramites/addparcelas',
                metodo: 'post',
                data: {
                    ...localState,
                    tramite: values.id
                }
            },
            [
                data => {
                    setIsBusy(false);
                    if (data.length === 0) {
                        setDialog(
                            `No se han incluido nuevas parcelas con el criterio de selección especificado.
                            Puede ser que no existan o bien ya se han incluido con anterioridad`
                        );
                    } else refresh(false);
                }
            ],
            true
        );
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Select
                        fullWidth
                        variant='standard'
                        name='bloqueo'
                        label='Bloqueo'
                        value={localState.bloqueo}
                        onChange={handleChange}
                        editing={true}
                        options={enums.locksParcel}
                    />
                </Grid>
                <Grid item xs>
                    <ComboMunicipios
                        fullWidth
                        variant='standard'
                        label='Municipio'
                        api='auxiliares/municipios'
                        name='municipio'
                        nameKey='municipioId'
                        refresh={refresh}
                        Icon={icons.MunicipioIcon}
                        value={localState.municipio}
                        onChange={handleChange}
                        editing={true}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item>
                    <Text
                        id={'poligono'}
                        fullWidth
                        variant='standard'
                        editing={true}
                        name='poligono'
                        label='Poligono'
                        type='entero'
                        value={localState.poligono}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        editing={true}
                        name='parcela'
                        label='Parcela'
                        variant='standard'
                        type='entero'
                        value={localState.parcela}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        editing={true}
                        name='recinto'
                        label='Recinto'
                        variant='standard'
                        value={localState.recinto}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Button
                        busy={isBusy}
                        toolTipText={'Buscar Parcela'}
                        icon={<icons.BuscarIcon />}
                        hidden={
                            editing ||
                            values.resolucion === 'Resuelto' ||
                            !localState.bloqueo ||
                            !localState.municipio ||
                            (!localState.poligono && !values.viticultor) ||
                            (!localState.parcela && !values.viticultor)
                        }
                        onClick={() => findParcelas()}
                        float={true}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent={'space-around'}
                alignItems={'center'}
            >
                <Grid item>
                    <Switch
                        value={punteo}
                        onChange={values => setPunteo(values[0].value)}
                        name='punteo'
                        label='Puntear Parcelas'
                        editing={true}
                    />
                </Grid>
                <Grid item xs>
                    <Grid
                        sx={{
                            marginTop: '10px',
                            marginBottom: '10px',
                            backgroundColor: 'lightgray',
                            height: 25,
                            borderRadius: 1
                        }}
                        container
                        justifyContent={'space-around'}
                        alignItems={'center'}
                    >
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Parcelas: ${punteo ? helpers.getValueFormatted('entero', totales.itemsPunteados) + ' de ' : ''}${helpers.getValueFormatted('entero', totales.items)}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Superficie: ${punteo ? helpers.getValueFormatted('d.4', totales.superficiePunteada) + ' de ' : ''}${helpers.getValueFormatted('d.4', totales.superficie)}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs>
                    <DataGrid
                        allowSelect={punteo ? 'multiple' : undefined}
                        changeSelectedItems={items => setPunteados(items)}
                        name='parcelas'
                        editing={editing}
                        readOnly={values.resolucion === 'Resuelto'}
                        rows={values.parcelas ? values.parcelas : []}
                        cols={(name, row, index) =>
                            cols(row, entityData, values.tipo)
                        }
                        getId={row => row.parcelaActual.id}
                        notAdd={true}
                        onDelete={(name, index, row) => {
                            execAction(
                                {
                                    api: entityData.api,
                                    metodo: 'delete',
                                    data: [row.id]
                                },
                                [() => refresh(false)]
                            );
                        }}
                        //minHeight={456}
                        //maxHeight={456}
                    >
                        {!punteo && (
                            <ViewEntity
                                readOnly
                                type={ENTITY_PARCELA}
                                refresh={refresh}
                                entity={entityQuery}
                            >
                                {DetailParcela}
                            </ViewEntity>
                        )}
                    </DataGrid>
                </Grid>
            </Grid>
            <Alert
                open={dialog !== ''}
                title='Parcelas seleccionadas'
                text={dialog}
                cancel={() => {
                    setDialog('');
                }}
            />
        </>
    );
};

export default Bloqueos;
