import { connect } from 'react-redux';
import {
    getToken,
    getAuthUser,
    getAuthInicio,
    getAuthPermisos,
    isAuthExpired,
    isAuthReady,
    isAuthLogged,
    hasAuthErrors
} from '../reducer';

import {
    init,
    login,
    logout,
    redirectTo,
    getAccesos,
    getPermiso,
    getEquipos,
    clearAuth
} from './actions';

/*
export const Auth = ({children, ...rest}) => {
    const { doInit }=rest;

    useEffect(()=>
    {
        doInit();

    },[]);

   return(children(rest))
};*/

const map2Props = state => {
    return {
        token: getToken(state),
        user: getAuthUser(state),
        permisos: getAuthPermisos(state),
        inicio: getAuthInicio(state),
        isReady: isAuthReady(state),
        isLogged: isAuthLogged(state),
        expired: isAuthExpired(state),
        hasErrors: !!hasAuthErrors(state),
        errors: hasAuthErrors(state),
        accesos: entidades => getAccesos(state, entidades),
        permiso: (permiso, entidad, instancia) =>
            getPermiso(state, permiso, entidad, instancia),
        equipos: () => getEquipos(state)
    };
};

// Mapeo las acciones que realiza el componente con el dispatch de las acciones del middleware
const map2Dispatch = dispatch => {
    return {
        doInit: () => {
            dispatch(init());
        },
        doLogin: (user, pwd, pin) => {
            dispatch(login(user, pwd, pin));
        },
        doLogout: () => {
            dispatch(logout());
        },
        setRedirectTo: link => {
            dispatch(redirectTo(link));
        },
        clear: () => {
            dispatch(clearAuth());
        }
    };
};

export const AuthContainer = (componente, nombre) => {
    return connect(
        map2Props,
        map2Dispatch,
        (stateProps, dispatchProps, ownProps) => {
            const api = {
                [nombre]: Object.assign({}, stateProps, dispatchProps)
            };

            return Object.assign({}, ownProps, api);
        }
    )(componente);
};

export default AuthContainer;
