export const LIST_INIT = 'LIST_INIT';
export const LIST_SUCCESS = 'LIST_SUCCESS';
export const LIST_FAILED = 'LIST_FAILED';
export const LIST_REFRESH = 'LIST_REFRESH';
export const LIST_REFRESHING = 'LIST_REFRESHING';
export const LIST_UNLOAD = 'LIST_UNLOAD';
export const LIST_UPDATING = 'LIST_UPDATING';
export const LIST_SAVED = 'LIST_SAVED';
export const LIST_DELETED = 'LIST_DELETED';
export const LIST_CLEAR = 'LIST_CLEAR';
export const LIST_CLEAR_FILTER = 'LIST_CLEAR_FILTER';
export const LIST_FILTERED = 'LIST_FILTERED';
export const LIST_REORDER = 'LIST_REORDER';
export const LIST_SELECT_ONE = 'LIST_SELECT_ONE';
export const LIST_SELECT_SOME = 'LIST_SELECT_SOME';
export const LIST_SELECT_ALL = 'LIST_SELECT_ALL';
export const LIST_SETVISIBLES = 'LIST_SETVISIBLES';
export const LIST_SETPAGE = 'LIST_SETPAGE';
export const LIST_FIRST_PAGE = 'LIST_FIRST_PAGE';
export const LIST_PREVIOUS_PAGE = 'LIST_PREVIOUS_PAGE';
export const LIST_NEXT_PAGE = 'LIST_NEXT_PAGE';
export const LIST_LAST_PAGE = 'LIST_LAST_PAGE';
