import React, { useState, useEffect } from 'react';

import { makeStyles } from '../../library';
import {
    Typography,
    Card,
    CardHeader,
    CardContent,
    LinearProgress,
    Divider,
    Tooltip,
    IconButton,
    Grid
} from '../../library';
import { StateLine } from '../..';

import { CloseIcon } from '../../icons';
import * as hlprs from '../../helpers';

import Toolbar from '../Edition';
import { Alert, Error } from '../../';

const useStyles = makeStyles(theme => ({
    root: {
        //backgroundColor: 'grey',
        //minHeight: '70%'
    },
    head: {
        //backgroundColor: 'red',
        padding: 6
    },
    content: {
        //backgroundColor: 'blue',
        padding: 8,
        height: '100%'
    },
    detail: {
        //backgroundColor: 'yellow',
        height: '100%',
        overflowY: 'auto'
    },

    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    wrapper: {
        //backgroundColor: 'maroon',
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-star',
        alignItems: 'center',
        '& > *': {
            marginRight: 2
        }
    }
}));

const getTitle = (item, entity) => {
    if (entity.isNew) return 'Nuevo';
    if (entity.isEditable) return 'Editando';

    const prps = item.resume;
    let title = '';

    if (!Array.isArray(prps)) {
        title = entity.entity[prps];
    } else {
        for (var k = 0; k <= prps.length - 1; k++) {
            if (title !== '') title = title + '-';
            const value = hlprs.getValue(entity.entity, prps[k]);
            title = title + (value ? value : '#');
        }
    }

    title = title === undefined ? item.title : title === null ? '' : title;

    return title;
};

const Element = props => {
    const {
        auth,
        entity,
        item,
        close,
        edit,
        del,
        readOnly,
        refreshIndex,
        lite,
        ...rest
    } = props;

    const classes = useStyles();
    const Detail = item.element;

    const [cancel, setCancel] = useState(false);
    const [values, setValues] = useState({});
    const [changes, setChanges] = useState({});
    const [op, setOp] = useState(-1);
    const [dataErrors, setDataErrors] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [componentTopPosition, setComponentTopPosition] = useState('0px');

    const updatePosition = () => {
        const element = document.getElementById('element');
        let positionInPixels = 172;

        if (element) {
            positionInPixels = element.getBoundingClientRect().top;
        }

        setComponentTopPosition(`${positionInPixels + 20}px`);
    };

    useEffect(() => {
        updatePosition();
        window.addEventListener('resize', updatePosition);

        if (item.api !== undefined) {
            entity.load(item.api);

            return () => {
                entity.unload();
                window.removeEventListener('resize', updatePosition);
            };
        }

        return () => window.removeEventListener('resize', updatePosition);
    }, []);

    useEffect(() => {
        if (entity.isReady) {
            if (!item.dfault) {
                setValues(hlprs.clone(entity.entity));
                setChanges(hlprs.clone(entity.changes));
            } else {
                const newEntity = {
                    ...hlprs.clone(entity.entity),
                    ...item.dfault
                };

                const newChanges = {
                    ...hlprs.clone(entity.changes),
                    ...item.dfault
                };

                entity.update(newChanges);

                setValues(newEntity);
                setChanges(newChanges);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity.isReady]);

    useEffect(() => {
        if (entity.isReady) {
            edit &&
                edit(
                    item.key,
                    cancel,
                    entity.entity.id,
                    entity.isEditable,
                    entity.entity
                );
            setCancel(false);
            if (!entity.isEditable) {
                setValues(hlprs.clone(entity.entity));
                setChanges({});
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity.isEditable]);

    useEffect(() => {
        if (entity.isReady) {
            if (!entity.isRefreshing) {
                setValues(hlprs.clone(entity.entity));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity.isRefreshing]);

    const refresh = onlyIndex => {
        entity.refresh(undefined, undefined, onlyIndex);
        refreshIndex && refreshIndex();
    };

    const reload = newEntity => {
        if (newEntity) {
            setValues(hlprs.clone(newEntity.entity));
            setChanges(hlprs.clone(newEntity.changes));
        } else {
            setValues(hlprs.clone(entity.entity));
            setChanges(hlprs.clone(entity.changes));
        }
    };

    const cleanField = fields => {
        if (entity.isEditable) {
            let newValues = values;
            let newChanges = changes;

            fields.forEach(field => {
                var value = newValues[field];
                if (value) {
                    var newValue = null;

                    if (typeof value === 'object' && Array.isArray(value)) {
                        newValue = [];
                    }

                    newValues[field] = newValue;

                    newChanges = {
                        ...newChanges,
                        [field]: newValue
                    };
                }
            });

            clearTimeout(op);
            setValues(newValues);
            setChanges(newChanges);
            setOp(setTimeout(() => entity.update(newChanges), 400));
        }
    };

    const handleChange = (chngs, detail, key) => {
        //console.log(chngs,detail,key)
        if (entity.isEditable) {
            let newValues = values;
            let newChanges = changes;

            if (detail && (key < 0 || key === undefined)) {
                if (key === undefined) {
                    // Añado una nueva linea
                    const newDetail = [];

                    if (
                        newValues[detail] !== undefined &&
                        newValues[detail] !== null
                    )
                        newValues[detail].forEach((listItem, index) =>
                            newDetail.push(listItem)
                        );

                    newDetail.push(chngs);

                    newValues[detail] = newDetail;

                    newChanges = {
                        ...newChanges,
                        [detail]: newDetail
                    };
                } else {
                    // Baja de linea
                    const newDetail = [];

                    newValues[detail].forEach(
                        (listItem, index) =>
                            index !== Math.abs(key) - 1 &&
                            newDetail.push(listItem)
                    );

                    newValues[detail] = newDetail;

                    newChanges = {
                        ...newChanges,
                        [detail]: newDetail
                    };
                }
            } else {
                chngs.forEach(change => {
                    if (
                        change.value !== null &&
                        typeof change.value === 'object'
                    ) {
                        if (Array.isArray(change.value.value)) {
                            // Combo Multiple
                            if (detail) {
                                const newDetail = newValues[detail].map(
                                    (listItem, index) =>
                                        index !== key
                                            ? listItem
                                            : {
                                                  ...listItem,
                                                  [change.field]:
                                                      change.value.value
                                              }
                                );

                                newValues[detail] = newDetail;

                                newChanges = {
                                    ...newChanges,
                                    [detail]: newDetail
                                };
                            } else {
                                newValues = {
                                    ...newValues,
                                    [change.field]: change.value.value
                                };
                                newChanges = {
                                    ...newChanges,
                                    [change.field]: change.value.value
                                };
                            }
                        } else {
                            // Combo Simple
                            if (detail) {
                                const newDetail = newValues[detail].map(
                                    (listItem, index) =>
                                        index !== key
                                            ? listItem
                                            : {
                                                  ...listItem,
                                                  [change.value.nameKey]:
                                                      change.value.value === ''
                                                          ? null
                                                          : change.value.value,
                                                  [change.value.name]:
                                                      change.value.selected
                                              }
                                );

                                newValues[detail] = newDetail;

                                newChanges = {
                                    ...newChanges,
                                    [detail]: newDetail
                                };
                            } else {
                                newValues = {
                                    ...newValues,
                                    [change.value.nameKey]:
                                        change.value.value === ''
                                            ? null
                                            : change.value.value,
                                    [change.value.name]: change.value.selected
                                };
                                newChanges = {
                                    ...newChanges,
                                    [change.value.nameKey]:
                                        change.value.value === ''
                                            ? null
                                            : change.value.value,
                                    [change.value.name]: change.value.selected
                                };
                            }
                        }
                    } else {
                        // Control simple
                        if (detail) {
                            const newDetail = newValues[detail].map(
                                (listItem, index) =>
                                    index !== key
                                        ? listItem
                                        : {
                                              ...listItem,
                                              [change.field]:
                                                  change.value === ''
                                                      ? null
                                                      : change.value
                                          }
                            );

                            newValues[detail] = newDetail;

                            newChanges = {
                                ...newChanges,
                                [detail]: newDetail
                            };
                        } else {
                            newValues = {
                                ...newValues,
                                [change.field]:
                                    change.value === '' ? null : change.value
                            };
                            newChanges = {
                                ...newChanges,
                                [change.field]:
                                    change.value === '' ? null : change.value
                            };
                        }
                    }
                });
            }

            clearTimeout(op);
            setValues(newValues);
            setChanges(newChanges);
            setOp(setTimeout(() => entity.update(newChanges), 400));
        }
    };

    const saveEntity = () => {
        entity.save();

        // Pongo esto porque es edicion continua
        setChanges({});
    };

    const deleteEntity = () => {
        if (del) {
            // POR AQUI HAGO EL BORRADO DESDE UN DETALLE EN UN DIALOGO
            entity.delete([() => del(item.id)]);
        } else {
            // POR AQUI INTENTO HACER EL BORRADO DESDE UN DETALLE CUALQUIERA
            entity.delete([
                () => {
                    close(item.key);
                }
            ]);
        }
    };

    const cancelEntity = () => {
        if (item.api)
            if (item.api.toLowerCase().indexOf('/new') !== -1) {
                close && close(item.key);
            } else {
                setCancel(true);
                entity.undo();
            }
        else {
            setCancel(true);
            entity.undo();
        }
    };

    const editEntity = () => {
        entity.edit(!entity.isEditable);
    };

    const handleError = (field, error) => {
        let newErrors = { ...dataErrors };

        if (field) {
            if (!error) delete newErrors[field];
            else {
                newErrors = { ...newErrors, [field]: error };
            }

            if (JSON.stringify(dataErrors) !== JSON.stringify(newErrors))
                setDataErrors(newErrors);
        }
    };

    /*
        si no pongo || Object.keys(values).length === 0 no carga los select y
        si lo pongo cuando viene de un alta con la entidad {} no carga el elemento
     */

    if (
        !entity.isReady ||
        (Object.keys(values).length === 0 &&
            entity.api.substring(entity.api.length - 3) !== 'new' &&
            !entity.hasErrors)
    )
        return (
            <div
                style={{
                    width: '100%'
                }}
            >
                <LinearProgress />
            </div>
        );

    return (
        <Card
            id={'element'}
            sx={{
                height: `calc(100vh - ${componentTopPosition})`
            }}
            raised={true}
        >
            <Error
                entities={[entity]}
                cancel={
                    entity.isEditable
                        ? undefined
                        : Object.keys(values).length === 0
                          ? () => close && close(item.key)
                          : undefined
                }
            />

            <CardHeader
                className={classes.head}
                title={
                    <Typography variant='h4'>
                        {getTitle(item, entity)}
                    </Typography>
                }
                subheader={!lite && '(*) Información requerida.'}
                action={
                    <div className={classes.wrapper}>
                        {!lite && (
                            <Typography variant='caption'>{`Ref. ${item.id}`}</Typography>
                        )}
                        <Toolbar
                            readOnly={readOnly}
                            lite={lite}
                            item={item}
                            entityData={item.entityData}
                            id={item.id}
                            editing={entity.isEditable}
                            updating={entity.isUpdating}
                            edit={editEntity}
                            save={saveEntity}
                            del={() => setOpenDelete(true)}
                            cancel={cancelEntity}
                            changes={
                                Object.keys(dataErrors).length > 0
                                    ? 0
                                    : Object.keys(entity.changes).length
                            }
                        />
                    </div>
                }
                avatar={
                    close && (
                        <Tooltip title={'Cerrar ' + item.title}>
                            <IconButton
                                //disabled={entity.isEditable}
                                onClick={() =>
                                    entity.isEditable &&
                                    Object.keys(entity.changes).length !== 0
                                        ? setOpenDialog(true)
                                        : close(item.key)
                                }
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
            />
            {(!lite || entity.isNew) && (
                <>
                    <Divider />
                    <CardContent className={classes.content}>
                        <Grid container className={classes.wrapper}>
                            <Grid item xs className={classes.detail}>
                                <Detail
                                    {...rest}
                                    dataErrors={dataErrors}
                                    related={item.related}
                                    entity={item.entityData}
                                    auth={auth}
                                    values={values}
                                    initial={entity.initial}
                                    editing={entity.isEditable} //&& !entity.isUpdating}
                                    adding={entity.isNew}
                                    updating={entity.isUpdating}
                                    handleChange={handleChange}
                                    handleError={handleError}
                                    cleanField={cleanField}
                                    refresh={refresh}
                                    reload={reload}
                                    lock={value =>
                                        value ? entity.lock() : entity.unlock()
                                    }
                                    execAction={(
                                        action,
                                        postActions,
                                        onlyData
                                    ) =>
                                        entity.exec(
                                            action,
                                            postActions,
                                            onlyData
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </>
            )}
            <Alert
                open={openDialog}
                title='¿Hay cambios pendientes de aceptar. Esta seguro de cerrar?'
                text={`Si "Acepta" perdera todos los cambios realizados desde la ultima vez que los guardo. Tenga en cuenta que esta acción es irreversible.`}
                cancel={() => setOpenDialog(false)}
                accept={() => {
                    setOpenDialog(false);
                    close(item.key);
                }}
            />
            <Alert
                open={openDelete}
                title='¿Esta seguro de eliminar el valor?'
                text={`Si "Acepta" este valor sera eliminado de todos los lugares en uso. Tenga en cuenta que esta acción es irreversible.`}
                cancel={() => {
                    setOpenDelete(false);
                }}
                accept={() => {
                    setOpenDelete(false);
                    deleteEntity();
                }}
            />
        </Card>
    );
};

export default Element;
