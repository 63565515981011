import React from 'react';

import { makeStyles } from '../../library';

import {
    IconButton,
    Tooltip,
    TableCell,
    TableHead,
    TableRow
} from '../../library';

import * as icons from '../../icons';

const useStyles = makeStyles(theme => ({
    row: {},
    cell: {
        padding: '0px',
        paddingRight: '4px'
    },
    actionCell: {
        padding: '0px'
    }
}));

const Foot = props => {
    const { cols, onAdd, onOpen, editing } = props;

    const classes = useStyles();

    return (
        <TableHead>
            <TableRow className={classes.row}>
                {((onOpen && !editing) || (onAdd && editing)) && (
                    <TableCell
                        className={classes.actionCell}
                        width='auto'
                        align={'center'}
                    ></TableCell>
                )}
                {cols &&
                    cols.map(col => (
                        <TableCell
                            className={classes.cell}
                            width={col.width}
                            //style={{minWidth:170}}
                            align={col.align}
                        >
                            {col.aggregate && 'acumulado'}
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
};

export default Foot;
