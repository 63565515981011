import React from 'react';

import { Grid } from 'core/components/library';

import {
    Select,
    DataGridPro as DataGrid,
    Text,
    Tag,
    Literal,
    ViewEntity
} from 'core/components';

import * as enums from 'core/enums';
import DetailTarea from '../../../../../seguimiento/Tareas/Detalle';
import * as helpers from '../../../../../../core/helpers';

import {
    ENTITY_EXPLOTACION_BODEGA,
    TAREAS
} from '../../../../../../store/entities/constants';
import { useSelector } from 'react-redux';
import DetailExplotacion from '../../../../../maestros/Explotaciones/Detalle';

const cols = (row, entityData) => [
    {
        key: 'codigo',
        title: 'Tarea',
        align: 'center',
        width: '10%',
        component: row && (
            <Literal text={row['codigo']}>
                <entityData.icon fontSize={'small'} />
            </Literal>
        )
    },
    {
        key: 'fecha',
        title: 'Fecha',
        align: 'center',
        width: '10%',
        component: row && (
            <>{helpers.getValueFormatted('datetime-base', row['fecha'])}</>
        )
    },
    {
        key: 'resumen',
        title: 'Nombre',
        align: 'left',
        width: '50%',
        component: row && <>{row['resumen']}</>
    },
    {
        key: 'prioridad',
        title: 'Prioridad',
        align: 'center',
        width: '10%',
        component: row && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(
                        enums.prioridades,
                        row.prioridad,
                        'color'
                    )
                }}
                Icon={enums.getItem(enums.prioridades, row.prioridad, 'icon')}
                value={row.prioridad}
            />
        )
    },
    {
        key: 'estado',
        title: 'Estado',
        align: 'center',
        width: '10%',
        component: row && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(enums.statesTask, row.estado, 'color')
                }}
                Icon={enums.getItem(enums.statesTask, row.estado, 'icon')}
                value={row.estado}
            />
        )
    },
    {
        key: 'validacion',
        title: 'Validacion',
        align: 'center',
        width: '10%',
        component: row && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(
                        enums.validationsTask,
                        row.validacion,
                        'color'
                    )
                }}
                Icon={enums.getItem(
                    enums.validationsTask,
                    row.validacion,
                    'icon'
                )}
                value={row.validacion}
            />
        )
    }
];
/*
const cols = (name, row, index, editing, adding, handleChange, refresh) => [
    {
        key: 'tarea',
        title: 'Tarea',
        align: 'left',
        width: '10%',
        component: row && (
            <Tag
                //variant="outlined"
                size={'small'}
                sx={{ color: 'green' }}
                Icon={icons.TareasIcon}
                value={row['codigo']}
                id={row['id']}
                relacionado={
                    !adding && {
                        Detail: DetailTarea,
                        resume: ['codigo'],
                        api: 'tareas',
                        readOnly: false
                    }
                }
                refresh={refresh}
            />
        )
    },
    {
        key: 'fecha',
        title: 'Fecha',
        align: 'left',
        width: '10%',
        component: row && <>{helpers.getValueFormatted('date', row.fecha)}</>
    },
    {
        key: 'resumen',
        title: 'Resumen',
        align: 'left',
        width: '50%',
        component: row && <>{row.resumen}</>
    },
    {
        key: 'prioridad',
        title: 'Prioridad',
        align: 'left',
        width: '10%',
        component: row && (
            <Select
                editing={editing}
                name='prioridad'
                variant='standard'
                margin='none'
                options={enums.prioridades}
                value={row['prioridad']}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    },
    {
        key: 'estado',
        title: 'Estado',
        align: 'left',
        width: '10%',
        component: row && (
            <Select
                editing={editing}
                name='estado'
                variant='standard'
                margin='none'
                options={enums.statesTask}
                value={row['estado']}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    },
    {
        key: 'validacion',
        title: 'Validacion',
        align: 'left',
        width: '10%',
        component: row && (
            <Select
                editing={editing}
                name='validacion'
                variant='standard'
                margin='none'
                options={enums.validationsTask}
                value={row['validacion']}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    }
];*/

const Tareas = props => {
    const { values, editing, adding, handleChange, refresh, execAction } =
        props;
    const entityData = useSelector(state => state.entities[TAREAS]);

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <DataGrid
                    //title='Centros'
                    name='tareas'
                    editing={editing}
                    rows={values.tareas ? values.tareas : []}
                    cols={(name, row, index) => cols(row, entityData)}
                    onDelete={(name, index, row) => {
                        execAction(
                            {
                                api: entityData.api,
                                metodo: 'delete',
                                data: [row.id]
                            },
                            [() => refresh(false)]
                        );
                    }}
                    //maxHeight={456}
                >
                    <ViewEntity
                        key={TAREAS}
                        refresh={refresh}
                        entity={entityData}
                        initValue={{
                            tramite: { id: values.id },
                            codigo: `${values.codigo}-${
                                values.tareas ? values.tareas.length + 1 : 1
                            }`
                        }}
                    >
                        {DetailTarea}
                    </ViewEntity>
                </DataGrid>
            </Grid>
        </Grid>
    );
};

export default Tareas;
