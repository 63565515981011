import {
    IconGlassFull,
    IconPalette,
    IconShadow,
    IconTag,
    IconListDetails,
    IconBuildingFactory,
    IconPackage,
    IconCircleCheck,
    IconBooks
} from '@tabler/icons';

import * as icons from 'core/icons';

const administracion = {
    id: 'administracion',
    title: 'Administración',
    type: 'group',
    visible: true,
    children: [
        {
            id: 'tramites',
            title: 'Tramites',
            type: 'item',
            url: '/administracion/tramites',
            icon: icons.TramitesIcon,
            breadcrumbs: false
        },
        {
            id: 'contabilidad',
            title: 'Contabilidad',
            type: 'collapse',
            visible: true,
            icon: icons.ContabilidadIcon,
            children: [
                {
                    id: 'partidasPresupuesto',
                    title: 'Partidas',
                    type: 'item',
                    visible: true,
                    url: '/administracion/contabilidad/partidas',
                    icon: icons.PartidasPresupuestoIcon,
                    breadcrumbs: false
                },
                {
                    id: 'presupuestoContable',
                    title: 'Presupuestos',
                    type: 'item',
                    visible: true,
                    url: '/administracion/contabilidad/presupuestos',
                    icon: icons.PresupuestoContableIcon,
                    breadcrumbs: false
                },
                {
                    id: 'cuentas',
                    title: 'Cuentas',
                    type: 'item',
                    visible: true,
                    url: '/administracion/contabilidad/cuentas',
                    icon: icons.CuentasIcon,
                    breadcrumbs: false
                },
                {
                    id: 'asientos',
                    title: 'Asientos',
                    type: 'item',
                    url: '/administracion/contabilidad/asientos',
                    icon: icons.AsientosIcon,
                    breadcrumbs: false
                },
                {
                    id: 'gastos',
                    title: 'Gastos',
                    type: 'item',
                    visible: true,
                    url: '/administracion/contabilidad/gastos',
                    icon: icons.GastosIcon,
                    breadcrumbs: false
                },
                {
                    id: 'diario',
                    title: 'Diario/Mayor',
                    type: 'item',
                    url: '/administracion/contabilidad/diario',
                    visible: true,
                    icon: icons.DiarioIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'tesoreria',
            title: 'Tesorería',
            type: 'collapse',
            icon: icons.TesoreriaIcon,
            visible: true,
            children: [
                {
                    id: 'formasPago',
                    title: 'Formas de Pago',
                    type: 'item',
                    url: '/administracion/tesoreria/formaspago',
                    icon: icons.FormasPagoIcon,
                    visible: true,
                    breadcrumbs: false
                },
                {
                    id: 'bancos',
                    title: 'Bancos',
                    type: 'item',
                    url: '/administracion/tesoreria/bancos',
                    icon: icons.BancosIcon,
                    visible: true,
                    breadcrumbs: false
                },
                {
                    id: 'vencimientos',
                    title: 'Vencimientos',
                    type: 'item',
                    url: '/administracion/tesoreria/vencimientos',
                    icon: icons.VencimientosIcon,
                    visible: true,
                    breadcrumbs: false
                },
                {
                    id: 'movimientosTesoreria',
                    title: 'Movimientos',
                    type: 'item',
                    url: '/administracion/tesoreria/movimientos',
                    icon: icons.CobrosPagosIcon,
                    visible: true,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'impuestos',
            title: 'Declaraciones / Impuestos',
            type: 'collapse',
            visible: false,
            icon: icons.TaxesIcon,
            children: [
                {
                    id: 'm347Proveedores',
                    title: '347 Proveedores',
                    type: 'item',
                    visible: true,
                    url: '/administracion/contabilidad/declaraciones/m347proveedores',
                    icon: icons.TaxIcon,
                    breadcrumbs: false
                },
                {
                    id: 'm347Clientes',
                    title: '347 Clientes',
                    type: 'item',
                    visible: false,
                    url: '/administracion/contabilidad/declaraciones/m347clientes',
                    icon: icons.TaxIcon,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default administracion;
