import React, { useEffect, useState } from 'react';

import { makeStyles, Tooltip } from '../library';
import {
    InputAdornment,
    TextField,
    Typography,
    Autocomplete,
    Chip,
    MenuItem
} from '../library';

import * as icons from '../icons';
import * as helpers from '../helpers';
import { SearchAcumulativeIcon, SearchIcon, SearchNotIcon } from '../icons';

const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-star',
        alignItems: 'center',
        '& > *': {
            marginRight: theme.spacing(1)
        }
    }
}));

const getNewFlag = flag => {
    if (flag === '!') return '===';
    if (flag === '===') return '=';

    return '!';
};

const getIconFlag = flag => {
    const size = 20;

    if (flag === '!')
        return <SearchNotIcon sx={{ fontSize: size, color: 'darkred' }} />;

    if (flag === '===')
        return (
            <SearchAcumulativeIcon sx={{ fontSize: size, color: 'darkblue' }} />
        );

    return <SearchIcon sx={{ fontSize: size }} />;
};

const getTextFlag = flag => {
    if (flag === '!') return 'Valores que NO INCLUYAN';

    if (flag === '===') return 'Valores que CUMPLAN ESTRICTAMENTE';

    return 'Valores que INCLUYAN';
};

const cargaOpciones = (lista, api) => {
    if (lista.isInit !== undefined) {
        return;
    }

    lista.load(api, undefined);
};

const FilterSelectorApi = props => {
    const {
        title,
        value,
        modificador,
        list,
        multiple,
        onFilter,
        onChange,
        onClick,
        api,
        color,
        field,
        icon
    } = props;
    const classes = useStyles();

    const [flag, setFlag] = useState(modificador);
    const [options, setOptions] = useState([]);
    const Icon = icon;

    useEffect(() => {
        return api ? list.unload : () => {};
    }, []);

    useEffect(() => {
        if (list.isReady) {
            setOptions(list.list.map(item => item[field]));
        }
    }, [list.isReady]);

    useEffect(() => {
        if (value) {
            filtra(value);
        }
    }, [JSON.stringify(value)]);

    useEffect(() => {
        if (value) {
            filtra(value);
        }
    }, [flag]);

    /*
    const getOption = value => {
        if (Array.isArray(value)) {
            let newArray = [];
            value.forEach(v => {
                newArray.push(getOption(v));
            });

            return newArray;
        }

        return list.list.find(option => option[field] === value);
    };

    const getValue = option => {
        if (Array.isArray(option)) {
            let newArray = [];
            option.forEach(o => {
                newArray.push(getValue(o));
            });

            return newArray;
        }

        const newValue = option ? option[field] : null;

        return newValue;
    };*/

    const filtra = value => {
        onChange && onChange(value);
        let newValue = null;

        if (Array.isArray(value))
            value.length === 0 ? (newValue = '') : (newValue = value);
        else !value ? (newValue = '') : (newValue = value);

        helpers.timeBox(() => onFilter && onFilter(newValue, flag), 200);
    };

    return (
        <Autocomplete
            filterSelectedOptions
            defaultValue={value ? value : []}
            style={{ minWidth: 200 }}
            multiple={multiple}
            onOpen={event => cargaOpciones(list, api)}
            onChange={(event, data) => filtra(data)}
            options={options}
            getOptionLabel={option => option}
            clearText='Borrar'
            closeText='Cerrar'
            openText='Abrir'
            noOptionsText={'No encontrado'}
            renderInput={props => (
                <TextField
                    {...props}
                    fullWidth
                    label={title}
                    variant='standard'
                    size='small'
                    margin='none'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        ...props.InputProps,
                        startAdornment: (
                            <React.Fragment>
                                <InputAdornment
                                    sx={{
                                        cursor:
                                            onClick && modificador
                                                ? 'pointer'
                                                : 'default'
                                    }}
                                    position='start'
                                    onClick={
                                        onClick &&
                                        modificador &&
                                        (() => {
                                            const newFlag = getNewFlag(flag);
                                            setFlag(newFlag);
                                            onClick(newFlag);
                                        })
                                    }
                                >
                                    {modificador ? (
                                        <Tooltip title={getTextFlag(flag)}>
                                            {getIconFlag(flag)}
                                        </Tooltip>
                                    ) : (
                                        <SearchIcon sx={{ fontSize: 20 }} />
                                    )}
                                </InputAdornment>
                                {props.InputProps.startAdornment}
                            </React.Fragment>
                        ),
                        endAdornment: (
                            <React.Fragment>
                                {props.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
            renderOption={(props, option, { selected }) => (
                <MenuItem {...props} value={option}>
                    <div className={classes.wrapper} style={{ color: color }}>
                        {selected && (
                            <icons.SelectedIcon
                                sx={{
                                    fontSize: 18,
                                    color: 'black'
                                }}
                            />
                        )}
                        {Icon && <Icon fontSize='small' />}
                        <Typography color='inherit' variant='h6'>
                            {option}
                        </Typography>
                    </div>
                </MenuItem>
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        style={{
                            color: color,
                            borderColor: color
                        }}
                        variant='outlined'
                        size='small'
                        icon={Icon && <Icon />}
                        label={option}
                        {...getTagProps({ index })}
                    />
                ))
            }
        />
    );
};

export default FilterSelectorApi;
