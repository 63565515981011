import React from 'react';

import { Text, Document, DataGridPro as DataGrid, Tag } from 'core/components';

import * as hlprs from 'core/helpers';
import { getAvatar } from 'core/helpers';
import DetailUsuario from '../../../../../usuarios/Detalle';

const cols = (name, row, index, editing, handleChange) => [
    /*{
        key: 'enlace',
        title: 'Documento',
        align: 'center',
        width: '8%',
        component: row && (
            <Document
                name='enlace'
                readOnly={!editing}
                width='50px'
                height='50px'
                value={row['enlace']}
                onChange={chngs => handleChange(chngs, name, index)}
            />
        )
    },*/
    {
        key: 'detalle',
        title: 'Detalle',
        align: 'left',
        width: '70%',
        component: row && (
            <Text
                fullWidth
                name='detalle'
                variant='standard'
                //margin='none'
                value={row['detalle']}
                onChange={chngs => handleChange(chngs, name, index)}
                editing={editing}
            />
        )
    },
    {
        key: 'fecha',
        title: 'Fecha',
        align: 'left',
        width: '15%',
        component: row && (
            <Text
                fullWidth
                name='fecha'
                variant='standard'
                //margin='none'
                type='datetime-local'
                value={hlprs.getValueFormatted('datetime-local', row['fecha'])}
                onChange={chngs => handleChange(chngs, name, index)}
                editing={editing}
            />
        )
    },
    {
        key: 'usuario',
        title: 'Usuario',
        align: 'left',
        width: '15%',
        component: row && (
            <Tag
                //variant='outlined'
                size={'small'}
                value={row.usuario && row.usuario.nombre}
                id={row.usuario && row.usuario.id}
                avatar={
                    row.usuario && getAvatar(row.usuario.id, row.usuario.avatar)
                }
            />
        )
    }
];

const Diario = props => {
    const { values, editing, auth, handleChange } = props;

    return (
        <DataGrid
            name={'diario'}
            editing={editing}
            rows={values.diario ? values.diario : []}
            cols={(name, row, index) =>
                cols(name, row, index, editing, handleChange)
            }
            onAdd={name => {
                handleChange(
                    {
                        usuario: auth.user,
                        fecha: hlprs.now()
                    },
                    name
                );
            }}
            onDelete={(name, index) =>
                handleChange(undefined, name, (index + 1) * -1)
            }
            minHeight={400}
            maxHeight={400}
        />
    );
};

export default Diario;
