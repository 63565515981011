import React, { useState, useEffect } from 'react';
import { makeStyles, clsx } from '../../library';
import {
    EditIcon,
    SaveIcon,
    CancelIcon,
    InfoIcon,
    DeleteIcon
} from '../../icons';
import { Alert, Button, Action } from '../../';
import { changeLogs } from '../../../enums';
import Logs from '../../../../views/entorno/Logs';
import { useSelector } from 'react-redux';
import { ENTITY_EXPLOTACION_BODEGA } from '../../../../store/entities/constants';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const Edition = props => {
    const {
        readOnly,
        item,
        entityData,
        id,
        editing,
        updating,
        edit,
        save,
        del,
        cancel,
        changes,
        lite
    } = props;
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);

    //TODO Truco solo valido para obtener entityData mientras no cambio los relacionados en Text, Tag, combo, .....
    const newEntity = useSelector(state => {
        if (entityData) return entityData;

        if (!item.type) return undefined;

        return state.entities[item.type];
    });
    // **********************************************************

    return (
        <div className={classes.root}>
            <Button
                hidden={editing || !lite || readOnly}
                toolTipText='Eliminar los datos de la entidad'
                disabled={false}
                onClick={event => del()}
                counter={0}
                busy={false}
                icon={<DeleteIcon />}
                float={true}
            />

            <Button
                hidden={editing || lite || readOnly}
                toolTipText='Editar los datos de la entidad'
                disabled={false}
                //onClick={event => entity.edit(!entity.isEditable)}
                onClick={event => edit()}
                counter={0}
                busy={false}
                icon={<EditIcon />}
                float={true}
            />

            {newEntity && newEntity.changeLogs && (
                <Action
                    toolTipText={
                        'Ver registros de auditoría e historial de cambios'
                    }
                    icon={<InfoIcon />}
                    hidden={editing || !newEntity || !id}
                    disabled={false}
                >
                    <Logs
                        entity={newEntity}
                        id={id}
                        logs={newEntity.changeLogs}
                        scopes={newEntity.scopes}
                    />
                </Action>
            )}

            <Button
                hidden={!editing || readOnly}
                toolTipText='Guardar los cambios registrados'
                disabled={updating || changes === 0}
                //onClick={event => entity.save()}
                onClick={event => save()}
                counter={changes}
                busy={updating}
                icon={<SaveIcon />}
                float={true}
            />

            <Button
                hidden={!editing || readOnly}
                toolTipText='Cancelar los cambios registrados'
                disabled={updating}
                //onClick={event => entity.undo()}
                onClick={() => (changes !== 0 ? setOpenDialog(true) : cancel())}
                counter={0}
                busy={false}
                icon={<CancelIcon />}
                float={true}
            />
            <Alert
                open={openDialog}
                title='¿Esta seguro de cancelar los cambios realizados?'
                text={`Si "Acepta" perdera todos los cambios realizados desde la ultima vez que los guardo. Tenga en cuenta que esta acción es irreversible.`}
                cancel={() => setOpenDialog(false)}
                accept={() => {
                    setOpenDialog(false);
                    cancel();
                }}
            />
        </div>
    );
};

export default Edition;
