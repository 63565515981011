export { default as SearchIcon } from '@mui/icons-material/Search';
export { default as SearchNotIcon } from '@mui/icons-material/SearchOff';
export { default as SearchAcumulativeIcon } from '@mui/icons-material/SavedSearch';
export { default as ViewIcon } from '@mui/icons-material/OpenInBrowser';
//export { default as ViewIcon } from '@mui/icons-material/Preview';
//export { default as ViewIcon } from '@mui/icons-material/Edit';
export { default as MoreIcon } from '@mui/icons-material/MoreVert';
export { default as LockedIcon } from '@mui/icons-material/Lock';
export { default as CloseIcon } from '@mui/icons-material/Close';
export { default as AddIcon } from '@mui/icons-material/Add';
export { default as AddItemIcon } from '@mui/icons-material/AddCircle';
export { default as DeleteIcon } from '@mui/icons-material/Delete';
export { default as DeleteItemIcon } from '@mui/icons-material/HighlightOff';
export { default as RefreshIcon } from '@mui/icons-material/Refresh';
export { default as EditIcon } from '@mui/icons-material/Edit';
export { default as SaveIcon } from '@mui/icons-material/Save';
export { default as CancelIcon } from '@mui/icons-material/Cancel';
export { default as NotDefinedIcon } from '@mui/icons-material/Block';
export { default as DateIcon } from '@mui/icons-material/Event';
export { default as PreviousIcon } from '@mui/icons-material/SkipPrevious';
export { default as NextIcon } from '@mui/icons-material/SkipNext';
export { default as PauseIcon } from '@mui/icons-material/Pause';
export { default as CopyIcon } from '@mui/icons-material/FileCopy';
export { default as PrintIcon } from '@mui/icons-material/Print';
export { default as LocationOffIcon } from '@mui/icons-material/LocationOff';
export { default as ErrorIcon } from '@mui/icons-material/Error';
export { default as SelectedIcon } from '@mui/icons-material/ArrowRightAlt';
export { default as SumIcon } from '@mui/icons-material/Functions';
export { default as TimerIcon } from '@mui/icons-material/Timer';
export { default as TasksIcon } from '@mui/icons-material/TaskAltOutlined';
export { default as TaskIcon } from '@mui/icons-material/TaskAltOutlined';
export { default as InfoIcon } from '@mui/icons-material/Info';
export { default as ExperimentIcon } from '@mui/icons-material/Science';
export { default as ToDayIcon } from '@mui/icons-material/Today';
export { default as EventIcon } from '@mui/icons-material/EditCalendar';
export { default as ReadyIcon } from '@mui/icons-material/Verified';
export { default as SignatureIcon } from '@mui/icons-material/Gesture';

export const htmlIcon = '/recursos/imagenes/tipos/html.png';
export const pdfIcon = '/recursos/imagenes/tipos/pdf.png';
export const docxIcon = '/recursos/imagenes/tipos/doc.png';
export const docIcon = '/recursos/imagenes/tipos/doc.png';
export const xlsxIcon = '/recursos/imagenes/tipos/xls.png';
export const xlsIcon = '/recursos/imagenes/tipos/xls.png';
export const odsIcon = '/recursos/imagenes/tipos/xls.png';
export const pptxIcon = '/recursos/imagenes/tipos/ppt.png';
export const pptIcon = '/recursos/imagenes/tipos/ppt.png';
