import React from 'react';

import {
    DashboardIcon,
    ProjectIcon,
    TimeControlIcon,
    TimeRecordIcon,
    TableroIcon,
    TareaIcon,
    UserIcon,
    EmployeeIcon,
    AdjustTimeIcon,
    //TeamIcon,
    //GroupIcon,
    FlowIcon
    //FunctionIcon,
    //RolIcon
} from 'core/icons';

const development = {
    // Direccion IP Backend
    //servidor: window.location.protocol + '//' + window.location.hostname,
    servidor: 'https://api.independientesjumilla.es',

    // Puerto Backend
    //puerto: '5051',
    puerto: '443',

    // Repositorio
    repository: 'https://api.independientesjumilla.es/api/repository',
    /*window.location.protocol +
        '//' +
        window.location.hostname +
        ':443' +
        '/api/repository',*/

    licencia: '',

    cliente: 'Asaja Jumilla',

    // Imagenes
    rutaImagenes: `/recursos/imagenes`,
    rutaFuentes: `/recursos/fuentes`,
    rutaReports: `/recursos/reports`,
    imagenPortada: 'portada.png',
    logoCliente: 'logo-cliente.png',

    // Perfiles
    rutaPerfiles: '/recursos/perfiles',

    // Datos de la aplicacion
    aplicacion: {
        titulo: 'IOPAS',
        descripcion: 'Gestión de Organizaciones Agrarias',
        copyright: 'itacasistemas',
        urlCopyRight: 'https://itacasistemas.com',
        año: 2024,
        cliente: `Asaja Jumilla`
    },

    // Entidades
    entidades: [
        {
            entidad: 'Dashboard',
            title: 'Cuadro de Mandos',
            href: '/dashboard',
            icon: <DashboardIcon />
        },
        {
            entidad: 'RegistroHorario',
            title: 'Registro Horario',
            href: '/registrohorario',
            icon: <TimeRecordIcon />
        },
        {
            entidad: 'ControlPresencia',
            title: 'Control Presencia',
            href: '/controlpresencia',
            icon: <TimeControlIcon />
        },
        {
            entidad: 'Tableros',
            title: 'tablero',
            href: '/tablero',
            icon: <TableroIcon />
        },
        {
            entidad: 'Tareas',
            title: 'Tareas',
            href: '/tareas',
            icon: <TareaIcon />
        },
        {
            entidad: 'Proyectos',
            title: 'Proyectos',
            href: '/proyectos',
            icon: <ProjectIcon />
        },
        {
            entidad: 'Empleados',
            title: 'Empleados',
            href: '/empleados',
            icon: <EmployeeIcon />
        },
        {
            entidad: 'LiquidacionesEmpleados',
            title: 'Liq. Empleados',
            href: '/liquidacionesempleados',
            icon: <AdjustTimeIcon />
        }
        /*
    {
      entidad:'Grupos',
      title: 'Grupos de Operarios',
      href: '/grupos',
      icon: <GroupIcon/>
    }*/
    ],

    // Entidades de Sistema
    entSistema: [
        /*
    {
      entidad:'Equipos',
      title: 'Equipos Organización',
      href: '/equipos',
      icon: <TeamIcon />
    },
    {
      entidad:'Funcionalidades',
      title: 'Funcionalidades',
      href: '/funcionalidades',
      icon: <FunctionIcon />
    },
    {
      entidad:'Roles',
      title: 'Roles Usuario',
      href: '/roles',
      icon: <RolIcon/>
    },
    */
        {
            entidad: 'Usuarios',
            title: 'Usuarios',
            href: '/usuarios',
            icon: <UserIcon />
        },

        {
            entidad: 'Flujos',
            title: 'Flujos tareas',
            href: '/flujos',
            icon: <FlowIcon />
        }
    ]
};

export default development;
