import React, { useEffect, useState } from 'react';
import { Divider, Grid } from 'core/components/library';
import {
    Text,
    Tabs,
    Combo,
    Documents,
    Links,
    Button,
    SelectModal
} from 'core/components';

import * as icons from 'core/icons';
import * as helpers from 'core/helpers';
import List from '../../../../store/List';
import { Chip, Typography } from '../../../../core/components/library';

import Detalles from './Tabs/TabDetalles';
import { LINK_SIGPAC } from '../../../../core/constants';

const ComboMunicipios = List(Combo, 'options', 'lstmunicipios');
const ComboDestinos = List(Combo, 'options', 'lstdestinosproduccion');
const ComboMarcos = List(Combo, 'options', 'lstmarcosplantacion');
const ComboPortaInjertos = List(Combo, 'options', 'lstportainjertos');
const ComboDerechos = List(Combo, 'options', 'lstderechos');
const ComboConducciones = List(Combo, 'options', 'lstconducciones');
const ComboCultivos = List(Combo, 'options', 'lstcultivos');
const ComboSistemasExplotacion = List(
    Combo,
    'options',
    'lstsistemasexplotacion'
);
const ComboRiegos = List(Combo, 'options', 'lstsistemasriego');
const ComboVariedades = List(Combo, 'options', 'lstvariedades');
let timeBoxParcelas = 0;

const getOperacion = tipo => {
    if (tipo.baja) return 'a dar de Baja';
    if (tipo.modificacion) return 'a Modificar';
    if (tipo.traspaso) return 'a Traspasar';

    return 'Actual';
};

const getDesidad = values => {
    let newDensidad = 0;

    if (
        values.marcox &&
        values.marcoy &&
        Number(values.marcox) !== 0 &&
        Number(values.marcoy) !== 0
    ) {
        newDensidad = Math.round(
            10000 / (Number(values.marcox) * Number(values.marcoy))
        );
    }

    return newDensidad;
};

const getModoCultivo = values => {
    let newModoCultivo = null;

    if (values.densidad < 1500 && values.densidad >= 1100) {
        newModoCultivo = { id: 2, descripcion: 'EXTENSIVO', clave: 'E' };
    }

    if (values.densidad > 1900 && values.densidad <= 3350) {
        newModoCultivo = { id: 1, descripcion: 'INTENSIVO', clave: 'I' };
    }

    if (values.densidad < 1100 || values.densidad > 3350) {
        newModoCultivo = { id: 5, descripcion: 'NO DOP', clave: 'N' };
    }

    return newModoCultivo;
};

const cols = [
    {
        column: ['municipio', 'descripcion'],
        title: 'Municipio',
        align: 'left',
        type: 'text'
    },
    {
        column: 'poligono',
        title: 'Poligono',
        align: 'center',
        type: 'text'
    },
    {
        column: 'parcela',
        title: 'Parcela',
        align: 'center',
        type: 'text'
    },
    {
        column: 'recinto',
        title: 'Recinto',
        align: 'center',
        type: 'text'
    },
    {
        column: ['variedad', 'descripcion'],
        title: 'Variedad',
        align: 'left',
        type: 'text'
    },
    {
        column: 'año',
        title: 'Año',
        align: 'right',
        type: 'text'
    },
    {
        column: 'superficie',
        title: 'Superficie',
        align: 'right',
        type: 'd.4'
    },
    {
        column: 'marcox',
        title: 'x (m)',
        align: 'right',
        type: 'd.2'
    },
    {
        column: 'marcoy',
        title: 'y (m)',
        align: 'right',
        type: 'd.2'
    },
    {
        column: 'densidad',
        title: 'Densidad',
        align: 'right',
        type: 'entero'
    },
    {
        column: ['sistemaRiego', 'descripcion'],
        title: 'S. Riego',
        align: 'center',
        type: 'text'
    },
    {
        column: ['conduccion', 'descripcion'],
        title: 'Conducción',
        align: 'center',
        type: 'text'
    },
    {
        column: ['modoCultivo', 'descripcion'],
        title: 'Cultivo',
        align: 'center',
        type: 'text'
    },
    {
        column: ['viticultor', 'nombre'],
        title: 'Propietario',
        align: 'left',
        type: 'text'
    }
];

const getOldValue = (old, values, property, field) => {
    if (old && values) {
        let oldValue =
            field && old[property] ? old[property][field] : old[property];
        let value =
            field && values[property]
                ? values[property][field]
                : values[property];

        if (oldValue === undefined || oldValue === '') oldValue = null;
        if (value === undefined || value === '') value = null;

        if (oldValue !== value) {
            return `antes "${oldValue}"`;
        }
    }

    return null;
};

const Parcela = props => {
    const {
        values,
        editing,
        handleChange,
        refresh,
        reload,
        execAction,
        adding
    } = props;

    const [linkSigPac, setLinkSigPac] = useState([]);
    const [parcelas, setParcelas] = useState([]);
    const [old, setOld] = useState({});

    useEffect(() => {
        if (values.oldValue) setOld(JSON.parse(values.oldValue));
        else setOld(null);
    }, [values.oldValue]);

    useEffect(() => {
        if (editing) {
            handleChange([
                helpers.getValueControl('text', 'densidad', getDesidad(values))
            ]);
        }
    }, [values.marcox, values.marcoy]);

    useEffect(() => {
        if (editing) {
            handleChange([
                helpers.getValueControl(
                    'combo',
                    'modoCultivo',
                    getModoCultivo(values)
                )
            ]);
        }
    }, [values.densidad]);

    useEffect(() => {
        if (editing) {
            handleChange([
                helpers.getValueControl(
                    'text',
                    'arrendatario',
                    values.sistemaExplotacion &&
                        values.sistemaExplotacion.descripcion === 'Propiedad'
                        ? values.tramite.viticultor.nombre
                        : null
                )
            ]);
        }
    }, [JSON.stringify(values.sistemaExplotacion)]);

    useEffect(() => {
        let link = '';

        if (values.municipio && values.poligono && values.parcela) {
            link = `${LINK_SIGPAC}?provincia=${values.municipio.ineProv}&municipio=${values.municipio.ine}&poligono=${values.poligono}&parcela=${values.parcela}`;

            if (values.recinto) {
                link = `${link}&recinto=${values.recinto}`;
            }

            setLinkSigPac([link]);
        } else {
            setLinkSigPac([]);
        }
    }, [
        JSON.stringify(values.municipio),
        values.poligo,
        values.parcela,
        values.recinto
    ]);

    const findParcelas = () => {
        const action = () => {
            const accion = {
                api: values.tramite.tipo.traspaso
                    ? 'parcelas/find'
                    : 'parcelas/findviticultor',
                metodo: 'post',
                data: { ...values }
            };
            execAction(
                accion,
                [
                    data => {
                        console.log(data);
                        setParcelas(data);
                    }
                ],
                true
            );
        };

        timeBoxParcelas > 0 && clearTimeout(timeBoxParcelas);
        timeBoxParcelas = setTimeout(action, 250);
    };

    const setParcela = parcela => {
        const changes = [];

        if (
            values.tramite &&
            values.tramite.tipo &&
            values.tramite.tipo.traspaso
        ) {
            changes.push(
                helpers.getValueControl(
                    'combo',
                    'titularAnterior',
                    parcela.viticultor
                )
            );
        }
        changes.push(
            helpers.getValueControl(
                'text',
                'oldValue',
                JSON.stringify(parcela, null, 4)
            )
        );

        changes.push(
            helpers.getValueControl('combo', 'parcelaActual', parcela)
        );
        parcela.municipio &&
            changes.push(
                helpers.getValueControl('combo', 'municipio', parcela.municipio)
            );
        parcela.poligono &&
            changes.push(
                helpers.getValueControl('text', 'poligono', parcela.poligono)
            );
        parcela.parcela &&
            changes.push(
                helpers.getValueControl('text', 'parcela', parcela.parcela)
            );
        parcela.recinto &&
            changes.push(
                helpers.getValueControl('text', 'recinto', parcela.recinto)
            );
        parcela.variedad &&
            changes.push(
                helpers.getValueControl('combo', 'variedad', parcela.variedad)
            );
        parcela.año &&
            changes.push(helpers.getValueControl('text', 'año', parcela.año));

        parcela.superficie &&
            changes.push(
                helpers.getValueControl(
                    'text',
                    'superficie',
                    parcela.superficie
                )
            );
        parcela.paraje &&
            changes.push(
                helpers.getValueControl('text', 'paraje', parcela.paraje)
            );
        parcela.marcoPlantacion &&
            changes.push(
                helpers.getValueControl(
                    'combo',
                    'marcoPlantacion',
                    parcela.marcoPlantacion
                )
            );
        parcela.marcox &&
            changes.push(
                helpers.getValueControl('text', 'marcox', parcela.marcox)
            );
        parcela.marcoy &&
            changes.push(
                helpers.getValueControl('text', 'marcoy', parcela.marcoy)
            );
        parcela.densidad &&
            changes.push(
                helpers.getValueControl('text', 'densidad', parcela.densidad)
            );
        parcela.modoCultivo &&
            changes.push(
                helpers.getValueControl(
                    'combo',
                    'modoCultivo',
                    parcela.modoCultivo
                )
            );
        parcela.portaInjertos &&
            changes.push(
                helpers.getValueControl(
                    'combo',
                    'portaInjertos',
                    parcela.portaInjertos
                )
            );

        parcela.conduccion &&
            changes.push(
                helpers.getValueControl(
                    'combo',
                    'conduccion',
                    parcela.conduccion
                )
            );
        parcela.sistemaRiego &&
            changes.push(
                helpers.getValueControl(
                    'combo',
                    'sistemaRiego',
                    parcela.sistemaRiego
                )
            );
        parcela.sistemaExplotacion &&
            changes.push(
                helpers.getValueControl(
                    'combo',
                    'sistemaExplotacion',
                    parcela.sistemaExplotacion
                )
            );
        parcela.arrendatario &&
            changes.push(
                helpers.getValueControl(
                    'text',
                    'arrendatario',
                    parcela.arrendatario
                )
            );
        parcela.destinoProduccion &&
            changes.push(
                helpers.getValueControl(
                    'combo',
                    'destinoProduccion',
                    parcela.destinoProduccion
                )
            );
        parcela.derechosViñedo &&
            changes.push(
                helpers.getValueControl(
                    'combo',
                    'derechosViñedo',
                    parcela.derechosViñedo
                )
            );
        parcela.notas &&
            changes.push(
                helpers.getValueControl('text', 'notas', parcela.notas)
            );

        handleChange(changes);
    };

    return (
        <form autoComplete='off' noValidate>
            <SelectModal
                maxWidth={'xl'}
                open={parcelas.length !== 0}
                close={() => setParcelas([])}
                selector={{
                    title: `Parcelas encontradas`,
                    list: parcelas,
                    action: item => {
                        setParcela(item);
                    },
                    columns: cols
                }}
            />
            <Grid container spacing={1}>
                {values.tramite && values.tramite.viticultor && (
                    <Grid item xs>
                        <Typography margin={'15px'} variant={'h5'}>
                            {`Viticultor: ${values.tramite.viticultor.nombre}`}
                        </Typography>
                    </Grid>
                )}
                {values.parcelaActual && (
                    <Grid item xs>
                        <Typography margin={'15px'} variant={'h5'}>
                            {`Parcela ${getOperacion(values.tramite.tipo)}: ` +
                                `${values.parcelaActual.municipio.descripcion}-${values.parcelaActual['poligono']}-${values.parcelaActual['parcela']}-${values.parcelaActual['recinto']}-${values.parcelaActual.variedad.descripcion}-${values.parcelaActual.año}`}
                        </Typography>
                    </Grid>
                )}
                {values.titularAnterior && (
                    <Grid item xs>
                        <Typography margin={'15px'} variant={'h5'}>
                            {`Titular Anterior: ${values.titularAnterior.nombre}`}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboMunicipios
                        helperText={getOldValue(
                            old,
                            values,
                            'municipio',
                            'descripcion'
                        )}
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Municipio'
                        api='auxiliares/municipios'
                        name='municipio'
                        nameKey='municipioId'
                        refresh={refresh}
                        Icon={icons.MunicipioIcon}
                        value={values.municipio}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item>
                    <Text
                        fullWidth
                        editing={editing}
                        name='poligono'
                        label='Poligono'
                        helperText={getOldValue(old, values, 'poligono')}
                        type='entero'
                        value={values.poligono}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        helperText={getOldValue(old, values, 'parcela')}
                        fullWidth
                        editing={editing}
                        name='parcela'
                        label='Parcela'
                        //variant='standard'
                        //margin='none'
                        type='entero'
                        value={values.parcela}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        helperText={getOldValue(old, values, 'recinto')}
                        fullWidth
                        editing={editing}
                        name='recinto'
                        label='Recinto / Subparcela'
                        //variant='standard'
                        //margin='none'
                        //type='0.3'
                        value={values.recinto}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs>
                    <ComboVariedades
                        helperText={getOldValue(
                            old,
                            values,
                            'variedad',
                            'descripcion'
                        )}
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Variedad'
                        api='auxiliares/variedadesuva'
                        name='variedad'
                        nameKey='variedadId'
                        Icon={icons.VarietyIcon}
                        refresh={refresh}
                        value={values.variedad}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item>
                    <Text
                        helperText={getOldValue(old, values, 'año')}
                        fullWidth
                        editing={editing}
                        name='año'
                        label='Año'
                        type='entero'
                        value={values.año}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Button
                        toolTipText={'Buscar Parcela'}
                        icon={<icons.BuscarIcon />}
                        hidden={
                            !editing ||
                            (editing &&
                                values.tramite &&
                                values.tramite.tipo &&
                                values.tramite.tipo.traspaso &&
                                (!values.poligono || !values.municipio))
                        }
                        onClick={() => findParcelas()}
                        float={true}
                    />
                </Grid>
                <Grid item>
                    <Links
                        toolTipText={'Abrir SIGPAC'}
                        icon={<icons.SigPacIcon />}
                        hidden={false}
                        disabled={linkSigPac.length === 0}
                        links={linkSigPac}
                    />
                </Grid>
            </Grid>

            <Divider style={{ margin: '12px 12px 12px 12px' }}>
                <Chip label={'Plantación'} />
            </Divider>
            <Grid container spacing={1}>
                <Grid item>
                    <Text
                        helperText={getOldValue(old, values, 'superficie')}
                        fullWidth
                        editing={editing}
                        name='superficie'
                        label='Superficie'
                        type='d.4'
                        value={values.superficie}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        helperText={getOldValue(old, values, 'paraje')}
                        fullWidth
                        editing={editing}
                        name='paraje'
                        label='Paraje'
                        value={values.paraje}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboMarcos
                        helperText={getOldValue(
                            old,
                            values,
                            'marcoPlantacion',
                            'descripcion'
                        )}
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Tipo Marco'
                        api='auxiliares/marcoplantacion'
                        name='marcoPlantacion'
                        nameKey='marcoPlantacionId'
                        refresh={refresh}
                        value={values.marcoPlantacion}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item>
                    <Text
                        helperText={getOldValue(old, values, 'marcox')}
                        fullWidth
                        editing={editing}
                        name='marcox'
                        label='x (m)'
                        type='d.2'
                        value={values.marcox}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        helperText={getOldValue(old, values, 'marcoy')}
                        fullWidth
                        editing={editing}
                        name='marcoy'
                        label='y (m)'
                        type='d.2'
                        value={values.marcoy}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Text
                        helperText={getOldValue(old, values, 'densidad')}
                        fullWidth
                        editing={editing}
                        name='densidad'
                        label='Cepas / ha'
                        type='entero'
                        value={values.densidad}
                        onFocus={event => event.target.select()}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs>
                    <ComboCultivos
                        helperText={getOldValue(
                            old,
                            values,
                            'modoCultivo',
                            'descripcion'
                        )}
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Cultivo'
                        api='auxiliares/modoscultivo'
                        name='modoCultivo'
                        nameKey='modoCultivoId'
                        refresh={refresh}
                        value={values.modoCultivo}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboPortaInjertos
                        helperText={getOldValue(
                            old,
                            values,
                            'portaInjertos',
                            'descripcion'
                        )}
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Porta Injerto'
                        api='auxiliares/portainjertos'
                        name='portaInjertos'
                        nameKey='portaInjertosId'
                        refresh={refresh}
                        value={values.portaInjertos}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item xs>
                    <ComboConducciones
                        helperText={getOldValue(
                            old,
                            values,
                            'conduccion',
                            'descripcion'
                        )}
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Conducción'
                        api='auxiliares/formasconduccion'
                        name='conduccion'
                        nameKey='conduccionId'
                        refresh={refresh}
                        value={values.conduccion}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item xs>
                    <ComboRiegos
                        helperText={getOldValue(
                            old,
                            values,
                            'sistemaRiego',
                            'descripcion'
                        )}
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Sistema Riego'
                        api='auxiliares/sistemasriego'
                        name='sistemaRiego'
                        nameKey='sistemaRiegoId'
                        refresh={refresh}
                        value={values.sistemaRiego}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
            </Grid>
            <Divider style={{ margin: '12px 12px 12px 12px' }}>
                <Chip label={'Detalles'} />
            </Divider>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboSistemasExplotacion
                        helperText={getOldValue(
                            old,
                            values,
                            'sistemaExplotacion',
                            'descripcion'
                        )}
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Sistemas Explotación'
                        api='auxiliares/sistemasexplotacion'
                        name='sistemaExplotacion'
                        nameKey='sistemaExplotacionId'
                        refresh={refresh}
                        value={values.sistemaExplotacion}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Text
                        helperText={getOldValue(old, values, 'arrendatario')}
                        fullWidth
                        editing={editing}
                        name='arrendatario'
                        label='Propietario / Arrendador'
                        //variant='standard'
                        //margin='none'
                        type='text'
                        value={values.arrendatario}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs>
                    <ComboDestinos
                        helperText={getOldValue(
                            old,
                            values,
                            'destinoProduccion',
                            'descripcion'
                        )}
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Destino Producción'
                        api='auxiliares/destinoproduccion'
                        name='destinoProduccion'
                        nameKey='destinoProduccionId'
                        refresh={refresh}
                        value={values.destinoProduccion}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item xs>
                    <ComboDerechos
                        helperText={getOldValue(
                            old,
                            values,
                            'derechosViñedo',
                            'descripcion'
                        )}
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Estado Derechos'
                        api='auxiliares/derechosviñedo'
                        name='derechosViñedo'
                        nameKey='derechosViñedoId'
                        refresh={refresh}
                        value={values.derechosViñedo}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Detalles',
                        icon: icons.DetailIcon,
                        component: (
                            <Detalles
                                {...props}
                                old={old}
                                getOldValue={getOldValue}
                            />
                        ),
                        hide: false
                    },
                    {
                        label: 'Documentos',
                        icon: icons.DocumentIcon,
                        component: (
                            <Documents
                                name='documentacion'
                                editing={editing}
                                onChange={handleChange}
                                rows={
                                    values.documentacion
                                        ? values.documentacion
                                        : []
                                }
                                minHeight={456}
                                maxHeight={456}
                            />
                        ),
                        hide: true
                    }
                ]}
            />
        </form>
    );
};

export default Parcela;
