import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from '../../../../ui-component/cards/MainCard';
import Transitions from '../../../../ui-component/extended/Transitions';
import Rol from './Rol';

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from '@tabler/icons';

import Auth from 'store/Auth';
import config from 'settings/environment';
// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = props => {
    const { auth } = props;
    const theme = useTheme();
    const customization = useSelector(state => state.customization);
    const navigate = useNavigate();

    const [sdm, setSdm] = useState(true);
    const [value, setValue] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        auth.doLogout();
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.secondary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={`${config.rutaPerfiles}/${auth.user.id}/${auth.user.avatar}`}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-List-grow' : undefined}
                        aria-haspopup='true'
                        color='inherit'
                    />
                }
                label={
                    <IconSettings
                        stroke={1.5}
                        size='1.5rem'
                        color={theme.palette.primary.main}
                    />
                }
                variant='outlined'
                ref={anchorRef}
                aria-controls={open ? 'menu-List-grow' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}
                color='primary'
            />
            <Popper
                placement='bottom-end'
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                >
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack
                                                direction='row'
                                                spacing={0.5}
                                                alignItems='center'
                                            >
                                                <Typography variant='h4'>
                                                    Bienvenido,
                                                </Typography>
                                                <Typography
                                                    component='span'
                                                    variant='h4'
                                                    sx={{ fontWeight: 400 }}
                                                >
                                                    {auth.user.nombre}
                                                </Typography>
                                            </Stack>
                                            <Typography variant='subtitle2'>
                                                {auth.user.puesto}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ p: 2 }}>
                                        <List
                                            component='nav'
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor:
                                                    theme.palette.background
                                                        .paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]:
                                                    {
                                                        minWidth: '100%'
                                                    },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    borderRadius: `${customization.borderRadius}px`
                                                }}
                                                selected={selectedIndex === 0}
                                                onClick={event =>
                                                    handleListItemClick(
                                                        event,
                                                        0,
                                                        '/perfil'
                                                    )
                                                }
                                            >
                                                <ListItemIcon>
                                                    <IconSettings
                                                        stroke={1.5}
                                                        size='1.3rem'
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant='body2'>
                                                            Perfil
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{
                                                    borderRadius: `${customization.borderRadius}px`
                                                }}
                                                selected={selectedIndex === 4}
                                                onClick={handleLogout}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout
                                                        stroke={1.5}
                                                        size='1.3rem'
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant='body2'>
                                                            Cerrar Sesión
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </List>
                                        <Divider />
                                        {auth.user.roles.map(r => (
                                            <Rol item={r} />
                                        ))}
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export const ProfileSectionContainer = Auth(ProfileSection, 'auth');

export default ProfileSectionContainer;
