import * as icons from 'core/icons';

const control = {
    id: 'control',
    title: 'Organo Control',
    type: 'group',
    collapse: false,
    visible: true,
    icon: icons.OrganoControlIcon,
    children: [
        {
            id: 'normas',
            title: 'Normas',
            type: 'item',
            url: '/gestion/control/normas',
            icon: icons.NormasIcon,
            visible: true,
            breadcrumbs: false
        },
        {
            id: 'auditorias',
            title: 'Auditorias',
            type: 'item',
            url: '/gestion/control/auditorias',
            visible: true,
            icon: icons.AuditoriasIcon,
            breadcrumbs: false
        },
        {
            id: 'inspecciones',
            title: 'Inspecciones',
            type: 'item',
            url: '/gestion/control/inspecciones',
            visible: true,
            icon: icons.InspeccionesIcon,
            breadcrumbs: false
        },
        {
            id: 'catas',
            title: 'Catas',
            type: 'item',
            url: '/gestion/control/catas',
            icon: icons.CatasIcon,
            visible: true,
            breadcrumbs: false
        }
    ]
};

export default control;
