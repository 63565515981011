import React, { useEffect } from 'react';

import { Divider, Grid } from 'core/components/library';

import { Text, Select, Tabs, Documents, Combo } from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';

import Detalles from './Tabs/TabDetalles';
import Contactos from './Tabs/TabContactos';
import List from '../../../../store/List';

let timeBoxCodigo = 0;
const ComboFormasJuridicas = List(Combo, 'options', 'lstformasjuridicas');

const Detalle = props => {
    const {
        values,
        editing,
        adding,
        handleChange,
        handleError,
        refresh,
        reload,
        execAction
    } = props;

    const actualizaCodigo = () => {
        if (editing) {
            const action = () => {
                const accion = {
                    api: 'clientes/nexts',
                    metodo: 'post',
                    data: {
                        fields: ['codigo'],
                        values: { ...values }
                    }
                };
                execAction(accion, [reload]);
            };

            timeBoxCodigo > 0 && clearTimeout(timeBoxCodigo);
            timeBoxCodigo = setTimeout(action, 250);
        }
    };

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item>
                    <Text
                        required={true}
                        autoSelect
                        name='codigo'
                        label='Código'
                        value={values.codigo}
                        onChange={handleChange}
                        onError={handleError}
                        editing={editing}
                        type='0.6'
                        nextValue={adding && actualizaCodigo}
                    />
                </Grid>
                <Grid item xs={3}>
                    <ComboFormasJuridicas
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Forma Jurídica'
                        api='auxiliares/formasjuridicas'
                        name='formaJuridica'
                        nameKey='formaJuridicaId'
                        refresh={refresh}
                        Icon={icons.FormaJuridicaIcon}
                        value={values.formaJuridica}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item>
                    <Text
                        name='nif'
                        label='NIF'
                        value={values.nif}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='nombre'
                        label='Nombre / Razón Social'
                        value={values.nombre}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='nombreComercial'
                        label='Nombre Comercial'
                        value={values.nombreComercial}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Divider style={{ margin: '12px 12px 0px 12px' }} />
                        <Grid item>
                            <Text
                                fullWidth
                                multiline
                                rows={2}
                                name='direccion'
                                label='Dirección'
                                value={values.direccion}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Text
                                        fullWidth
                                        name='cp'
                                        label='Cód. Postal'
                                        value={values.cp}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        name='poblacion'
                                        label='Población'
                                        value={values.poblacion}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Text
                                fullWidth
                                name='provincia'
                                label='Provincia'
                                value={values.provincia}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Divider style={{ margin: '12px 12px 0px 12px' }} />
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        multiline
                                        rows={2}
                                        name='tlf'
                                        label='Tlfno.'
                                        value={values.tlf}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        multiline
                                        rows={2}
                                        name='fax'
                                        label='Fax'
                                        value={values.fax}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Text
                                fullWidth
                                name='email'
                                label='email'
                                value={values.email}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item>
                            <Text
                                fullWidth
                                name='web'
                                label='web'
                                value={values.web}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Detalles',
                        icon: icons.DetailIcon,
                        component: <Detalles {...props} />
                    },
                    {
                        label: 'Contactos',
                        icon: icons.ContactsIcon,
                        component: <Contactos {...props} />,
                        hide: false
                    },
                    {
                        label: 'Documentos',
                        icon: icons.DocumentIcon,
                        component: (
                            <Documents
                                name='documentacion'
                                editing={editing}
                                onChange={handleChange}
                                rows={
                                    values.documentacion
                                        ? values.documentacion
                                        : []
                                }
                                maxHeight={456}
                            />
                        ),
                        disabled: false
                    }
                ]}
            />
        </form>
    );
};

export default Detalle;
