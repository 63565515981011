import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, Divider, List, Typography, Button } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();

    const [open, setOpen] = useState(!item.collapse);

    const handleClick = () => {
        !item.locked && setOpen(!open);
    };

    // menu List collapse & items
    const items = item.children?.map(menu => {
        if (item.visible) {
            switch (menu.type) {
                case 'collapse':
                    return <NavCollapse key={menu.id} menu={menu} level={1} />;
                case 'item':
                    return <NavItem key={menu.id} item={menu} level={1} />;
                default:
                    return (
                        <Typography
                            key={menu.id}
                            variant='h6'
                            color='error'
                            align='center'
                        >
                            Menu Items Error
                        </Typography>
                    );
            }
        }

        return null;
    });

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Button
                            variant='text'
                            size='small'
                            onClick={handleClick}
                            sx={{ padding: 0 }}
                            disableElevation
                        >
                            <Typography
                                variant='caption'
                                sx={{ ...theme.typography.menuCaption }}
                                display='block'
                                gutterBottom
                            >
                                {item.title}
                                {item.caption && (
                                    <Typography
                                        variant='caption'
                                        sx={{
                                            ...theme.typography.subMenuCaption
                                        }}
                                        display='block'
                                        gutterBottom
                                        onClick={() => setOpen(!open)}
                                    >
                                        {item.caption}
                                    </Typography>
                                )}
                            </Typography>
                        </Button>
                    )
                }
            >
                <Collapse in={open} timeout='auto' unmountOnExit>
                    {items}
                </Collapse>
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
