import React from 'react';

import { makeStyles } from '../library';
import {
    IconButton,
    BottomNavigation,
    BottomNavigationAction,
    Divider,
    TextField,
    FormGroup,
    FormControlLabel,
    Switch,
    Tooltip
} from '../library';

import moment from 'moment';

import * as icons from '../icons';
import { singular, dateFormat } from '../helpers';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        width: '100%'
    },
    limites: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%'
        //width:'100%'
    }
}));

const aplyFormat = (value, opt) => {
    return moment(value).format(dateFormat(opt.period));
};

const incrementValue = (increment, value, opt) => {
    return moment(value).add(increment, opt.period);
};

const getRange = (range, opt, increment) => {
    let since = range === null ? undefined : range[0];
    let until = range === null ? undefined : range[1];

    if (increment !== 0) {
        since = incrementValue(increment, since, opt);
        until = incrementValue(increment, until, opt);
    }

    return [aplyFormat(since, opt), aplyFormat(until, opt)];
};

const Selector = props => {
    const { checks, options, type, setOption, setCheck, option, range } = props;

    const classes = useStyles();

    const handleChangeOption = (selector, newVal, increment) => {
        const newOption = selector
            ? options.findIndex(opt => opt.value === newVal)
            : option;

        const newRange = !selector
            ? getRange(newVal, options[newOption], increment)
            : getRange(range, options[newOption], increment);

        setOption(newOption, newRange);
    };

    const handleChangeCheck = event => {
        setCheck(event.target.name, event.target.checked);
    };

    if (!options && !checks) return null;

    return (
        <div className={classes.root}>
            {options && [
                <BottomNavigation
                    value={options[option].value}
                    onChange={(event, newValue) =>
                        handleChangeOption(true, newValue)
                    }
                >
                    {options.map((opt, index) => (
                        <BottomNavigationAction
                            showLabel={true}
                            label={opt.label}
                            value={opt.value}
                            icon={<opt.icon />}
                        />
                    ))}
                </BottomNavigation>,
                <Divider orientation='vertical' flexItem={true} />
            ]}

            {options && type === 'date' && (
                <div className={classes.limites}>
                    <IconButton
                        onClick={() => handleChangeOption(false, range, -1)}
                    >
                        <icons.PreviousIcon />
                    </IconButton>
                    <div>
                        <TextField
                            label='Desde'
                            value={aplyFormat(range[0], options[option])}
                            margin='dense'
                            variant='standard'
                            type={
                                singular(options[option].period) === 'day'
                                    ? 'date'
                                    : singular(options[option].period)
                            }
                            onChange={event => {
                                const until =
                                    moment(range[1]) <
                                    moment(event.target.value)
                                        ? event.target.value
                                        : range[1];

                                handleChangeOption(
                                    false,
                                    [event.target.value, until],
                                    0
                                );
                            }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                readOnly: options[option].period === 'years'
                            }}
                        />
                        <TextField
                            label='Hasta'
                            value={aplyFormat(range[1], options[option])}
                            margin='dense'
                            variant='standard'
                            type={
                                singular(options[option].period) === 'day'
                                    ? 'date'
                                    : singular(options[option].period)
                            }
                            onChange={event => {
                                const since =
                                    moment(range[0]) >
                                    moment(event.target.value)
                                        ? event.target.value
                                        : range[0];
                                handleChangeOption(
                                    false,
                                    [since, event.target.value],
                                    0
                                );
                            }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                readOnly: options[option].period === 'years'
                            }}
                        />
                    </div>
                    <IconButton
                        onClick={() => handleChangeOption(false, range, 1)}
                    >
                        <icons.NextIcon />
                    </IconButton>
                    <Divider orientation='vertical' flexItem={true} />
                </div>
            )}

            {checks && [
                <FormGroup aria-label='position' row>
                    {checks.map((chk, index) => (
                        <Tooltip title={chk.helperText}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name={chk.name}
                                        color='primary'
                                        size='small'
                                        checked={chk.value}
                                        onChange={handleChangeCheck}
                                    />
                                }
                                label={chk.label}
                                labelPlacement='bottom'
                            />
                        </Tooltip>
                    ))}
                </FormGroup>,
                <Divider orientation='vertical' flexItem={true} />
            ]}
        </div>
    );
};

export default Selector;
