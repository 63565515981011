import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'core/components/library';
import {
    DataGridPro as DataGrid,
    Literal,
    Switch,
    Tag,
    ViewEntity
} from 'core/components';
//import * as hlprs from 'core/helpers';
import * as iconsSelector from 'core/icons';
import { ENTITY_ETIQUETA_MARCA } from '../../../../../../store/entities/constants';
import DetailFormato from 'views/maestros/Etiquetas/Detalle';
import * as enums from '../../../../../../core/enums';
import { statesEntity } from '../../../../../../core/enums';

const cols = (row, entityData) => [
    {
        key: 'marca',
        title: 'Marca',
        align: 'left',
        width: '15%',
        component: row && row.marca && (
            <Literal text={row.marca.nombre}>
                <iconsSelector.BrandsIcon fontSize={'small'} />
            </Literal>
        )
    },
    {
        key: 'nombre',
        title: 'Nombre',
        align: 'left',
        width: '20%',
        component: row && row.nombre
    },
    {
        key: 'variedadVino',
        title: 'Vino',
        align: 'left',
        width: '20%',
        component: row && row.variedadVino && (
            <Literal text={row.variedadVino.descripcion}>
                <iconsSelector.WineIcon fontSize={'small'} />
            </Literal>
        )
    },
    {
        key: 'envase',
        title: 'Envase',
        align: 'left',
        width: '15%',
        component: row && row.envase && (
            <Literal text={row.envase.descripcion}>
                <iconsSelector.PackageIcon fontSize={'small'} />
            </Literal>
        )
    },
    {
        key: 'variedad',
        title: 'Variedad',
        align: 'left',
        width: '20%',
        component: row && row.variedadUva && (
            <Literal text={row.variedadUva}>
                <iconsSelector.GrapeIcon fontSize={'small'} />
            </Literal>
        )
    },
    {
        key: 'estado',
        title: 'Estado',
        align: 'center',
        width: '10%',
        component: row && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(statesEntity, row.estado, 'color')
                }}
                Icon={enums.getItem(statesEntity, row.estado, 'icon')}
                value={row.estado}
            />
        )
    }
];

const Etiquetas = props => {
    const { values, editing, adding, handleChange, refresh, execAction } =
        props;

    const entityData = useSelector(
        state => state.entities[ENTITY_ETIQUETA_MARCA]
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs>
                <DataGrid
                    //title='Etiquetas'
                    name='etiquetas'
                    editing={editing}
                    rows={values.etiquetas ? values.etiquetas : []}
                    cols={(name, row, index) => cols(row, entityData)}
                    onDelete={(name, index, row) => {
                        execAction(
                            {
                                api: entityData.api,
                                metodo: 'delete',
                                data: [row.id]
                            },
                            [() => refresh(false)]
                        );
                    }}
                    maxHeight={456}
                >
                    <ViewEntity
                        key={ENTITY_ETIQUETA_MARCA}
                        refresh={refresh}
                        entity={entityData}
                        initValue={{
                            regEmbotellado: values.regEmbotellado,
                            explotacion: {
                                id: values.id,
                                bodega: { id: values.bodega.id }
                            }
                        }}
                    >
                        {DetailFormato}
                    </ViewEntity>
                </DataGrid>
            </Grid>
        </Grid>
    );
};

export default Etiquetas;
