import React, { useEffect, useState } from 'react';
import { makeStyles } from '../../../core/components/library';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Paper,
    IconButton,
    Tooltip,
    Typography
} from '../../../core/components/library';
import { CloseIcon } from '../../../core/components/icons';
import { LOG_DATA, LOG_STATE } from '../../../core/constants';
import Selector from './Selector';
import ChangeLog from './ChangeLog';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-star',
        '& > *': {
            marginLeft: theme.spacing(1)
        }
    },
    root: {
        minWidth: '610px',
        height: '90vh',
        position: 'absolute',
        top: '5vh',
        right: '0vh'
    },
    selector: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: theme.palette.background.paper
        boxShadow: theme.shadows[20]
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[20],
        padding: theme.spacing(2, 4, 3)
    }
}));

const getLog = (log, entity, id, scopes) => {
    if (!log) return null;

    if (log === 'LOG_STATE') {
        return <ChangeLog entity={entity} id={id} scopes={scopes} />;
    }

    //if (log === 'LOG_DATA')
    return <DialogContent />;
};

const PaperComponent = props => {
    const classes = useStyles();
    return <Paper {...props} />;
};

const Logs = props => {
    const { open, onClose, entity, id, change, logs, scopes } = props;
    const classes = useStyles();

    const [log, setLog] = useState(
        logs && logs.length !== 0 ? logs[0].value : null
    );

    return (
        <Dialog
            maxWidth={'lg'}
            classes={{ paper: classes.root }}
            scroll='paper'
            open={open}
            PaperComponent={PaperComponent}
        >
            <DialogTitle style={{ padding: 4 }}>
                <div className={classes.wrapper}>
                    <Tooltip title={'Cerrar'}>
                        <IconButton size='small' onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography variant='h5'>
                        Registros de Auditoría / Historial de cambios
                    </Typography>
                </div>
            </DialogTitle>
            {getLog(log, entity, id, scopes)}
            <DialogActions className={classes.selector}>
                <Selector log={log} setLog={setLog} logs={logs} />
            </DialogActions>
        </Dialog>
    );
};

export default Logs;
