import React, { useEffect, useState } from 'react';

import FilterText from './FilterText';
import FilterSelector from './FilterSelector';
import FilterSelectorApi from './FilterSelectorApi';
import FilterCheck from './FilterCheck';
import List from '../../../store/List';

//const FilterApi = List(FilterSelectorApi, 'list', 'lst' + Date.now());

const Filter = props => {
    const { type, options, ...rest } = props;
    const [FilterApi, setFilterApi] = useState(null);

    useEffect(() => {
        if (options && !Array.isArray(options)) {
            setFilterApi(
                List(
                    FilterSelectorApi,
                    'list',
                    `lstFilterOptions${props.title}`
                )
            );
        }
    }, []);

    if (type.toLowerCase() === 'checkbox') return <FilterCheck {...props} />;

    if (options) {
        if (Array.isArray(options)) return <FilterSelector {...props} />;

        if (!FilterApi) {
            return null;
        }

        return (
            <FilterApi
                api={options.api}
                field={options.field}
                color={options.color}
                icon={options.icon}
                {...rest}
            />
        );
    }

    return <FilterText {...props} />;
};

export default Filter;
