import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid, Divider } from 'core/components/library';
import { v4 as uuidv4 } from 'uuid';
import {
    Text,
    ViewEntity,
    DataGridPro as DataGrid,
    Tag,
    Button,
    Alert,
    Combo,
    Filter,
    Select
} from 'core/components';

import * as helpers from 'core/helpers';
import * as enums from '../../../../core/enums';

import List from '../../../../store/List';

import DetailParcela from 'views/maestros/Parcelas/Detalle';
import DetailTramite from 'views/tramites/Tramites/Detalle';

import { ENTITY_PARCELA, ENTITY_TRAMITES } from 'store/entities/constants';
import { DateTime } from 'luxon';
import * as icons from '../../../../core/icons';
import { Chip, Typography } from '../../../../core/components/library';

const ComboMunicipios = List(Combo, 'options', 'lstmunicipiosfiltro');

const getLiteral = value => {
    if (value) return value;

    return '#';
};
const getRendimiento = values => {
    const superficie = values.superficie;
    const produccion =
        values.modoCultivo.clave === 'I'
            ? values.variedad.produccionIntensivo
            : values.modoCultivo.clave === 'E'
              ? values.variedad.produccionExtensivo
              : 0;

    return Number((superficie * produccion).toFixed(2));
};
const colsRecolectado = (name, row, handleChange, selectedParcel) => [
    {
        key: 'kgs',
        title: 'Kilos',
        align: 'left',
        component: row && (
            <Text
                fullWidth
                editing={true}
                name='kgs'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['kgs']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values => {
                        if (selectedParcel.bloqueo === 'Cosecha en Verde') {
                            let newValue = { ...values[0] };
                            newValue.value = '0,00';
                            handleChange([newValue], name, row.id);
                        } else {
                            handleChange(values, name, row.id);
                        }
                    })
                }
            />
        )
    },
    {
        key: 'baume',
        title: 'º Baume',
        align: 'left',
        component: row && (
            <Text
                fullWidth
                editing={true}
                name='baume'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['baume']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    handleChange &&
                    (values => {
                        if (selectedParcel.bloqueo === 'Cosecha en Verde') {
                            let newValue = { ...values[0] };
                            newValue.value = '0,00';
                            handleChange([newValue], name, row.id);
                        } else {
                            handleChange(values, name, row.id);
                        }
                    })
                }
            />
        )
    }
];
const colsDeclarado = row => [
    {
        key: 'Vendimia',
        title: 'Vend.',
        align: 'center',
        //width: '5%',
        component: row && row.vendimia
    },
    {
        key: 'municipio',
        title: 'Municipio',
        align: 'left',
        width: '7%',
        component:
            row && row.parcela.municipio && row.parcela.municipio.descripcion
    },
    {
        key: 'poligono',
        title: 'Pol.',
        align: 'center',
        component: row && row.parcela.poligono
    },
    {
        key: 'parcela',
        title: 'Par.',
        align: 'center',
        component: row && row.parcela.parcela
    },
    {
        key: 'recinto',
        title: 'Rec.',
        align: 'center',
        component: row && row.parcela.recinto
    },
    {
        key: 'variedad',
        title: 'Variedad',
        align: 'left',
        width: '7%',
        component: row && row.variedad && row.variedad.descripcion
    },
    {
        key: 'uso',
        title: '% Uso',
        align: 'right',
        component: row && helpers.getValueFormatted('d.4', row.uso)
    },
    {
        key: 'superficieUso',
        title: 'Superf.',
        align: 'right',
        component: row && helpers.getValueFormatted('d.4', row.superficieUso)
    },
    {
        key: 'baume',
        title: 'ºBme.',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.baume)
    },
    {
        key: 'recolectado',
        title: 'Recolec.',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsRecolectados)
    },
    {
        key: 'kgsMaxUso',
        title: 'Máx.',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsMaxUso)
    },
    {
        key: 'rendimiento',
        title: '% Rend.',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.rendimiento)
    },
    {
        key: 'kgsExcedidos',
        title: 'Exceso',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsExcedidos)
    },
    {
        key: 'exceso',
        title: '% Exc.',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.exceso)
    },
    {
        key: 'kgsBloqueados',
        title: 'Bloqueado',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsBloqueados)
    },
    {
        key: 'kgsNoAptos',
        title: 'No Apto',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsNoAptos)
    },
    {
        key: 'kgsNoDop',
        title: 'No DOP',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsNoDop)
    },
    {
        key: 'kgsdeclarado',
        title: 'Declarado',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsDeclarados)
    },
    {
        key: 'incidencia',
        title: 'Incidencia',
        width: '10%',
        align: 'center',
        component: row && row.incidencia
    }
];
const colsAnteriores = row => [
    {
        key: 'declaracion',
        title: 'Declaración',
        align: 'center',
        component: row && row.tramite && row.tramite.codigo
    },
    {
        key: 'fecha',
        title: 'Fecha',
        align: 'center',
        component: row && helpers.getValueFormatted('date', row.tramite.fecha)
    },
    {
        key: 'recolectado',
        title: 'Recolectado',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.kgsRecolectados)
    },
    {
        key: 'uso',
        title: '% Uso',
        align: 'right',
        component: row && helpers.getValueFormatted('d.2', row.uso)
    }
];
const cols = row => [
    {
        key: 'municipio',
        title: 'Municipio',
        align: 'left',
        //width: '5%',
        component: row && getLiteral(row.municipio.descripcion)
    },
    {
        key: 'poligono',
        title: 'Poligono',
        align: 'center',
        //width: '5%',
        component: row && getLiteral(row['poligono'])
    },
    {
        key: 'parcela',
        title: 'Parcela',
        align: 'center',
        //width: '5%',
        component: row && getLiteral(row['parcela'])
    },
    {
        key: 'recinto',
        title: 'Recinto',
        align: 'center',
        //width: '5%',
        component: row && getLiteral(row['recinto'])
    },
    {
        key: 'variedad',
        title: 'Variedad',
        align: 'left',
        //width: '5%',
        component: row && getLiteral(row.variedad.descripcion)
    },
    {
        key: 'año',
        title: 'Año',
        align: 'center',
        //width: '5%',
        component: row && getLiteral(row['año'])
    },
    {
        key: 'modoCultivo',
        title: 'Cultivo',
        align: 'center',
        //width: '14%',
        component: row && row.modoCultivo && <>{row.modoCultivo.descripcion}</>
    },
    {
        key: 'superficie',
        title: 'Superficie',
        align: 'right',
        type: 'd.4',
        //width: '5%',
        //aggregate: 'sum',
        component: row && helpers.getValueFormatted('d.4', row.superficie)
    },
    {
        key: 'rendimiento',
        title: 'Rendimiento',
        align: 'right',
        type: 'd.2',
        //width: '8%',
        //aggregate: 'sum',
        component:
            row &&
            helpers.getValueFormatted(
                'd.2',
                row.variedad && row.modoCultivo ? getRendimiento(row) : 0
            )
    },
    {
        key: 'bloqueo',
        title: 'Bloqueo',
        align: 'center',
        width: '18%',
        component: row && row.bloqueo && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(
                        enums.locksParcel,
                        row.bloqueo,
                        'color'
                    )
                }}
                Icon={enums.getItem(enums.locksParcel, row.bloqueo, 'icon')}
                value={row.bloqueo}
            />
        )
    }
];

const Declaracion = props => {
    const { parcelario, cosecha, values, execAction, refresh } = props;

    const [openDialog, setOpenDialog] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [selectedParcel, setSelectedParcel] = useState(null);
    const [cuota, setCuota] = useState(values.cuota);
    const [cuotasAnteriores, setCuotasAnteriores] = useState(null);
    const [recolectado, setRecolectado] = useState([]);
    const [changes, setChanges] = useState([]);
    const [filtroParcelario, setFiltroParcelario] = useState('');
    const [totales, setTotales] = useState({
        superficie: 0,
        recolectados: 0,
        excedidos: 0,
        bloqueados: 0,
        noAptos: 0,
        noDop: 0,
        declarados: 0
    });
    const [totalParcela, setTotalParcela] = useState({
        kgs: 0,
        baume: 0
    });

    useEffect(() => {
        parcelario.load(`parcelas/viticultor/${values.viticultor.id}`);
        cosecha.load(`cosecha/${values.campaña}/${values.viticultor.id}`);

        return () => {
            parcelario.unload();
            cosecha.unload();
        };
    }, []);

    useEffect(() => {
        if (selectedParcel && recolectado.length === 0) {
            initRecolectado();
        }
    }, [JSON.stringify(selectedParcel)]);

    useEffect(() => {
        if (parcelario.original && parcelario.original.length !== 0) {
            initRecolectado();
        }
        calculaTotales();
        getCuotasAnteriores();
    }, [JSON.stringify(cosecha.list)]);

    useEffect(() => {
        if (selectedParcel) getTotalParcela();
    }, [JSON.stringify(selectedParcel), JSON.stringify(recolectado)]);

    const entityQuery = useSelector(state => state.entities[ENTITY_PARCELA]);
    const entityQueryTramite = useSelector(
        state => state.entities[ENTITY_TRAMITES]
    );

    const initRecolectado = () => {
        let newRecolectado = [];

        parcelario.original.forEach(item => {
            if (cosecha && cosecha.list) {
                cosecha.list.forEach(itemDeclarado => {
                    if (
                        itemDeclarado.parcela.id === item.id &&
                        itemDeclarado.tramite.id === values.id
                    ) {
                        newRecolectado.push({
                            id: uuidv4(),
                            idCosecha: itemDeclarado.id,
                            parcela: { ...item },
                            kgs: itemDeclarado.kgsRecolectados,
                            baume: itemDeclarado.baume,
                            vacio: false
                        });
                        //isEmpty = false;
                    }
                });
            }

            /*
            if (
                item.bloqueo === 'Cosecha en Verde' &&
                values.explotacion.bodega.censo === 'No Inscrita'
            ) {
                if (isEmpty) {
                    const id = uuidv4();
                    const newItem = {
                        id: id,
                        idCosecha: null,
                        parcela: { ...item },
                        kgs: '0.00',
                        baume: '0.00',
                        vacio: false
                    };
                    const newChange = {
                        id: id,
                        vendimia: values.campaña,
                        tramite: values.id,
                        fecha: DateTime.fromISO(values.fecha).toISODate(),
                        viticultor: values.viticultor
                            ? values.viticultor.id
                            : null,
                        explotacion: values.explotacion
                            ? values.explotacion.id
                            : null,
                        noDop: values.explotacion
                            ? values.explotacion.bodega.censo === 'No Inscrita'
                            : true
                    };
                    newRecolectado.push(newItem);
                    newChanges.push({ ...newChange, ...newItem });
                }
            } else {
                newRecolectado.push({
                    id: uuidv4(),
                    idCosecha: null,
                    parcela: { ...item },
                    kgs: null,
                    baume: null,
                    vacio: true
                });
            }*/
            newRecolectado.push({
                id: uuidv4(),
                idCosecha: null,
                parcela: { ...item },
                kgs: null,
                baume: null,
                vacio: true
            });
        });
        setRecolectado(newRecolectado);
    };
    const handleChange = (values, name, id) => {
        let newRecolectado = recolectado.map(item => {
            if (item.id === id) {
                let newItem = { ...item };
                values.forEach(value => {
                    newItem = { ...newItem, [value.field]: value.value };
                });
                addChange(id, newItem);

                return { ...newItem, vacio: false };
            } else {
                return item;
            }
        });

        if (
            newRecolectado.filter(
                item => item.vacio && item.parcela.id === selectedParcel.id
            ).length === 0
        ) {
            newRecolectado.push({
                id: uuidv4(),
                parcela: { ...selectedParcel },
                kgs: null,
                baume: null,
                vacio: true
            });
        }

        setRecolectado(newRecolectado);
    };
    const addChange = (id, change) => {
        let newChanges = changes.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    ...change
                };
            } else {
                return item;
            }
        });

        if (!changes.some(item => item.id === id)) {
            const newChange = {
                id: id,
                vendimia: values.campaña,
                tramite: values.id,
                fecha: DateTime.fromISO(values.fecha).toISODate(),
                viticultor: values.viticultor ? values.viticultor.id : null,
                explotacion: values.explotacion ? values.explotacion.id : null,
                noDop: values.explotacion
                    ? values.explotacion.bodega.censo === 'No Inscrita'
                    : true
            };

            newChanges.push({ ...newChange, ...change });
        }
        setChanges(newChanges);
    };
    const saveDeclaracion = () => {
        setIsBusy(true);
        execAction(
            {
                api: 'cosecha/updateCosecha',
                metodo: 'post',
                data: changes
            },
            [
                data => {
                    setChanges([]);
                    setIsBusy(false);
                    cosecha.refresh(false);
                    filterParcelario(filtroParcelario);
                    refresh(false);
                }
            ],
            true
        );
    };
    const getTotalParcela = () => {
        let kgs = 0;
        let baumePonderado = 0;

        recolectado.forEach(item => {
            if (item.parcela.id === selectedParcel.id) {
                kgs += Number(item.kgs);
                baumePonderado += Number(
                    Number(item.kgs) * Number(item.baume).toFixed(2)
                );
            }
        });
        const baume = kgs === 0 ? 0 : Number((baumePonderado / kgs).toFixed(2));
        setTotalParcela({
            kgs: kgs,
            baume: baume
        });
    };
    const getResumeCosecha = arr => {
        return arr.reduce((acumulador, objActual) => {
            let encontrado = acumulador.find(
                obj => obj.tramite.id === objActual.tramite.id
            );

            if (encontrado) {
                encontrado.kgsRecolectados =
                    Number(objActual.kgsRecolectados) +
                    Number(encontrado.kgsRecolectados);
                encontrado.uso = Number(objActual.uso) + Number(encontrado.uso);
            } else {
                acumulador.push({ ...objActual });
            }

            return acumulador;
        }, []);
    };
    const allowRecoleccion = parcel => {
        /*if (
            values.explotacion.bodega.censo !== 'No Inscrita' &&
            parcel.bloqueo !== 'Cosecha en Verde'
        )
            return true;

        if (values.explotacion.bodega.censo === 'No Inscrita') return true;

        return false;*/
        return true;
    };
    const calculaTotales = () => {
        let newTotales = {
            superficie: 0,
            recolectados: 0,
            excedidos: 0,
            bloqueados: 0,
            noAptos: 0,
            noDop: 0,
            declarados: 0
        };

        cosecha.list.forEach(item => {
            if (item.tramite.id === values.id) {
                newTotales.superficie += Number(item.superficieUso);
                newTotales.recolectados += Number(item.kgsRecolectados);
                newTotales.excedidos += Number(item.kgsExcedidos);
                newTotales.bloqueados += Number(item.kgsBloqueados);
                newTotales.noAptos += Number(item.kgsNoAptos);
                newTotales.noDop += Number(item.kgsNoDop);
                newTotales.declarados += Number(item.kgsDeclarados);
            }
        });

        setTotales(newTotales);
    };
    const filterParcelario = valor => {
        setFiltroParcelario(valor);
        if (valor === '') {
            parcelario.filter(['id'], '', 'in', 'text');
            parcelario.filter(['id'], '', '!in', 'text');
        } else {
            let ids = [];
            parcelario.original.forEach(parcela => {
                if (
                    recolectado.filter(item => item.parcela.id === parcela.id)
                        .length > 1 ||
                    cosecha.list.some(item => item.parcela.id === parcela.id)
                ) {
                    ids.push(parcela.id);
                }
            });
            if (valor) {
                parcelario.filter(['id'], '', 'in', 'text');
                parcelario.filter(['id'], ids, '!in', 'text');
            } else {
                parcelario.filter(['id'], '', '!in', 'text');
                parcelario.filter(['id'], ids, 'in', 'text');
            }
        }
    };
    const getCuotasAnteriores = () => {
        let anterior = null;

        if (cosecha && cosecha.list) {
            const items = cosecha.list.filter(
                item => item.tramite.id !== values.id
            );
            if (items.length > 0) {
                if (items[0].tramite.cuota === 'Cuota parcial') {
                    anterior = 'Cuota parcial';
                } else {
                    anterior = 'Cuota ya emitida';
                }
            }
        }

        if ((!cuota && anterior) || (cuota && anterior && cuota !== anterior)) {
            setCuota(anterior);
            addChange('cuota', {
                cuota: anterior
            });
        }

        setCuotasAnteriores(anterior);
    };

    //if (!(!parcelario.isInit && parcelario.isReady)) return null;

    return (
        <form autoComplete='off' noValidate>
            <Grid sx={{ marginTop: '10px' }} container>
                <Grid item xs={9}>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <Filter
                                title={'Municipio'}
                                type={'text'}
                                modificador={'='}
                                options={{
                                    api: 'auxiliares/municipios',
                                    field: 'descripcion',
                                    icon: icons.MunicipioIcon,
                                    color: 'gray'
                                }}
                                onFilter={(valor, modificador) =>
                                    parcelario.filter(
                                        [['municipio', 'descripcion']],
                                        valor,
                                        'in',
                                        'text',
                                        modificador
                                    )
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Filter
                                title={'Poligono'}
                                multiple={false}
                                type={'number'}
                                modificador={'='}
                                onFilter={(value, modificador) =>
                                    parcelario.filter(
                                        ['poligono'],
                                        value,
                                        'between',
                                        'number',
                                        modificador
                                    )
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Filter
                                title={'Parcela'}
                                multiple={false}
                                type={'number'}
                                modificador={'='}
                                onFilter={(value, modificador) =>
                                    parcelario.filter(
                                        ['parcela'],
                                        value,
                                        'between',
                                        'number',
                                        modificador
                                    )
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Filter
                                title={'Recinto'}
                                multiple={false}
                                type={'text'}
                                modificador={'==='}
                                onFilter={(value, modificador) =>
                                    parcelario.filter(
                                        ['recinto'],
                                        value,
                                        '',
                                        'text',
                                        modificador
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs>
                            <Filter
                                title={'Pendiente/ Declarado'}
                                titleTrue={'Pendiente'}
                                titleFalse={'Declarado'}
                                type={'checkbox'}
                                onFilter={filterParcelario}
                                width={1}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    {values.liquidacion !== 'Liquidado' && (
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Select
                                    fullWidth
                                    name='cuota'
                                    label='Tipo Cuota a emitir'
                                    value={cuota}
                                    onChange={values => {
                                        setCuota(values[0].value);
                                        addChange('cuota', {
                                            cuota: values[0].value
                                        });
                                    }}
                                    editing={!cuotasAnteriores}
                                    options={
                                        cuotasAnteriores
                                            ? enums.typeQuota
                                            : enums.typeQuota.filter(
                                                  c =>
                                                      c.value !==
                                                      'Cuota ya emitida'
                                              )
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    busy={isBusy}
                                    disabled={changes.length === 0}
                                    counter={changes.length}
                                    toolTipText={'Actualizar la declaración'}
                                    icon={<icons.SaveIcon />}
                                    onClick={saveDeclaracion}
                                    float={true}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={changes.length === 0}
                                    toolTipText={'Cancelar los cambios'}
                                    icon={<icons.CancelIcon />}
                                    onClick={() => setOpenDialog(true)}
                                    float={true}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid sx={{ marginTop: '1px' }} container spacing={3}>
                <Grid item xs={9}>
                    <DataGrid
                        sort={[]}
                        allowSelect={'simple'}
                        changeSelectedItems={items =>
                            setSelectedParcel(items[0])
                        }
                        title={'Parcelario'}
                        readOnly
                        name='parcelas'
                        editing={false}
                        rows={parcelario && parcelario.list && parcelario.list}
                        cols={(name, row, index) => cols(row)}
                        minHeight={400}
                        maxHeight={400}
                        highlightRow={row =>
                            recolectado.filter(
                                item => item.parcela.id === row.id
                            ).length > 1 ||
                            cosecha.list.some(
                                item => item.parcela.id === row.id
                            )
                        }
                    />
                </Grid>
                <Grid item xs>
                    {selectedParcel && (
                        <Grid container direction={'column'}>
                            {allowRecoleccion(selectedParcel) && (
                                <>
                                    <Grid item xs>
                                        <DataGrid
                                            title={'Recolectado'}
                                            name='declaracion'
                                            editing={
                                                values.liquidacion !==
                                                'Liquidado'
                                            }
                                            rows={recolectado.filter(
                                                item =>
                                                    item.parcela.id ===
                                                    selectedParcel.id
                                            )}
                                            cols={(name, row, index) =>
                                                colsRecolectado(
                                                    name,
                                                    row,
                                                    handleChange,
                                                    selectedParcel
                                                )
                                            }
                                            onDelete={(name, index, row) => {
                                                handleChange(
                                                    [
                                                        {
                                                            field: 'kgs',
                                                            value: ''
                                                        },
                                                        {
                                                            field: 'baume',
                                                            value: ''
                                                        }
                                                    ],
                                                    name,
                                                    row.id
                                                );
                                            }}
                                            minHeight={180}
                                            maxHeight={180}
                                        />
                                    </Grid>
                                    <Grid sx={{ marginTop: '10px' }} item xs>
                                        <Grid
                                            container
                                            alignItems={'center'}
                                            //spacing={2}
                                        >
                                            <Grid item xs>
                                                <Grid
                                                    sx={{
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        backgroundColor:
                                                            'lightgray',
                                                        height: 25,
                                                        borderRadius: 1
                                                    }}
                                                    container
                                                    justifyContent={
                                                        'space-around'
                                                    }
                                                    alignItems={'center'}
                                                >
                                                    <Grid item>
                                                        <Typography
                                                            variant={'h5'}
                                                        >
                                                            {`Kilos: ${helpers.getValueFormatted('d.2', totalParcela.kgs)}`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            variant={'h5'}
                                                        >
                                                            {`º Baume: ${helpers.getValueFormatted('d.2', totalParcela.baume)}`}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs>
                                <DataGrid
                                    name='anteriores'
                                    title={'Otras Declaraciones'}
                                    editing={false}
                                    readOnly={true}
                                    rows={
                                        cosecha &&
                                        cosecha.list &&
                                        getResumeCosecha(
                                            cosecha.list.filter(
                                                item =>
                                                    item.tramite.id !==
                                                        values.id &&
                                                    item.parcela.id ===
                                                        selectedParcel.id
                                            )
                                        )
                                    }
                                    cols={(name, row, index) =>
                                        colsAnteriores(row)
                                    }
                                    getId={row => row.tramite.id}
                                    minHeight={120}
                                    maxHeight={120}
                                >
                                    <ViewEntity
                                        readOnly
                                        type={ENTITY_TRAMITES}
                                        //refresh={cosecha && cosecha.refresh}
                                        entity={entityQueryTramite}
                                    >
                                        {DetailTramite}
                                    </ViewEntity>
                                </DataGrid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Divider style={{ margin: '12px 12px 12px 12px' }}>
                        <Chip size='small' label={'Declarado'} />
                    </Divider>
                </Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2}>
                {/*<Grid item>
                    <Button
                        isBusy={isBusy}
                        toolTipText={'Refrescar la declaración'}
                        icon={<icons.RefreshIcon />}
                        onClick={() => {
                            setIsBusy(true);
                            console.log(cosecha.list);
                            setIsBusy(false);
                        }}
                        float={true}
                    />
                </Grid>*/}
                <Grid item xs>
                    <Grid
                        sx={{
                            marginTop: '10px',
                            marginBottom: '10px',
                            backgroundColor: 'lightgray',
                            height: 25,
                            borderRadius: 1
                        }}
                        container
                        justifyContent={'space-around'}
                        alignItems={'center'}
                    >
                        <Grid item>
                            <Typography variant={'h5'}>TOTAL</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Superficie: ${helpers.getValueFormatted('d.4', totales.superficie)}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Recolectados: ${helpers.getValueFormatted('d.2', totales.recolectados)}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Excedidos: ${helpers.getValueFormatted('d.2', totales.excedidos)}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Bloqueados: ${helpers.getValueFormatted('d.2', totales.bloqueados)}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`No Aptos: ${helpers.getValueFormatted('d.2', totales.noAptos)}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`No DOP: ${helpers.getValueFormatted('d.2', totales.noDop)}`}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Declarados: ${helpers.getValueFormatted('d.2', totales.declarados)}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs>
                    <DataGrid
                        name='cosecha'
                        editing={false}
                        readOnly={true}
                        rows={
                            cosecha &&
                            cosecha.list &&
                            cosecha.list.filter(
                                item => item.tramite.id === values.id
                            )
                        }
                        cols={(name, row, index) => colsDeclarado(row)}
                        //getId={row => row.id}
                        highlightRow={row =>
                            selectedParcel
                                ? row.parcela.id === selectedParcel.id
                                : false
                        }
                        minHeight={400}
                        maxHeight={400}
                    >
                        {/*<ViewEntity
                            readOnly
                            type={ENTITY_PARCELA}
                            refresh={refresh}
                            entity={entityQuery}
                        >
                            {DetailParcela}
                        </ViewEntity>*/}
                    </DataGrid>
                </Grid>
            </Grid>
            <Alert
                open={openDialog}
                title='¿Esta seguro de cancelar los cambios realizados?'
                text={`Si "Acepta" perdera todos los cambios realizados desde la ultima vez que los guardo. Tenga en cuenta que esta acción es irreversible.`}
                cancel={() => setOpenDialog(false)}
                accept={() => {
                    setOpenDialog(false);
                    initRecolectado();
                    setCuota(values.cuota);
                }}
            />
        </form>
    );
};

const DeclaracionContainer = List(
    List(Declaracion, 'parcelario', 'parcelario'),
    'cosecha',
    'cosechaTramite'
);
export default DeclaracionContainer;
