// Entidades del entorno
export const ENTITY_CONTACTO = 'contacto';

// Entidades de Bodegas
export const ENTITY_BODEGA = 'bodega';
export const ENTITY_EXPLOTACION_BODEGA = 'explotacionBodega';
export const ENTITY_MARCA = 'marcaBodega';
export const ENTITY_ETIQUETA_MARCA = 'etiquetaMarca';

// Entidades de Agricultores
export const ENTITY_VITICULTOR = 'viticultor';
export const ENTITY_SOCIO = 'socio';
export const ENTITY_PROVEEDOR = 'proveedor';
export const ENTITY_CLIENTE = 'cliente';
export const ENTITY_PARCELA = 'parcela';
export const ENTITY_COSECHA = 'cosecha';

// Entidades contabilidad
export const ENTITY_SUBCUENTA = 'subcuenta';

// Entidades trámites
export const ENTITY_TRAMITES = 'tramites';
export const ENTITY_RESOLUCIONES = 'resoluciones';
export const ENTITY_ACTAS = 'actas';
export const ENTITY_PARCELA_TRAMITE = 'parcelaTramite';
export const ENTITY_LIQUIDACIONES = 'Liquidaciones';

export const ENTITY_PARCELA_ACTA = 'parcelaActa';

export const BANCOS = 'Bancos';
export const VENCIMIENTOS = 'Vencimientos';
export const DIARIO = 'Diario';
export const MOVIMIENTOS_TESORERIA = 'MovimientosTesoreria';

export const FORMASPAGO = 'FormasPago';
export const PARTIDAS_PRESUPUESTO = 'PartidasPresupuesto';
export const PRESUPUESTO_CONTABLE = 'PresupuestoContable';
export const GASTOS = 'Gastos';
export const TIPOS_TRAMITE = 'TiposTramite';

export const TAREAS = 'Tareas';
export const AUDITORIAS = 'Auditorias';
export const IMPUESTO_347_PROVEEDORES = 'Impuesto347Proveedores';
export const IMPUESTO_347_CLIENTES = 'Impuesto347Clientes';
