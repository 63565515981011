import React from 'react';
import { Divider, Grid, Chip } from 'core/components/library';
import { Select, Combo, DataGridPro as DataGrid } from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import List from 'store/List';

import DetailUsuario from 'views/usuarios/Detalle';
import { maxHeight, minHeight } from '@mui/system';

const ComboUsuarios = List(Combo, 'options', 'lstintervinientes');

const cols = (name, row, index, editing, handleChange, refresh, viewRol) => [
    {
        key: 'usuario',
        title: 'Representante',
        align: 'left',
        width: viewRol ? '60%' : '100%',
        component: row && (
            <ComboUsuarios
                fullWidth
                margin='none'
                variant='standard'
                api='usuarios'
                name='usuario'
                nameKey='usuarioId'
                relacionado={{
                    Detail: DetailUsuario,
                    resume: ['nombre'],
                    readOnly: true
                }}
                refresh={refresh}
                Icon={icons.UsuarioIcon}
                value={row.usuario}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option =>
                    `${option['nombre']} ${option['puesto'] ? '(' + option['puesto'] + ')' : ''}`
                }
            />
        )
    },
    /*{
        key: 'formulario',
        title: 'Formulario',
        align: 'left',
        width: '33%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='formulario'
                variant='standard'
                margin='none'
                value={row['formulario']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    },*/
    viewRol && {
        key: 'rol',
        title: 'Rol',
        align: 'left',
        width: '40%',
        component: row && (
            <Select
                fullWidth
                variant='standard'
                margin='none'
                name='rol'
                value={row['rol']}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                options={enums.representationRol}
            />
        )
    }
];

const Representantes = props => {
    const {
        values,
        editing,
        handleChange,
        refresh,
        title,
        name,
        viewRol,
        maxHeight,
        minHeight
    } = props;

    return (
        <Grid container direction='column' spacing={1}>
            <Grid item xs>
                <Divider style={{ margin: '12px 12px 12px 12px' }}>
                    <Chip label={title} />
                </Divider>
            </Grid>
            <Grid item xs>
                <DataGrid
                    name={name}
                    editing={editing}
                    rows={values[name] ? values[name] : []}
                    cols={(name, row, index) =>
                        cols(
                            name,
                            row,
                            index,
                            editing,
                            handleChange,
                            refresh,
                            viewRol ? viewRol : false
                        )
                    }
                    onAdd={name => {
                        handleChange({}, name);
                    }}
                    onDelete={(name, index) =>
                        handleChange(undefined, name, (index + 1) * -1)
                    }
                    minHeight={minHeight}
                    maxHeight={maxHeight}
                />
            </Grid>
        </Grid>
    );
};

export default Representantes;
