import React from 'react';

import { useRoutes } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import themes from './themes';
import NavigationScroll from './layout/NavigationScroll';

import { LAYOUT_MAIN, LAYOUT_MINIMAL } from 'core/constants';
import MainLayout from './layout/MainLayout';
import MinimalLayout from './layout/MinimalLayout';

import config from '../../config';

export const Template = props => {
    const { routes, customization } = props;

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    {useRoutes(routes, config.basename)}
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export const layouts = {
    [LAYOUT_MAIN]: <MainLayout />,
    [LAYOUT_MINIMAL]: <MinimalLayout />
};
