import React, { useEffect } from 'react';
import { DateTime } from 'luxon';

import { Divider, Grid, Stack } from 'core/components/library';

import { Text, Select, Tabs, Documents, Combo, Switch } from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import * as helpers from 'core/helpers';

import Apuntes from './Tabs/Apuntes';
import Vencimientos from './Tabs/Vencimientos';
import List from '../../../../store/List';

import DetailProveedor from 'views/compras/Proveedores/Detalle';
import DetailSubcuenta from 'views/contabilidad/subcuentas/Detalle';
import DetailCliente from '../../../ventas/Clientes/Detalle';
import DetailSocio from '../../../maestros/Socios/Detalle';
import { ENTITY_CLIENTE } from '../../../../store/entities/constants';

const ComboProveedores = List(Combo, 'options', 'lstProveedores');
const ComboSubcuentas = List(Combo, 'options', 'lstSubcuentas');
const ComboMedios = List(Combo, 'options', 'lstMedios');
const ComboClientes = List(Combo, 'options', 'lstClientes');
const ComboSocios = List(Combo, 'options', 'lstSocios');

let timeBoxApuntes = 0;

const getEntidad = (values, handleChange, editing, refresh) => {
    if (!values.entrada) {
        return (
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboProveedores
                        fullWidth
                        api='proveedores'
                        name='proveedor'
                        nameKey='proveedorid'
                        label='Proveedor'
                        relacionado={{
                            Detail: DetailProveedor,
                            resume: ['nombre'],
                            readOnly: false
                        }}
                        refresh={refresh}
                        Icon={icons.ProveedoresIcon}
                        value={values.proveedor}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `[${option.nif}]-${option.nombre}`
                        }
                    />
                </Grid>
                <Grid item xs></Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboSocios
                        fullWidth
                        api='maestros/socios'
                        name='socio'
                        nameKey='socioId'
                        label='Socio'
                        relacionado={{
                            Detail: DetailSocio,
                            resume: ['nombre'],
                            readOnly: false
                        }}
                        refresh={refresh}
                        Icon={icons.SociosIcon}
                        value={values.socio}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `${option.nombre}-[${option.codigo}]-[${option.nif}]`
                        }
                        filters={[
                            {
                                fields: ['estado'],
                                values: ['Activo'],
                                operator: 'in',
                                type: 'text'
                            }
                        ]}
                        searchKeys={['codigo', 'nombre', 'nif']}
                    />
                </Grid>
                <Grid item xs>
                    <ComboClientes
                        fullWidth
                        api='clientes'
                        name='cliente'
                        nameKey='clienteId'
                        label='Cliente'
                        relacionado={{
                            type: ENTITY_CLIENTE,
                            Detail: DetailCliente,
                            resume: ['nombre'],
                            readOnly: false
                        }}
                        refresh={refresh}
                        Icon={icons.ClientesIcon}
                        value={values.cliente}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `[${option.nif}]-[${option.codigo}]-${option.nombre}`
                        }
                        searchKeys={['codigo', 'nombre', 'nif']}
                    />
                </Grid>
            </Grid>
        );
    }
};

const buildConcepto = values => {
    const preposicion = values.entrada ? ' de ' : ' a ';
    let concepto = values.entrada
        ? `Cobro ${values.tipo ? values.tipo : ''} por`
        : `Pago ${values.tipo ? values.tipo : ''} con`;

    if (values.metodoPago) concepto = concepto + ' ' + values.metodoPago;
    if (values.numero) concepto = concepto + ' ' + values.numero;

    if (values.proveedor)
        concepto = concepto + preposicion + values.proveedor.nombre;
    if (values.empleado)
        concepto = concepto + preposicion + values.empleado.nombre;
    if (values.socio) concepto = concepto + preposicion + values.socio.nombre;
    if (values.cliente)
        concepto = concepto + preposicion + values.cliente.nombre;

    return concepto;
};

const Detalle = props => {
    const { values, editing, handleChange, refresh, reload, execAction } =
        props;

    useEffect(() => {
        if (editing) {
            const changes = [];

            if (values.entrada) {
                if (values.proveedor) {
                    changes.push(
                        helpers.getValueControl('text', 'proveedor', null)
                    );
                }

                if (values.emppleado) {
                    changes.push(
                        helpers.getValueControl('text', 'empleado', null)
                    );
                }
            } else {
                if (values.socio) {
                    changes.push(
                        helpers.getValueControl('text', 'socio', null)
                    );
                }
                if (values.cliente) {
                    changes.push(
                        helpers.getValueControl('text', 'cliente', null)
                    );
                }
            }

            changes.push(
                helpers.getValueControl(
                    'text',
                    'concepto',
                    buildConcepto(values)
                )
            );

            changes.push(
                helpers.getValueControl('combo', 'vencimientos', null)
            );

            handleChange(changes);

            actualizaApuntes('Entrada');
        }
    }, [values.entrada]);

    useEffect(() => {
        if (editing) {
            if (values.medio) {
                handleChange([
                    helpers.getValueControl(
                        'combo',
                        'subcuenta',
                        values.medio.subcuenta
                    ),
                    helpers.getValueControl('combo', 'vencimientos', null)
                ]);
            }
        }
    }, [JSON.stringify(values.medio)]);

    useEffect(() => {
        if (editing) {
            handleChange([
                helpers.getValueControl(
                    'text',
                    'concepto',
                    buildConcepto(values)
                ),
                helpers.getValueControl('combo', 'vencimientos', null)
            ]);
        }
    }, [JSON.stringify(values.proveedor)]);

    useEffect(() => {
        if (editing) {
            const changes = [];

            if (values.cliente) {
                changes.push(helpers.getValueControl('combo', 'socio', null));
            }

            changes.push(
                helpers.getValueControl('combo', 'vencimientos', null)
            );

            changes.push(
                helpers.getValueControl(
                    'text',
                    'concepto',
                    buildConcepto(values)
                )
            );

            handleChange(changes);
        }
    }, [JSON.stringify(values.cliente)]);

    useEffect(() => {
        if (editing) {
            const changes = [];

            if (values.socio) {
                changes.push(helpers.getValueControl('combo', 'cliente', null));
            }

            changes.push(
                helpers.getValueControl('combo', 'vencimientos', null)
            );

            changes.push(
                helpers.getValueControl(
                    'text',
                    'concepto',
                    buildConcepto(values)
                )
            );

            handleChange(changes);
        }
    }, [JSON.stringify(values.socio)]);

    useEffect(() => {
        if (editing) {
            handleChange([
                helpers.getValueControl(
                    'text',
                    'concepto',
                    buildConcepto(values)
                )
            ]);
        }
    }, [values.metodoPago, values.numero, values.tipo]);

    useEffect(() => {
        if (editing) {
            handleChange([
                helpers.getValueControl(
                    'text',
                    'concepto',
                    buildConcepto(values)
                ),
                helpers.getValueControl('combo', 'vencimientos', null)
            ]);
        }
    }, [values.tipo]);

    useEffect(() => {
        if (editing) {
            handleChange([
                helpers.getValueControl('text', 'fechaCargo', values.fecha),
                helpers.getValueControl('text', 'fechaValor', values.fecha)
            ]);
        }
    }, [values.fecha]);

    useEffect(() => {
        actualizaApuntes('FechaValor');
    }, [values.fechaValor]);

    useEffect(() => {
        if (editing) {
            handleChange([
                helpers.getValueControl('combo', 'vencimientos', null)
            ]);
        }
    }, [values.fechaCargo]);

    useEffect(() => {
        actualizaApuntes('Subcuenta');
    }, [JSON.stringify(values.subcuenta)]);

    useEffect(() => {
        actualizaApuntes('Concepto');
    }, [values.concepto]);

    useEffect(() => {
        actualizaApuntes('Importe');
    }, [values.importe]);

    useEffect(() => {
        actualizaApuntes('Vencimientos');
    }, [JSON.stringify(values.vencimientos)]);

    const actualizaApuntes = emisor => {
        if (editing) {
            const action = () => {
                console.log('Apuntes', emisor);
                const accion = {
                    api: 'tesoreria/movimientos/asiento',
                    metodo: 'post',
                    data: { ...values }
                };

                execAction(accion, [reload]);
            };

            timeBoxApuntes > 0 && clearTimeout(timeBoxApuntes);
            timeBoxApuntes = setTimeout(action, 800);
        }
    };

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Stack
                        sx={{ height: '100%' }}
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Switch
                            value={values.entrada}
                            onChange={handleChange}
                            name='entrada'
                            label='Entrada'
                            editing={editing}
                        />
                    </Stack>
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='fecha'
                        label='Fecha'
                        value={values.fecha}
                        type='date'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='fechaCargo'
                        label='Fecha Cargo'
                        value={values.fechaCargo}
                        type='date'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='fechaValor'
                        label='Fecha Valor'
                        value={values.fechaValor}
                        type='date'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs style={{ marginLeft: '30px' }}>
                    <Text
                        autoSelect
                        fullWidth
                        name='importe'
                        label='Importe €'
                        value={values.importe}
                        type='d.2   '
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs style={{ marginRight: '30px' }}>
                    <Text
                        autoSelect
                        fullWidth
                        name='gastos'
                        label='Gastos Fin. €'
                        value={values.gastos}
                        type='d.2   '
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>

                <Grid item xs>
                    <Select
                        fullWidth
                        editing={editing}
                        label='Tipo Documento'
                        name='tipo'
                        options={enums.tiposDocumento.filter(item =>
                            item.entrada !== undefined
                                ? item.entrada === values.entrada
                                : true
                        )}
                        value={values.tipo}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs>
                    <Select
                        fullWidth
                        editing={editing}
                        label='Metodo'
                        name='metodoPago'
                        options={enums.tiposPago}
                        value={values.metodoPago}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='numero'
                        label='Número'
                        value={values.numero}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboMedios
                        fullWidth
                        api='bancos/mediospago'
                        name='medio'
                        nameKey='medioid'
                        label={`Medio de ${values.entrada ? 'Cobro' : 'Pago'}`}
                        refresh={refresh}
                        Icon={icons.BancosIcon}
                        value={values.medio}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `${option.banco.nombre}-[${option.alias}]-[${option.subcuenta.id}]`
                        }
                        searchKeys={['subcuenta.id', 'alias', 'banco.nombre']}
                    />
                </Grid>
                <Grid item xs>
                    <ComboSubcuentas
                        fullWidth
                        api='subcuentas/activas'
                        name='subcuenta'
                        nameKey='subcuentaid'
                        label='Cuenta Contable'
                        relacionado={{
                            Detail: DetailSubcuenta,
                            resume: ['descripcion'],
                            readOnly: false
                        }}
                        refresh={refresh}
                        Icon={icons.CuentasIcon}
                        value={values.subcuenta}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `${option.id} ${option.descripcion}`
                        }
                    />
                </Grid>
            </Grid>
            {getEntidad(values, handleChange, editing, refresh)}
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        multiline
                        rows={4}
                        name='concepto'
                        label='Concepto'
                        value={values.concepto}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        multiline
                        rows={4}
                        name='notas'
                        label='Notas'
                        value={values.notas}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Vencimientos',
                        icon: icons.VencimientosIcon,
                        component: <Vencimientos {...props} />,
                        disabled: false
                    },
                    {
                        label: 'Apuntes',
                        icon: icons.AsientosIcon,
                        component: <Apuntes {...props} />,
                        disabled: false
                    },
                    {
                        label: 'Documentos',
                        icon: icons.DocumentIcon,
                        component: (
                            <Documents
                                name='documentacion'
                                editing={editing}
                                onChange={handleChange}
                                rows={
                                    values.documentacion
                                        ? values.documentacion
                                        : []
                                }
                                maxHeight={456}
                            />
                        ),
                        disabled: false
                    }
                ]}
            />
        </form>
    );
};

export default Detalle;
