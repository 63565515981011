import React, { useState } from 'react';

import { makeStyles, paperShadow, gridSpacing } from '../../library';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Paper,
    SimpleDraggable as Draggable,
    Typography
} from '../../library';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[paperShadow],
        padding: theme.spacing(gridSpacing)
    }
}));

const PaperComponent = props => {
    return (
        <Draggable
            handle='#draggable-dialog-title'
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
};

const Detail = props => {
    const { open, item, close, edit, del, readOnly, maxWidth, lite } = props;
    const classes = useStyles();

    if (!open) return null;

    const Container = item.container;

    return (
        <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            fullWidth={true}
            maxWidth={maxWidth ? maxWidth : 'xl'}
        >
            <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
                <Typography variant='h4'>{item.title}</Typography>
            </DialogTitle>
            <DialogContent className={classes.paper}>
                <Container
                    lite={lite}
                    item={item}
                    close={close}
                    edit={edit}
                    del={del}
                    readOnly={readOnly}
                />
            </DialogContent>
        </Dialog>
    );
};

export default Detail;
