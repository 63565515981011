import React, { useEffect } from 'react';
import { DateTime } from 'luxon';

import { Divider, Grid } from 'core/components/library';

import { Text, Select, Tabs, Documents, Combo, Emit } from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import * as helpers from 'core/helpers';

import Apuntes from './Tabs/TabApuntes';
import Conceptos from './Tabs/TabConceptos';
import Vencimientos from './Tabs/TabVencimientos';
import List from '../../../../store/List';

import DetailSocio from 'views/maestros/Socios/Detalle';
import DetailCliente from 'views/ventas/Clientes/Detalle';
import DetailSubcuenta from 'views/contabilidad/subcuentas/Detalle';
import DetailFormaPago from 'views/tesoreria/formas-pago/Detalle';
import DetailTipo from '../../../tramites/Tipos/Detalle';
import {
    ENTITY_SOCIO,
    ENTITY_CLIENTE
} from '../../../../store/entities/constants';

const ComboTipos = List(Combo, 'options', 'lstTiposLiquidacion');
const ComboSocios = List(Combo, 'options', 'lstSocios');
const ComboClientes = List(Combo, 'options', 'lstClientes');
const ComboSubcuentas = List(Combo, 'options', 'lstSubcuentas');
const ComboFormaPago = List(Combo, 'options', 'lstFormasPago');

let timeBoxApuntes = 0;
let timeBoxVencimientos = 0;
let timeBoxCodigo = 0;

const buildConcepto = values => {
    let concepto = 'Liq.';

    if (values.codigo) concepto = concepto + ' ' + values.codigo;

    if (values.descripcion) concepto = concepto + ' ' + values.descripcion;

    if (values.cliente) concepto = concepto + ' ' + values.cliente.nombre;

    if (values.socio) concepto = concepto + ' ' + values.socio.nombre;

    return concepto;
};

const Detalle = props => {
    const {
        values,
        editing,
        handleChange,
        handleError,
        refresh,
        reload,
        execAction,
        adding
    } = props;

    useEffect(() => {
        if (editing) {
            const changes = [];

            if (values.tipoLiquidacion) {
                if (values.tipoLiquidacion.socio && values.cliente) {
                    changes.push(
                        helpers.getValueControl('combo', 'cliente', null)
                    );
                }

                if (values.tipoLiquidacion.cliente && values.socio) {
                    changes.push(
                        helpers.getValueControl('combo', 'socio', null)
                    );
                }

                changes.push(
                    helpers.getValueControl(
                        'text',
                        'serie',
                        values.tipoLiquidacion.serie
                    )
                );

                changes.push(
                    helpers.getValueControl(
                        'text',
                        'descripcion',
                        values.tipoLiquidacion.concepto
                    )
                );

                changes.push(
                    helpers.getValueControl(
                        'text',
                        'tipo',
                        values.tipoLiquidacion.rectificativa
                            ? 'Rectificativo'
                            : 'Normal'
                    )
                );

                handleChange(changes);
            }

            /*
            changes.push(
                helpers.getValueControl(
                    'text',
                    'concepto',
                    buildConcepto(values)
                )
            );*/

            handleChange(changes);
        }
    }, [JSON.stringify(values.tipoLiquidacion)]);

    useEffect(() => {
        if (editing) {
            const changes = [];

            if (values.socio) {
                if (values.socio.subcuenta) {
                    changes.push(
                        helpers.getValueControl(
                            'combo',
                            'subcuenta',
                            values.socio.subcuenta
                        )
                    );
                }

                if (values.socio.formaPago) {
                    changes.push(
                        helpers.getValueControl(
                            'combo',
                            'formaPago',
                            values.socio.formaPago
                        )
                    );
                }

                //changes.push(helpers.getValueControl('combo', 'bodega', null));
            }

            changes.push(
                helpers.getValueControl(
                    'text',
                    'concepto',
                    buildConcepto(values)
                )
            );

            handleChange(changes);
        }
    }, [JSON.stringify(values.socio)]);

    useEffect(() => {
        if (editing) {
            const changes = [];

            if (values.cliente) {
                if (values.cliente.subcuenta) {
                    changes.push(
                        helpers.getValueControl(
                            'combo',
                            'subcuenta',
                            values.cliente.subcuenta
                        )
                    );
                }

                if (values.cliente.subcuenta) {
                    changes.push(
                        helpers.getValueControl(
                            'combo',
                            'formaPago',
                            values.cliente.formaPago
                        )
                    );
                }

                //changes.push(helpers.getValueControl('combo', 'viticultor', null));
            }

            changes.push(
                helpers.getValueControl(
                    'text',
                    'concepto',
                    buildConcepto(values)
                )
            );

            handleChange(changes);
        }
    }, [JSON.stringify(values.cliente)]);

    useEffect(() => {
        actualizaApuntes('Concepto');
        actualizaVencimientos('Concepto');
    }, [values.conceptoa]);

    useEffect(() => {
        actualizaApuntes('Total');
        actualizaVencimientos('Total');
    }, [values.total]);

    useEffect(() => {
        actualizaApuntes('Subcuenta');
        actualizaVencimientos('Subcuenta');
    }, [JSON.stringify(values.subcuenta)]);

    useEffect(() => {
        handleChange([
            helpers.getValueControl('text', 'concepto', buildConcepto(values))
        ]);
    }, [values.codigo, values.descripcion]);

    useEffect(() => {
        actualizaApuntes('Conceptos');
    }, [JSON.stringify(values.conceptos)]);

    useEffect(() => {
        actualizaVencimientos('FormaPago');
    }, [JSON.stringify(values.formaPago)]);

    const isReadyApuntes = () => {
        if (!values.concepto) return false;
        if (!values.fecha) return false;
        if (!values.subcuenta) return false;
        if (!values.total) return false;
        if (!values.conceptos) return false;

        return true;
    };

    const actualizaApuntes = emisor => {
        if (editing && isReadyApuntes()) {
            const action = () => {
                console.log('Apuntes', emisor);
                const accion = {
                    api: 'Liquidaciones/asiento',
                    metodo: 'post',
                    data: { ...values }
                };
                execAction(accion, [reload]);
            };

            timeBoxApuntes > 0 && clearTimeout(timeBoxApuntes);
            timeBoxApuntes = setTimeout(action, 800);
        }
    };

    const isReadyVencimientos = () => {
        if (!values.concepto) return false;
        if (!values.fecha) return false;
        if (!values.subcuenta) return false;
        if (!values.total) return false;
        if (!values.formaPago) return false;

        return true;
    };

    const actualizaVencimientos = emisor => {
        if (editing && isReadyVencimientos()) {
            const action = () => {
                console.log('Vencimientos', emisor);
                const accion = {
                    api: 'Liquidaciones/vencimientos',
                    metodo: 'post',
                    data: { ...values }
                };
                execAction(accion, [reload]);
            };

            timeBoxVencimientos > 0 && clearTimeout(timeBoxVencimientos);
            timeBoxVencimientos = setTimeout(action, 800);
        }
    };

    const actualizaCodigo = () => {
        if (editing) {
            const action = () => {
                const accion = {
                    api: 'liquidaciones/nexts',
                    metodo: 'post',
                    data: {
                        fields: ['codigo'],
                        values: { ...values }
                    }
                };
                execAction(accion, [reload]);
            };

            timeBoxCodigo > 0 && clearTimeout(timeBoxCodigo);
            timeBoxCodigo = setTimeout(action, 250);
        }
    };

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <ComboTipos
                        fullWidth
                        api='tiposLiquidacion'
                        name='tipoLiquidacion'
                        nameKey='tipoLiquidacionid'
                        label='Clase Liquidación'
                        /*relacionado={{
                            Detail: DetailTipo,
                            resume: ['descripcion'],
                            readOnly: true
                        }}*/
                        refresh={refresh}
                        Icon={icons.DefinicionIcon}
                        value={values.tipoLiquidacion}
                        onChange={handleChange}
                        editing={adding}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `[${option.id}]-${option.descripcion}`
                        }
                        searchKeys={['id', 'descripcion']}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='fecha'
                        label='Fecha'
                        value={values.fecha}
                        type='date'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Text
                        fullWidth
                        name='serie'
                        label='Serie'
                        value={values.serie}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        required
                        fullWidth
                        name='codigo'
                        label='Número'
                        value={values.codigo}
                        onChange={handleChange}
                        onError={handleError}
                        editing={adding}
                        type={`p${values.serie ? values.serie : ''}${
                            values.fecha
                                ? DateTime.fromISO(values.fecha).year
                                : ''
                        }.5`}
                        nextValue={adding && actualizaCodigo}
                    />
                </Grid>
                <Grid item>
                    <Emit
                        disabled={false}
                        hidden={editing}
                        toolTipText={'Emitir Liquidación'}
                        formularios={{
                            id: 'Liquidacion',
                            descripcion: 'Formato de Liquidacion'
                        }}
                        reports={[
                            {
                                id: 'Liquidacion',
                                title: 'Liquidacion',
                                resume: 'Liquidacion',
                                filters: []
                            }
                        ]}
                        values={values}
                    />
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs>
                    <Select
                        fullWidth
                        name='tipo'
                        label='Tipo'
                        value={values.tipo}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.tiposGasto}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={5}>
                    {values.tipoLiquidacion &&
                        values.tipoLiquidacion.cliente && (
                            <ComboClientes
                                fullWidth
                                api='clientes'
                                name='cliente'
                                nameKey='clienteId'
                                label='Cliente'
                                relacionado={{
                                    type: ENTITY_CLIENTE,
                                    Detail: DetailCliente,
                                    resume: ['nombre'],
                                    readOnly: false
                                }}
                                refresh={refresh}
                                Icon={icons.ClientesIcon}
                                value={values.cliente}
                                onChange={handleChange}
                                editing={editing}
                                propertyValue='id'
                                getOptionLabel={option =>
                                    `[${option.nif}]-${option.nombre}`
                                }
                                searchKeys={['nombre', 'nif']}
                            />
                        )}
                    {values.tipoLiquidacion && values.tipoLiquidacion.socio && (
                        <ComboSocios
                            fullWidth
                            api='maestros/socios'
                            name='socio'
                            nameKey='socioId'
                            label='Socio'
                            relacionado={{
                                type: ENTITY_SOCIO,
                                Detail: DetailSocio,
                                resume: ['nombre'],
                                readOnly: false
                            }}
                            refresh={refresh}
                            Icon={icons.SociosIcon}
                            value={values.socio}
                            onChange={(par1, par2) => {
                                console.log(par1, par2);
                                handleChange(par1, par2);
                            }}
                            editing={editing}
                            propertyValue='id'
                            getOptionLabel={option =>
                                `${option.nombre}-[${option.codigo}]-[${option.nif}]-[${option.estado}]`
                            }
                            filters={{
                                fields: ['estado'],
                                values: ['Activo'],
                                operator: 'in',
                                type: 'text'
                            }}
                            searchKeys={['codigo', 'nombre', 'nif', 'estado']}
                        />
                    )}
                </Grid>
                <Grid item xs>
                    <ComboSubcuentas
                        fullWidth
                        api='subcuentas/activas'
                        name='subcuenta'
                        nameKey='subcuentaid'
                        label='Cuenta Contable'
                        relacionado={{
                            Detail: DetailSubcuenta,
                            resume: ['descripcion'],
                            readOnly: false
                        }}
                        refresh={refresh}
                        Icon={icons.CuentasIcon}
                        value={values.subcuenta}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `${option.id} ${option.descripcion}`
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <ComboFormaPago
                        fullWidth
                        api='formaspago'
                        name='formaPago'
                        nameKey='formaPagoid'
                        label='Forma de Cobro'
                        relacionado={{
                            maxWidth: 'md',
                            Detail: DetailFormaPago,
                            resume: ['descripcion'],
                            readOnly: false
                        }}
                        refresh={refresh}
                        Icon={icons.FormasPagoIcon}
                        value={values.formaPago}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option.descripcion}`}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Text
                        fullWidth
                        name='descripcion'
                        label='Descripcion'
                        value={values.descripcion}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='concepto'
                        label='Concepto'
                        value={values.concepto}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Conceptos',
                        icon: icons.LaboralIcon,
                        component: <Conceptos {...props} />,
                        disabled: false
                    },
                    {
                        label: 'Apuntes',
                        icon: icons.AsientosIcon,
                        component: <Apuntes {...props} />,
                        disabled: false
                    },
                    {
                        label: 'Vencimientos',
                        icon: icons.VencimientosIcon,
                        component: <Vencimientos {...props} />,
                        disabled: false
                    },
                    {
                        label: 'Documentos',
                        icon: icons.DocumentIcon,
                        component: (
                            <Documents
                                name='documentacion'
                                editing={editing}
                                onChange={handleChange}
                                rows={
                                    values.documentacion
                                        ? values.documentacion
                                        : []
                                }
                                maxHeight={456}
                            />
                        ),
                        disabled: false
                    }
                ]}
            />
        </form>
    );
};

export default Detalle;
