import React, { useState, useEffect } from 'react';

import { makeStyles, clsx } from '../../library';

import {
    Card,
    Grid,
    CardActions,
    CardContent,
    Table,
    TableContainer,
    TableBody,
    TableFooter,
    TableCell,
    Typography,
    TablePagination
} from '../../library';

import Head from './Head';
import Row from './Row';
import Foot from './Foot';

const useStyles = makeStyles(theme => {
    return {
        root: {},
        content: {
            padding: 0,
            minHeight: '65vh',
            maxHeight: '65vh'
        },
        container: {
            //maxWidth: 800,
            maxHeight: '65vh'
        },
        actions: {
            justifyContent: 'space-between',
            alingItems: 'center',
            padding: 0,
            //marginRight:12,
            //marginLeft:12,
            backgroundColor: theme.palette.grey['50'],
            maxHeight: 60
        }
    };
});

const hasFooter = columns => {
    return columns.some(item => item.aggregate);
};

const Index = props => {
    const { className, disabled, view, entities, columns, readOnly, ...rest } =
        props;
    const classes = useStyles();
    const [componentTopPosition, setComponentTopPosition] = useState('0px');

    const updatePosition = () => {
        const element = document.getElementById('index');
        if (element) {
            const positionInPixels = element.getBoundingClientRect().top;
            setComponentTopPosition(`${positionInPixels + 80}px`);
        }
    };

    useEffect(() => {
        updatePosition();
        window.addEventListener('resize', updatePosition);

        // Elimina el manejador de evento al desmontar el componente
        return () => window.removeEventListener('resize', updatePosition);
    }, []);

    return (
        <Card className={clsx(classes.root, className)} raised={true}>
            <CardContent
                id={'index'}
                sx={{
                    padding: 0,
                    height: `calc(100vh - ${componentTopPosition})`
                }}
            >
                <TableContainer
                    sx={{
                        padding: 0,
                        height: `calc(100vh - ${componentTopPosition})`
                    }}
                >
                    <Table stickyHeader size='small'>
                        <Head
                            disabled={disabled}
                            entities={entities}
                            columns={columns}
                        />
                        <TableBody>
                            {entities.listPaged.map((item, index) => (
                                <Row
                                    key={index}
                                    view={view}
                                    disabled={disabled}
                                    columns={columns}
                                    entities={entities}
                                    item={item}
                                    rowNum={index}
                                    readOnly={readOnly}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>

            <CardActions className={classes.actions}>
                <TablePagination
                    showFirstButton={true}
                    showLastButton={true}
                    component='div'
                    count={entities.list.length}
                    onPageChange={(event, page) => entities.setPage(page)}
                    onRowsPerPageChange={event =>
                        entities.setVisibles(event.target.value)
                    }
                    page={entities.page}
                    rowsPerPage={entities.visibles}
                    rowsPerPageOptions={[5, 10, 15, 20, 30, 50, 100]}
                    labelRowsPerPage={
                        <Typography variant='caption'>
                            Filas por Página
                        </Typography>
                    }
                    labelDisplayedRows={({ from, to, count, page }) => (
                        <Typography variant='caption'>{`Elementos del ${from} al ${to} de un total de  ${count}`}</Typography>
                    )}
                />
                {hasFooter(columns) && (
                    <Foot
                        //labels
                        entities={entities}
                        columns={columns.filter(item => item.column)}
                    />
                )}
            </CardActions>
        </Card>
    );
};

export default Index;
