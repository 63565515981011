import React, { useState } from 'react';
import { Button } from '..';

const Action = props => {
    const { hidden, disabled, icon, children, toolTipText } = props;
    const [open, setOpen] = useState(false);
    if (hidden) return null;

    return (
        <>
            <Button
                hidden={hidden}
                toolTipText={toolTipText}
                disabled={disabled}
                onClick={event => {
                    setOpen(true);
                }}
                icon={icon}
                float={true}
            />
            {children &&
                open &&
                React.cloneElement(children, {
                    open: open,
                    onClose: () => setOpen(false)
                })}
        </>
    );
};

export default Action;
