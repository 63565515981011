import React from 'react';
import { makeStyles, Grid } from 'core/components/library';
import { Text, Combo, Select, Switch } from 'core/components';

import * as icons from 'core/icons';

import DetailSubcuenta from 'views/contabilidad/subcuentas/Detalle';
import DetailFormaPago from 'views/tesoreria/formas-pago/Detalle';
import List from '../../../../../../store/List';
import * as enums from '../../../../../../core/enums';
import { ENTITY_BODEGA } from '../../../../../../store/entities/constants';
import DetailBodega from '../../../../Bodegas/Detalle';

const ComboSubcuentas = List(Combo, 'options', 'lstSubcuentas');
const ComboFormaPago = List(Combo, 'options', 'lstFormasPago');
const ComboCooperativas = List(Combo, 'options', 'lstCooperativas');
const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const Detalles = props => {
    const {
        values,
        editing,
        adding,
        initial,
        handleChange,
        refresh,
        estableceFiltro
    } = props;
    const classes = useStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Grid container direction='column'>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <Text
                                fullWidth
                                name='registro'
                                label='Registro Viticola'
                                value={values.registro}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item xs>
                            <Text
                                fullWidth
                                name='seica'
                                label='Seica'
                                value={values.seica}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <Switch
                                value={values.cooperativista}
                                onChange={handleChange}
                                name='cooperativista'
                                label='Cooperativista'
                                editing={editing}
                            />
                        </Grid>
                        {values.cooperativista && (
                            <Grid item xs>
                                <ComboCooperativas
                                    fullWidth
                                    api='bodegas'
                                    name='cooperativa'
                                    nameKey='bodegaid'
                                    label='Cooperativa'
                                    relacionado={{
                                        type: ENTITY_BODEGA,
                                        Detail: DetailBodega,
                                        resume: ['nombre'],
                                        readOnly: false
                                    }}
                                    refresh={refresh}
                                    Icon={icons.CellarIcon}
                                    value={values.cooperativa}
                                    onChange={handleChange}
                                    editing={editing}
                                    propertyValue='id'
                                    getOptionLabel={option => option.nombre}
                                    searchKeys={['id', 'nombre']}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs>
                        <ComboSubcuentas
                            fullWidth
                            api='subcuentas/activas'
                            name='subcuenta'
                            nameKey='subcuentaid'
                            label='Cuenta Contable'
                            relacionado={{
                                maxWidth: 'md',
                                Detail: DetailSubcuenta,
                                resume: ['descripcion'],
                                readOnly: false,
                                dfault: {
                                    id: '4300' + values.codigo,
                                    descripcion: values.nombre
                                }
                            }}
                            refresh={refresh}
                            Icon={icons.CuentasIcon}
                            value={values.subcuenta}
                            onChange={handleChange}
                            editing={editing}
                            propertyValue='id'
                            getOptionLabel={option =>
                                `${option.id} ${option.descripcion}`
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <ComboFormaPago
                            fullWidth
                            api='formaspago'
                            name='formaPago'
                            nameKey='formaPagoid'
                            label='Forma de Pago habitual'
                            relacionado={{
                                maxWidth: 'md',
                                Detail: DetailFormaPago,
                                resume: ['descripcion'],
                                readOnly: false
                            }}
                            refresh={refresh}
                            Icon={icons.FormasPagoIcon}
                            value={values.formaPago}
                            onChange={handleChange}
                            editing={editing}
                            propertyValue='id'
                            getOptionLabel={option => `${option.descripcion}`}
                        />
                    </Grid>
                    <Grid item xs>
                        <Text
                            label='Numero de Cuenta (IBAN)'
                            fullWidth
                            editing={editing}
                            name='iban'
                            value={values.iban}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Grid container direction='column'>
                    <Grid item xs>
                        <Text
                            fullWidth
                            multiline
                            rows={5}
                            name='notas'
                            label='Notas'
                            value={values.notas}
                            onChange={handleChange}
                            editing={editing}
                        />
                    </Grid>
                    <Grid item xs>
                        <Text
                            fullWidth
                            multiline
                            rows={5}
                            name='incidencias'
                            label='Incidencias'
                            value={values.incidencias}
                            onChange={handleChange}
                            editing={editing}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Detalles;
