import React from 'react';
import { makeStyles } from 'core/components/library';
import {
    Paper,
    ToggleButton,
    ToggleButtonGroup
} from 'core/components/library';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-star',
        '& > *': {
            marginLeft: theme.spacing(1)
        }
    },
    root: {
        height: '90vh',
        position: 'absolute',
        top: '5vh',
        right: '0vh'
    },
    selector: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: theme.palette.background.paper
        boxShadow: theme.shadows[20]
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[20],
        padding: theme.spacing(2, 4, 3)
    }
}));

const Selector = props => {
    const { log, logs, setLog } = props;
    const classes = useStyles();

    if (!logs || (logs && logs.length === 0))
        return 'No hay registros disponibles';

    return (
        <Paper elevation={0} className={classes.selector}>
            <ToggleButtonGroup
                color='primary'
                value={log}
                exclusive
                onChange={(event, newLog) =>
                    newLog ? setLog(newLog) : setLog(log)
                }
            >
                {logs &&
                    logs.length !== 0 &&
                    logs.map(log => {
                        const Icon = log.icon;

                        return (
                            <ToggleButton value={log.value}>
                                <Icon />
                                {log.label}
                            </ToggleButton>
                        );
                    })}
            </ToggleButtonGroup>
        </Paper>
    );
};

export default Selector;
