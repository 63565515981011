import { PrintIcon } from '../icons';
import { Button } from '../index';
import React, { useEffect, useState, useContext } from 'react';
import ReportContext from '../../../store/Reports';
import * as LZString from 'lz-string';

const print = (report, values) => {
    const key = `/form/${report.id}`;
    localStorage.setItem(
        key,
        LZString.compress(
            JSON.stringify({
                report: report,
                data: values
            })
        )
    );

    window.open(key);
};

const getReports = (reports, forms, values, entities, openReport) => {
    let newReports = null;

    if (reports && reports.length !== 0) {
        newReports = {
            title: 'Formatos disponibles',
            list: reports,
            action: report => print(report, values ? values : entities),
            columns: [
                {
                    column: 'title',
                    title: 'Titulo',
                    align: 'left',
                    type: 'text'
                },
                {
                    column: 'resume',
                    title: 'Resumen',
                    align: 'left',
                    type: 'text'
                }
            ]
        };
    } else if (forms && forms.length !== 0) {
        newReports = {
            title: 'Formatos disponibles',
            list: forms.map(form => {
                return {
                    id: form.id,
                    title: form.descripcion,
                    resume: form.id + (form.edicion ? '-' + form.edicion : ''),
                    edicion: form.edicion,
                    filters: []
                };
            }),
            action: report => print(report, values ? values : entities),
            columns: [
                {
                    column: 'title',
                    title: 'Titulo',
                    align: 'left',
                    type: 'text'
                },
                {
                    column: 'resume',
                    title: 'Resumen',
                    align: 'left',
                    type: 'text'
                }
            ]
        };
    }

    return newReports;
};

const Emit = props => {
    const {
        disabled,
        hidden,
        toolTipText,
        float,
        values,
        forms,
        reports,
        entities,
        Icon
    } = props;

    const { openReport } = useContext(ReportContext);

    const selector = getReports(reports, forms, values, entities, openReport);

    if (!selector) return null;

    return (
        <Button
            hidden={hidden}
            toolTipText={toolTipText}
            disabled={disabled}
            counter={0}
            busy={false}
            icon={!Icon ? <PrintIcon /> : <Icon />}
            selector={selector.list.length > 1 ? selector : null}
            onClick={
                selector.list.length === 1
                    ? () => selector.action(selector.list[0])
                    : undefined
            }
            float={float ? float : true}
        />
    );
};

export default Emit;
