import React, { useEffect, useState } from 'react';

import { Grid, Typography } from 'core/components/library';

import {
    Text,
    DataGridPro as DataGrid,
    ViewEntity,
    Literal,
    Tag,
    Switch
} from 'core/components';

import * as icons from 'core/icons';
import DetailParcela from 'views/maestros/Parcelas/Detalle';
import { ENTITY_PARCELA } from '../../../../../../store/entities/constants';
import { useSelector } from 'react-redux';
import * as helpers from '../../../../../../core/helpers';
import * as enums from '../../../../../../core/enums';
import { locksParcel } from '../../../../../../core/enums';

const getLiteral = value => {
    if (value) return value;

    return '#';
};

const getRendimiento = values => {
    const superficie = values.superficie;
    const produccion =
        values.modoCultivo.clave === 'I'
            ? values.variedad.produccionIntensivo
            : values.modoCultivo.clave === 'E'
              ? values.variedad.produccionExtensivo
              : 0;

    return Number((superficie * produccion).toFixed(2));
};

const cols = (row, entityData) => [
    {
        key: 'municipio',
        title: 'Municipio',
        align: 'left',
        //width: '5%',
        component: row && getLiteral(row.municipio.descripcion)
    },
    {
        key: 'poligono',
        title: 'Poligono',
        align: 'center',
        //width: '5%',
        component: row && getLiteral(row['poligono'])
    },
    {
        key: 'parcela',
        title: 'Parcela',
        align: 'center',
        //width: '5%',
        component: row && getLiteral(row['parcela'])
    },
    {
        key: 'recinto',
        title: 'Recinto',
        align: 'center',
        //width: '5%',
        component: row && getLiteral(row['recinto'])
    },
    {
        key: 'variedad',
        title: 'Variedad',
        align: 'left',
        //width: '5%',
        component: row && getLiteral(row.variedad.descripcion)
    },
    {
        key: 'año',
        title: 'Año',
        align: 'center',
        //width: '5%',
        component: row && getLiteral(row['año'])
    },
    {
        key: 'modoCultivo',
        title: 'Cultivo',
        align: 'center',
        //width: '14%',
        component: row && row.modoCultivo && <>{row.modoCultivo.descripcion}</>
    },
    {
        key: 'superficie',
        title: 'Superficie',
        align: 'right',
        type: 'd.4',
        //width: '5%',
        //aggregate: 'sum',
        component: row && helpers.getValueFormatted('d.4', row.superficie)
    },
    {
        key: 'rendimiento',
        title: 'Rendimiento',
        align: 'right',
        type: 'd.2',
        //width: '8%',
        //aggregate: 'sum',
        component:
            row &&
            helpers.getValueFormatted(
                'd.2',
                row.variedad && row.modoCultivo ? getRendimiento(row) : 0
            )
    },
    {
        key: 'bloqueo',
        title: 'Bloqueo',
        align: 'center',
        width: '15%',
        component: row && row.bloqueo && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(locksParcel, row.bloqueo, 'color')
                }}
                Icon={enums.getItem(locksParcel, row.bloqueo, 'icon')}
                value={row.bloqueo}
            />
        )
    }
];

const Parcelas = props => {
    const { values, editing, execAction, refresh } = props;
    const [punteo, setPunteo] = useState(false);
    const [punteados, setPunteados] = useState([]);
    const [totales, setTotales] = useState({
        items: 0,
        itemsPunteados: 0,
        superficie: 0,
        superficiePunteada: 0,
        rendimiento: 0,
        rendimientoPunteado: 0
    });

    const entityData = useSelector(state => state.entities[ENTITY_PARCELA]);

    useEffect(() => {
        calculaTotales();
    }, [JSON.stringify(values.parcelas), JSON.stringify(punteados)]);

    const calculaTotales = () => {
        let newTotales = {
            items: 0,
            itemsPunteados: 0,
            superficie: 0,
            superficiePunteada: 0,
            rendimiento: 0,
            rendimientoPunteado: 0
        };

        if (values && values.parcelas) {
            values.parcelas.forEach(parcela => {
                if (parcela.estado === 'Activo') {
                    newTotales.items += 1;
                    newTotales.superficie += Number(parcela.superficie);

                    let rendimiento = 0;
                    if (parcela.modoCultivo && parcela.variedad) {
                        rendimiento = getRendimiento(parcela);
                        newTotales.rendimiento += rendimiento;
                    }

                    if (punteados.some(item => item.id === parcela.id)) {
                        newTotales.itemsPunteados += 1;
                        newTotales.superficiePunteada += Number(
                            parcela.superficie
                        );
                        newTotales.rendimientoPunteado += rendimiento;
                    }
                }
            });
        }

        setTotales(newTotales);
    };

    return (
        <>
            <Grid
                container
                justifyContent={'space-around'}
                alignItems={'center'}
            >
                <Grid item>
                    <Switch
                        value={punteo}
                        onChange={values => setPunteo(values[0].value)}
                        name='punteo'
                        label='Puntear Parcelas'
                        editing={true}
                    />
                </Grid>
                <Grid item xs>
                    <Grid
                        sx={{
                            marginTop: '10px',
                            marginBottom: '10px',
                            backgroundColor: 'lightgray',
                            height: 25,
                            borderRadius: 1
                        }}
                        container
                        justifyContent={'space-around'}
                        alignItems={'center'}
                    >
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Parcelas: ${punteo ? helpers.getValueFormatted('entero', totales.itemsPunteados) + ' de ' : ''}${helpers.getValueFormatted('entero', totales.items)}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Superficie: ${punteo ? helpers.getValueFormatted('d.4', totales.superficiePunteada) + ' de ' : ''}${helpers.getValueFormatted('d.4', totales.superficie)}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>
                                {`Máx. Rendimiento: ${punteo ? helpers.getValueFormatted('d.2', totales.rendimientoPunteado) + ' de ' : ''}${helpers.getValueFormatted('d.2', totales.rendimiento)}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs>
                    <DataGrid
                        allowSelect={punteo ? 'multiple' : undefined}
                        changeSelectedItems={items => setPunteados(items)}
                        readOnly
                        name='parcelas'
                        editing={editing}
                        rows={
                            values.parcelas
                                ? values.parcelas.filter(
                                      p => p.estado === 'Activo'
                                  )
                                : []
                        }
                        cols={(name, row, index) => cols(row, entityData)}
                        //maxHeight={450}
                    >
                        {!punteo && (
                            <ViewEntity
                                //readOnly
                                type={ENTITY_PARCELA}
                                refresh={refresh}
                                entity={entityData}
                            >
                                {DetailParcela}
                            </ViewEntity>
                        )}
                    </DataGrid>
                </Grid>
            </Grid>
        </>
    );
};

export default Parcelas;
