import React, { useEffect } from 'react';

import { Grid, Divider, Chip } from 'core/components/library';

import {
    Select,
    DataGridPro as DataGrid,
    Text,
    Combo,
    Tag,
    Switch
} from 'core/components';

import * as helpers from 'core/helpers';
import * as enums from 'core/enums';
import * as icons from '../../../../../../core/icons';
import List from '../../../../../../store/List';
import DetailTramite from '../../../../../tramites/Tramites/Detalle';
const ComboMedidas = List(Combo, 'options', 'lstMedidas');

// Concepto
const validaUnidades = (changes, values) => {
    const unidades = Number(changes[0].value && changes[0].value !== '')
        ? Number(changes[0].value)
        : 0;
    const precio =
        values.precio && values.precio !== '' ? Number(values.precio) : 0;
    const importe = unidades * precio;

    changes.push({ field: 'importe', value: importe.toFixed(2) });

    return changes;
};
const validaPrecio = (changes, values) => {
    const precio = Number(changes[0].value && changes[0].value !== '')
        ? Number(changes[0].value)
        : 0;
    const unidades =
        values.unidades && values.unidades !== '' ? Number(values.unidades) : 0;
    const importe = unidades * precio;

    changes.push({ field: 'importe', value: importe.toFixed(2) });

    return changes;
};
const validaImporte = (changes, values) => {
    const importe = Number(changes[0].value && changes[0].value !== '')
        ? Number(changes[0].value)
        : 0;
    let unidades =
        values.unidades && values.unidades !== '' ? Number(values.unidades) : 0;
    let precio;

    if (unidades === 0) {
        precio = importe;
        unidades = 1;
        changes.push({ field: 'unidades', value: unidades.toFixed(4) });
    } else {
        precio = importe / unidades;
    }

    changes.push({ field: 'precio', value: precio.toFixed(4) });

    return changes;
};

// Totales
const validaIva = (changes, values) => {
    const baseImponible =
        values.baseImponible === '' ? 0 : Number(values.baseImponible);

    const recargoFinanciero =
        values.recargoFinanciero === '' ? 0 : Number(values.recargoFinanciero);

    const suplidos = values.suplidos === '' ? 0 : Number(values.suplidos);

    const iva = Number(changes[0].value && changes[0].value !== '')
        ? Number(changes[0].value)
        : 0;
    const total = Number(
        baseImponible + iva + suplidos + Number(recargoFinanciero)
    ).toFixed(2);

    changes.push({ field: 'total', value: total });

    return changes;
};
const validaSuplidos = (changes, values) => {
    const baseImponible =
        values.baseImponible === '' ? 0 : Number(values.baseImponible);
    const iva = values.iva === '' ? 0 : Number(values.iva);
    const recargo = values.recargo === '' ? 0 : Number(values.recargo);
    const recargoFinanciero =
        values.recargoFinanciero === '' ? 0 : Number(values.recargoFinanciero);
    const retencion = values.retencion === '' ? 0 : Number(values.retencion);
    const suplidos = Number(changes[0].value && changes[0].value !== '')
        ? Number(changes[0].value)
        : 0;
    const total = Number(
        baseImponible +
            iva +
            recargo -
            retencion +
            suplidos +
            Number(recargoFinanciero)
    ).toFixed(2);

    changes.push({ field: 'total', value: total });

    return changes;
};
const validaFinanciero = (changes, values, porc) => {
    const baseImponible =
        values.baseImponible === '' ? 0 : Number(values.baseImponible);
    const iva = values.iva === '' ? 0 : Number(values.iva);
    const recargo = values.recargo === '' ? 0 : Number(values.recargo);
    const suplidos = values.suplidos === '' ? 0 : Number(values.suplidos);
    const retencion = values.retencion === '' ? 0 : Number(values.retencion);
    let pRecargoFinanciero = 0;
    let recargoFinanciero = 0;

    if (porc) {
        pRecargoFinanciero = Number(changes[0].value && changes[0].value !== '')
            ? Number(changes[0].value)
            : 0;
        recargoFinanciero = Number(
            (baseImponible * pRecargoFinanciero) / 100
        ).toFixed(2);
        changes.push({ field: 'recargoFinanciero', value: recargoFinanciero });
    } else {
        recargoFinanciero = Number(changes[0].value && changes[0].value !== '')
            ? Number(changes[0].value)
            : 0;
        pRecargoFinanciero = Number(
            baseImponible === 0 ? 0 : (recargoFinanciero * 100) / baseImponible
        ).toFixed(2);

        changes.push({
            field: 'pRecargoFinanciero',
            value: pRecargoFinanciero
        });
    }

    const total = Number(
        baseImponible +
            iva +
            recargo -
            retencion +
            suplidos +
            Number(recargoFinanciero)
    ).toFixed(2);
    changes.push({ field: 'total', value: total });

    console.log(changes);

    return changes;
};

const actualizaTotales = values => {
    // Calculo los conceptos según sea tasa o importe y la base imponible
    let importes = 0;
    let tasas = 0;

    values.conceptos.forEach(concepto => {
        if (concepto.tasa) tasas = tasas + Number(concepto.importe);
        else importes = importes + Number(concepto.importe);
    });
    const baseImponible = importes + tasas;

    const iva = values.iva && values.iva !== '' ? Number(values.iva) : 0;
    const suplidos =
        values.suplidos && values.suplidos !== '' ? Number(values.suplidos) : 0;
    const pRecargoFinanciero =
        values.pRecargoFinanciero && values.pRecargoFinanciero !== ''
            ? Number(values.pRecargoFinanciero)
            : 0;
    const recargoFinanciero = Number(
        (baseImponible * pRecargoFinanciero) / 100
    ).toFixed(2);
    const total = Number(
        baseImponible + iva + suplidos + Number(recargoFinanciero)
    );

    return [
        { field: 'tasas', value: tasas.toFixed(2) },
        { field: 'importes', value: importes.toFixed(2) },
        { field: 'baseImponible', value: baseImponible.toFixed(2) },
        { field: 'recargoFinanciero', value: recargoFinanciero },
        { field: 'total', value: total.toFixed(2) }
    ];
};

/*
const validaImportes = (changes, values) => {
    const importes = Number(changes[0].value && changes[0].value !== '')
        ? Number(changes[0].value)
        : 0;
    const tasas = values.tasas === '' ? 0 : Number(values.tasas);
    const baseImponible = Number(importes + tasas).toFixed(2);
    const iva = values.iva === '' ? 0 : Number(values.iva);
    const recargo = values.recargo === '' ? 0 : Number(values.recargo);
    const recargoFinanciero =
        values.recargoFinanciero === '' ? 0 : Number(values.recargoFinanciero);
    const retencion = values.retencion === '' ? 0 : Number(values.retencion);
    const suplidos = values.suplidos === '' ? 0 : Number(values.suplidos);
    const total = Number(
        baseImponible +
            iva +
            recargo -
            retencion +
            suplidos +
            Number(recargoFinanciero)
    ).toFixed(2);

    changes.push({ field: 'baseImponible', value: baseImponible });
    changes.push({ field: 'total', value: total });

    return changes;
};
const validaTasas = (changes, values) => {
    const tasas = Number(changes[0].value && changes[0].value !== '')
        ? Number(changes[0].value)
        : 0;
    const importes = values.importes === '' ? 0 : Number(values.importes);
    const baseImponible = Number(importes + tasas).toFixed(2);
    const iva = values.iva === '' ? 0 : Number(values.iva);
    const recargo = values.recargo === '' ? 0 : Number(values.recargo);
    const recargoFinanciero =
        values.recargoFinanciero === '' ? 0 : Number(values.recargoFinanciero);
    const retencion = values.retencion === '' ? 0 : Number(values.retencion);
    const suplidos = values.suplidos === '' ? 0 : Number(values.suplidos);
    const total = Number(
        baseImponible +
            iva +
            recargo -
            retencion +
            suplidos +
            Number(recargoFinanciero)
    ).toFixed(2);

    changes.push({ field: 'baseImponible', value: baseImponible });
    changes.push({ field: 'total', value: total });

    return changes;
};

 */

const cols = (name, row, index, editing, handleChange, refresh) => [
    {
        key: 'tramite',
        title: 'Tramite',
        align: 'left',
        width: '5%',
        component: row && row.tramite && (
            <Tag
                //variant="outlined"
                size={'small'}
                sx={{ color: 'green' }}
                Icon={icons.TramitesIcon}
                value={row.tramite.codigo}
                id={row.tramite.id}
                relacionado={{
                    Detail: DetailTramite,
                    resume: ['codigo'],
                    api: 'tramites/solicitud',
                    readOnly: false
                }}
                refresh={refresh}
            />
        )
    },
    {
        key: 'tasa',
        title: 'Tasa',
        align: 'center',
        width: '3%',
        component: row && (
            <Switch
                value={row.tasa}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                name='tasa'
                //label='El importe es una tasa'
                editing={editing}
            />
        )
    },
    {
        key: 'concepto',
        title: 'Concepto',
        align: 'left',
        width: '47%',
        component: row && (
            <Text
                multiline
                fullWidth
                editing={editing}
                name='descripcion'
                variant='standard'
                margin='none'
                value={row['descripcion']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    },
    {
        key: 'medida',
        title: 'Und. Medida',
        align: 'left',
        width: '15%',
        component: row && !row.tasa && (
            <ComboMedidas
                fullWidth
                margin='none'
                variant='standard'
                api='auxiliares/unidadesmedida/'
                name='medida'
                nameKey='medidaid'
                refresh={refresh}
                Icon={icons.MedicionIcon}
                value={row.medida}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option =>
                    `${option.id} (${option.descripcion})`
                }
            />
        )
    },
    {
        key: 'unidades',
        title: 'Unidades',
        align: 'left',
        width: '10%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='unidades'
                variant='standard'
                margin='none'
                type='d.4'
                value={row['unidades']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(validaUnidades(values, row), name, index))
                }
            />
        )
    },
    {
        key: 'precio',
        title: 'Precio €',
        align: 'left',
        width: '10%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='precio'
                variant='standard'
                margin='none'
                type='d.4'
                value={row['precio']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(validaPrecio(values, row), name, index))
                }
            />
        )
    },
    {
        key: 'importe',
        title: 'Importe €',
        align: 'left',
        width: '10%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='importe'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['importe']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(validaImporte(values, row), name, index))
                }
            />
        )
    }
];

const Conceptos = props => {
    const { values, editing, adding, handleChange, refresh } = props;

    useEffect(() => {
        if (editing && values.conceptos) {
            handleChange(actualizaTotales(values));
        }
    }, [JSON.stringify(values.conceptos)]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs>
                    <DataGrid
                        name='conceptos'
                        editing={editing}
                        rows={values.conceptos ? values.conceptos : []}
                        cols={(name, row, index) =>
                            cols(
                                name,
                                row,
                                index,
                                editing,
                                handleChange,
                                refresh
                            )
                        }
                        onAdd={name => {
                            handleChange(
                                {
                                    linea:
                                        values.conceptos &&
                                        values.conceptos.length !== 0
                                            ? values.conceptos[
                                                  values.conceptos.length - 1
                                              ].linea + 1
                                            : 1,
                                    unidades: (0).toFixed(4),
                                    precio: (0).toFixed(4),
                                    importe: (0).toFixed(2)
                                },
                                name
                            );
                        }}
                        onDelete={(name, index) =>
                            handleChange(undefined, name, (index + 1) * -1)
                        }
                        minHeight={250}
                        maxHeight={250}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Divider style={{ margin: '12px 12px 12px 12px' }}>
                        <Chip label={'TOTALES'} />
                    </Divider>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='importes'
                        label='Importes €'
                        value={values.importes}
                        /*
                        onChange={changes =>
                            handleChange(validaImportes(changes, values))
                        }*/
                        onFocus={event => event.target.select()}
                        editing={false}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        type='d.2'
                        fullWidth
                        name='tasas'
                        label='Tasas €'
                        value={values.tasas}
                        /*
                        onChange={changes =>
                            handleChange(validaTasas(changes, values))
                        }*/
                        onFocus={event => event.target.select()}
                        editing={false} // && values.tipoLiquidacion && values.tipoLiquidacion.subcuentaTasa}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='baseImponible'
                        label='Base Imponible €'
                        value={values.baseImponible}
                        onChange={handleChange}
                        onFocus={event => event.target.select()}
                        editing={false}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='iva'
                        label='IVA €'
                        value={values.iva}
                        onChange={changes =>
                            handleChange(validaIva(changes, values))
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='suplidos'
                        label='Suplidos €'
                        value={values.suplidos}
                        onChange={changes =>
                            handleChange(validaSuplidos(changes, values))
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item>
                    <Text
                        sx={{ maxWidth: 92 }}
                        type='d.2'
                        name='pRecargoFinanciero'
                        label='R. Financ. %'
                        value={values.pRecargoFinanciero}
                        onChange={changes =>
                            handleChange(
                                validaFinanciero(changes, values, true)
                            )
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='recargoFinanciero'
                        label='Recargo Finan. €'
                        value={values.recargoFinanciero}
                        onChange={changes =>
                            handleChange(
                                validaFinanciero(changes, values, false)
                            )
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='total'
                        label='Total €'
                        value={values.total}
                        onChange={handleChange}
                        onFocus={event => event.target.select()}
                        editing={false}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Conceptos;
