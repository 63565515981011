import React from 'react';

import { Grid } from 'core/components/library';

import {
    Select,
    DataGridPro as DataGrid,
    Text,
    Combo,
    Tag,
    Switch
} from 'core/components';

import * as helpers from 'core/helpers';
import * as enums from 'core/enums';
import * as icons from '../../../../../../core/icons';
import List from '../../../../../../store/List';

const ComboFormularios = List(Combo, 'options', 'lstFormularios');

const cols = (name, row, index, editing, handleChange, refresh) => [
    {
        key: 'formulario',
        title: 'Formulario',
        align: 'left',
        width: '70%',
        component: row && (
            <ComboFormularios
                fullWidth
                api='auxiliares/formularios'
                name='formulario'
                nameKey='formularioid'
                variant='standard'
                refresh={refresh}
                Icon={icons.PrintIcon}
                value={row.formulario}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option =>
                    `[${option.id}]-${option.descripcion} (Ed. ${option.edicion})`
                }
                searchKeys={['id', 'descripcion', 'edicion', 'tipo']}
            />
        )
    },
    {
        key: 'uso',
        title: 'Uso',
        align: 'left',
        width: '25%',
        component: row && (
            <Select
                fullWidth
                variant='standard'
                name='uso'
                value={row.uso}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                options={enums.usesForm}
            />
        )
    },
    {
        key: 'activo',
        title: 'Activo',
        align: 'center',
        width: '5%',
        component: row && (
            <Switch
                value={row.activo}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                name='activo'
                editing={editing}
            />
        )
    }
];

const Formularios = props => {
    const { values, editing, adding, handleChange, refresh } = props;

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <DataGrid
                    name='formularios'
                    editing={editing}
                    rows={values.formularios ? values.formularios : []}
                    cols={(name, row, index) =>
                        cols(name, row, index, editing, handleChange, refresh)
                    }
                    onAdd={name => {
                        handleChange({}, name);
                    }}
                    onDelete={(name, index) =>
                        handleChange(undefined, name, (index + 1) * -1)
                    }
                    minHeight={250}
                    maxHeight={250}
                />
            </Grid>
        </Grid>
    );
};

export default Formularios;
