import React, { useEffect } from 'react';

import { Divider, Grid } from 'core/components/library';

import {
    Text,
    Button,
    Select,
    Switch,
    Tabs,
    Documents,
    Combo,
    Tag,
    DataGridPro as DataGrid
} from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import List from '../../../../store/List';
import DetailMarca from '../../Marcas/Detalle';
import DetailExplotacion from '../../Explotaciones/Detalle';

import Caracteristicas from './Tabs/TabCaracteristicas';
import Versiones from './Tabs/TabVersiones';
import { statesEntity } from 'core/enums';
import * as helpers from '../../../../core/helpers';
import {
    ENTITY_EXPLOTACION_BODEGA,
    ENTITY_MARCA
} from '../../../../store/entities/constants';

const ComboMarcas = List(Combo, 'options', 'lstMarcas');
const ComboExplotaciones = List(Combo, 'options', 'lstExplotaciones');

let timeBoxCodigo = 0;

const Etiqueta = props => {
    const {
        values,
        editing,
        adding,
        handleChange,
        refresh,
        reload,
        related,
        execAction
    } = props;

    useEffect(() => {
        if (
            editing &&
            values &&
            values.explotacion &&
            values.explotacion.regEmbotellado
        ) {
            handleChange([
                helpers.getValueControl(
                    'text',
                    'regEmbotellado',
                    values.explotacion.regEmbotellado
                )
            ]);
        }
    }, [JSON.stringify(values.explotacion)]);

    useEffect(() => {
        if (editing) {
            const changes = [];

            if (values.marca) {
                changes.push(
                    helpers.getValueControl(
                        'text',
                        'acronimo',
                        values.marca.acronimo + '-'
                    )
                );

                handleChange(changes);
            }
        }
    }, [JSON.stringify(values.marca)]);

    const actualizaCodigo = () => {
        if (editing) {
            const action = () => {
                const accion = {
                    api: 'etiquetasmarca/nexts',
                    metodo: 'post',
                    data: {
                        fields: ['nombre'],
                        values: { ...values }
                    }
                };
                execAction(accion, [reload]);
            };

            timeBoxCodigo > 0 && clearTimeout(timeBoxCodigo);
            timeBoxCodigo = setTimeout(action, 250);
        }
    };

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboMarcas
                        fullWidth
                        api='marcasbodega/'
                        name='marca'
                        nameKey='marcaId'
                        label='Marca'
                        relacionado={{
                            type: ENTITY_MARCA,
                            maxWidth: 'lg',
                            Detail: DetailMarca,
                            resume: ['nombre'],
                            dfault:
                                values.explotacion && values.explotacion.bodega
                                    ? {
                                          bodega: {
                                              id: values.explotacion.bodega.id
                                          }
                                      }
                                    : undefined,
                            readOnly: false
                        }}
                        refresh={refresh}
                        Icon={icons.BrandsIcon}
                        value={values.marca}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => option.nombre}
                        searchKeys={['nombre']}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='nombre'
                        label='Nombre'
                        value={values.nombre}
                        onChange={handleChange}
                        editing={editing}
                        type={`p${values.acronimo ? values.acronimo : ''}.5`}
                        nextValue={adding && actualizaCodigo}
                    />
                </Grid>

                <Grid item xs>
                    <Tag
                        variant={'outline'}
                        align={'center'}
                        size={'medium'}
                        sx={{
                            color: enums.getItem(
                                statesEntity,
                                values.estado,
                                'color'
                            )
                        }}
                        Icon={enums.getItem(
                            statesEntity,
                            values.estado,
                            'icon'
                        )}
                        value={values.estado}
                    />
                </Grid>
            </Grid>
            {!related && (
                <Grid container spacing={1}>
                    <Grid item xs>
                        <ComboExplotaciones
                            fullWidth
                            api='explotaciones/'
                            name='explotacion'
                            nameKey='explotacionId'
                            label='Centro'
                            relacionado={{
                                type: ENTITY_EXPLOTACION_BODEGA,
                                maxWidth: 'lg',
                                Detail: DetailExplotacion,
                                resume: ['nombre'],
                                readOnly: false
                            }}
                            refresh={refresh}
                            Icon={icons.SitesIcon}
                            value={values.explotacion}
                            onChange={handleChange}
                            editing={editing}
                            propertyValue='id'
                            getOptionLabel={option =>
                                `${option.codigo}-${option.nombre}-[${option.bodega.nombre}]`
                            }
                            searchKeys={['codigo', 'nombre', 'bodega.nombre']}
                        />
                    </Grid>
                </Grid>
            )}
            <Tabs
                tabs={[
                    {
                        label: 'Detalles',
                        icon: icons.DetailIcon,
                        component: <Caracteristicas {...props} />,
                        hide: false
                    },
                    {
                        label: 'Versiones',
                        icon: icons.VersionsIcon,
                        component: <Versiones {...props} />
                    },
                    {
                        label: 'Documentos',
                        icon: icons.DocumentIcon,
                        component: (
                            <Documents
                                name='documentacion'
                                editing={editing}
                                onChange={handleChange}
                                rows={
                                    values.documentacion
                                        ? values.documentacion
                                        : []
                                }
                                minHeight={456}
                                maxHeight={456}
                            />
                        ),
                        disabled: false
                    }
                ]}
            />
        </form>
    );
};

export default Etiqueta;
