import React, { useState, useEffect } from 'react';

import { CircularProgress, makeStyles } from '../library';
import { ButtonBase, Tooltip } from '../library';
import { Modal } from '../';

import mime from 'mime';

import * as icons from '../icons';
import { config } from '../library';

import Upload from './Upload';

const useStyles = (avatar, width, height, objectFit) =>
    makeStyles(theme => ({
        wrapper: {
            display: 'inline-flex',
            position: 'relative',
            '&:hover $button': {
                visibility: 'visible'
            }
        },
        visor: {
            caretColor: 'transparent',
            width: width,
            height: height,
            border: '1px solid #ddd',
            borderRadius: avatar ? '100px' : '4px',
            padding: '2px',
            objectFit: objectFit ? objectFit : 'scale-down',
            '&:hover': {
                boxShadow: '0 0 2px 1px rgba(0, 140, 186, 0.5)'
            }
        },
        buttonDelete: {
            minHeight: '25px',
            minWidth: '25px',
            maxHeight: '40px',
            maxWidth: '40px',
            width: '50%',
            height: '50%',
            visibility: 'collapse',
            position: 'absolute',
            //bottom: 0,
            right: 0
        },
        iconAdd: {
            width: '50%',
            height: '50%',
            color: 'rgba(0, 140, 186, 0.5)',
            '&:hover': {
                color: 'green'
            }
        },
        iconError: {
            width: '50%',
            height: '50%',
            color: 'red'
        },
        iconDelete: {
            width: '100%',
            height: '100%',
            color: 'rgba(0, 140, 186, 0.5)',
            '&:hover': {
                color: 'red'
            }
        },
        progreso: {
            position: 'absolute'
        }
    }))();

const isInRepo = link => {
    if (link.startsWith('repo://')) return true;

    return false;
};

const getLink = link => {
    if (link)
        if (isInRepo(link)) return config.repository + '/' + link.substring(7);

    return link;
};

const getImage = (link, classe, ext) => {
    if (classe === 'image' || classe === 'video') return link;

    if (!ext) {
        return icons['htmlIcon'];
    }

    return icons[ext + 'Icon'] ? icons[ext + 'Icon'] : icons['htmlIcon'];
};

const Visor = props => {
    const { src, estilo, type, classe } = props;

    const newSrc = src + '?' + new Date().getTime();

    if (classe === 'video')
        return (
            <video
                alt='Video'
                loop
                muted
                className={estilo}
                onMouseEnter={event => event.target.play()}
                onMouseLeave={event => event.target.pause()}
            >
                <source src={newSrc} type={type} />
            </video>
        );
    else return <img alt='Doc.' src={newSrc} className={estilo} />;
};

const Document = props => {
    const {
        name,
        onChange,
        value,
        width,
        height,
        avatar,
        readOnly,
        objectFit
    } = props;

    const classes = useStyles(avatar, width, height, objectFit);

    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const [source, setSource] = useState('');
    const [classe, setClasse] = useState('');
    const [type, setType] = useState('');
    const [link, setLink] = useState(getLink(value));
    const [openUpload, setOpenUpload] = useState(false);

    useEffect(() => {
        if (value) {
            const type = mime.getType(value);
            const extension = mime.getExtension(type);
            let classe = '';

            if (type) {
                setType(type);
                classe = type.split('/')[0];
                setClasse(classe);
            }

            setSource(getImage(getLink(value), classe, extension));
        }
    }, [value]);

    const getData = (source, singleFile) => {
        setError('');

        if ((source.files && source.files.length !== 0) || singleFile) {
            setUploading(true);

            const file = singleFile ? source : source.files[0];

            const data = new FormData();
            data.append('file', file);

            fetch(config.repository, {
                method: 'POST',
                body: data
            })
                .then(response => response.json())
                .then(data => {
                    if (!data.code) return handleChange(name, data.link);

                    setError(data.message);
                })
                .catch(error => {
                    setError(error.message);
                    console.log(error);
                })
                .finally(() => setUploading(false));
        } else {
            const newUri =
                source.getData('url') !== ''
                    ? source.getData('url')
                    : source.getData('text');
            handleChange(name, newUri);
        }
    };

    const clearData = () => {
        if (!isInRepo(value)) return clear();

        setUploading(true);

        return fetch(getLink(value), {
            method: 'DELETE'
        })
            .then(response => response.json())
            .then(data => {
                if (data.code) {
                    console.log(data);
                }
                clear();
            })
            .catch(error => {
                console.log(error);
                setError(error.message);
            })
            .finally(() => setUploading(false));
    };

    const clear = () => handleChange(name, '');

    const handleChange = (name, value) => {
        if (!onChange) return;

        onChange([
            {
                field: name,
                value: value
            }
        ]);
    };

    const uploadFile = file => {
        getData(file, true);
        setOpenUpload(false);
    };

    if (!value)
        return (
            <div
                style={{
                    position: 'relative',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <ButtonBase
                    disabled={readOnly}
                    className={classes.visor}
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    onClick={() => {
                        if (error !== '') setError('');
                        setOpenUpload(true);
                    }}
                    onKeyDown={event => event.preventDefault()}
                    onPaste={event => {
                        getData(event.clipboardData);
                        event.preventDefault();
                    }}
                    onDrop={event => {
                        getData(event.dataTransfer);
                        event.preventDefault();
                    }}
                >
                    <Tooltip
                        title={
                            error === ''
                                ? 'Pulse para añadir un documento'
                                : error
                        }
                    >
                        {error === '' ? (
                            <icons.AddItemIcon className={classes.iconAdd} />
                        ) : (
                            <icons.ErrorIcon className={classes.iconError} />
                        )}
                    </Tooltip>
                    {uploading && (
                        <CircularProgress
                            disableShrink
                            className={classes.progreso}
                            size={'40%'}
                        />
                    )}
                </ButtonBase>
                <Modal
                    open={openUpload}
                    close={() => setOpenUpload(false)}
                    title={'Seleccionar / Capturar documento'}
                    maxWidth={'xl'}
                    //fullScreen={fullScreen}
                >
                    <Upload getFile={uploadFile} />
                </Modal>
            </div>
        );

    return (
        <div
            style={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <div className={classes.wrapper}>
                <ButtonBase
                    onClick={() => window.open(getLink(value), '_blank')}
                >
                    <Visor
                        src={source}
                        classe={classe}
                        type={type}
                        estilo={classes.visor}
                    />
                </ButtonBase>

                {clear && !readOnly && (
                    <ButtonBase
                        className={classes.buttonDelete}
                        onClick={() => clearData()}
                    >
                        <icons.DeleteItemIcon className={classes.iconDelete} />
                    </ButtonBase>
                )}
            </div>
        </div>
    );
};

export default Document;
