import React, { useEffect } from 'react';

import { Divider, Grid } from 'core/components/library';
import { Text, Tabs, Combo, Documents, Select } from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import * as helpers from 'core/helpers';
import { Chip } from '../../../../../../core/components/library';

import List from '../../../../../../store/List';
import DetailUsuario from '../../../../../usuarios/Detalle';

const ComboIncidencias = List(Combo, 'options', 'lstincidenciastramite');
/*
const buildObservaciones = values => {
    let newObservaciones=''

    if (values.incidencias) {
        values.incidencias.forEach(incidencia => {
                newObservaciones = `${newObservaciones}${procesaTexto(incidencia.detalles, values)}\n\n`;
            }
        )
    }

    return newObservaciones;
}

const procesaTexto = (texto,values) => {
    let newValue=texto

    if (values.poligono) newValue=newValue.replace('{poligono}',values.poligono);
    if (values.parcela!=='')newValue=newValue.replace('{parcela}',values.parcela);
    if (values.recinto!=='') newValue=newValue.replace('{recinto}',values.recinto);
    if (values.municipio) newValue=newValue.replace('{municipio}',values.municipio.descripcion);
    if (values.fechaRegistro) newValue=newValue.replace('{fechaRegistro}',helpers.getValueFormatted('date',values.fechaRegistro));
    if (values.porcentaje) newValue=newValue.replace('{porcentaje}',helpers.getValueFormatted('d.2',values.porcentaje));
    if (values.variedadErronea) newValue=newValue.replace('{variedadErronea}',values.variedadErronea);
    if (values.variedad) newValue=newValue.replace('{variedad}',values.variedad.descripcion);
    if (values.marcox) newValue=newValue.replace('{marcox}',helpers.getValueFormatted('d.2',values.marcox));
    if (values.marcoy) newValue=newValue.replace('{marcoy}',helpers.getValueFormatted('d.2',values.marcoy));

    return newValue;
}
*/
const Mediciones = props => {
    const {
        values,
        editing,
        handleChange,
        refresh,
        reload,
        execAction,
        adding
    } = props;

    /*
    useEffect(() => {
            if (editing) {
                // Modifico las observaciones de la parcela
                handleChange([helpers.getValueControl('text','notas',buildObservaciones(values))])
            }
        },
        [values.fechaRegistro,values.porcentaje,values.variedadErronea,JSON.stringify(values.incidencias)]
    );*/

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={3}>
                <Grid item>
                    <Grid container direction={'column'} spacing={1}>
                        <Divider style={{ margin: '12px 12px 12px 12px' }}>
                            <Chip label={'Mediciones'} />
                        </Divider>
                        <Grid item xs>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        editing={editing}
                                        name='medidax1'
                                        label='Medicion 1 x (m)'
                                        type='d.2'
                                        value={values.medidax1}
                                        onFocus={event => event.target.select()}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        editing={editing}
                                        name='mediday1'
                                        label='Medición 1 y (m)'
                                        type='d.2'
                                        value={values.mediday1}
                                        onFocus={event => event.target.select()}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        editing={editing}
                                        name='medidax2'
                                        label='Medicion 2 x (m)'
                                        type='d.2'
                                        value={values.medidax2}
                                        onFocus={event => event.target.select()}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        editing={editing}
                                        name='mediday2'
                                        label='Medición 2 y (m)'
                                        type='d.2'
                                        value={values.mediday2}
                                        onFocus={event => event.target.select()}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        editing={editing}
                                        name='medidax3'
                                        label='Medicion 3 x (m)'
                                        type='d.2'
                                        value={values.medidax3}
                                        onFocus={event => event.target.select()}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        editing={editing}
                                        name='mediday3'
                                        label='Medición 3 y (m)'
                                        type='d.2'
                                        value={values.mediday3}
                                        onFocus={event => event.target.select()}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        editing={editing}
                                        name='medidax4'
                                        label='Medicion 4 x (m)'
                                        type='d.2'
                                        value={values.medidax4}
                                        onFocus={event => event.target.select()}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        editing={editing}
                                        name='mediday4'
                                        label='Medición 4 y (m)'
                                        type='d.2'
                                        value={values.mediday4}
                                        onFocus={event => event.target.select()}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        editing={editing}
                                        name='medidax5'
                                        label='Medicion 5 x (m)'
                                        type='d.2'
                                        value={values.medidax5}
                                        onFocus={event => event.target.select()}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <Text
                                        fullWidth
                                        editing={editing}
                                        name='mediday5'
                                        label='Medición 5 y (m)'
                                        type='d.2'
                                        value={values.mediday5}
                                        onFocus={event => event.target.select()}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Grid container direction={'column'} spacing={1}>
                        <Grid item xs>
                            <ComboIncidencias
                                fullWidth
                                api='auxiliares/tiposincidenciatramite'
                                multiple
                                name='incidencias'
                                label='Incidencias'
                                //Icon={icons.Icon}
                                //helperText="Validadores de la tarea"
                                value={
                                    values.incidencias
                                        ? values.incidencias.map(item => item)
                                        : []
                                }
                                onChange={handleChange}
                                editing={editing}
                                propertyValue='id'
                                getOptionLabel={option => option.descripcion}
                                getItem={item => ({
                                    id: item.id,
                                    descripcion: item.descripcion,
                                    detalles: item.detalles
                                })}
                                refresh={refresh}
                            />
                        </Grid>
                        <Grid item xs>
                            <Text
                                fullWidth
                                editing={editing}
                                name='porcentaje'
                                label='Porcentaje'
                                type='d.2'
                                value={values.porcentaje}
                                onFocus={event => event.target.select()}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs>
                            <Text
                                fullWidth
                                editing={editing}
                                name='variedadErronea'
                                label='Variedad Erronea'
                                value={values.variedadErronea}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        multiline
                        rows={15}
                        name='notas'
                        label='Observaciones'
                        value={values.notas}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Mediciones;
