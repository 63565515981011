import React from 'react';

import { makeStyles } from '../../library';

import {
    IconButton,
    Tooltip,
    TableCell,
    TableHead,
    TableRow
} from '../../library';

import * as icons from '../../icons';

const useStyles = makeStyles(theme => ({
    row: {},
    cell: {
        padding: '0px',
        paddingRight: '4px'
    },
    actionCell: {
        padding: '0px'
    }
}));

const Head = props => {
    const { cols, onAdd, onDelete, onOpen, editing, notAdd } = props;

    const classes = useStyles();

    return (
        <TableHead>
            <TableRow className={classes.row}>
                {((onOpen && !editing) ||
                    (onAdd && editing) ||
                    (onDelete && editing)) && (
                    <TableCell
                        className={classes.actionCell}
                        width='auto'
                        align={'center'}
                    >
                        {!notAdd && (onAdd || onOpen) && (
                            <Tooltip
                                arrow
                                placement='left-start'
                                title={
                                    onOpen
                                        ? editing
                                            ? 'Salga del modo edición para poder Añadir'
                                            : 'Añadir'
                                        : onAdd && editing
                                          ? 'Añadir'
                                          : undefined
                                }
                            >
                                <IconButton
                                    size='small'
                                    onClick={
                                        onOpen
                                            ? editing
                                                ? undefined
                                                : onOpen
                                            : onAdd
                                              ? onAdd
                                              : undefined
                                    }
                                >
                                    <icons.AddIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                        )}
                    </TableCell>
                )}
                {cols &&
                    cols.map((col, index) => (
                        <TableCell
                            key={index}
                            className={classes.cell}
                            width={col.width}
                            //style={{minWidth:170}}
                            align={col.align}
                        >
                            {col.title}
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
};

export default Head;
