import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { Divider, Grid } from 'core/components/library';
import { Text, Tabs, Emit, Tag, Select, Switch } from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import * as helpers from 'core/helpers';

import Parcelas from './Tabs/TabParcelas';
import Firmas from './Tabs/TabFirmas';
import DetailUsuario from '../../../usuarios/Detalle';
import * as hlprs from '../../../../core/components/helpers';
import { statesEntity, statesResolution } from 'core/enums';

const Detalle = props => {
    const {
        values,
        editing,
        handleChange,
        refresh,
        reload,
        execAction,
        adding
    } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Tag
                        sx={{ marginTop: '10px' }}
                        hidden={!values.propietario}
                        value={values.propietario && values.propietario.nombre}
                        id={values.propietario && values.propietario.id}
                        avatar={
                            values.propietario &&
                            helpers.getAvatar(
                                values.propietario.id,
                                values.propietario.avatar
                            )
                        }
                        relacionado={{
                            Detail: DetailUsuario,
                            resume: ['nombre'],
                            api: 'usuarios',
                            readOnly: true
                        }}
                        refresh={refresh}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='fecha'
                        label='Fecha'
                        value={hlprs.getValueFormatted(
                            'datetime-local',
                            values.fecha
                        )}
                        type='datetime-local'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item>
                    <Emit
                        disabled={false}
                        hidden={editing}
                        toolTipText={'Emitir Resolución'}
                        forms={
                            values.tramite &&
                            values.tramite.tipo &&
                            values.tramite.tipo.formularios
                                ? helpers.getForms(
                                      values.tramite.tipo.formularios,
                                      'Resolucion'
                                  )
                                : []
                        }
                        values={values}
                    />
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs>
                    <Select
                        fullWidth
                        name='resultado'
                        label='Resultado'
                        value={values.resultado}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.resultsResolution}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='concepto'
                        label='Concepto'
                        value={values.concepto}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Switch
                        align={'center'}
                        value={values.liquidar}
                        onChange={handleChange}
                        name='liquidar'
                        label='Liquidar'
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        multiline
                        rows={3}
                        name='notas'
                        label='Notas'
                        value={values.notas}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Parcelas',
                        icon: icons.ParcelIcon,
                        hide:
                            values.tramite &&
                            values.tramite.tipo &&
                            !values.tramite.tipo.bloqueo
                                ? !values.tramite.tipo.parcela
                                : true,
                        component: <Parcelas {...props} />
                    },
                    {
                        label: 'Firmas',
                        icon: icons.FirmasIcon,
                        component: <Firmas {...props} />,
                        disabled: false
                    }
                ]}
            />
        </form>
    );
};

export default Detalle;
