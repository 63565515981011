import React, { useEffect, useState } from 'react';

import { Checkbox, FormControlLabel, makeStyles } from '../library';

import * as helpers from '../helpers';

const useStyles = makeStyles(theme => {
    return {
        label: {
            //borderWidth: '1px',
            //borderStyle: 'solid',
            //borderColor: 'red',
            //borderRadius: '4px',
            margin: '9px 1px 0px 0px',
            paddingRight: '10px'
        }
    };
});

const FilterCheck = props => {
    const {
        title,
        titleTrue,
        titleFalse,
        value,
        onFilter,
        onChange,
        disabled
    } = props;
    const classes = useStyles();

    // 0 -> No Filtro  1-> Los que cumplen  >1 -> Los que no cumplen
    const [checked, setChecked] = useState(value ? value : 0);

    useEffect(() => {
        if (value) {
            filtra(value);
        }
    }, []);

    const filtra = value => {
        onChange && onChange(value);

        const newValue = value === 0 ? '' : value === 1;
        helpers.timeBox(() => onFilter && onFilter(newValue), 250);
    };

    return (
        <FormControlLabel
            className={classes.label}
            disabled={disabled}
            label={
                checked === 0 ? title : checked === 1 ? titleTrue : titleFalse
            }
            control={
                <Checkbox
                    size={'small'}
                    checked={checked === 1}
                    indeterminate={checked === 2}
                    onClick={event => {
                        const newValue = checked > 1 ? 0 : checked + 1;
                        setChecked(newValue);
                        filtra(newValue);
                        event.preventDefault();
                    }}
                />
            }
        />
    );
};

export default FilterCheck;
