import React, { useEffect } from 'react';
import { makeStyles, Grid } from 'core/components/library';
import { Text, Combo, DataGridPro as DataGrid, Tag } from 'core/components';

import * as icons from 'core/icons';

import DetailSubcuenta from 'views/contabilidad/subcuentas/Detalle';

import List from 'store/List';

const ComboSubcuentas = List(Combo, 'options', 'lstSubcuentasPresupuesto');

const Detalles = props => {
    const { values, editing, handleChange, refresh } = props;

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <ComboSubcuentas
                    fullWidth
                    api='subcuentas/activas'
                    multiple
                    name='subcuentas'
                    label='Cuentas'
                    Icon={icons.CuentasIcon}
                    value={
                        values.subcuentas
                            ? values.subcuentas.map(item => item)
                            : []
                    }
                    onChange={handleChange}
                    editing={editing}
                    propertyValue='id'
                    getOptionLabel={option =>
                        `${option.id} ${option.descripcion}`
                    }
                    getItem={item => ({
                        id: item.id,
                        descripcion: item.descripcion
                    })}
                    relacionado={{
                        maxWidth: 'md',
                        Detail: DetailSubcuenta,
                        resume: ['descripcion'],
                        readOnly: false
                    }}
                    refresh={refresh}
                />
            </Grid>
            <Grid item xs>
                <Text
                    fullWidth
                    multiline
                    rows={10}
                    name='notas'
                    label='Notas'
                    value={values.notas}
                    onChange={handleChange}
                    editing={editing}
                />
            </Grid>
        </Grid>
    );
};

export default Detalles;
