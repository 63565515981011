import React, { useEffect } from 'react';
import { makeStyles, Grid } from 'core/components/library';
import { Text, Combo, DataGridPro as DataGrid, Tag } from 'core/components';

import * as icons from 'core/icons';
import List from '../../../../../../store/List';
import DetailMencion from 'views/entorno/Menciones/Detalle';

const ComboMenciones = List(Combo, 'options', 'lstMenciones');

const Detalles = props => {
    const {
        values,
        editing,
        adding,
        initial,
        handleChange,
        refresh,
        estableceFiltro,
        list
    } = props;

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Text
                        fullWidth
                        name='para'
                        label='Elaborado Para'
                        value={values.para}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <ComboMenciones
                        fullWidth
                        api='menciones'
                        multiple
                        name='menciones'
                        label='Menciones'
                        Icon={icons.MentionIcon}
                        value={
                            values.menciones
                                ? values.menciones.map(item => item)
                                : []
                        }
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => option.id}
                        getItem={item => ({
                            id: item.id
                        })}
                        relacionado={{
                            maxWidth: 'xs',
                            Detail: DetailMencion,
                            resume: ['id'],
                            readOnly: false,
                            lite: true
                        }}
                        refresh={refresh}
                        searchKeys={['id']}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        multiline
                        rows={10}
                        name='notas'
                        label='Notas'
                        value={values.notas}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Detalles;
