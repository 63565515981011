import * as hlprs from './helpers';

export const customTypes = type => {
    if (!type) return null;

    let idType = type;
    let max = 0;

    type.startsWith('0.') && (idType = 'contadornumerico');
    type.startsWith('d.') && (idType = 'decimal');
    type.startsWith('y') && (idType = 'contadoraño');
    type.startsWith('p') && (idType = 'contadorprefijo');

    switch (idType.toLowerCase()) {
        case 'contadortemporal':
            return {
                base: 'Text',
                pattern:
                    '^[0-9]{1,3}[:]{1}[0-5]{0,1}[0-9]{1}[:]{1}[0-5]{0,1}[0-9]{1}$',
                chars: '^[:.,0-9]$',
                replaceChars: { ',': ':', '.': ':' },
                messageError: 'Valor incorrecto',
                apply: value =>
                    Number.isInteger(value)
                        ? hlprs.contadorTemporal(value)
                        : value,
                getValue: function (value) {
                    return value.match(this.pattern)
                        ? hlprs.segundosContador(value.replace(/[.,]/g, ':'))
                        : value;
                },
                //replace: function (value){ return !this.replaceChars ? value : replace(value,this.replaceChars)},
                isCharValid: function (char) {
                    return char.match(this.chars);
                },
                isValueOk: function (value) {
                    return !Number.isInteger(value)
                        ? value.match(this.pattern)
                        : true;
                }
            };

        case 'contadornumerico':
            max = Number(type.substring(2));
            return {
                base: 'Text',
                pattern: `^[0-9]{1,${max}}$`,
                chars: '^[0-9]$',
                messageError: 'Valor incorrecto',
                apply: function (value) {
                    return hlprs.contadorNumerico(value, max);
                },
                getValue: function (value) {
                    return hlprs.contadorNumerico(value, max);
                },
                isCharValid: function (char) {
                    return char.match(this.chars);
                },
                isValueOk: function (value) {
                    return value.match(this.pattern);
                }
            };

        case 'contadoraño':
            const str = type.split('.');
            max = Number(str[1]);
            const año = str[0].substring(1);

            if (año === 'NaN' || año.length !== 4) return null;

            return {
                base: 'Text',
                pattern: `^[0-9]{1,${max + 4}}$`,
                chars: '^[0-9]$',
                messageError: 'año Valor incorrecto',
                apply: function (value) {
                    if (value.length === max + 4) return value;

                    return hlprs.contadorAño(value, max, año);
                },
                getValue: function (value) {
                    return hlprs.contadorAño(value, max, año);
                },
                isCharValid: function (char) {
                    return char.match(this.chars);
                },
                isValueOk: function (value) {
                    return (
                        value.match(this.pattern) &&
                        value.substring(0, 4) === año
                    );
                }
            };

        case 'contadorprefijo':
            const pStr = type.split('.');
            max = Number(pStr[1]);
            const prefijo = pStr[0].substring(1);

            return {
                base: 'Text',
                pattern: `^${prefijo}\\d{1,${max}}$`,
                chars: '^[0-9]$',
                messageError: 'prefijo Valor incorrecto',
                apply: function (value) {
                    return value;
                },
                getValue: function (value) {
                    const contador = hlprs.contadorNumerico(
                        value.replace(prefijo, ''),
                        max
                    );

                    if (!contador || isNaN(contador)) return null;

                    return prefijo + contador;
                },
                isCharValid: function (char) {
                    return char.match(this.chars);
                },
                isValueOk: function (value) {
                    return value.match(this.pattern);
                }
            };

        case 'entero':
            return {
                base: 'Text',
                pattern: `^[-]{0,1}[.0-9]{1,15}$`,
                chars: '^[-0-9]$',
                messageError: 'Valor incorrecto',
                apply: function (value) {
                    return hlprs.getNumberFormatted('' + value, 0);
                },
                getValue: function (value) {
                    return value.replaceAll('.', '');
                },
                isCharValid: function (char) {
                    return char.match(this.chars);
                },
                isValueOk: function (value) {
                    return value.match(this.pattern);
                }
            };

        case 'decimal':
            max = Number(type.substring(2));
            return {
                base: 'Text',
                pattern: `^[-]{0,1}[.0-9]{0,10}[,.]{0,1}[0-9]{1,${max}}$`,
                chars: '^[-.,0-9]$',
                replaceChars: { '.': ',' },
                messageError: 'Valor incorrecto',
                apply: function (value, force) {
                    //console.log('Apply',value);
                    return hlprs.getNumberFormatted(value, force && max);
                },
                getValue: function (value) {
                    //console.log('GetValue',value);
                    const newValue = this.replaceChars
                        ? replace(value, this.replaceChars)
                        : value;
                    return hlprs.getNumber(newValue);
                },
                isCharValid: function (char) {
                    return char.match(this.chars);
                },
                isValueOk: function (value) {
                    //console.log('IsValidValue',value);
                    return value.match(this.pattern);
                }
            };

        case 'subcuenta':
            return {
                base: 'Text',
                pattern: `^[.0-9]{1,10}$`,
                chars: '^[.,0-9]$',
                messageError: 'Valor incorrecto',
                apply: function (value, force) {
                    //console.log('apply',value,force);
                    return hlprs.getSubcuenta(value, force && 10);
                },
                getValue: function (value) {
                    //console.log('getValue',value);
                    return hlprs.getSubcuenta(value.replaceAll(',', '.'));
                },
                isCharValid: function (char) {
                    //console.log('isCharValid',char);
                    return char.match(this.chars);
                },
                isValueOk: function (value) {
                    //console.log('isValueOk',value);
                    return value.match(this.pattern);
                }
            };

        case 'partida':
            return {
                base: 'Text',
                pattern: `^[.0-9]{1,15}$`,
                chars: '^[.,0-9]$',
                messageError: 'Valor incorrecto',
                apply: function (value) {
                    //console.log('apply',value,force);
                    return value;
                },
                getValue: function (value) {
                    //console.log('getValue',value);
                    return value.replaceAll(',', '.');
                },
                isCharValid: function (char) {
                    //console.log('isCharValid',char);
                    return char.match(this.chars);
                },
                isValueOk: function (value) {
                    //console.log('isValueOk',value);
                    return value.match(this.pattern);
                }
            };

        default:
            return null;
    }
};

const replace = (value, replaceChars) => {
    const replaces = Object.keys(replaceChars);
    let newValue = value;

    replaces.forEach(r => {
        const index = newValue.lastIndexOf(r);
        if (index >= 0) {
            if (r !== '.' || (r === '.' && index === value.length - 1)) {
                newValue =
                    newValue.substring(0, index) +
                    replaceChars[r] +
                    newValue.substring(index + 1);
            }
        }
    });
    //console.log(value,newValue)
    return newValue;
};
