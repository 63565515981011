import React from 'react';
import { Divider, Grid, Chip } from 'core/components/library';
import { Text, Document } from 'core/components';
import Representantes from 'entorno/Representantes';

const Firmas = props => {
    const { values, editing, handleChange, refresh } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Grid item xs>
                            <Divider style={{ margin: '12px 12px 12px 12px' }}>
                                <Chip label={'INTERESADO / REPRESENTANTE'} />
                            </Divider>
                        </Grid>
                        <Grid item xs>
                            <Text
                                fullWidth
                                name='representante'
                                label='Representante'
                                value={values.representante}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item xs>
                            <Grid
                                container
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Document
                                    name='firma'
                                    readOnly={!editing}
                                    width='300px'
                                    height='200px'
                                    value={values.firma}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Grid item xs>
                        <Representantes
                            name={'intervinientes'}
                            title={'REPRESENTANTES DOP'}
                            values={values}
                            editing={editing}
                            handleChange={handleChange}
                            refresh={refresh}
                            viewRol
                        />
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default Firmas;
