// material-ui
import { styled } from '@mui/material/styles';
import {
    Button,
    Card,
    CardContent,
    Grid,
    Stack,
    Typography
} from '@mui/material';

import { IconCertificate, IconSettings } from '@tabler/icons';

// styles
const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.warning.light,
    marginTop: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    maxWidth: '300px',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '19px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '65px',
        right: '-150px'
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '3px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '145px',
        right: '-70px'
    }
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const Rol = props => {
    const { item } = props;

    return (
        <CardStyle global={item.global}>
            <CardContent>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <Typography variant='h4'>
                            <IconCertificate stroke={1.5} size='1.3rem' />
                            {item.id}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant='subtitle2'
                            color='grey.900'
                            sx={{ opacity: 0.6 }}
                        >
                            {item.descripcion}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </CardStyle>
    );
};
export default Rol;
