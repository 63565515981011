import React, { useState } from 'react';

import { Grid } from 'core/components/library';
import { Text, DataGridPro as DataGrid, Combo } from 'core/components';

import * as icons from 'core/icons';
import List from 'store/List';

const ComboRoles = List(Combo, 'options', 'lstRoles');

const cols = (name, row, index, editing, handleChange) => [
    {
        key: 'orden',
        title: '#',
        align: 'left',
        width: '2%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='orden'
                variant='standard'
                margin='none'
                type='entero'
                value={row['orden']}
                onFocus={event => event.target.select()}
                onChange={chngs => handleChange(chngs, name, index)}
            />
        )
    },
    {
        key: 'resumen',
        title: 'Resumen',
        align: 'left',
        width: '32%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='resumen'
                variant='standard'
                margin='none'
                type='text'
                value={row['resumen']}
                onFocus={event => event.target.select()}
                onChange={chngs => handleChange(chngs, name, index)}
            />
        )
    },
    {
        key: 'roles',
        title: 'Roles',
        align: 'left',
        width: '22%',
        component: row && (
            <ComboRoles
                fullWidth
                multiple
                margin='none'
                variant='standard'
                api='roles/funcionales'
                name='roles'
                nameKey='rolesid'
                Icon={icons.RolIcon}
                value={row.roles}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option => option.id}
            />
        )
    },
    {
        key: 'validadores',
        title: 'Validadores',
        align: 'left',
        width: '22%',
        component: row && (
            <ComboRoles
                fullWidth
                multiple
                margin='none'
                variant='standard'
                api='roles/funcionales'
                name='validadores'
                nameKey='rolesid'
                Icon={icons.RolIcon}
                value={row.validadores}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option => option.id}
            />
        )
    },
    {
        key: 'informados',
        title: 'Informados',
        align: 'left',
        width: '22%',
        component: row && (
            <ComboRoles
                fullWidth
                multiple
                margin='none'
                variant='standard'
                api='roles/funcionales'
                name='informados'
                nameKey='rolesid'
                Icon={icons.RolIcon}
                value={row.informados}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option => option.id}
            />
        )
    }
];
const Tareas = props => {
    const {
        values,
        editing,
        adding,
        initial,
        handleChange,
        refresh,
        estableceFiltro
    } = props;

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <DataGrid
                    name='tareas'
                    editing={editing}
                    rows={values.tareas ? values.tareas : []}
                    cols={(name, row, index) =>
                        cols(name, row, index, editing, handleChange)
                    }
                    onAdd={name => handleChange({}, name)}
                    onDelete={(name, index) =>
                        handleChange(undefined, name, (index + 1) * -1)
                    }
                    minHeight={350}
                    maxHeight={350}
                />
            </Grid>
        </Grid>
    );
};

export default Tareas;
