import React from 'react';

import { makeStyles } from '../../library';

import { IconButton, Tooltip, TableCell, TableRow } from '../../library';

import * as icons from '../../icons';

const useStyles = highlightRow =>
    makeStyles(theme => ({
        row: {
            cursor: 'pointer',
            '&:hover $actions': {
                visibility: 'visible'
            },
            '&.Mui-selected': {
                backgroundColor: theme.palette.primary.light
            },
            backgroundColor: highlightRow
                ? theme.palette.secondary.light
                : undefined
        },
        cell: {
            padding: '0px',
            paddingRight: '4px',
            paddingTop: '4px',
            paddingBottom: '4px'
        },
        actions: {
            display: 'flex',
            justifyContent: 'center',
            visibility: 'collapse'
        },
        actionCell: {
            padding: '0px'
        }
    }));

const Row = props => {
    const {
        index,
        key,
        cols,
        onDelete,
        onOpen,
        onSelect,
        selected,
        editing,
        highlightRow
    } = props;
    const classes = useStyles(highlightRow)();

    /*
    let classRow =
        onOpen && !editing
            ? { cursor: 'pointer' }
            : onSelect
              ? { cursor: 'pointer' }
              : undefined;
*/

    return (
        <TableRow
            styles={{ backgroundColor: 'red' }}
            className={classes.row}
            hover
            selected={selected}
            key={key}
            onClick={
                onOpen && !editing ? onOpen : onSelect ? onSelect : undefined
            }
        >
            {onDelete && ((onOpen && !editing) || (editing && !onOpen)) && (
                <TableCell className={classes.actionCell} align={'center'}>
                    {onDelete && (
                        <div className={classes.actions}>
                            <Tooltip
                                arrow
                                title={'Borrar'}
                                placement='left-start'
                            >
                                <IconButton
                                    size='small'
                                    onClick={event => {
                                        event.stopPropagation();
                                        onDelete();
                                    }}
                                >
                                    <icons.DeleteIcon
                                        color='secondary'
                                        fontSize='small'
                                    />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </TableCell>
            )}
            {cols &&
                cols.map(col => {
                    return (
                        /*<Tooltip
                            arrow
                            title={
                                onOpen
                                    ? editing
                                        ? 'Salga del modo edición para poder ver el detalle'
                                        : 'Pulse aquí para ver el detalle'
                                    : undefined
                            }
                        >*/
                        <TableCell
                            className={classes.cell}
                            //width={col.width}
                            align={col.align}
                        >
                            {col.component}
                        </TableCell>
                        // </Tooltip>
                    );
                })}
        </TableRow>
    );
};

export default Row;
