import React, { useEffect } from 'react';

import { Grid } from 'core/components/library';

import {
    Text,
    DataGridPro as DataGrid,
    ViewEntity,
    Literal,
    Tag
} from 'core/components';

import * as icons from 'core/icons';
import DetailNuevaParcela from '../../../NuevaParcela';
import DetailParcela from '../../../Parcela';
import { ENTITY_PARCELA_TRAMITE } from '../../../../../../store/entities/constants';
import { useSelector } from 'react-redux';
import * as helpers from '../../../../../../core/helpers';

const getLiteral = value => {
    if (!value) return '#';
    if (value.descripcion) return value.descripcion;
    return value;
};

const cols = (row, entityData, tipo) => [
    {
        key: 'parcela',
        title:
            tipo &&
            (tipo.alta && !tipo.traspaso ? 'Nueva Parcela' : 'Parcela Actual'),
        align: 'left',
        width: !tipo.traspaso ? '35%' : '20%',
        component:
            row &&
            (row.parcelaActual
                ? `${getLiteral(row.parcelaActual.municipio)}-` +
                  `${getLiteral(row.parcelaActual['poligono'])}-` +
                  `${getLiteral(row.parcelaActual['parcela'])}-` +
                  `${getLiteral(row.parcelaActual['recinto'])}-` +
                  `${getLiteral(row.parcelaActual.variedad)}-` +
                  `${getLiteral(row.parcelaActual.año)}`
                : `${getLiteral(row.municipio)}-` +
                  `${getLiteral(row['poligono'])}-${getLiteral(row['parcela'])}-` +
                  `${getLiteral(row['recinto'])}-` +
                  `${getLiteral(row.variedad)}-${getLiteral(row.año)}`)
    },
    {
        key: 'superficie',
        title: 'Superficie',
        align: 'right',
        width: '5%',
        component: row && helpers.getValueFormatted('d.4', row.superficie)
    },
    {
        key: 'marcox',
        title: 'x (m)',
        align: 'right',
        width: '4%',
        component: row && helpers.getValueFormatted('d.2', row.marcox)
    },
    {
        key: 'marcoy',
        title: 'y (m)',
        align: 'right',
        width: '4%',
        component: row && helpers.getValueFormatted('d.2', row.marcoy)
    },
    {
        key: 'densidad',
        title: 'Densidad',
        align: 'right',
        width: '6%',
        component: row && helpers.getValueFormatted('entero', row.densidad)
    },
    {
        key: 'sistemaRiego',
        title: 'S. Riego',
        align: 'center',
        width: !tipo.traspaso ? '17%' : '12%',
        component: row && row.sistemaRiego && row.sistemaRiego.descripcion
    },
    {
        key: 'conduccion',
        title: 'Conducción',
        align: 'center',
        width: !tipo.traspaso ? '15%' : '12%',
        component: row && row.conduccion && row.conduccion.descripcion
    },
    {
        key: 'modoCultivo',
        title: 'Cultivo',
        align: 'center',
        width: !tipo.traspaso ? '14%' : '12%',
        component: row && row.modoCultivo && row.modoCultivo.descripcion
    },
    tipo &&
        tipo.traspaso && {
            key: 'titularAnterior',
            title: 'Titular Anterior',
            align: 'left',
            width: '22%',
            component: row && (
                <Literal text={row.titularAnterior.nombre}>
                    <icons.ViticultorIcon fontSize={'small'} />
                </Literal>
            )
        }
];

const Parcelas = props => {
    const { values, editing, execAction, refresh } = props;

    const entityData = useSelector(
        state => state.entities[ENTITY_PARCELA_TRAMITE]
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs>
                <DataGrid
                    //title='Centros'
                    name='parcelas'
                    editing={editing}
                    rows={values.parcelas ? values.parcelas : []}
                    cols={(name, row, index) =>
                        cols(row, entityData, values.tipo)
                    }
                    onDelete={(name, index, row) => {
                        execAction(
                            {
                                api: entityData.api,
                                metodo: 'delete',
                                data: [row.id]
                            },
                            [() => refresh(false)]
                        );
                    }}
                    //maxHeight={456}
                >
                    <ViewEntity
                        type={ENTITY_PARCELA_TRAMITE}
                        refresh={refresh}
                        entity={entityData}
                        initValue={{
                            tramite: values
                        }}
                    >
                        {values.tipo.alta && !values.tipo.traspaso
                            ? DetailNuevaParcela
                            : DetailParcela}
                    </ViewEntity>
                </DataGrid>
            </Grid>
        </Grid>
    );
};

export default Parcelas;
