import React, { useEffect } from 'react';

import { Grid } from '@mui/material';

import {
    Switch,
    DataGridPro as DataGrid,
    Tag,
    Combo,
    Text,
    Select
} from 'core/components';

import * as icons from 'core/icons';
import * as helpers from 'core/helpers';
import List from '../../../../../../store/List';
import * as enums from '../../../../../../core/enums';

const ComboParcelas = List(Combo, 'options', 'lstparcelasacta');

const cols = (name, row, index, editing, handleChange, refresh, tramite) => [
    {
        key: 'parcela',
        title: 'Parcela',
        align: 'left',
        width: '35%',
        component: row && (
            <ComboParcelas
                fullWidth
                margin='none'
                variant='standard'
                api={
                    tramite.tipo.acta
                        ? 'parcelas-actas/tramite/' + tramite.id
                        : 'parcelas-tramites/tramite/' + tramite.id
                }
                name={tramite.tipo.acta ? 'parcelaActa' : 'parcelaTramite'}
                nameKey={
                    tramite.tipo.acta ? 'parcelaActaId' : 'parcelaTramiteId'
                }
                refresh={refresh}
                Icon={icons.ParcelIcon}
                value={tramite.tipo.acta ? row.parcelaActa : row.parcelaTramite}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option =>
                    `${option.municipio.descripcion}-${option['poligono']}-${option['parcela']}-${option['recinto']}-${option.variedad.descripcion}-${option['año']}`
                }
            />
        )
    },
    {
        key: 'notas',
        title: 'Notas',
        align: 'left',
        width: '65%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='notas'
                variant='standard'
                margin='none'
                value={row['notas']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    }
];

const Parcelas = props => {
    const { values, editing, handleChange, refresh } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid sx={{ marginTop: '10px' }} container spacing={2}>
                <Grid item xs>
                    <DataGrid
                        name='parcelas'
                        editing={editing}
                        rows={values.parcelas ? values.parcelas : []}
                        cols={(name, row, index) =>
                            cols(
                                name,
                                row,
                                index,
                                editing,
                                handleChange,
                                refresh,
                                values.tramite
                            )
                        }
                        onAdd={name => {
                            handleChange({}, name);
                        }}
                        onDelete={(name, index) =>
                            handleChange(undefined, name, (index + 1) * -1)
                        }
                        //minHeight={350}
                        //maxHeight={350}
                        //maxWidth={1000}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Parcelas;
