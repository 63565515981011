import React from 'react';

import { Grid } from 'core/components/library';

import {
    Select,
    DataGridPro as DataGrid,
    Text,
    Tag,
    Switch,
    ViewEntity,
    Literal
} from 'core/components';

import * as hlprs from 'core/helpers';
import * as enums from 'core/enums';
import * as icons from '../../../../../../core/icons';
import DetailActa from '../../../../actas/Detalle';
import * as helpers from '../../../../../../core/helpers';
import { useSelector } from 'react-redux';
import {
    ENTITY_ACTAS,
    ENTITY_RESOLUCIONES
} from '../../../../../../store/entities/constants';
import DetailResolucion from '../../../../Resoluciones/Detalle';

const cols = (row, entityData) => [
    {
        key: 'concepto',
        title: 'Acta',
        align: 'left',
        width: '60%',
        component: row && (
            <Literal text={row['concepto']}>
                <entityData.icon fontSize={'small'} />
            </Literal>
        )
    },
    {
        key: 'fecha',
        title: 'Fecha',
        align: 'left',
        width: '20%',
        component: row && helpers.getValueFormatted('date', row.fecha)
    },
    {
        key: 'firma',
        title: 'Firmada',
        align: 'center',
        width: '20%',
        component: row && (
            <Switch
                align={'center'}
                value={row['firma'] !== null}
                name='firma'
                editing={false}
            />
        )
    }
];

const Actas = props => {
    const { values, editing, adding, handleChange, refresh, execAction } =
        props;
    const entityData = useSelector(state => state.entities[ENTITY_ACTAS]);

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <DataGrid
                    readOnly={values.resolucion === 'Resuelto'}
                    name='actas'
                    editing={editing}
                    rows={values.actas ? values.actas : []}
                    cols={(name, row, index) => cols(row, entityData)}
                    onDelete={(name, index, row) => {
                        execAction(
                            {
                                api: entityData.api,
                                metodo: 'delete',
                                data: [row.id]
                            },
                            [() => refresh(false)]
                        );
                    }}
                    //maxHeight={456}
                >
                    <ViewEntity
                        key={ENTITY_ACTAS}
                        refresh={refresh}
                        entity={entityData}
                        initValue={{
                            concepto: `Acta visita a campo por ${values.tipo.descripcion} ${values.viticultor.nombre}`,
                            tramite: {
                                id: values.id,
                                tipo: values.tipo,
                                viticultor: values.viticultor,
                                fechaRegistro: values.fechaRegistro
                            }
                        }}
                    >
                        {DetailActa}
                    </ViewEntity>
                </DataGrid>
            </Grid>
        </Grid>
    );
};

export default Actas;
