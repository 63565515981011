import React from 'react';

import { Grid } from 'core/components/library';

import {
    Select,
    DataGridPro as DataGrid,
    Text,
    Combo,
    Tag,
    Switch
} from 'core/components';

import * as helpers from 'core/helpers';
import * as enums from 'core/enums';
import * as icons from '../../../../../../core/icons';
import List from '../../../../../../store/List';

const ComboMedidas = List(Combo, 'options', 'lstMedidas');
const ComboTipos = List(Combo, 'options', 'lstTiposLiquidacion');
const ComboEnvases = List(Combo, 'options', 'lstEnvases');

const cols = (name, row, index, editing, handleChange, refresh) => [
    {
        key: 'tipo',
        title: 'Tipo',
        align: 'left',
        width: '52%',
        component: row && (
            <ComboTipos
                fullWidth
                api='tiposLiquidacion'
                name='tipoLiquidacion'
                nameKey='tipoLiquidacionid'
                variant='standard'
                /*relacionado={{
                    Detail: DetailTipo,
                    resume: ['descripcion'],
                    readOnly: true
                }}*/
                refresh={refresh}
                Icon={icons.DefinicionIcon}
                value={row.tipoLiquidacion}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option =>
                    `[${option.id}]-${option.descripcion}`
                }
                searchKeys={['id', 'descripcion']}
            />
        )
    },
    {
        key: 'resolucion',
        title: 'Resolución',
        align: 'left',
        width: '15%',
        component: row && (
            <Select
                fullWidth
                variant='standard'
                name='resolucion'
                value={row.resolucion}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                options={enums.resultsResolution}
            />
        )
    },
    {
        key: 'tasa',
        title: 'Tasa',
        align: 'center',
        width: '3%',
        component: row && (
            <Switch
                value={row.tasa}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                name='tasa'
                //label='El importe es una tasa'
                editing={editing}
            />
        )
    },
    /*{
        key: 'envase',
        title: 'Envase',
        align: 'left',
        width: '20%',
        component: row && !row.tasa && (
            <ComboEnvases
                fullWidth
                api='auxiliares/envases/'
                name='envase'
                nameKey='envaseId'
                variant='standard'
                Icon={icons.PackageIcon}
                value={row.envase}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option => option.descripcion}
                searchKeys={['descripcion']}
            />
        )
    },*/
    {
        key: 'medida',
        title: 'Und. Medida',
        align: 'left',
        width: '20%',
        component: row && !row.tasa && (
            <ComboMedidas
                fullWidth
                margin='none'
                variant='standard'
                api='auxiliares/unidadesmedida/'
                name='medida'
                nameKey='medidaid'
                refresh={refresh}
                Icon={icons.MedicionIcon}
                value={row.medida}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option =>
                    `${option.id} (${option.descripcion})`
                }
            />
        )
    },
    {
        key: 'importe',
        title: 'Importe €',
        align: 'left',
        width: '10%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='importe'
                variant='standard'
                margin='none'
                type='d.4'
                value={row['importe']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    }
];

const Liquidaciones = props => {
    const { values, editing, adding, handleChange, refresh } = props;

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <DataGrid
                    name='liquidaciones'
                    editing={editing}
                    rows={values.liquidaciones ? values.liquidaciones : []}
                    cols={(name, row, index) =>
                        cols(name, row, index, editing, handleChange, refresh)
                    }
                    onAdd={name => {
                        handleChange(
                            {
                                importe: (0).toFixed(2)
                            },
                            name
                        );
                    }}
                    onDelete={(name, index) =>
                        handleChange(undefined, name, (index + 1) * -1)
                    }
                    minHeight={250}
                    maxHeight={250}
                />
            </Grid>
        </Grid>
    );
};

export default Liquidaciones;
