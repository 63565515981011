import React, { useEffect } from 'react';

import { Divider, Grid } from '@mui/material';

import {
    Text,
    Button,
    Select,
    Switch,
    Tabs,
    Combo,
    Documents
} from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';

import Detalles from './Tabs/TabDetalles';
import Tareas from './Tabs/TabTareas';
import Liquidaciones from './Tabs/TabLiquidaciones';
import Formularios from './Tabs/TabFormularios';

import { Chip } from '../../../../core/components/library';
import * as helpers from '../../../../core/helpers';

const Detalle = props => {
    const { values, editing, handleChange, refresh } = props;

    // Definimos a quien afecta
    useEffect(() => {
        if (editing && values.bodega) {
            const changes = [];
            changes.push(helpers.getValueControl('text', 'centro', false));
            changes.push(helpers.getValueControl('text', 'etiqueta', false));
            changes.push(helpers.getValueControl('text', 'viticultor', false));
            handleChange(changes);
        }
    }, [JSON.stringify(values.bodega)]);

    useEffect(() => {
        if (editing && values.centro) {
            const changes = [];
            changes.push(helpers.getValueControl('text', 'bodega', false));
            changes.push(helpers.getValueControl('text', 'etiqueta', false));
            //changes.push(helpers.getValueControl('text', 'viticultor', false));
            handleChange(changes);
        }
    }, [JSON.stringify(values.centro)]);

    useEffect(() => {
        if (editing && values.etiqueta) {
            const changes = [];
            changes.push(helpers.getValueControl('text', 'bodega', false));
            changes.push(helpers.getValueControl('text', 'centro', false));
            changes.push(helpers.getValueControl('text', 'viticultor', false));
            handleChange(changes);
        }
    }, [JSON.stringify(values.etiqueta)]);

    useEffect(() => {
        if (editing && values.viticultor) {
            const changes = [];
            changes.push(helpers.getValueControl('text', 'bodega', false));
            //changes.push(helpers.getValueControl('text', 'centro', false));
            changes.push(helpers.getValueControl('text', 'etiqueta', false));
            handleChange(changes);
        }
    }, [JSON.stringify(values.viticultor)]);

    // Definimos el comportamiento
    useEffect(() => {
        if (editing && values.alta) {
            const changes = [];
            changes.push(helpers.getValueControl('text', 'baja', false));
            changes.push(
                helpers.getValueControl('text', 'modificacion', false)
            );
            changes.push(helpers.getValueControl('text', 'bloqueo', false));
            changes.push(helpers.getValueControl('text', 'produccion', false));
            handleChange(changes);
        }
    }, [JSON.stringify(values.alta)]);

    useEffect(() => {
        if (editing && values.baja) {
            const changes = [];
            changes.push(helpers.getValueControl('text', 'alta', false));
            changes.push(
                helpers.getValueControl('text', 'modificacion', false)
            );
            changes.push(helpers.getValueControl('text', 'traspaso', false));
            changes.push(helpers.getValueControl('text', 'bloqueo', false));
            changes.push(helpers.getValueControl('text', 'produccion', false));
            handleChange(changes);
        }
    }, [JSON.stringify(values.baja)]);

    useEffect(() => {
        if (editing && values.modificacion) {
            const changes = [];
            changes.push(helpers.getValueControl('text', 'alta', false));
            changes.push(helpers.getValueControl('text', 'baja', false));
            changes.push(helpers.getValueControl('text', 'traspaso', false));
            changes.push(helpers.getValueControl('text', 'bloqueo', false));
            changes.push(helpers.getValueControl('text', 'produccion', false));
            handleChange(changes);
        }
    }, [JSON.stringify(values.modificacion)]);

    useEffect(() => {
        if (editing && values.traspaso) {
            const changes = [];
            //changes.push(helpers.getValueControl('text', 'alta', false));
            changes.push(helpers.getValueControl('text', 'baja', false));
            changes.push(
                helpers.getValueControl('text', 'modificacion', false)
            );
            changes.push(helpers.getValueControl('text', 'bloqueo', false));
            changes.push(helpers.getValueControl('text', 'produccion', false));
            handleChange(changes);
        }
    }, [JSON.stringify(values.traspaso)]);

    useEffect(() => {
        if (editing && values.bloqueo) {
            const changes = [];
            changes.push(helpers.getValueControl('text', 'alta', false));
            changes.push(helpers.getValueControl('text', 'baja', false));
            changes.push(
                helpers.getValueControl('text', 'modificacion', false)
            );
            changes.push(helpers.getValueControl('text', 'traspaso', false));
            changes.push(helpers.getValueControl('text', 'produccion', false));
            handleChange(changes);
        }
    }, [JSON.stringify(values.bloqueo)]);

    useEffect(() => {
        if (editing && values.produccion) {
            const changes = [];
            changes.push(helpers.getValueControl('text', 'alta', false));
            changes.push(helpers.getValueControl('text', 'baja', false));
            changes.push(
                helpers.getValueControl('text', 'modificacion', false)
            );
            changes.push(helpers.getValueControl('text', 'traspaso', false));
            changes.push(helpers.getValueControl('text', 'bloqueo', false));
            handleChange(changes);
        }
    }, [JSON.stringify(values.produccion)]);

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Select
                        fullWidth
                        name='clase'
                        label='Clase'
                        value={values.clase}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.claseTramite}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='descripcion'
                        label='Descripción'
                        value={values.descripcion}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs>
                    <Grid container spacing={1} direction={'column'}>
                        <Grid item xs>
                            <Divider style={{ margin: '12px 12px 12px 12px' }}>
                                <Chip size='small' label={'Afecta a'} />
                            </Divider>
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <Switch
                                        value={values.cliente}
                                        onChange={handleChange}
                                        name='cliente'
                                        label='Cliente'
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Switch
                                        value={values.socio}
                                        onChange={handleChange}
                                        name='socio'
                                        label='Socio'
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Switch
                                        value={values.parcela}
                                        onChange={handleChange}
                                        name='parcela'
                                        label='Parcela'
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Grid container spacing={1} direction={'column'}>
                        <Grid item xs>
                            <Divider style={{ margin: '12px 12px 12px 12px' }}>
                                <Chip size='small' label={'Comportamiento'} />
                            </Divider>
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <Switch
                                        value={values.alta}
                                        onChange={handleChange}
                                        name='alta'
                                        label='Alta'
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Switch
                                        value={values.baja}
                                        onChange={handleChange}
                                        name='baja'
                                        label='Baja'
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Switch
                                        value={values.modificacion}
                                        onChange={handleChange}
                                        name='modificacion'
                                        label='Modificación'
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Switch
                                        value={values.traspaso}
                                        onChange={handleChange}
                                        name='traspaso'
                                        label='Cambio Titularidad'
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Switch
                                        value={values.bloqueo}
                                        onChange={handleChange}
                                        name='bloqueo'
                                        label='Bloqueo'
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Switch
                                        value={values.produccion}
                                        onChange={handleChange}
                                        name='produccion'
                                        label='Produccion'
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Grid container spacing={1} direction={'column'}>
                        <Grid item xs>
                            <Divider style={{ margin: '12px 12px 12px 12px' }}>
                                <Chip size='small' label={'Requisitos'} />
                            </Divider>
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <Switch
                                        value={values.acta}
                                        onChange={handleChange}
                                        name='acta'
                                        label='Acta'
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Switch
                                        value={values.resolucion}
                                        onChange={handleChange}
                                        name='resolucion'
                                        label='Resolución'
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Switch
                                        value={values.liquidacion}
                                        onChange={handleChange}
                                        name='liquidacion'
                                        label='Liquidación'
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Switch
                                        value={values.seguimiento}
                                        onChange={handleChange}
                                        name='seguimiento'
                                        label='Seguimiento'
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Detalles',
                        icon: icons.DetailIcon,
                        component: <Detalles {...props} />,
                        hide: false
                    },
                    {
                        label: 'Tareas',
                        icon: icons.TareasIcon,
                        component: <Tareas {...props} />,
                        hide: values && values.seguimiento ? false : true
                    },
                    {
                        label: 'Liquidación',
                        icon: icons.LiquidacionesIcon,
                        component: <Liquidaciones {...props} />,
                        hide: values && values.liquidacion ? false : true
                    },
                    {
                        label: 'Formularios',
                        icon: icons.PrintIcon,
                        component: <Formularios {...props} />,
                        hide: false
                    }
                ]}
            />
        </form>
    );
};

export default Detalle;
