import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'core/components/library';
import { DialogContent, DialogActions, Grid } from 'core/components/library';
import { Filter, StateLine, ViewEntity } from 'core/components';
import * as enums from 'core/enums';
import * as constants from 'store/entities/constants';
import List from 'store/List';
import DetailSolicitud from 'views/tramites/Tramites/Detalle';
import DetailResolucion from 'views/tramites/Resoluciones/Detalle';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-star',
        '& > *': {
            marginLeft: theme.spacing(1)
        }
    },
    root: {
        height: '90vh',
        position: 'absolute',
        top: '5vh',
        right: '0vh'
    },
    selector: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: theme.palette.background.paper
        boxShadow: theme.shadows[20]
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[20],
        padding: theme.spacing(2, 4, 3)
    }
}));

const getOptions = scopes => {
    let options = [];
    scopes.forEach(scope => {
        if (scope.value === 'Miembro') {
            options = options.concat(enums.statesEntity);
        }
        if (scope.value === 'Produccion') {
            options = options.concat(enums.locksParcel);
        }
    });

    return options;
};

const ChangeLog = props => {
    const { events, entity, id, scopes } = props;
    const classes = useStyles();

    useEffect(() => {
        const api = `estados/${entity.entity}/${id}`;
        events.load(api);

        return events.unload;
    }, []);

    /* Posibilidad filtrando contra el servidor por ambito
    const [filtro, setFiltro] = useState(null);
    useEffect(() => {
        if (false) {
            const api = `estados/${entity.entity}/${filtro}/${id}`;
            events.refresh(false, api);
        }
    }, [filtro]);*/

    const entitySolicitud = useSelector(
        state => state.entities[constants.ENTITY_TRAMITES]
    );
    const entityResolucion = useSelector(
        state => state.entities[constants.ENTITY_RESOLUCIONES]
    );

    return [
        <DialogActions className={classes.selector}>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Filter
                        title={'Fecha'}
                        type={'text'}
                        onFilter={valor =>
                            events.filter(['fecha'], valor, 'between', 'date')
                        }
                    />
                </Grid>
                <Grid item xs>
                    <Filter
                        title={'Estado'}
                        type={'select'}
                        onFilter={valor =>
                            events.filter(['estado'], valor, 'in', 'text')
                        }
                        options={getOptions(scopes).filter(i => i.value)}
                    />
                </Grid>
                <Grid item xs>
                    <Filter
                        title={'Ambito'}
                        type={'select'}
                        multiple={false}
                        onFilter={valor =>
                            events.filter(['ambito'], valor, 'in', 'text')
                        }
                        value={
                            scopes && scopes.length !== 0
                                ? scopes[0].value
                                : undefined
                        }
                        options={scopes}
                    />
                </Grid>
            </Grid>
        </DialogActions>,
        <DialogContent className={classes.paper}>
            {events.isReady && !events.isInit && (
                <StateLine
                    principal={'estado'}
                    secondary={'motivo'}
                    date={'fecha'}
                    period
                    events={events.list}
                    options={getOptions(scopes)}
                    onClick={(event, index, open) => {
                        if (event.resolucion)
                            open(
                                constants.ENTITY_RESOLUCIONES,
                                event.resolucion.id
                            );
                        if (event.tramite)
                            open(constants.ENTITY_TRAMITES, event.tramite.id);
                    }}
                >
                    <ViewEntity
                        key={constants.ENTITY_TRAMITES}
                        refresh={events.refresh}
                        entity={entitySolicitud}
                    >
                        {DetailSolicitud}
                    </ViewEntity>
                    <ViewEntity
                        key={constants.ENTITY_RESOLUCIONES}
                        refresh={events.refresh}
                        entity={entityResolucion}
                    >
                        {DetailResolucion}
                    </ViewEntity>
                </StateLine>
            )}
        </DialogContent>
    ];
};

const ChangeLogContainer = List(ChangeLog, 'events', 'lstLogState');

export default ChangeLogContainer;
