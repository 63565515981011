import React, { useEffect, useState } from 'react';

import { makeStyles, styled } from '../library';

import { Auth, Entity, TextField, Tooltip, IconButton } from '../library';

import { Detail, Element } from '../Crud';

import * as icons from 'core/icons';

import { customTypes } from '../customTypes';
import { gridColumnVisibilityModelSelector } from '@mui/x-data-grid';
import { calculateNewValue } from '@testing-library/user-event/dist/utils';

const useStyles = makeStyles(theme => ({
    root: {}
}));

// Esto es una prueba para retocar los estilos del TextBox ahora  no hace nada
const TextStyled = styled(TextField)({
    '& input:valid + fieldset': {
        //borderColor: 'green',
        //borderWidth: 2,
    }
});

const Text = props => {
    const classes = useStyles();

    const {
        autoSelect,
        required,
        variant,
        margin,
        helperText,
        error,
        type,
        editing,
        onChange,
        onError,
        onFocus,
        validation,
        value,
        Icon,
        relacionado,
        nextValue,
        refresh,
        fullWidth,
        ...rest
    } = props;

    const [detail, setDetail] = useState(null);
    const [old, setOld] = useState(value ? value : '');
    const [format, setFormat] = useState(null);

    useEffect(() => {
        const newFormat = customTypes(type);
        setFormat(newFormat);
    }, [type]);

    const handleChange = (name, value) => {
        if (!onChange) return;

        const newValue = format ? format.getValue(value) : value;
        onChange([
            {
                field: name,
                value: newValue
            }
        ]);
    };

    const handleError = () => {
        if (error) {
            onError && onError(rest.name, error);
            return true;
        }

        if (!value && required) {
            onError && onError(rest.name, 'Valor Requerido');
            return true;
        }

        if (!isValueOk(applyType())) {
            onError && onError(rest.name, 'Valor incorrecto');
            return true;
        }

        onError && onError(rest.name, null);
        return false;
    };

    const lockRelacionado = () => {
        const alta = value === null || value === undefined ? true : false;

        if (alta) return true;

        return false;
    };

    const openDetail = id => {
        const key = Date.now();
        const newId =
            value === null || value === undefined
                ? 'new'
                : value[relacionado.propertyId];

        setDetail({
            key: key,
            id: newId,
            title: relacionado.title ? relacionado.title : rest.label,
            resume: relacionado.resume,
            api: relacionado.api + '/' + newId,
            container: Entity(Auth(Element, 'auth'), 'entity', key),
            element: relacionado.Detail,
            dfault: newId === 'new' ? relacionado.dfault : undefined
        });
    };

    const editDetail = (key, cancel, id, isEditable, entity) => {
        if (!isEditable) {
            if (!cancel) {
                if (editing) {
                    if (value !== null && value !== undefined) {
                        // Modificacion: refresco solo al padre
                        refresh && refresh(true);
                    }

                    const newValue = {
                        selected: {
                            [relacionado.propertyId]:
                                entity[relacionado.propertyId],
                            [relacionado.propertyValue]:
                                entity[relacionado.propertyValue],
                            forzado: true
                        },
                        name: rest.name
                    };

                    // Meto los cambios en el control
                    handleChange(rest.name, newValue);
                }
                // Refresco el elemento al que pertenezco y su padre
                else refresh && refresh(false);

                // Cierro el dialogo
                setDetail(null);
            }
        }
    };

    const applyType = force => {
        const newValue =
            relacionado && relacionado.propertyValue
                ? value[relacionado.propertyValue]
                : value;

        if (!newValue) return '';

        if (!format) return newValue;

        return format.apply(newValue, force);
    };

    const isCharValid = event => {
        format && !format.isCharValid(event.key) && event.preventDefault();
    };

    const isValueOk = value => {
        if (value !== null && value !== undefined && value !== '') {
            if (format) return format.isValueOk(value);
        }

        return true;
    };

    const validate = control => {
        if (control.name === 'codigo') console.log('me voy');
        if (!isValueOk(applyType())) {
            //control.focus();
            return;
        }

        if (format) {
            const newValue = applyType(true);
            newValue !== control.value && handleChange(control.name, newValue);
        }

        /*
        if (validation && validation(value))


        else {

            if (old !== value) {
                if (validation) {
                    if (!validation(value)) {
                        control.focus();
                    }else setOld(value);
                }else{
                    setOld(value);
                }
            }
        }*/
    };

    const undo = control => {
        if (old !== value) handleChange(control.name, old);
    };

    return [
        <TextStyled
            {...rest}
            required={required ? true : false}
            type={format ? format.base : type}
            value={applyType()}
            error={handleError()}
            helperText={
                format && helperText
                    ? !isValueOk(applyType())
                        ? format.messageError
                        : helperText
                    : helperText
            }
            fullWidth={fullWidth}
            onFocus={event => {
                autoSelect && event.target.select();
                onFocus && onFocus(event);
            }}
            onBlur={event => validate(event.target)}
            onChange={event =>
                handleChange(event.target.name, event.target.value)
            }
            onKeyPress={isCharValid}
            onKeyDown={event => {
                if (event.key === 'Escape') {
                    event.preventDefault();
                    undo(event.target);
                }
            }}
            margin={margin ? margin : 'dense'}
            size='small'
            variant={variant ? variant : 'outlined'}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                readOnly: relacionado ? true : !editing,
                startAdornment: !relacionado ? (
                    !nextValue || !editing ? undefined : (
                        <React.Fragment>
                            <Tooltip
                                title={`Siguiente ${rest.label} disponible`}
                            >
                                <IconButton
                                    style={{ padding: 0, marginRight: 4 }}
                                    size='small'
                                    onClick={event => nextValue()}
                                >
                                    <icons.NextValueIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    )
                ) : (
                    <React.Fragment>
                        <Tooltip
                            title={
                                value === null ||
                                value === undefined ||
                                `Abrir ${rest.label ? rest.label : ''}`
                            }
                        >
                            <IconButton
                                disabled={lockRelacionado()}
                                style={{ padding: 0, marginRight: 4 }}
                                size='small'
                                onClick={event => openDetail()}
                            >
                                {Icon && <Icon color='primary' />}
                            </IconButton>
                        </Tooltip>
                    </React.Fragment>
                )
            }}
        />,
        <Detail
            maxWidth={
                relacionado && relacionado.maxWidth && relacionado.maxWidth
            }
            item={detail}
            open={detail !== null}
            close={() => setDetail(null)}
            edit={editDetail}
            readOnly={relacionado && relacionado.readOnly}
        />
    ];
};

export default Text;
