import React from 'react';

import { makeStyles, gridSpacing } from '../../library';
import {
    Avatar,
    Button,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Grid,
    Typography
} from '../../library';

import { Filter } from '../../';

import { SearchIcon, LockedIcon } from '../../icons';

import * as icons from '../../icons';
import * as helpers from '../../helpers';

const useStyles = makeStyles(theme => ({
    root: {},
    avatar: {
        //backgroundColor: theme.palette.primary.main
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-star',
        alignItems: 'center',
        '& > *': {
            marginRight: theme.spacing(1)
        }
    }
}));

const Toolbar = props => {
    const {
        className,
        title,
        disabled,
        filters,
        menu,
        selector,
        Icon,
        resize
    } = props;

    const classes = useStyles();

    return (
        <Card raised={true}>
            <CardHeader
                style={{ padding: 6 }}
                title={
                    <Typography variant='h4'>
                        {disabled && (
                            <LockedIcon color='error' fontSize='inherit' />
                        )}
                        {` ${title}`}
                    </Typography>
                }
                subheader={
                    disabled
                        ? 'Bloqueado'
                        : filters.length !== 0 &&
                          'Utilice las opciones de filtro para buscar por cada campo'
                }
                action={!disabled && menu}
                avatar={
                    <Button disabled={!resize} onClick={resize && resize}>
                        <Avatar className={classes.avatar}>
                            {Icon ? <Icon /> : helpers.getInitials(title)}
                        </Avatar>
                    </Button>
                }
            />
            {(filters.length !== 0 || selector) && (
                <div>
                    <Divider />
                    <CardContent style={{ padding: 8 }}>
                        <Grid container spacing={1}>
                            {selector && (
                                <Grid item xs={12} md>
                                    <Grid container spacing={1}>
                                        {selector}
                                    </Grid>
                                </Grid>
                            )}

                            {filters.length !== 0 && (
                                <Grid item xs={12} md>
                                    <Grid
                                        container
                                        spacing={1}
                                        alignItems={'center'}
                                    >
                                        {filters.map(
                                            (filter, index) =>
                                                filter.filter && (
                                                    <Grid
                                                        key={index}
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={getAncho(filter)}
                                                    >
                                                        <Filter
                                                            title={
                                                                filter.titleFilter
                                                            }
                                                            titleTrue={
                                                                filter.titleTrue
                                                            }
                                                            titleFalse={
                                                                filter.titleFalse
                                                            }
                                                            value={filter.value}
                                                            modificador={
                                                                filter.modificador
                                                            }
                                                            options={
                                                                filter.options
                                                            }
                                                            multiple={
                                                                filter.multiple ===
                                                                undefined
                                                                    ? true
                                                                    : filter.multiple
                                                            }
                                                            type={filter.type}
                                                            onFilter={
                                                                filter.filter
                                                            }
                                                            onChange={value =>
                                                                filter.setValue(
                                                                    filter,
                                                                    value
                                                                )
                                                            }
                                                            onClick={modificador => {
                                                                filter.setModificador(
                                                                    filter,
                                                                    modificador
                                                                );
                                                            }}
                                                            filter={filter}
                                                            index={index}
                                                            disabled={disabled}
                                                        />
                                                    </Grid>
                                                )
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </div>
            )}
        </Card>
    );
};

const getAncho = filter => {
    if (filter.width) return filter.width;

    if (filter.options) return 4;

    if (filter.type.toLowerCase() === 'checkbox') return 3;

    if (filter.type.toLowerCase() === 'date') return 3;

    return 3;
};

export default Toolbar;
