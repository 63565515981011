import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'core/components/library';
import {
    DataGridPro as DataGrid,
    Switch,
    Literal,
    ViewEntity,
    Tag
} from 'core/components';
import DetailExplotacion from '../../../../Explotaciones/Detalle';
import { ENTITY_EXPLOTACION_BODEGA } from '../../../../../../store/entities/constants';
import * as enums from '../../../../../../core/enums';
import { statesEntity } from '../../../../../../core/enums';
import * as actions from 'store/actions';

const cols = (row, entityData) => [
    {
        key: 'operador',
        title: 'Cod. Operador',
        align: 'center',
        width: '10%',
        component: row && <>{row['codigo']}</>
    },
    {
        key: 'nombre',
        title: 'Nombre',
        align: 'left',
        width: '40%',
        component: row && (
            <Literal text={row['nombre']}>
                <entityData.icon fontSize={'small'} />
            </Literal>
        )
    },
    {
        key: 'estado',
        title: 'Estado',
        align: 'center',
        width: '10%',
        component: row && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(statesEntity, row.estado, 'color')
                }}
                Icon={enums.getItem(statesEntity, row.estado, 'icon')}
                value={row.estado}
            />
        )
    },
    {
        key: 'elaboracion',
        title: 'Elaboración',
        align: 'center',
        width: '10%',
        component: row && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(statesEntity, row.elaboracion, 'color')
                }}
                Icon={enums.getItem(statesEntity, row.elaboracion, 'icon')}
                value={row.elaboracion}
            />
        )
    },
    {
        key: 'almacenamiento',
        title: 'Almacenamiento',
        align: 'center',
        width: '10%',
        component: row && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(
                        statesEntity,
                        row.almacenamiento,
                        'color'
                    )
                }}
                Icon={enums.getItem(statesEntity, row.almacenamiento, 'icon')}
                value={row.almacenamiento}
            />
        )
    },
    {
        key: 'crianza',
        title: 'Crianza',
        align: 'center',
        width: '10%',
        component: row && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(statesEntity, row.crianza, 'color')
                }}
                Icon={enums.getItem(statesEntity, row.crianza, 'icon')}
                value={row.crianza}
            />
        )
    },
    {
        key: 'embotellado',
        title: 'Embotellado',
        align: 'center',
        width: '10%',
        component: row && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(statesEntity, row.embotellado, 'color')
                }}
                Icon={enums.getItem(statesEntity, row.embotellado, 'icon')}
                value={row.embotellado}
            />
        )
    }
];

const Explotaciones = props => {
    const { values, editing, execAction, refresh } = props;

    const entityData = useSelector(
        state => state.entities[ENTITY_EXPLOTACION_BODEGA]
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs>
                <DataGrid
                    //title='Centros'
                    name='explotaciones'
                    editing={editing}
                    rows={values.explotaciones ? values.explotaciones : []}
                    cols={(name, row, index) => cols(row, entityData)}
                    onDelete={(name, index, row) => {
                        execAction(
                            {
                                api: entityData.api,
                                metodo: 'delete',
                                data: [row.id]
                            },
                            [() => refresh(false)]
                        );
                    }}
                    maxHeight={456}
                >
                    <ViewEntity
                        type={ENTITY_EXPLOTACION_BODEGA}
                        refresh={refresh}
                        entity={entityData}
                        initValue={{ bodega: { id: values.id } }}
                    >
                        {DetailExplotacion}
                    </ViewEntity>
                </DataGrid>
            </Grid>
        </Grid>
    );
};

export default Explotaciones;
