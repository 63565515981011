import React, { useEffect } from 'react';
import { makeStyles, Grid } from 'core/components/library';
import { Text, Combo, DataGridPro as DataGrid, Tag } from 'core/components';

import * as icons from 'core/icons';

import DetailCuenta from '../../../Detalle';
import DetailPartida from '../../../../partidas/Detalle';

import List from '../../../../../../store/List';

const ComboSubcuentas = List(Combo, 'options', 'lstContrapartidas');
const ComboPartidas = List(Combo, 'options', 'lstPartidas');

const cols = (name, row, index, refresh) => [
    {
        key: 'id',
        title: 'Ubicación P.G. Contable',
        align: 'left',
        component: row && (
            <Text
                fullWidth
                margin='none'
                type='text'
                variant='standard'
                Icon={icons.CuentasIcon}
                name='id'
                value={{
                    id: row['cuenta'],
                    descripcion: `${row['cuenta']} ${row['descripcion']}`
                }}
                refresh={refresh}
                relacionado={{
                    Detail: DetailCuenta,
                    propertyId: 'id',
                    propertyValue: 'descripcion',
                    resume: ['descripcion'],
                    api: 'cuentas',
                    readOnly: false,
                    maxWidth: 'md'
                }}
            />
        )
    }
];

const Detalles = props => {
    const {
        values,
        editing,
        adding,
        initial,
        handleChange,
        refresh,
        estableceFiltro,
        list
    } = props;

    useEffect(() => {
        if (values.id && values.id !== '') {
            const api = 'cuentas/subcuentas/' + values.id;
            list.load(api);
        }

        return list.unload;
    }, []);

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <DataGrid
                    editing={editing}
                    name='cuentas'
                    rows={list.isReady && list.list ? list.list : []}
                    cols={(name, row, index) =>
                        cols(name, row, index, list.refresh)
                    }
                    onAdd={() => list.refresh()}
                    maxHeight={456}
                    relacionado={{
                        Detail: DetailCuenta,
                        api: 'cuentas',
                        resume: ['descripcion'],
                        dfault: {},
                        readOnly: false,
                        maxWidth: 'md'
                    }}
                />
            </Grid>
            <Grid item xs>
                <Grid container direction='column' spacing={1}>
                    <Grid item xs>
                        <ComboSubcuentas
                            fullWidth
                            api='subcuentas/activas'
                            name='contrapartida'
                            nameKey='contrapartidaid'
                            label='Contrapartida'
                            relacionado={{
                                maxWidth: 'md',
                                Detail: DetailCuenta,
                                resume: ['descripcion'],
                                readOnly: false
                            }}
                            refresh={refresh}
                            Icon={icons.CuentasIcon}
                            value={values.contrapartida}
                            onChange={handleChange}
                            editing={editing}
                            propertyValue='id'
                            getOptionLabel={option =>
                                `${option.id} ${option.descripcion}`
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <ComboPartidas
                            fullWidth
                            api='partidaspresupuesto'
                            name='partida'
                            nameKey='partidaid'
                            label='Partida'
                            relacionado={{
                                maxWidth: 'md',
                                Detail: DetailPartida,
                                resume: ['descripcion'],
                                readOnly: false
                            }}
                            refresh={refresh}
                            Icon={icons.PartidasPresupuestoIcon}
                            value={values.partida}
                            onChange={handleChange}
                            editing={editing}
                            propertyValue='id'
                            getOptionLabel={option =>
                                `${option.id} ${option.descripcion}`
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <Text
                            fullWidth
                            multiline
                            rows={10}
                            name='notas'
                            label='Notas'
                            value={values.notas}
                            onChange={handleChange}
                            editing={editing}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const DetallesContainer = List(Detalles, 'list', 'lstPGC');
export default DetallesContainer;
