import React, { useEffect } from 'react';

import { Grid, Divider, Chip } from 'core/components/library';

import { Select, DataGridPro as DataGrid, Text, Combo } from 'core/components';

import * as helpers from 'core/helpers';
import * as enums from 'core/enums';
import List from '../../../../../../store/List';
import DetailSubcuenta from 'views/contabilidad/subcuentas/Detalle';
import * as icons from '../../../../../../core/icons';

const ComboSubcuentas = List(Combo, 'options', 'lstSubcuentas');

const validaImporte = (values, row) => {
    const importe =
        values[0].value && values[0].value !== '' ? Number(values[0].value) : 0;
    const pIva = row.pIva && row.pIva !== '' ? Number(row.pIva) : 0;
    const pRecargo =
        row.pRecargo && row.pRecargo !== '' ? Number(row.pRecargo) : 0;
    const iva = (importe * pIva) / 100;
    const recargo = (importe * pRecargo) / 100;

    values.push({ field: 'iva', value: iva.toFixed(2) });
    values.push({ field: 'recargo', value: recargo.toFixed(2) });

    return values;
};
const validaIva = (values, row) => {
    const pIva =
        values[0].value && values[0].value !== '' ? Number(values[0].value) : 0;
    const importe = row.importe && row.importe !== '' ? Number(row.importe) : 0;
    const iva = (importe * pIva) / 100;

    values.push({ field: 'iva', value: iva.toFixed(2) });

    return values;
};
const validaRecargo = (values, row) => {
    const pRecargo =
        values[0].value && values[0].value !== '' ? Number(values[0].value) : 0;
    const importe = row.importe && row.importe !== '' ? Number(row.importe) : 0;
    const recargo = (importe * pRecargo) / 100;

    values.push({ field: 'recargo', value: recargo.toFixed(2) });

    return values;
};
const actualizaTotales = values => {
    const pRetencion =
        values.pRetencion && values.pRetencion !== ''
            ? Number(values.pRetencion)
            : 0;
    const pRecargoFinanciero =
        values.pRecargoFinanciero && values.pRecargoFinanciero !== ''
            ? Number(values.pRecargoFinanciero)
            : 0;
    const suplidos =
        values.suplidos && values.suplidos !== '' ? Number(values.suplidos) : 0;
    let baseImponible = 0;
    let iva = 0;
    let recargo = 0;

    values.partidas.forEach(partida => {
        baseImponible = baseImponible + Number(partida.importe);
        iva = iva + Number(partida.iva);
        recargo = recargo + Number(partida.recargo);
    });

    const retencion = Number((baseImponible * pRetencion) / 100).toFixed(2);
    const recargoFinanciero = Number(
        (baseImponible * pRecargoFinanciero) / 100
    ).toFixed(2);
    const total = Number(
        baseImponible +
            iva +
            recargo -
            retencion +
            suplidos +
            Number(recargoFinanciero)
    );

    return [
        { field: 'baseImponible', value: baseImponible.toFixed(2) },
        { field: 'iva', value: iva.toFixed(2) },
        { field: 'recargo', value: recargo.toFixed(2) },
        { field: 'retencion', value: retencion },
        { field: 'recargoFinanciero', value: recargoFinanciero },
        { field: 'total', value: total.toFixed(2) }
    ];
};
const validaRetencion = (changes, values, porc) => {
    const baseImponible =
        values.baseImponible === '' ? 0 : Number(values.baseImponible);
    const iva = values.iva === '' ? 0 : Number(values.iva);
    const recargo = values.recargo === '' ? 0 : Number(values.recargo);
    const suplidos = values.suplidos === '' ? 0 : Number(values.suplidos);
    const recargoFinanciero =
        values.recargoFinanciero === '' ? 0 : Number(values.recargoFinanciero);
    let pRetencion = 0;
    let retencion = 0;

    if (porc) {
        pRetencion = Number(changes[0].value && changes[0].value !== '')
            ? Number(changes[0].value)
            : 0;
        retencion = Number((baseImponible * pRetencion) / 100).toFixed(2);
        changes.push({ field: 'retencion', value: retencion });
    } else {
        retencion = Number(changes[0].value && changes[0].value !== '')
            ? Number(changes[0].value)
            : 0;
        pRetencion = Number(
            baseImponible === 0 ? 0 : (retencion * 100) / baseImponible
        ).toFixed(2);

        changes.push({ field: 'pRetencion', value: pRetencion });
    }

    const total = Number(
        baseImponible + iva + recargo - retencion + suplidos + recargoFinanciero
    ).toFixed(2);
    changes.push({ field: 'total', value: total });

    return changes;
};
const validaFinanciero = (changes, values, porc) => {
    const baseImponible =
        values.baseImponible === '' ? 0 : Number(values.baseImponible);
    const iva = values.iva === '' ? 0 : Number(values.iva);
    const recargo = values.recargo === '' ? 0 : Number(values.recargo);
    const suplidos = values.suplidos === '' ? 0 : Number(values.suplidos);
    const retencion = values.retencion === '' ? 0 : Number(values.retencion);
    let pRecargoFinanciero = 0;
    let recargoFinanciero = 0;

    if (porc) {
        pRecargoFinanciero = Number(changes[0].value && changes[0].value !== '')
            ? Number(changes[0].value)
            : 0;
        recargoFinanciero = Number(
            (baseImponible * pRecargoFinanciero) / 100
        ).toFixed(2);
        changes.push({ field: 'recargoFinanciero', value: recargoFinanciero });
    } else {
        recargoFinanciero = Number(changes[0].value && changes[0].value !== '')
            ? Number(changes[0].value)
            : 0;
        pRecargoFinanciero = Number(
            baseImponible === 0 ? 0 : (recargoFinanciero * 100) / baseImponible
        ).toFixed(2);

        changes.push({
            field: 'pRecargoFinanciero',
            value: pRecargoFinanciero
        });
    }

    const total = Number(
        baseImponible +
            iva +
            recargo -
            retencion +
            suplidos +
            Number(recargoFinanciero)
    ).toFixed(2);
    changes.push({ field: 'total', value: total });

    return changes;
};
const validaSuplidos = (changes, values) => {
    const baseImponible =
        values.baseImponible === '' ? 0 : Number(values.baseImponible);
    const iva = values.iva === '' ? 0 : Number(values.iva);
    const recargo = values.recargo === '' ? 0 : Number(values.recargo);
    const recargoFinanciero =
        values.recargoFinanciero === '' ? 0 : Number(values.recargoFinanciero);
    const retencion = values.retencion === '' ? 0 : Number(values.retencion);
    const suplidos = Number(changes[0].value && changes[0].value !== '')
        ? Number(changes[0].value)
        : 0;
    const total = Number(
        baseImponible +
            iva +
            recargo -
            retencion +
            suplidos +
            Number(recargoFinanciero)
    ).toFixed(2);

    changes.push({ field: 'total', value: total });

    return changes;
};
const cols = (name, row, index, editing, handleChange, refresh) => [
    {
        key: 'subcuenta',
        title: 'Cuenta Gasto',
        align: 'left',
        width: '40%',
        component: row && (
            <ComboSubcuentas
                fullWidth
                margin='none'
                variant='standard'
                api='subcuentas/activas'
                name='subcuenta'
                nameKey='subcuentaid'
                relacionado={{
                    Detail: DetailSubcuenta,
                    resume: ['descripcion'],
                    readOnly: false
                }}
                refresh={refresh}
                Icon={icons.CuentasIcon}
                value={row.subcuenta}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option => `${option.id} ${option.descripcion} `}
            />
        )
    },
    {
        key: 'importe',
        title: 'Importe €',
        align: 'left',
        //width: '1%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='importe'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['importe']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(validaImporte(values, row), name, index))
                }
            />
        )
    },
    {
        key: 'pIva',
        title: 'IVA %',
        align: 'left',
        //width: '40%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='pIva'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['pIva']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(validaIva(values, row), name, index))
                }
            />
        )
    },
    {
        key: 'iva',
        title: 'IVA €',
        align: 'left',
        //width: '1%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='iva'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['iva']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    },
    {
        key: 'pRecargo',
        title: 'Req. Equiv. %',
        align: 'left',
        //width: '1%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='pRecargo'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['pRecargo']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(validaRecargo(values, row), name, index))
                }
            />
        )
    },
    {
        key: 'recargo',
        title: 'Recargo €',
        align: 'left',
        //width: '1%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='recargo'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['recargo']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    }
];
const Partidas = props => {
    const { values, editing, adding, handleChange, refresh } = props;

    useEffect(() => {
        if (editing && values.partidas) {
            handleChange(actualizaTotales(values));
        }
    }, [JSON.stringify(values.partidas)]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs>
                    <DataGrid
                        name='partidas'
                        editing={editing}
                        rows={values.partidas ? values.partidas : []}
                        cols={(name, row, index) =>
                            cols(
                                name,
                                row,
                                index,
                                editing,
                                handleChange,
                                refresh
                            )
                        }
                        onAdd={name => {
                            handleChange(
                                {
                                    linea:
                                        values.partidas &&
                                        values.partidas.length !== 0
                                            ? values.partidas[
                                                  values.partidas.length - 1
                                              ].linea + 1
                                            : 1,
                                    subcuenta:
                                        values.subcuenta &&
                                        values.subcuenta.contrapartida &&
                                        values.subcuenta.contrapartida,
                                    importe: (0).toFixed(2),
                                    pIva: (21).toFixed(2),
                                    iva: (0).toFixed(2),
                                    pRecargo: (0).toFixed(2),
                                    recargo: (0).toFixed(2)
                                },
                                name
                            );
                        }}
                        onDelete={(name, index) =>
                            handleChange(undefined, name, (index + 1) * -1)
                        }
                        minHeight={250}
                        maxHeight={250}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Divider style={{ margin: '12px 12px 12px 12px' }}>
                        <Chip label={'TOTALES'} />
                    </Divider>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                {/*
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='bruto'
                        label='Bruto €'
                        value={values.bruto}
                        onChange={handleChange}
                        onFocus = {event => event.target.select()}
                        editing={false}
                    />
                </Grid>
                <Grid item>
                    <Text
                        sx={{maxWidth:92}}
                        type='d.2'
                        name='pDescuento'
                        label='Descuento %'
                        value={values.pDescuento}
                        onChange={handleChange}
                        onFocus = {event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        type='d.2'
                        fullWidth
                        name='descuento'
                        label='Descuento €'
                        value={values.descuento}
                        onChange={handleChange}
                        onFocus = {event => event.target.select()}
                        editing={editing}

                    />
                </Grid>
                */}
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='baseImponible'
                        label='Base Imponible €'
                        value={values.baseImponible}
                        onChange={handleChange}
                        onFocus={event => event.target.select()}
                        editing={false}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='iva'
                        label='IVA €'
                        value={values.iva}
                        onChange={handleChange}
                        onFocus={event => event.target.select()}
                        editing={false}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='recargo'
                        label='Recargo Equiv. €'
                        value={values.recargo}
                        onChange={handleChange}
                        editing={false}
                    />
                </Grid>
                <Grid item>
                    <Text
                        sx={{ maxWidth: 92 }}
                        type='d.2'
                        name='pRetencion'
                        label='Retención %'
                        value={values.pRetencion}
                        onChange={changes =>
                            handleChange(validaRetencion(changes, values, true))
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='retencion'
                        label='Retención €'
                        value={values.retencion}
                        onChange={changes =>
                            handleChange(
                                validaRetencion(changes, values, false)
                            )
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='suplidos'
                        label='Suplidos €'
                        value={values.suplidos}
                        onChange={changes =>
                            handleChange(validaSuplidos(changes, values))
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item>
                    <Text
                        sx={{ maxWidth: 92 }}
                        type='d.2'
                        name='pRecargoFinanciero'
                        label='R. Financ. %'
                        value={values.pRecargoFinanciero}
                        onChange={changes =>
                            handleChange(
                                validaFinanciero(changes, values, true)
                            )
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='recargoFinanciero'
                        label='Recargo Finan. €'
                        value={values.recargoFinanciero}
                        onChange={changes =>
                            handleChange(
                                validaFinanciero(changes, values, false)
                            )
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='total'
                        label='Total €'
                        value={values.total}
                        onChange={handleChange}
                        onFocus={event => event.target.select()}
                        editing={false}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Partidas;
