import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Grid, Typography, Divider } from '@mui/material';

import * as icons from 'core/icons';
import * as hlprs from 'core/helpers';
import * as enums from 'core/enums';

import { Text } from 'core/components';

const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const Detalles = props => {
    const {
        values,
        editing,
        adding,
        initial,
        handleChange,
        refresh,
        estableceFiltro
    } = props;
    const classes = useStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Text
                    fullWidth
                    multiline
                    rows={8}
                    name='detalle'
                    label='Detalles (privado)'
                    value={values.detalle}
                    onChange={handleChange}
                    editing={editing}
                />
            </Grid>
            <Grid item xs>
                <Text
                    fullWidth
                    multiline
                    rows={8}
                    name='observaciones'
                    label='Observaciones (publico)'
                    value={values.observaciones}
                    onChange={handleChange}
                    editing={editing}
                />
            </Grid>
        </Grid>
    );
};

export default Detalles;
