import React, { useEffect, useState } from 'react';

import { InputAdornment, TextField, Tooltip } from '../library';

import { SearchIcon, SearchAcumulativeIcon, SearchNotIcon } from '../icons';
import * as helpers from '../helpers';

const getNewFlag = flag => {
    if (flag === '!') return '===';
    if (flag === '===') return '=';

    return '!';
};

const getIconFlag = flag => {
    const size = 20;

    if (flag === '!')
        return <SearchNotIcon sx={{ fontSize: size, color: 'darkred' }} />;

    if (flag === '===')
        return (
            <SearchAcumulativeIcon sx={{ fontSize: size, color: 'darkblue' }} />
        );

    return <SearchIcon sx={{ fontSize: size }} />;
};

const getTextFlag = flag => {
    if (flag === '!') return 'Valores que NO INCLUYAN';

    if (flag === '===') return 'Valores que CUMPLAN ESTRICTAMENTE';

    return 'Valores que INCLUYAN';
};

const FilterText = props => {
    const {
        title,
        value,
        modificador,
        type,
        onFilter,
        onChange,
        onClick,
        disabled
    } = props;

    const [flag, setFlag] = useState(modificador);

    useEffect(() => {
        if (value) {
            filtra(value);
        }
    }, []);

    useEffect(() => {
        if (value) {
            filtra(value);
        }
    }, [flag]);

    const filtra = newValue => {
        onChange && onChange(newValue);
        helpers.timeBox(
            () => onFilter && onFilter(procesaValor(newValue, type), flag),
            450
        );
    };

    return (
        <TextField
            defaultValue={value}
            type={'text'} //filter.type}
            margin='none'
            size='small'
            fullWidth
            label={title}
            variant='standard'
            onChange={event => filtra(event.target.value)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                readOnly: disabled,
                startAdornment: (
                    <InputAdornment
                        sx={{
                            cursor:
                                onClick && modificador ? 'pointer' : 'default'
                        }}
                        position='start'
                        onClick={
                            onClick &&
                            modificador &&
                            (() => {
                                const newFlag = getNewFlag(flag);
                                setFlag(newFlag);
                                onClick(newFlag);
                            })
                        }
                    >
                        {modificador ? (
                            <Tooltip title={getTextFlag(flag)}>
                                {getIconFlag(flag)}
                            </Tooltip>
                        ) : (
                            <SearchIcon sx={{ fontSize: 20 }} />
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
};

const procesaValor = (value, type) => {
    if (value.includes('.')) {
        switch (type) {
            case 'text':
                const rango = value.split('*');
                let newValue = '';

                rango.forEach(
                    s =>
                        (newValue = newValue + '*' + procesaSubcuenta(s.trim()))
                );

                return newValue.substring(1);

            case 'number':
                return value; //value.replace('.',',')

            default:
                return value;
        }
    }

    return value;
};

const procesaSubcuenta = value => {
    const partes = value.split('.');

    if (partes.length > 1) {
        if (partes[1] !== '') {
            const sufijo = partes[1].split(' ')[0];
            const cadena = `${partes[0]}${'0'.repeat(
                10 - partes[0].length - sufijo.length
            )}${sufijo}`;

            return cadena;
        }
    }

    return value;
};

export default FilterText;
