import React, { useState } from 'react';

import { makeStyles } from '../library';

import { Avatar, Auth, Chip, Entity, Typography, Stack } from '../library';

import * as hlprs from '../helpers';

import { Detail, Element } from '../Crud';

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginRight: '4px'
        //marginTop:'10px',
        //padding:'4px',
        //display: 'block',

        //width: '100%',
        //height: '100%',
        /*
        '& > *': {
            marginBottom: '4px',
        },*/
    },
    hlpText: {
        marginLeft: '8px'
    }
}));

const Tag = props => {
    const classes = useStyles();
    const {
        id,
        avatar,
        Icon,
        hidden,
        helperText,
        value,
        relacionado,
        refresh,
        align,
        ...rest
    } = props;

    const [detail, setDetail] = useState(null);

    const openDetail = () => {
        const key = Date.now();

        setDetail({
            key: key,
            id: id,
            title: relacionado.title ? relacionado.title : helperText,
            resume: relacionado.resume,
            api: relacionado.api + '/' + id,
            container: Entity(Auth(Element, 'auth'), 'entity', key),
            element: relacionado.Detail
        });
    };

    const editDetail = (key, cancel, id, isEditable, entity) => {
        if (!isEditable) {
            if (!cancel) {
                // Refresco el elemento al que pertenezco y su padre
                refresh && refresh(false);

                // Cierro el dialogo
                setDetail(null);
            }
        }
    };

    const closeDetail = () => {
        // Refresco el elemento al que pertenezco y su padre
        refresh && refresh(false);

        setDetail(null);
    };

    if (hidden) return null;

    return [
        <Stack
            sx={{
                height: '100%'
                //margin: '0px 10px 0px 10px'
            }}
            direction='row'
            alignItems='center'
            justifyContent={align ? align : 'start'}
        >
            <Chip
                {...rest}
                label={value}
                avatar={
                    avatar || !Icon ? (
                        <Avatar src={avatar}>{hlprs.getInitials(value)}</Avatar>
                    ) : (
                        <Icon />
                    )
                }
                onClick={relacionado && value && openDetail}
            />
            <br />
            {helperText && (
                <Typography className={classes.hlpText} variant='caption'>
                    {helperText}
                </Typography>
            )}
        </Stack>,
        <Detail
            maxWidth={
                relacionado && relacionado.maxWidth && relacionado.maxWidth
            }
            item={detail}
            open={detail !== null}
            close={closeDetail}
            edit={editDetail}
            readOnly={relacionado && relacionado.readOnly}
        />
    ];
};

export default Tag;
