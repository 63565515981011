import React, { useEffect, useState } from 'react';
import { Auth, Entity, makeStyles } from '../library';
import { Detail, Element } from '../Crud';

const ViewEntity = props => {
    const {
        lite,
        entity,
        id,
        open,
        maxWidth,
        readOnly,
        initValue,
        onOpen,
        onClose,
        onChange,
        refresh,
        children
    } = props;
    const [detail, setDetail] = useState(null);

    useEffect(() => {
        if (open) openDetail();
    }, [open]);

    // Esta es la version de DAtGrifPro con las mejoras despues hay que probarlo para el tag, text, combo, etc
    const openDetail = () => {
        const key = Date.now();
        const newId = id ? id : 'new';

        setDetail({
            related: true,
            key: key,
            id: newId,
            entityData: entity,
            title: entity.title,
            resume: entity.resume,
            api: `${entity.api}${newId}`,
            container: Entity(Auth(Element, 'auth'), 'entity', key),
            element: children,
            dfault: id === 'new' && initValue ? initValue : undefined
            //list: rows // Mirar a ver donde se usaba porque no se si es necesario
        });

        onOpen && onOpen();
    };

    const closeDetail = () => {
        refresh && refresh(false);
        setDetail(null);
        onClose && onClose();
    };

    const editDetail = (key, cancel, id, isEditable, entity) => {
        onChange && onChange(entity);
    };

    const deleteDetail = id => {
        //HACE FALTA CREAR UN onDelete

        // Cierro el diálogo
        setDetail(null);
    };

    return (
        <Detail
            lite={lite}
            maxWidth={maxWidth}
            item={detail}
            open={detail !== null}
            close={closeDetail}
            edit={editDetail}
            del={deleteDetail}
            readOnly={readOnly}
        />
    );
};

export default ViewEntity;
