import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { Grid, Divider, Chip, Stack } from 'core/components/library';

import {
    Select,
    DataGridPro as DataGrid,
    Text,
    Combo,
    Button,
    Tag
} from 'core/components';

import * as helpers from 'core/helpers';
import * as enums from 'core/enums';

import DetailVencimiento from 'views/tesoreria/vencimientos/Detalle';
import * as icons from '../../../../../../core/icons';
import List from '../../../../../../store/List';

const ComboVencimientos = List(Combo, 'options', 'lstVencimientos');

const calculaImportes = (field, newValue, values) => {
    const valores = {
        importe:
            values.importe && values.importe !== ''
                ? Number(values.importe)
                : 0,
        gastos:
            values.gastos && values.gastos !== '' ? Number(values.gastos) : 0,
        recargos:
            values.recargos && values.recargos !== ''
                ? Number(values.recargos)
                : 0,
        intereses:
            values.intereses && values.intereses !== ''
                ? Number(values.intereses)
                : 0
    };

    valores[field] = newValue[0].value !== '' ? Number(newValue[0].value) : 0;

    const total =
        valores.importe + valores.gastos + valores.recargos + valores.intereses;

    newValue.push({ field: 'total', value: total.toFixed(2) });

    return newValue;
};

const getFiltroVencimientos = values => {
    const filtro = [];

    filtro.push(['entrada'], [values.entrada]);
    //values.metodoPago && filtro.push(['metodoPago'],[values.metodoPago])

    return filtro;
};

const validaVencimiento = (handleChange, values, name, index) => {
    const newValues = values;

    /*
    newValues.push({
        field:'subcuenta',
        value: values[0].value.selected.subcuenta
    })*/

    newValues.push({
        field: 'importe',
        value: values[0].value.selected.pendiente
    });

    newValues.push({
        field: 'total',
        value: values[0].value.selected.pendiente
    });

    //console.log(values,name,index)

    handleChange(newValues, name, index);
};

const cols = (name, row, index, editing, handleChange, refresh, values) => [
    /*{
        key: 'tipo',
        title: 'Tipo',
        align: 'left',
        component: row && row.vencimiento && (
            <>
                {row.vencimiento.tipo}
            </>
        )
    },*/
    {
        key: 'vencimiento',
        title: 'Vencimiento',
        align: 'left',
        width: '42%',
        component: row && (
            <ComboVencimientos
                fullWidth
                margin='none'
                variant='standard'
                api='vencimientos/pendientes'
                name='vencimiento'
                nameKey='vencimientoId'
                relacionado={{
                    Detail: DetailVencimiento,
                    resume: ['concepto'],
                    readOnly: true
                }}
                refresh={refresh}
                Icon={icons.VencimientosIcon}
                value={row.vencimiento}
                onChange={
                    handleChange &&
                    (values =>
                        validaVencimiento(handleChange, values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option => `${option.concepto}`}
                //filtro={getFiltroVencimientos(values)}
            />
        )
    },
    /*{
        key: 'librado',
        title: 'Librado',
        align: 'left',
        icon: icons.ProveedoresIcon,
        component: row && row.vencimiento && row.vencimiento.proveedor && (
            <>
                <Stack alignItems={'center'} direction={'row'}>
                    <icons.ProveedoresIcon/>
                    {`[${row.vencimiento.proveedor.nif}]-
                        ${row.vencimiento.proveedor.nombre}
                        ${row.subcuenta && false ? ('-' + row.subcuenta.id) : ''}
                        `}
                </Stack>
            </>
        )
    },*/
    {
        key: 'metodoPago',
        title: 'Metodo',
        align: 'left',
        component: row && row.vencimiento && <>{row.vencimiento.metodoPago}</>
    },
    {
        key: 'fechaVencimiento',
        title: 'Vencimiento',
        align: 'center',
        component: row && row.vencimiento && (
            <>
                {DateTime.fromISO(
                    row.vencimiento.fechaVencimiento
                ).toLocaleString(DateTime.DATE_SHORT)}
            </>
        )
    },
    {
        key: 'pendiente',
        title: 'Pendiente €',
        align: 'left',
        width: '7%',
        component: row && row.vencimiento && (
            <>{helpers.getNumberFormatted(row.vencimiento.pendiente, 2)}</>
        )
    },
    {
        key: 'importe',
        title: 'Asignado €',
        align: 'left',
        width: '7%',
        component: row && (
            <Text
                autoSelect
                fullWidth
                editing={editing}
                name='importe'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['importe']}
                autoselect={true}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(
                            calculaImportes('importe', values, row),
                            name,
                            index
                        ))
                }
            />
        )
    },
    {
        key: 'gastos',
        title: 'Gastos €',
        align: 'left',
        width: '7%',
        component: row && (
            <Text
                autoSelect
                fullWidth
                editing={editing}
                name='gastos'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['gastos']}
                autoselect={true}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(
                            calculaImportes('gastos', values, row),
                            name,
                            index
                        ))
                }
            />
        )
    },
    {
        key: 'recargos',
        title: 'Recargos €',
        align: 'left',
        width: '7%',
        component: row && (
            <Text
                autoSelect
                fullWidth
                editing={editing}
                name='recargos'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['recargos']}
                autoselect={true}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(
                            calculaImportes('recargos', values, row),
                            name,
                            index
                        ))
                }
            />
        )
    },
    {
        key: 'intereses',
        title: 'Intereses €',
        align: 'left',
        width: '7%',
        component: row && (
            <Text
                autoSelect
                fullWidth
                editing={editing}
                name='intereses'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['intereses']}
                autoselect={true}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(
                            calculaImportes('intereses', values, row),
                            name,
                            index
                        ))
                }
            />
        )
    },
    {
        key: 'total',
        title: 'Importe €',
        align: 'left',
        width: '7%',
        component: row && <>{helpers.getNumberFormatted(row.total, 2)}</>
    }
];

const Vencimientos = props => {
    const {
        values,
        editing,
        adding,
        handleChange,
        refresh,
        reload,
        refreshing,
        execAction
    } = props;

    useEffect(() => {
        if (values.vencimientos) {
            actualizaTotales(values.vencimientos);
        }
    }, [JSON.stringify(values.vencimientos)]);

    const actualizaTotales = vencimientos => {
        let total = 0;

        vencimientos.forEach(vencimiento => {
            total = total + Number(vencimiento.total);
        });

        handleChange([
            helpers.getValueControl('text', 'importe', total.toFixed(2))
        ]);
    };

    const action = () => {
        const accion = {
            api: 'tesoreria/movimientos/vencimientos',
            metodo: 'post',
            data: { ...values }
        };
        execAction(accion, [reload]);
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs>
                    <DataGrid
                        name='vencimientos'
                        editing={editing}
                        rows={values.vencimientos ? values.vencimientos : []}
                        cols={(name, row, index) =>
                            cols(
                                name,
                                row,
                                index,
                                editing,
                                handleChange,
                                refresh,
                                values
                            )
                        }
                        onAdd={name =>
                            handleChange(
                                {
                                    fecha: values.fechaCargo,
                                    gastos: (0).toFixed(2),
                                    recargos: (0).toFixed(2),
                                    intereses: (0).toFixed(2),
                                    importe: (0).toFixed(2),
                                    total: (0).toFixed(2)
                                },
                                name
                            )
                        }
                        onDelete={(name, index) =>
                            handleChange(undefined, name, (index + 1) * -1)
                        }
                        minHeight={250}
                        maxHeight={250}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Divider style={{ margin: '12px 12px 12px 12px' }}>
                        <Chip label={'TOTALES'} />
                    </Divider>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Button
                        toolTipText='Buscar Vencimientos'
                        disabled={!editing}
                        onClick={event => action()}
                        counter={0}
                        busy={refreshing}
                        icon={<icons.BuscarIcon />}
                        float={true}
                    />
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs></Grid>
                <Grid item xs></Grid>
                <Grid item xs>
                    <Text
                        autoSelect
                        fullWidth
                        type='d.2'
                        name='importe'
                        label='Importe €'
                        value={values.importe}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Vencimientos;
