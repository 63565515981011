import React, { useState } from 'react';

import { Grid } from 'core/components/library';
import { Text, DataGridPro as DataGrid } from 'core/components';
import * as hlprs from '../../../../../../core/components/helpers';

const cols = (name, row, index, editing, handleChange) => [
    {
        key: 'documento',
        title: 'Documento',
        align: 'left',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='documento'
                variant='standard'
                margin='none'
                type='text'
                value={row['documento']}
                onFocus={event => event.target.select()}
                onChange={chngs => handleChange(chngs, name, index)}
            />
        )
    }
];
const Detalles = props => {
    const {
        values,
        editing,
        adding,
        initial,
        handleChange,
        refresh,
        estableceFiltro
    } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs>
                <Text
                    fullWidth
                    multiline
                    rows={10}
                    name='detalles'
                    label='Dealles'
                    value={values.detalles}
                    onChange={handleChange}
                    editing={editing}
                />
            </Grid>
            <Grid item xs>
                <DataGrid
                    title='Documentación Requerida'
                    name='documentacion'
                    editing={editing}
                    rows={values.documentacion ? values.documentacion : []}
                    cols={(name, row, index) =>
                        cols(name, row, index, editing, handleChange)
                    }
                    onAdd={name => handleChange({}, name)}
                    onDelete={(name, index) =>
                        handleChange(undefined, name, (index + 1) * -1)
                    }
                    minHeight={250}
                    maxHeight={250}
                />
            </Grid>
        </Grid>
    );
};

export default Detalles;
