import { Suspense } from 'react';

// project imports
import Loader from '../../templates/Berry/ui-component/Loader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = Component => props => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;
