import React, { useState } from 'react';

import { makeStyles } from '../library';
import { Text, Document } from '../';

import { DataGridPro as DataGrid } from '../';

import * as hlprs from '../helpers';
import { unmountComponentAtNode } from 'react-dom';

const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headRow: {},
    row: {},
    headCell: {
        padding: '0px',
        paddingRight: '4px'
    },
    cell: {
        padding: '0px',
        paddingRight: '4px',
        paddingTop: '4px',
        paddingBottom: '4px'
    },
    actionHeadCell: {
        padding: '0px'
    }
}));

const cols = (name, row, index, editing, handleChange) => [
    {
        key: 'enlace',
        title: 'Documento',
        align: 'center',
        width: '8%',
        component: row && (
            <Document
                name='enlace'
                readOnly={!editing}
                width='50px'
                height='50px'
                value={row['enlace']}
                onChange={chngs => handleChange(chngs, name, index)}
            />
        )
    },
    {
        key: 'resumen',
        title: 'Resumen',
        align: 'left',
        width: '70%',
        component: row && (
            <Text
                fullWidth
                name='resumen'
                variant='standard'
                //margin='none'
                value={row['resumen']}
                onChange={chngs => handleChange(chngs, name, index)}
                editing={editing}
            />
        )
    },
    {
        key: 'fecha',
        title: 'Fecha',
        align: 'left',
        width: '22%',
        component: row && (
            <Text
                fullWidth
                name='fecha'
                variant='standard'
                //margin='none'
                type='datetime-local'
                value={hlprs.getValueFormatted('datetime-local', row['fecha'])}
                onChange={chngs => handleChange(chngs, name, index)}
                editing={editing}
            />
        )
    }
];

const Documents = props => {
    const { name, editing, rows, onChange, maxHeight, dateBase, adjust } =
        props;

    const handleChange = (chngs, name, index) => {
        if (onChange) {
            let newChanges = chngs;

            if (name !== 'fecha') {
                if (!rows[index].fecha) {
                    const fecha = dateBase ? dateBase : hlprs.now();
                    newChanges = chngs.concat({
                        field: 'fecha',
                        value: fecha
                    });
                }
            }

            onChange(newChanges, name, index);
        }
    };

    return (
        <DataGrid
            name={name}
            editing={editing}
            rows={rows ? rows : []}
            cols={(name, row, index) =>
                cols(name, row, index, editing, handleChange)
            }
            onAdd={name => onChange({ fecha: hlprs.now() }, name)}
            onDelete={(name, index) =>
                onChange(undefined, name, (index + 1) * -1)
            }
            maxHeight={maxHeight}
        />
    );
};

export default Documents;
