import React from 'react';

import { makeStyles } from '../library';
import { MenuItem, TextField, Typography } from '../library';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-star',
        alignItems: 'center',
        '& > *': {
            marginRight: theme.spacing(1)
        }
    }
}));

const Select = props => {
    const classes = useStyles();
    const {
        editing,
        options,
        onChange,
        margin,
        variant,
        fullWidth,
        value,
        ...rest
    } = props;

    return (
        <TextField
            {...rest}
            // LO DE PONER EL VALUE ASI ES PORQUE SI NO FALLA CUANDO ES UNDEFINED NO QUITAR
            value={value === undefined ? null : value}
            select
            fullWidth={fullWidth}
            margin={margin ? margin : 'dense'}
            size='small'
            variant={variant ? variant : 'outlined'}
            onChange={event =>
                onChange([
                    {
                        field: event.target.name,
                        value: event.target.value
                    }
                ])
            }
            InputLabelProps={{ shrink: true }}
            InputProps={{
                readOnly: !editing
            }}
        >
            {options.map(item => {
                const Icon = item.icon;

                return (
                    <MenuItem value={item.value}>
                        <div
                            className={classes.wrapper}
                            style={{ color: item.color }}
                        >
                            <Icon fontSize='small' />
                            <Typography color='inherit'>
                                {item.label}
                            </Typography>
                        </div>
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default Select;
