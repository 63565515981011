// DEPRECATED LO NECEISTO PARA EL COMPONENTE REPORT
import { React, useEffect, useState } from 'react';
import { PDFViewer, usePDF } from '@react-pdf/renderer';
import Report from './Report';
import * as helpers from 'core/helpers';

const ReportView = props => {
    const { ...rest } = props;

    const [report, setReport] = useState(null);
    const [data, setData] = useState(null);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        const key = window.location.pathname;
        const aux = getReport(key);

        setReport(aux.report);
        setData(aux.data);
        if (aux.report.groups)
            setGroups(getGroups(aux.data.list, aux.report.groups));
    }, []);

    //const [instance, updateInstance] = usePDF({ document: myReport });

    if (!data || !report) {
        return null;
    }

    //console.log(groups);

    //if (instance.loading) return <div>Loading ...</div>;
    //if (instance.error) return <div>Something went wrong:</div>;

    const myReport = <Report report={report} data={data} groups={groups} />;

    return (
        <div style={{ minHeight: '100vh' }}>
            <PDFViewer
                showToolbar={true}
                style={{ width: '100%', height: '98vh' }}
            >
                {myReport}
            </PDFViewer>
        </div>
    );
};

export default ReportView;

const getReport = key => {
    return JSON.parse(sessionStorage.getItem(key));
};

const getGroups = (data, groups) => {
    let newGroups = [];

    groups.forEach(group => {
        // Calculo los valores
        let values = [];
        data.forEach(item => {
            const value = helpers.getValue(item, group.field.split('.'));

            if (!values.includes(value)) {
                values.push(value);
            }
        });
        values.sort();
        newGroups.push({ ...group, values: values });
    });

    console.log(newGroups);
    return newGroups;
};

export const print = (report, data) => {
    const key = `/report/${report.key}`;
    sessionStorage.setItem(
        key,
        JSON.stringify({
            report: report,
            data: data
        })
    );

    window.open(key);
};
