import React, { useEffect } from 'react';

import { Divider, Grid } from 'core/components/library';

import { Text, Button, Select, Switch, Tabs, Documents } from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';

import Detalles from './Tabs/Detalles';

const Detalle = props => {
    const { values, editing, handleChange } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item>
                    <Text
                        type='subcuenta'
                        required={true}
                        fullWidth
                        name='id'
                        label='Cuenta'
                        value={values.id}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='descripcion'
                        label='Decricpción'
                        value={values.descripcion}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Switch
                        value={values.bloqueada}
                        onChange={handleChange}
                        name='bloqueada'
                        label='Bloqueada'
                        editing={editing}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Text
                        fullWidth
                        name='debe'
                        type={'d.2'}
                        label='Debe'
                        value={values.debe}
                        editing={false}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Text
                        fullWidth
                        name='haber'
                        type={'d.2'}
                        label='Haber'
                        value={values.haber}
                        editing={false}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Text
                        fullWidth
                        name='saldo'
                        type={'d.2'}
                        label='Saldo'
                        value={values.saldo}
                        editing={false}
                    />
                </Grid>
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Detalles',
                        icon: icons.DetailIcon,
                        component: <Detalles {...props} />
                    }
                ]}
            />
        </form>
    );
};

export default Detalle;
