import React, { useEffect } from 'react';
import { DateTime } from 'luxon';

import { Divider, Grid } from 'core/components/library';
import {
    Text,
    Button,
    Select,
    Switch,
    Tabs,
    Combo,
    Documents,
    Tag,
    Emit
} from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import * as helpers from 'core/helpers';

import List from 'store/List';

import Etiquetas from './Tabs/TabEtiquetas';
import Detalles from './Tabs/TabDetalles';
import Parcelas from './Tabs/TabParcelas';
import Bloqueos from './Tabs/TabBloqueos';
import Tareas from './Tabs/TabTareas';
import Actas from './Tabs/TabActas';
import Firmas from './Tabs/TabFirmas';
import Resoluciones from './Tabs/TabResoluciones';
import Liquidaciones from './Tabs/TabLiquidaciones';
import Bodegas from './Tabs/TabBodegas';
import Cosecha from './Tabs/TabCosecha';

import DetailUsuario from 'views/usuarios/Detalle';
import DetailTipo from 'views/tramites/Tipos/Detalle';
import DetailBodega from 'views/maestros/Bodegas/Detalle';
import DetailExplotacion from 'views/maestros/Explotaciones/Detalle';
import DetailMarca from 'views/maestros/Marcas/Detalle';
import DetailViticultor from 'views/maestros/Viticultores/Detalle';
import DetailSocio from 'views/maestros/Socios/Detalle';
import DetailCliente from 'views/ventas/Clientes/Detalle';

import { statesEntity } from 'core/enums';
import * as hlprs from '../../../../core/components/helpers';
import {
    ENTITY_BODEGA,
    ENTITY_EXPLOTACION_BODEGA,
    ENTITY_MARCA,
    ENTITY_VITICULTOR,
    ENTITY_CLIENTE,
    ENTITY_SOCIO
} from '../../../../store/entities/constants';
import DetailMencion from '../../../entorno/Menciones/Detalle';

const ComboTipos = List(Combo, 'options', 'lstTiposTramite');
const ComboBodegas = List(Combo, 'options', 'lstbodegas');
const ComboCentros = List(Combo, 'options', 'lstcentros');
const ComboMarcas = List(Combo, 'options', 'lstmarcas');
const ComboEtiquetas = List(Combo, 'options', 'lstetiquetas');
const ComboViticultores = List(Combo, 'options', 'lstviticultores');
const ComboSocios = List(Combo, 'options', 'lstsocios');
const ComboClientes = List(Combo, 'options', 'lstclientes');
//const ComboParcelas = List(Combo, 'options', 'lstparcelas');

const getClase = (tipo, prop) => {
    if (tipo && tipo.clase === 'Solicitud') {
        if (prop === 'icon') return icons.SolicitudesIcon;
        return tipo.clase;
    }

    if (tipo && tipo.clase === 'Declaracion') {
        if (prop === 'icon') return icons.DeclaracionesIcon;
        return tipo.clase;
    }

    if (tipo && tipo.clase === 'Inspeccion') {
        if (prop === 'icon') return icons.InspeccionesIcon;
        return tipo.clase;
    }

    if (prop === 'icon') return icons.TramitesIcon;
    return 'Tramite';
};

const getDetalle = (tipo, props) => {
    if (!tipo) return null;
    if (!tipo.vista) return null;

    switch (tipo.vista.toLowerCase()) {
        case 'formato':
            return <Etiquetas {...props} />;

        case 'parcelas':
            return <Parcelas {...props} />;

        case 'bodegas':
            return <Bodegas {...props} />;

        case 'bloqueos':
            return <Bloqueos {...props} />;

        case 'cosecha':
            return <Cosecha {...props} />;

        default:
            return null;
    }
};

const getConcepto = values => {
    let newConcepto = values.tipo ? values.tipo.descripcion : '';
    let newEntidad = '';

    if (values.viticultor) newEntidad = values.viticultor.nombre;
    if (values.bodega) {
        newEntidad = values.bodega.nombre;
    }

    if (values.explotacion) {
        if (newEntidad !== '') newEntidad += ' a ';
        newEntidad += `${values.explotacion.nombre}-[${values.explotacion.bodega.nombre}]`;
    }

    return `${newConcepto} de ${newEntidad}`;
};

const getTipoRepresentante = values => {
    if (
        values.tipo &&
        values.tipo.clase === 'Declaracion' &&
        values.tipo.produccion
    ) {
        if (values.explotacion?.bodega?.formaJuridica?.opfh) {
            return 'Bodega';
        } else {
            return 'Viticultor';
        }
    }

    if (values.tipo && values.viticultor) return 'Viticultor';

    if (values.tipo && (values.bodega || values.explotacion)) return 'Bodega';

    return null;
};

const getRepresentante = contactos => {
    console.log(contactos);
    const representante = contactos.find(contacto => {
        let ok = true;

        if (contacto.tipo !== 'Representante' && contacto.tipo !== 'Autorizado')
            ok = false;

        return ok;
    });

    return representante;
};

let timeBoxCodigo = 0;

const Detalle = props => {
    const {
        values,
        editing,
        handleChange,
        handleError,
        cleanField,
        refresh,
        refreshing,
        updating,
        reload,
        execAction,
        adding
    } = props;

    useEffect(() => {
        if (editing && adding) {
            const changes = [];

            if (values.tipo) {
                if (values.tipo.tareas.length === 0) {
                    changes.push(
                        helpers.getValueControl('text', 'estado', 'Finalizado')
                    );
                }

                changes.push(
                    helpers.getValueControl(
                        'text',
                        'resolucion',
                        values.tipo.resolucion ? 'Por Resolver' : null
                    )
                );

                changes.push(
                    helpers.getValueControl(
                        'text',
                        'liquidacion',
                        !values.tipo.liquidacion
                            ? 'No Liquidable'
                            : values.tipo.resolucion
                              ? 'No Liquidable'
                              : 'Por Liquidar'
                    )
                );
            } else {
                changes.push(
                    helpers.getValueControl('text', 'estado', 'Pendiente')
                );

                changes.push(
                    helpers.getValueControl('text', 'resolucion', null)
                );

                changes.push(
                    helpers.getValueControl(
                        'text',
                        'liquidacion',
                        'No Liquidable'
                    )
                );
            }

            changes.push(
                helpers.getValueControl('text', 'concepto', getConcepto(values))
            );

            changes.push(
                helpers.getValueControl(
                    'text',
                    'representaA',
                    getTipoRepresentante(values)
                )
            );

            handleChange(changes);
        }
    }, [JSON.stringify(values.tipo)]);

    useEffect(() => {
        if (editing) {
            const changes = [];
            changes.push(
                helpers.getValueControl('text', 'concepto', getConcepto(values))
            );

            changes.push(
                helpers.getValueControl(
                    'text',
                    'representaA',
                    getTipoRepresentante(values)
                )
            );
            /*
            if (values.viticultor)
                changes.push(
                    helpers.getValueControl(
                        'text',
                        'representante',
                        getRepresentante(values.viticultor.contactos)
                    )
                );

            if (values.bodega)
                changes.push(
                    helpers.getValueControl(
                        'text',
                        'representante',
                        getRepresentante(values.bodega.contactos)
                    )
                );*/

            handleChange(changes);
        }
    }, [
        JSON.stringify(values.viticultor),
        JSON.stringify(values.bodega),
        JSON.stringify(values.explotacion)
    ]);

    const actualizaCodigo = () => {
        if (editing) {
            const action = () => {
                const accion = {
                    api: 'tramites/nexts',
                    metodo: 'post',
                    data: {
                        fields: ['codigo'],
                        values: { ...values }
                    }
                };
                execAction(accion, [reload]);
            };

            timeBoxCodigo > 0 && clearTimeout(timeBoxCodigo);
            timeBoxCodigo = setTimeout(action, 250);
        }
    };

    const liquida = () => {
        const accion = {
            api: 'tramites/liquidacion',
            metodo: 'post',
            data: { ...values }
        };
        execAction(accion, [() => refresh(false)]);
    };

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Tag
                        sx={{ marginTop: '10px' }}
                        hidden={!values.propietario}
                        value={values.propietario && values.propietario.nombre}
                        id={values.propietario && values.propietario.id}
                        avatar={
                            values.propietario &&
                            helpers.getAvatar(
                                values.propietario.id,
                                values.propietario.avatar
                            )
                        }
                        relacionado={{
                            Detail: DetailUsuario,
                            resume: ['nombre'],
                            api: 'usuarios',
                            readOnly: true
                        }}
                        refresh={refresh}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        required
                        fullWidth
                        name='fecha'
                        label='Fecha'
                        value={hlprs.getValueFormatted(
                            'datetime-local',
                            values.fecha
                        )}
                        type='datetime-local'
                        onChange={handleChange}
                        onError={handleError}
                        editing={adding}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        required
                        fullWidth
                        name='codigo'
                        label='Código'
                        value={values.codigo}
                        onChange={handleChange}
                        onError={handleError}
                        editing={adding}
                        type={`y${DateTime.fromISO(values.fecha).year}.5`}
                        nextValue={adding && actualizaCodigo}
                    />
                </Grid>
                <Grid item>
                    <Emit
                        disabled={false}
                        hidden={editing}
                        toolTipText={'Emitir Solicitud'}
                        forms={
                            values.tipo && values.tipo.formularios
                                ? helpers.getForms(
                                      values.tipo.formularios,
                                      'Solicitud'
                                  )
                                : []
                        }
                        values={values}
                    />
                </Grid>
                <Grid item xs />
                {/*<Grid item>
                    <Emit
                        disabled={false}
                        hidden={editing}
                        toolTipText={'Emitir Resolución'}
                        formularios={
                            values.tramite &&
                            values.tramite.tipo.formularios.filter(
                                f => f.tipo === 'Solicitud'
                            )
                        }
                        values={values}
                    />
                </Grid>*/}

                <Grid item xs>
                    <Tag
                        align={'center'}
                        size={'medium'}
                        sx={{
                            color: enums.getItem(
                                enums.statesExecution,
                                values.estado,
                                'color'
                            )
                        }}
                        Icon={enums.getItem(
                            enums.statesExecution,
                            values.estado,
                            'icon'
                        )}
                        value={values.estado}
                    />
                </Grid>
                {values && values.tipo && values.tipo.resolucion && (
                    <Grid item xs>
                        <Tag
                            align={'center'}
                            size={'medium'}
                            sx={{
                                color: enums.getItem(
                                    enums.statesResolution,
                                    values.resolucion,
                                    'color'
                                )
                            }}
                            Icon={enums.getItem(
                                enums.statesResolution,
                                values.resolucion,
                                'icon'
                            )}
                            value={values.resolucion}
                        />
                    </Grid>
                )}
                {values && values.tipo && values.tipo.liquidacion && (
                    <>
                        <Grid item xs>
                            <Tag
                                align={'center'}
                                size={'medium'}
                                sx={{
                                    color: enums.getItem(
                                        enums.statesLiquidation,
                                        values.liquidacion,
                                        'color'
                                    )
                                }}
                                Icon={enums.getItem(
                                    enums.statesLiquidation,
                                    values.liquidacion,
                                    'icon'
                                )}
                                value={values.liquidacion}
                            />
                        </Grid>
                        {values.liquidacion === 'Por Liquidar' && (
                            <Grid item xs>
                                <Button
                                    toolTipText='Liquida Tramite'
                                    disabled={editing}
                                    onClick={event => liquida()}
                                    counter={0}
                                    busy={refreshing}
                                    icon={<icons.LiquidacionesIcon />}
                                    float={true}
                                />
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={1}>
                    <Select
                        fullWidth
                        name='campaña'
                        label='Campaña'
                        value={values.campaña}
                        onChange={handleChange}
                        editing={adding}
                        options={enums.campañas}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ComboTipos
                        required={true}
                        fullWidth
                        api='tipostramite'
                        name='tipo'
                        nameKey='tipoid'
                        label='Tipo'
                        relacionado={{
                            Detail: DetailTipo,
                            resume: ['descripcion'],
                            readOnly: true
                        }}
                        refresh={refresh}
                        Icon={icons.DefinicionIcon}
                        value={values.tipo}
                        onChange={handleChange}
                        onError={handleError}
                        editing={adding}
                        propertyValue='id'
                        getOptionLabel={option => `${option.descripcion}`}
                        searchKeys={['descripcion']}
                    />
                </Grid>
            </Grid>
            {values.tipo &&
                (values.tipo.socio ||
                    (values.tipo.parcela && !values.tipo.bloqueo)) && (
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <ComboSocios
                                required
                                fullWidth
                                api='maestros/socios'
                                name='socio'
                                nameKey='socioid'
                                label='Socio'
                                relacionado={{
                                    type: ENTITY_SOCIO,
                                    Detail: DetailSocio,
                                    resume: ['nombre'],
                                    readOnly: false
                                }}
                                refresh={refresh}
                                Icon={icons.SociosIcon}
                                value={values.socio}
                                onChange={handleChange}
                                onError={handleError}
                                editing={adding}
                                propertyValue='id'
                                getOptionLabel={option =>
                                    `${option.nombre}-[${option.codigo}]-[${option.nif}]-[${option.estado}]`
                                }
                                filters={[
                                    {
                                        fields: ['estado'],
                                        values: ['Activo', 'Borrador'],
                                        operator: 'in',
                                        type: 'text'
                                    }
                                ]}
                                searchKeys={[
                                    'codigo',
                                    'nombre',
                                    'nif',
                                    'estado'
                                ]}
                            />
                        </Grid>
                    </Grid>
                )}
            {values.tipo && values.tipo.cliente && (
                <Grid container spacing={1}>
                    <Grid item xs>
                        <ComboClientes
                            required
                            fullWidth
                            api='clientes'
                            name='cliente'
                            nameKey='clienteid'
                            label='Cliente'
                            relacionado={{
                                type: ENTITY_CLIENTE,
                                Detail: DetailCliente,
                                resume: ['nombre'],
                                readOnly: false
                            }}
                            refresh={refresh}
                            Icon={icons.ClientesIcon}
                            value={values.cliente}
                            onChange={handleChange}
                            onError={handleError}
                            editing={adding}
                            propertyValue='id'
                            getOptionLabel={option =>
                                `${option.nombre}-[${option.codigo}]-[${option.nif}]`
                            }
                            searchKeys={['codigo', 'nombre', 'nif']}
                        />
                    </Grid>
                </Grid>
            )}

            {values.tipo &&
                (values.tipo.viticultor ||
                    (values.tipo.parcela && !values.tipo.bloqueo)) && (
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <ComboViticultores
                                required
                                fullWidth
                                api='viticultores'
                                name='viticultor'
                                nameKey='viticultorid'
                                label='Viticultor'
                                relacionado={{
                                    type: ENTITY_VITICULTOR,
                                    Detail: DetailViticultor,
                                    resume: ['nombre'],
                                    readOnly: false
                                }}
                                refresh={refresh}
                                Icon={icons.ViticultoresIcon}
                                value={values.viticultor}
                                onChange={handleChange}
                                onError={handleError}
                                editing={adding}
                                propertyValue='id'
                                getOptionLabel={option =>
                                    `${option.nombre}-[${option.codigo}]-[${option.nif}]-[${option.estado}]`
                                }
                                filters={[
                                    {
                                        fields: ['estado'],
                                        values: ['Activo', 'Borrador'],
                                        operator: 'in',
                                        type: 'text'
                                    }
                                ]}
                                searchKeys={[
                                    'codigo',
                                    'nombre',
                                    'nif',
                                    'estado'
                                ]}
                            />
                        </Grid>
                        <Grid item>
                            <Text
                                fullWidth
                                error={
                                    (!values.fechaRegistro ||
                                        (values.fechaRegistro &&
                                            DateTime.fromISO(
                                                values.fechaRegistro
                                            ) <
                                                DateTime.fromISO(
                                                    `${values.fecha ? DateTime.fromISO(values.fecha).year : DateTime.now().year}-06-01`
                                                ))) &&
                                    'Caducado'
                                }
                                name='fechaRegistro'
                                label='Fecha RV'
                                value={values.fechaRegistro}
                                type='date'
                                onChange={handleChange}
                                onError={handleError}
                                editing={adding}
                            />
                        </Grid>
                    </Grid>
                )}
            {values.tipo &&
                values.tipo.bodega &&
                !values.tipo.centro &&
                !values.tipo.marca && (
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <ComboBodegas
                                required
                                fullWidth
                                api='bodegas'
                                name='bodega'
                                nameKey='bodegaid'
                                label='Bodega'
                                relacionado={{
                                    type: ENTITY_BODEGA,
                                    Detail: DetailBodega,
                                    resume: ['nombre'],
                                    readOnly: false
                                }}
                                refresh={refresh}
                                Icon={icons.CellarIcon}
                                value={values.bodega}
                                onChange={handleChange}
                                onError={handleError}
                                editing={adding}
                                propertyValue='id'
                                getOptionLabel={option =>
                                    `${option.nombre}-[${option.nif}]`
                                }
                                searchKeys={['nombre', 'nif']}
                                filters={[
                                    {
                                        fields: ['estado'],
                                        values: ['Activo'],
                                        operator: 'in',
                                        type: 'text'
                                    }
                                ]}
                            />
                        </Grid>
                    </Grid>
                )}
            {values.tipo && values.tipo.centro && !values.tipo.etiqueta && (
                <Grid container spacing={1}>
                    <Grid item xs>
                        <ComboCentros
                            required
                            fullWidth
                            api='explotaciones'
                            name='explotacion'
                            nameKey='explotacionid'
                            label='Bodega / Centro Explotación'
                            relacionado={{
                                type: ENTITY_EXPLOTACION_BODEGA,
                                Detail: DetailExplotacion,
                                resume: ['nombre'],
                                readOnly: false
                            }}
                            refresh={refresh}
                            Icon={icons.SitesIcon}
                            value={values.explotacion}
                            onChange={handleChange}
                            onError={handleError}
                            editing={adding}
                            propertyValue='id'
                            getOptionLabel={option =>
                                `${option.codigo}-${option.nombre}-[${option.bodega.nombre}]`
                            }
                            searchKeys={['codigo', 'nombre', 'bodega.nombre']}
                            filters={[
                                {
                                    fields: ['estado'],
                                    values: ['Activo', 'Borrador'],
                                    operator: 'in',
                                    type: 'text'
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
            )}
            {values.tipo && false && (
                <Grid container spacing={1}>
                    <Grid item xs>
                        <ComboEtiquetas
                            fullWidth
                            api='etiquetasbodega'
                            name='etiqueta'
                            nameKey='marcaid'
                            label='Marca'
                            relacionado={{
                                type: ENTITY_MARCA,
                                Detail: DetailMarca,
                                resume: ['marca'],
                                readOnly: false
                            }}
                            refresh={refresh}
                            Icon={icons.BrandsIcon}
                            value={values.marca}
                            onChange={handleChange}
                            editing={adding}
                            propertyValue='id'
                            getOptionLabel={option => `${option.nombre}`}
                            searchKeys={['nombre']}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        required
                        fullWidth
                        name='concepto'
                        label='Concepto'
                        value={values.concepto}
                        onChange={handleChange}
                        onError={handleError}
                        editing={editing}
                    />
                </Grid>
            </Grid>

            {!adding && (
                <Tabs
                    tabs={[
                        {
                            label: getClase(values.tipo),
                            icon: getClase(values.tipo, 'icon'),
                            component: getDetalle(
                                values ? values.tipo : '',
                                props
                            )
                        },
                        {
                            label: 'Detalles',
                            icon: icons.DetailIcon,
                            component: <Detalles {...props} />
                        },
                        {
                            label: 'Documentos',
                            icon: icons.DocumentIcon,
                            component: (
                                <Documents
                                    dateBase={helpers.now(values.fecha)}
                                    name='documentacion'
                                    editing={editing}
                                    onChange={handleChange}
                                    rows={
                                        values.documentacion
                                            ? values.documentacion
                                            : []
                                    }
                                    //maxHeight={300}
                                />
                            )
                        },
                        {
                            label: 'Tareas',
                            icon: icons.TareaIcon,
                            component: <Tareas {...props} />,
                            hide:
                                values && values.tipo
                                    ? !values.tipo.seguimiento
                                    : true
                        },
                        {
                            label: 'Firmas',
                            icon: icons.FirmasIcon,
                            component: <Firmas {...props} />
                        },
                        {
                            label: 'Actas',
                            icon: icons.ActaIcon,
                            component: <Actas {...props} />,
                            hide:
                                values && values.tipo ? !values.tipo.acta : true
                        },
                        {
                            label: 'Resoluciones',
                            icon: icons.ResolucionesIcon,
                            component: <Resoluciones {...props} />,
                            hide:
                                values && values.tipo
                                    ? !values.tipo.resolucion
                                    : true
                        },
                        {
                            label: 'Liquidaciones',
                            icon: icons.LiquidacionesIcon,
                            component: <Liquidaciones {...props} />,
                            hide:
                                values && values.tipo
                                    ? !values.tipo.liquidacion
                                    : true
                        }
                    ]}
                />
            )}
        </form>
    );
};

export default Detalle;
