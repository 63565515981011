import React from 'react';
import { Grid } from 'core/components/library';
import Representantes from 'entorno/Representantes';

const Firmas = props => {
    const { values, editing, handleChange, refresh } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={2}>
                {/*<Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Grid item xs>
                            <Divider style={{ margin: '12px 12px 12px 12px' }}>
                                <Chip label={'REPRESENTANTES DOP'} />
                            </Divider>
                        </Grid>
                        <Grid item xs>
                            <DataGrid
                                name='intervinientes'
                                editing={editing}
                                rows={
                                    values.intervinientes
                                        ? values.intervinientes
                                        : []
                                }
                                cols={(name, row, index) =>
                                    cols(
                                        name,
                                        row,
                                        index,
                                        editing,
                                        handleChange,
                                        refresh
                                    )
                                }
                                onAdd={name => {
                                    handleChange({}, name);
                                }}
                                onDelete={(name, index) =>
                                    handleChange(
                                        undefined,
                                        name,
                                        (index + 1) * -1
                                    )
                                }
                                minHeight={350}
                                maxHeight={350}
                                //maxWidth={1000}
                            />
                        </Grid>
                    </Grid>
                </Grid>*/}
                <Grid item xs>
                    <Representantes
                        name={'intervinientes'}
                        title={'REPRESENTANTES DOP'}
                        values={values}
                        editing={editing}
                        handleChange={handleChange}
                        refresh={refresh}
                    />
                </Grid>
                <Grid item xs></Grid>
            </Grid>
        </form>
    );
};

export default Firmas;
