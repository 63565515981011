import { lazy } from 'react';
import Loadable from 'core/components/Loadable';

import { LAYOUT_MINIMAL } from '../core/constants';

const Login = Loadable(lazy(() => import('entorno/login')));

export const publicRoutes = layouts => [
    {
        path: '*',
        element: layouts[LAYOUT_MINIMAL],
        children: [
            {
                path: '*',
                element: <Login />
            }
        ]
    }
];
