import React from 'react';

import { makeStyles } from '../library';

import { AppBar, Tabs as MaterialTabs, Tab as MaterialTab } from '../library';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '15px',
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        minHeight: '320px'
    },
    tabPanel: {
        marginTop: '15px'
    }
}));

const TabPanel = props => {
    const { classes, children, value, index, ...other } = props;

    return (
        <div
            className={classes.tabPanel}
            role='tabpanel'
            hidden={value !== index}
            id={index}
            {...other}
        >
            {value === index && children}
        </div>
    );
};

const Tabs = props => {
    const { className, tabs, ...rest } = props;
    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar
                style={{ maxHeight: '65px' }}
                position='static'
                color='default'
            >
                <MaterialTabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                    {...rest}
                >
                    {tabs
                        .filter(tab => !tab.hide)
                        .map((tab, index) => (
                            <MaterialTab
                                wrapped
                                id={index}
                                label={tab.label}
                                icon={
                                    tab.icon ? (
                                        <tab.icon fontSize='small' />
                                    ) : undefined
                                }
                                disabled={tab.disabled && tab.disabled}
                            />
                        ))}
                </MaterialTabs>
            </AppBar>
            {tabs
                .filter(tab => !tab.hide)
                .map((tab, index) => (
                    <TabPanel classes={classes} value={value} index={index}>
                        {tab.component}
                    </TabPanel>
                ))}
        </div>
    );
};

export default Tabs;
