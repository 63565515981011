import React from 'react';
import { makeStyles, Grid } from 'core/components/library';
import { Text, Combo, Select } from 'core/components';

import * as icons from 'core/icons';

import DetailSubcuenta from 'views/contabilidad/subcuentas/Detalle';
import DetailFormaPago from 'views/tesoreria/formas-pago/Detalle';
import List from '../../../../../../store/List';
import * as enums from '../../../../../../core/enums';

const ComboSubcuentas = List(Combo, 'options', 'lstSubcuentas');
const ComboFormaPago = List(Combo, 'options', 'lstFormasPago');

const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const Detalles = props => {
    const {
        values,
        editing,
        adding,
        initial,
        handleChange,
        refresh,
        estableceFiltro
    } = props;
    const classes = useStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Grid container direction='column' spacing={1}>
                    <Grid item xs>
                        <ComboSubcuentas
                            fullWidth
                            api='subcuentas/activas'
                            name='subcuenta'
                            nameKey='subcuentaid'
                            label='Cuenta Contable'
                            relacionado={{
                                maxWidth: 'md',
                                Detail: DetailSubcuenta,
                                resume: ['descripcion'],
                                readOnly: false,
                                dfault: {
                                    id: '4000' + values.codigo,
                                    descripcion: values.nombre
                                }
                            }}
                            refresh={refresh}
                            Icon={icons.CuentasIcon}
                            value={values.subcuenta}
                            onChange={handleChange}
                            editing={editing}
                            propertyValue='id'
                            getOptionLabel={option =>
                                `${option.id} ${option.descripcion}`
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <ComboFormaPago
                            fullWidth
                            api='formaspago'
                            name='formaPago'
                            nameKey='formaPagoid'
                            label='Forma de Cobro habitual'
                            relacionado={{
                                maxWidth: 'md',
                                Detail: DetailFormaPago,
                                resume: ['descripcion'],
                                readOnly: false
                            }}
                            refresh={refresh}
                            Icon={icons.FormasPagoIcon}
                            value={values.formaPago}
                            onChange={handleChange}
                            editing={editing}
                            propertyValue='id'
                            getOptionLabel={option => `${option.descripcion}`}
                        />
                    </Grid>
                    <Grid item xs>
                        <Text
                            label='Numero de Cuenta (IBAN)'
                            fullWidth
                            editing={editing}
                            name='iban'
                            value={values.iban}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Text
                    fullWidth
                    multiline
                    rows={10}
                    name='notas'
                    label='Notas'
                    value={values.notas}
                    onChange={handleChange}
                    editing={editing}
                />
            </Grid>
        </Grid>
    );
};

export default Detalles;
