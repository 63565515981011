import React, { useEffect } from 'react';

import { Divider, Grid } from '@mui/material';

import { Text, Combo, Select, Documents, Switch, Emit } from 'core/components';
import * as enums from '../../../../core/enums';
import * as icons from '../../../../core/icons';
import {
    CONTACT_TYPE_AUTORIZADO,
    CONTACT_TYPE_CONTACTO,
    CONTACT_TYPE_DIRECCION
} from '../../../../core/constants';
import List from 'store/List';
import { ENTITY_BODEGA } from '../../../../store/entities/constants';
import DetailBodega from '../../../maestros/Bodegas/Detalle';

const ComboBodegas = List(Combo, 'options', 'lstbodegasrepresentadas');
const reportsViticultor = [
    {
        id: 'Autorizacion',
        title: 'Modelo de Autorización para el conbtacto',
        resume: 'Autorización',
        filters: []
    }
];
const reportsBodega = [];

const Contacto = props => {
    const { values, editing, handleChange, refresh } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Select
                        fullWidth
                        name='tipo'
                        label='Tipo'
                        value={values.tipo}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.contactType}
                    />
                </Grid>
                <Grid item>
                    <Switch
                        value={values.activo}
                        onChange={handleChange}
                        name='activo'
                        label='Activo'
                        editing={editing}
                    />
                </Grid>
                <Grid item>
                    <Emit
                        disabled={false}
                        hidden={editing}
                        toolTipText={'Emitir Autorización'}
                        reports={
                            values.viticultor
                                ? reportsViticultor
                                : reportsBodega
                        }
                        values={values}
                    />
                </Grid>
                {values.activo &&
                    values.tipo &&
                    values.tipo === CONTACT_TYPE_AUTORIZADO && (
                        <>
                            <Grid item>
                                <Switch
                                    value={values.indefinido}
                                    onChange={handleChange}
                                    name='indefinido'
                                    label='Indefinidamente'
                                    editing={editing}
                                />
                            </Grid>
                            {!values.indefinido && (
                                <Grid item>
                                    <Text
                                        fullWidth
                                        name='hasta'
                                        label='Valido hasta'
                                        value={values.hasta}
                                        type='date'
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            )}
                        </>
                    )}
                <Grid item xs />
                <Grid item xs>
                    <Text
                        fullWidth
                        name='cargo'
                        label='Relación'
                        value={values.cargo}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                {values.tipo &&
                    values.tipo !== CONTACT_TYPE_DIRECCION &&
                    values.tipo !== CONTACT_TYPE_CONTACTO && (
                        <Grid item xs={2}>
                            <Text
                                fullWidth
                                name='nif'
                                label='NIF'
                                value={values.nif}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    )}
            </Grid>
            {values.tipo &&
                values.tipo !== CONTACT_TYPE_DIRECCION &&
                values.tipo !== CONTACT_TYPE_CONTACTO && (
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <Combo
                                options={enums.authDocument.map(
                                    option => option.value
                                )}
                                fullWidth
                                multiple
                                name='acreditado'
                                label='Acreditado con'
                                Icon={icons.RolIcon}
                                value={values.acreditado}
                                onChange={handleChange}
                                editing={editing}
                                getOptionLabel={option => option}
                            />
                        </Grid>
                        <Grid item xs>
                            <Combo
                                options={enums.authAction.map(
                                    option => option.value
                                )}
                                fullWidth
                                multiple
                                name='autorizado'
                                label='Acreditado para'
                                Icon={icons.TramitesIcon}
                                value={values.autorizado}
                                onChange={handleChange}
                                editing={editing}
                                getOptionLabel={option => option}
                            />
                        </Grid>
                        {values.viticultor &&
                            values.tipo === CONTACT_TYPE_AUTORIZADO && (
                                <Grid item xs>
                                    <ComboBodegas
                                        fullWidth
                                        api='bodegas'
                                        name='bodegaRepresentada'
                                        nameKey='bodegaRepresentadaid'
                                        label='Bodega / Cooperativa Representada'
                                        relacionado={{
                                            type: ENTITY_BODEGA,
                                            Detail: DetailBodega,
                                            resume: ['nombre'],
                                            readOnly: false
                                        }}
                                        refresh={refresh}
                                        Icon={icons.CellarIcon}
                                        value={values.bodegaRepresentada}
                                        onChange={handleChange}
                                        editing={editing}
                                        propertyValue='id'
                                        getOptionLabel={option =>
                                            `${option.nombre}`
                                        }
                                        searchKeys={['nombre']}
                                    />
                                </Grid>
                            )}
                    </Grid>
                )}
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='nombre'
                        label={
                            values.tipo
                                ? values.tipo === CONTACT_TYPE_DIRECCION
                                    ? 'Descripción'
                                    : 'Nombre'
                                : ''
                        }
                        value={values.nombre}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Divider style={{ margin: '12px 12px 0px 12px' }} />
                        <Grid item>
                            <Text
                                fullWidth
                                multiline
                                rows={2}
                                name='direccion'
                                label='Dirección'
                                value={values.direccion}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Text
                                        fullWidth
                                        name='cp'
                                        label='Cód. Postal'
                                        value={values.cp}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        name='poblacion'
                                        label='Población'
                                        value={values.poblacion}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        name='provincia'
                                        label='Provincia'
                                        value={values.provincia}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        name='pais'
                                        label='Pais'
                                        value={values.pais}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Divider style={{ margin: '12px 12px 0px 12px' }} />
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        multiline
                                        rows={2}
                                        name='tlf'
                                        label='Tlfno.'
                                        value={values.tlf}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        multiline
                                        rows={2}
                                        name='fax'
                                        label='Fax'
                                        value={values.fax}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Text
                                fullWidth
                                name='email'
                                label='@'
                                value={values.email}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item>
                            <Text
                                fullWidth
                                name='web'
                                label='web'
                                value={values.web}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Text
                        fullWidth
                        multiline
                        rows={10}
                        name='notas'
                        label='Notas'
                        value={values.notas}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Documents
                        name='documentacion'
                        editing={editing}
                        onChange={handleChange}
                        rows={values.documentacion ? values.documentacion : []}
                        maxHeight={456}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Contacto;
