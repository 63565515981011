import React, { useState } from 'react';

import { makeStyles } from '../../library';

import {
    AddIcon,
    DeleteIcon,
    RefreshIcon,
    PrintIcon,
    CopyIcon
} from '../../icons';

import { Alert, Button } from '../../';

const isDisabled = (entities, baja) => {
    if (!Array.isArray(entities)) {
        if (baja)
            return (
                entities.isUpdating ||
                entities.isRefreshing ||
                entities.itemsSelected === 0
            );

        return entities.isUpdating || entities.isRefreshing;
    }

    let disabled = false;

    entities.map(entity => {
        disabled = disabled || entity.isUpdating || entity.isRefreshing;

        baja && (disabled = disabled || entity.itemsSelected === 0);

        return disabled;
    });

    return disabled;
};

const isUpdating = entities => {
    if (!Array.isArray(entities)) {
        return entities.isUpdating;
    }

    let updating = false;

    entities.map(entity => {
        updating = updating || entity.isUpdating;

        return updating;
    });

    return updating;
};

const itemsSelected = entities => {
    if (!Array.isArray(entities)) {
        return entities.itemsSelected;
    }

    let selected = 0;

    entities.map(entity => {
        selected = selected + entity.itemsSelected;

        return selected;
    });

    return selected;
};

const getToCopy = entities => {
    if (itemsSelected(entities) !== 1) return '';

    if (!Array.isArray(entities)) {
        return entities.selected[0];
    }

    let selected = '';

    entities.map(entity => {
        if (entity.itemsSelected === 1) selected = entity.selected[0];

        return selected;
    });

    return selected;
};

const isBusy = entities => {
    if (!Array.isArray(entities)) {
        return entities.isRefreshing || entities.isInit;
    }

    let busy = false;

    entities.map(entity => {
        busy = busy || entity.isRefreshing || entity.isInit;

        return busy;
    });

    return busy;
};

const refresh = entities => {
    if (!Array.isArray(entities)) {
        return entities.refresh(false);
    }

    entities.map(entity => {
        return entity.refresh(false);
    });
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const Actions = props => {
    const {
        className,
        add,
        entities,
        print,
        float,
        readOnly,
        optionals,
        ...rest
    } = props;
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);

    return (
        <div className={classes.root}>
            <Button
                hidden={readOnly}
                toolTipText={
                    itemsSelected(entities) === 1
                        ? 'Copiar elemento'
                        : 'Nuevo elemento'
                }
                disabled={isDisabled(entities)}
                onClick={() => add(getToCopy(entities))}
                counter={
                    itemsSelected(entities) === 1 ? (
                        <CopyIcon color='action' fontSize='small' />
                    ) : undefined
                }
                busy={false}
                icon={<AddIcon />}
                float={float}
            />
            <Button
                hidden={readOnly}
                toolTipText='Borrar los elementos seleccionados'
                disabled={isDisabled(entities, true)}
                onClick={() => setOpenDialog(true)}
                counter={itemsSelected(entities)}
                busy={isUpdating(entities)}
                icon={<DeleteIcon />}
                float={float}
            />

            <Button
                toolTipText='Actualiza los datos'
                disabled={isDisabled(entities)}
                onClick={event => refresh(entities)}
                counter={0}
                busy={isBusy(entities)}
                icon={<RefreshIcon />}
                float={float}
            />

            <Button
                hidden={!print}
                toolTipText='Imprimir Informes'
                disabled={isDisabled(entities)}
                selector={print && print.list.length > 1 && print}
                counter={0}
                busy={false}
                icon={<PrintIcon />}
                onClick={
                    print && print.list.length === 1
                        ? () => print.action(print.list[0])
                        : undefined
                }
                float={float}
            />

            {optionals}

            <Alert
                open={openDialog}
                title='¿Esta seguro de eliminar los elementos seleccionados?'
                text={`Si "Acepta" eliminara todos los elementos de la lista que ha seleccionado. Tenga en cuenta que esta acción es irreversible.`}
                cancel={() => setOpenDialog(false)}
                accept={() => {
                    setOpenDialog(false);
                    entities.delete();
                }}
            />
        </div>
    );
};

export default Actions;
