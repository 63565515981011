import React, { useEffect, useState } from 'react';

import { Grid, Divider, Chip } from 'core/components/library';

import {
    Select,
    DataGridPro as DataGrid,
    Text,
    Combo,
    Button,
    Tag
} from 'core/components';

import * as helpers from 'core/helpers';
import * as enums from 'core/enums';

import DetailVencimiento from 'views/tesoreria/vencimientos/Detalle';
import * as icons from '../../../../../../core/icons';

const calculaImportes = (field, newValue, values) => {
    const valores = {
        importe:
            values.importe && values.importe !== ''
                ? Number(values.importe)
                : 0,
        gastos:
            values.gastos && values.gastos !== '' ? Number(values.gastos) : 0,
        recargos:
            values.recargos && values.recargos !== ''
                ? Number(values.recargos)
                : 0,
        intereses:
            values.intereses && values.intereses !== ''
                ? Number(values.intereses)
                : 0,
        efectivo:
            values.efectivo && values.efectivo !== ''
                ? Number(values.efectivo)
                : 0
    };

    valores[field] = newValue[0].value !== '' ? Number(newValue[0].value) : 0;

    const total =
        valores.importe + valores.gastos + valores.recargos + valores.intereses;
    const pendiente = total - valores.efectivo;

    newValue.push({ field: 'total', value: total.toFixed(2) });
    newValue.push({ field: 'pendiente', value: pendiente.toFixed(2) });

    return newValue;
};

const cols = (name, row, index, editing, handleChange, refresh) => [
    {
        key: 'recibo',
        title: 'Recibo',
        align: 'left',
        //width: '10%',
        component: row && (
            <Tag
                //variant="outlined"
                size={'small'}
                sx={{ color: 'green' }}
                Icon={icons.VencimientosIcon}
                value={row['recibo']}
                id={row['id']}
                relacionado={{
                    Detail: DetailVencimiento,
                    resume: ['recibo'],
                    api: 'vencimientos',
                    readOnly: false
                }}
                refresh={refresh}
            />
        )
    },
    {
        key: 'metodoPago',
        title: 'Medio',
        align: 'left',
        //width: '20%',
        component: row && (
            <Select
                editing={editing}
                name='metodoPago'
                variant='standard'
                margin='none'
                options={enums.tiposPago}
                value={row['metodoPago']}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    },
    {
        key: 'fechaVencimiento',
        title: 'Vencimiento',
        align: 'left',
        //width: '15%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='fechaVencimiento'
                variant='standard'
                margin='none'
                type='date'
                value={row['fechaVencimiento']}
                autoselect={true}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    },
    {
        key: 'importe',
        title: 'Importe €',
        align: 'left',
        width: '7%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='importe'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['importe']}
                autoselect={true}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(
                            calculaImportes('importe', values, row),
                            name,
                            index
                        ))
                }
            />
        )
    },
    {
        key: 'gastos',
        title: 'Gastos €',
        align: 'left',
        width: '7%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='gastos'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['gastos']}
                autoselect={true}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(
                            calculaImportes('gastos', values, row),
                            name,
                            index
                        ))
                }
            />
        )
    },
    {
        key: 'recargos',
        title: 'Recargos €',
        align: 'left',
        width: '7%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='recargos'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['recargos']}
                autoselect={true}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(
                            calculaImportes('recargos', values, row),
                            name,
                            index
                        ))
                }
            />
        )
    },
    {
        key: 'intereses',
        title: 'Intereses €',
        align: 'left',
        width: '10%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='intereses'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['intereses']}
                autoselect={true}
                onChange={
                    handleChange &&
                    (values =>
                        handleChange(
                            calculaImportes('intereses', values, row),
                            name,
                            index
                        ))
                }
            />
        )
    },
    {
        key: 'total',
        title: 'Total €',
        align: 'left',
        width: '7%',
        component: row && (
            <Text
                fullWidth
                editing={false}
                name='total'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['total']}
                autoselect={true}
            />
        )
    },
    {
        key: 'efectivo',
        title: 'Efectivo €',
        align: 'left',
        width: '7%',
        component: row && (
            <Text
                fullWidth
                editing={false}
                name='efectivo'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['efectivo']}
                autoselect={true}
            />
        )
    },
    {
        key: 'pendiente',
        title: 'Pendiente €',
        align: 'left',
        width: '7%',
        component: row && (
            <Text
                fullWidth
                editing={false}
                name='pendiente'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['pendiente']}
                autoselect={true}
            />
        )
    }
];

const Vencimientos = props => {
    const {
        values,
        editing,
        adding,
        handleChange,
        refresh,
        reload,
        refreshing,
        execAction
    } = props;

    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (values.vencimientos) {
            if (values.vencimientos.length !== 0)
                actualizaTotales(values.vencimientos);
        }
    }, [JSON.stringify(values.vencimientos)]);

    const actualizaTotales = vencimientos => {
        let total = 0;

        vencimientos.forEach(vencimiento => {
            total = total + Number(vencimiento.total);
        });

        setTotal(total.toFixed(2));
    };

    const action = () => {
        const accion = {
            api: 'gastos/vencimientos',
            metodo: 'post',
            data: { ...values }
        };
        execAction(accion, [reload]);
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs>
                    <DataGrid
                        name='vencimientos'
                        editing={editing}
                        rows={values.vencimientos ? values.vencimientos : []}
                        cols={(name, row, index) =>
                            cols(
                                name,
                                row,
                                index,
                                editing,
                                handleChange,
                                refresh
                            )
                        }
                        onAdd={name =>
                            handleChange(
                                {
                                    importe: 0,
                                    gastos: 0,
                                    recargos: 0,
                                    intereses: 0,
                                    total: 0,
                                    pendiente: 0,
                                    efectivo: 0
                                },
                                name
                            )
                        }
                        onDelete={(name, index) =>
                            handleChange(undefined, name, (index + 1) * -1)
                        }
                        minHeight={250}
                        maxHeight={250}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Divider style={{ margin: '12px 12px 12px 12px' }}>
                        <Chip label={'TOTALES'} />
                    </Divider>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Button
                        toolTipText='Actualiza'
                        disabled={!editing}
                        onClick={event => action()}
                        counter={0}
                        busy={refreshing}
                        icon={<icons.RefreshIcon />}
                        float={true}
                    />
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs></Grid>
                <Grid item xs></Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='total'
                        label='Total €'
                        value={total}
                        editing={false}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Vencimientos;
