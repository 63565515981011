import React, { useEffect } from 'react';
import { DateTime } from 'luxon';

import { Divider, Grid, Stack, Box } from 'core/components/library';

import {
    Text,
    Select,
    Tabs,
    Documents,
    Combo,
    Tag,
    Switch,
    Button,
    DataGridPro as DataGrid
} from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import * as helpers from 'core/helpers';

import List from '../../../../store/List';

import DetailProveedor from 'views/compras/Proveedores/Detalle';
import DetailCliente from 'views/ventas/Clientes/Detalle';
import DetailSocio from 'views/maestros/Socios/Detalle';
import DetailSubcuenta from 'views/contabilidad/subcuentas/Detalle';
import DetailFormaPago from 'views/tesoreria/formas-pago/Detalle';
import * as iconSelector from '../../../../core/icons';
import DetailGasto from '../../../contabilidad/gastos/Detalle';
import DetailLiquidacion from '../../../liquidaciones/Liquidaciones/Detalle';
import DetailMovimiento from 'views/tesoreria/movimientos/Detalle';
import { Chip } from '../../../../core/components/library';
import { ENTITY_CLIENTE } from '../../../../store/entities/constants';

const ComboProveedores = List(Combo, 'options', 'lstProveedores');
const ComboClientes = List(Combo, 'options', 'lstClientes');
const ComboSocios = List(Combo, 'options', 'lstSocios');
const ComboSubcuentas = List(Combo, 'options', 'lstSubcuentas');
const ComboFormaPago = List(Combo, 'options', 'lstFormasPago');
const ComboMedios = List(Combo, 'options', 'lstMediosPago');

const calculaImportes = (field, newValue, values) => {
    const valores = {
        importe:
            values.importe && values.importe !== ''
                ? Number(values.importe)
                : 0,
        gastos:
            values.gastos && values.gastos !== '' ? Number(values.gastos) : 0,
        recargos:
            values.recargos && values.recargos !== ''
                ? Number(values.recargos)
                : 0,
        intereses:
            values.intereses && values.intereses !== ''
                ? Number(values.intereses)
                : 0,
        efectivo:
            values.efectivo && values.efectivo !== ''
                ? Number(values.efectivo)
                : 0
    };

    valores[field] = newValue[0].value !== '' ? Number(newValue[0].value) : 0;

    const total =
        valores.importe + valores.gastos + valores.recargos + valores.intereses;
    const pendiente = total - valores.efectivo;

    newValue.push({ field: 'total', value: total.toFixed(2) });
    newValue.push({ field: 'pendiente', value: pendiente.toFixed(2) });

    return newValue;
};

const getEntidad = (values, handleChange, editing, refresh) => {
    switch (values.tipo) {
        case 'Gasto':
        case 'Compra':
            return (
                <ComboProveedores
                    fullWidth
                    api='proveedores'
                    name='proveedor'
                    nameKey='proveedorid'
                    label='Proveedor'
                    relacionado={{
                        Detail: DetailProveedor,
                        resume: ['nombre'],
                        readOnly: false
                    }}
                    refresh={refresh}
                    Icon={icons.ProveedoresIcon}
                    value={values.proveedor}
                    onChange={handleChange}
                    editing={editing}
                    propertyValue='id'
                    getOptionLabel={option =>
                        `[${option.nif}]-${option.nombre}`
                    }
                />
            );
        case 'Liquidacion':
            return values.cliente ? (
                <ComboClientes
                    fullWidth
                    api='clientes'
                    name='cliente'
                    nameKey='clienteId'
                    label='Cliente'
                    relacionado={{
                        type: ENTITY_CLIENTE,
                        Detail: DetailCliente,
                        resume: ['nombre'],
                        readOnly: false
                    }}
                    refresh={refresh}
                    Icon={icons.ClientesIcon}
                    value={values.cliente}
                    onChange={handleChange}
                    editing={editing}
                    propertyValue='id'
                    getOptionLabel={option =>
                        `[${option.nif}]-[${option.codigo}]-${option.nombre}`
                    }
                    searchKeys={['codigo', 'nombre', 'nif']}
                />
            ) : (
                <ComboSocios
                    fullWidth
                    api='maestros/socios'
                    name='socio'
                    nameKey='socioId'
                    label='Socio'
                    relacionado={{
                        Detail: DetailSocio,
                        resume: ['nombre'],
                        readOnly: false
                    }}
                    refresh={refresh}
                    Icon={icons.SociosIcon}
                    value={values.socio}
                    onChange={handleChange}
                    editing={editing}
                    propertyValue='id'
                    getOptionLabel={option =>
                        `${option.nombre}-[${option.codigo}]-[${option.nif}]`
                    }
                    searchKeys={['codigo', 'nombre', 'nif']}
                />
            );

        default:
            return null;
    }
};

const getTipoInternal = item => {
    switch (item.tipo) {
        case 'Gasto':
            return {
                id: item.gastoId,
                color: 'red',
                icon: iconSelector.GastosIcon,
                relacionado: {
                    maxWidth: 'lg',
                    Detail: DetailGasto,
                    resume: ['concepto'],
                    api: 'gastos',
                    readOnly: false
                }
            };
        case 'Liquidacion':
            return {
                id: item.liquidacionId,
                color: 'green',
                icon: iconSelector.LiquidacionesIcon,
                relacionado: {
                    maxWidth: 'lg',
                    Detail: DetailLiquidacion,
                    resume: ['concepto'],
                    api: 'Liquidaciones',
                    readOnly: false
                }
            };

        default:
            return {};
    }
};

const cols = (name, row, index) => [
    {
        key: 'fecha',
        title: 'Fecha',
        align: 'center',
        component: row && (
            <Tag
                //variant="outlined"
                size={'small'}
                sx={{ color: 'green' }}
                Icon={icons.CobrosPagosIcon}
                value={helpers.getValueFormatted('date', row.fecha)}
                id={row.movimiento.id}
                relacionado={{
                    Detail: DetailMovimiento,
                    resume: ['concepto'],
                    api: 'tesoreria/movimientos',
                    readOnly: true
                }}
            />
        )
    },
    {
        key: 'banco',
        title: 'Banco',
        align: 'left',
        component: row && <>{row.movimiento.medio.banco.nombre}</>
    },
    {
        key: 'medio',
        title: 'Medio',
        align: 'left',
        component: row && <>{row.movimiento.medio.alias}</>
    },
    {
        key: 'metodo',
        title: 'Metodo',
        align: 'left',
        component: row && <>{row.movimiento.metodoPago}</>
    },
    {
        key: 'importe',
        title: 'Asignado €',
        align: 'left',
        //width: '7%',
        component: row && <>{helpers.getValueFormatted('d.2', row.importe)}</>
    },
    {
        key: 'gastos',
        title: 'Gastos €',
        align: 'left',
        //width: '7%',
        component: row && <>{helpers.getValueFormatted('d.2', row.gastos)}</>
    },
    {
        key: 'recargos',
        title: 'Recargos €',
        align: 'left',
        //width: '7%',
        component: row && <>{helpers.getValueFormatted('d.2', row.recargos)}</>
    },
    {
        key: 'intereses',
        title: 'Intereses €',
        align: 'left',
        //width: '7%',
        component: row && <>{helpers.getValueFormatted('d.2', row.intereses)}</>
    },
    {
        key: 'total',
        title: 'Importe €',
        align: 'left',
        //width: '7%',
        component: row && <>{helpers.getValueFormatted('d.2', row.total)}</>
    }
];

const Detalle = props => {
    const {
        values,
        editing,
        handleChange,
        refresh,
        reload,
        execAction,
        getTipo
    } = props;

    // Esto lo hago porque getTipo esta en el principal y si llamo al detalle desde un relacionado peta
    // Hay que refactorizaarlo para evitar el pufo
    const tipo = getTipo ? getTipo(values) : getTipoInternal(values);

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Stack
                        sx={{ height: '100%' }}
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Switch
                            value={values.entrada}
                            onChange={handleChange}
                            name='entrada'
                            label='Entrada'
                            editing={editing}
                        />
                    </Stack>
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='fecha'
                        label='Fecha'
                        value={values.fecha}
                        type='date'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='recibo'
                        label='Recibo'
                        value={values.recibo}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Stack
                        sx={{ height: '100%' }}
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Tag
                            //variant="outlined"
                            //size={'small'}
                            sx={{ color: tipo.color }}
                            value={values['tipo']}
                            id={tipo.id}
                            Icon={tipo.icon}
                            refresh={refresh}
                            relacionado={tipo.relacionado}
                        />
                    </Stack>
                </Grid>

                <Grid item xs></Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='fechaVencimiento'
                        label='Fecha Vencimiento'
                        value={values.fechaVencimiento}
                        type='date'
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs></Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='concepto'
                        label='Concepto'
                        value={values.concepto}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            {getEntidad(values, handleChange, editing, refresh)}
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item>
                            <Select
                                fullWidth
                                name='metodoPago'
                                label='Método Pago'
                                value={values.metodoPago}
                                onChange={handleChange}
                                editing={editing}
                                options={enums.tiposPago}
                            />
                        </Grid>
                        <Grid item xs>
                            <ComboMedios
                                fullWidth
                                api='bancos/mediospago'
                                name='medio'
                                nameKey='medioid'
                                label='Medio Pago Predeterminado'
                                refresh={refresh}
                                Icon={icons.BancosIcon}
                                value={values.medio}
                                onChange={handleChange}
                                editing={editing}
                                propertyValue='id'
                                getOptionLabel={option =>
                                    `${option.banco.nombre}-[${option.alias}]-[${option.subcuenta.id}]`
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        multiline
                        rows={4}
                        name='notas'
                        label='Notas'
                        value={values.notas}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Divider style={{ margin: '12px 12px 12px 12px' }}>
                        <Chip label={'IMPORTES'} />
                    </Divider>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='importe'
                        label='Importe €'
                        value={values.importe}
                        onChange={newValue =>
                            handleChange(
                                calculaImportes('importe', newValue, values)
                            )
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='gastos'
                        label='Gastos €'
                        value={values.gastos}
                        onChange={newValue =>
                            handleChange(
                                calculaImportes('gastos', newValue, values)
                            )
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='recargos'
                        label='Recargos €'
                        value={values.recargos}
                        onChange={newValue =>
                            handleChange(
                                calculaImportes('recargos', newValue, values)
                            )
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='intereses'
                        label='Intereses €'
                        value={values.intereses}
                        onChange={newValue =>
                            handleChange(
                                calculaImportes('intereses', newValue, values)
                            )
                        }
                        onFocus={event => event.target.select()}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='total'
                        label='Total €'
                        value={values.total}
                        //onChange={handleChange}
                        onFocus={event => event.target.select()}
                        editing={false}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='efectivo'
                        label='Efectivo €'
                        value={values.efectivo}
                        //onChange={handleChange}
                        onFocus={event => event.target.select()}
                        editing={false}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='pendiente'
                        label='Pendiente €'
                        value={values.pendiente}
                        //onChange={handleChange}
                        onFocus={event => event.target.select()}
                        editing={false}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Divider style={{ margin: '12px 12px 12px 12px' }}>
                        <Chip label={'MOVIMIENTOS'} />
                    </Divider>
                </Grid>
            </Grid>
            {values.movimientos && values.movimientos.length > 0 && (
                <Grid container spacing={1}>
                    <Grid item xs>
                        <DataGrid
                            name='movimientos'
                            editing={editing}
                            rows={values.movimientos ? values.movimientos : []}
                            cols={(name, row, index) => cols(name, row, index)}
                            minHeight={250}
                            maxHeight={250}
                        />
                    </Grid>
                </Grid>
            )}
        </form>
    );
};

export default Detalle;
