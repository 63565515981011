import React from 'react';

import { makeStyles } from '../library';

import {
    Card,
    CardActions,
    CardContent,
    Scrollbar,
    Table,
    TableContainer,
    TableBody,
    Typography,
    TablePagination
} from '../library';

import Head from './Head';
import Row from './Row';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 4
    },
    actions: {
        justifyContent: 'flex-start'
    }
}));

const DataGrid = props => {
    const {
        className,
        disabled,
        view,
        name,
        list,
        columns,
        editing,
        lockRow,
        onChange,
        dfault,
        add,
        edit,
        erase,
        select,
        maxHeight,
        rowHeight,
        headHeight,
        ...rest
    } = props;
    const classes = useStyles();

    return (
        <Scrollbar>
            <TableContainer style={{ maxHeight: maxHeight }}>
                <Table stickyHeader size='small'>
                    <Head
                        height={headHeight}
                        disabled={disabled}
                        columns={columns}
                        add={add}
                        dfault={dfault}
                        onChange={
                            editing &&
                            onChange &&
                            (chngs => onChange(chngs, name, undefined))
                        }
                    />
                    <TableBody>
                        {list.map((item, index) => (
                            <Row
                                height={rowHeight}
                                id={index}
                                edit={edit}
                                erase={erase}
                                disabled={disabled}
                                columns={columns}
                                item={item}
                                select={select}
                                lockRow={lockRow}
                                onChange={
                                    editing &&
                                    onChange &&
                                    ((chngs, key) => onChange(chngs, name, key))
                                }
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
    );
};

export default DataGrid;
