import React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '../library';

const message = entities => {
    let msg = '';

    entities.forEach(entity => {
        if (entity.hasErrors) {
            msg =
                msg +
                `
                Error [${entity.errors.code}] - ${entity.errors.message}`;
        }
    });

    return msg;
};

const hasErrors = entities => {
    return entities.filter(entity => entity.hasErrors).length !== 0;
};

const clear = entities => {
    entities.map(entity => entity.hasErrors && entity.clear());
};

const Error = props => {
    const { entities, accept, cancel } = props;

    const handleClose = () => {
        clear(entities);
        cancel && cancel();
    };

    const handleResume = () => {
        accept && accept();
        //limpiaError(entidades)
        //setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={hasErrors(entities)}
                //onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{'Error'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {message(entities)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary'>
                        {accept ? 'Cancelar' : 'Cerrar'}
                    </Button>

                    {accept && (
                        <Button
                            onClick={handleResume}
                            color='primary'
                            autoFocus
                        >
                            Continuar
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Error;
