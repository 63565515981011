import React from 'react';

import { makeStyles } from 'core/components/library';
import { Divider, Grid, Typography } from 'core/components/library';

import * as icons from 'core/icons';
import * as enums from 'core/enums';
import * as hlprs from 'core/helpers';

import List from 'store/List';

import { Avatar, Combo, Select, Text, Switch, Document } from 'core/components';

//import DetailEmpleado from 'views/Empleados/Detalle'

const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

//const ComboEmpleados = List(Combo, 'options', 'lstEmpleados');
const ComboRoles = List(Combo, 'options', 'lstRoles');

const Detalle = props => {
    const { auth, values, editing, adding, handleChange, refresh } = props;
    const classes = useStyles();

    return (
        <form autoComplete='on' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Avatar
                        size='medium'
                        sx={{ marginBottom: '10px', marginLeft: '10px' }}
                        //variant="square"
                        //color="secondary"
                        //hidden={!values.propietario}
                        value={values.nombre}
                        image={hlprs.getAvatar(values.id, values.avatar)}
                    />
                </Grid>
                <Grid item xs>
                    <Switch
                        value={values.activo}
                        onChange={handleChange}
                        name='activo'
                        label='Activo'
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='nombre'
                        label='Nombre'
                        //helperText="Nombre del usuario"
                        value={values.nombre}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='email'
                        label='eMail'
                        //helperText="Correo electronico"
                        value={values.email}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    {/*<ComboEmpleados
                        api="empleados"
                        name="empleado"
                        nameKey="idEmpleado"
                        label="Empleado"
                        Icon={icons.EmployeeIcon}
                        helperText="Seleccione un Empleado"
                        value={values.empleado}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue="id"
                        getOptionLabel={(option) => option.nombre}
                        relacionado={{
                            Detail: DetailEmpleado,
                            resume:['nombre'],
                            dfault:{
                                nombre:values.nombre
                            }
                        }}
                        refresh={refresh}
                    />*/}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Text
                        fullWidth
                        name='puesto'
                        label='Cargo'
                        //helperText="Correo electronico"
                        value={values.puesto}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <ComboRoles
                        fullWidth
                        api='roles'
                        multiple
                        name='roles'
                        label='Roles'
                        Icon={icons.RolIcon}
                        //helperText="Roles a los que pertenece el usuario"
                        value={values.roles}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            !option.equipo
                                ? `* ${option.id}`
                                : `${option.equipo.id} - ${option.id}`
                        }
                        refresh={refresh}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <Typography variant='caption'>
                                Datos de inicio de sesión
                            </Typography>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <Text
                                fullWidth
                                name='login'
                                label='Login'
                                //helperText="Login de usuario"
                                value={values.login}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <Text
                                fullWidth
                                type='password'
                                name='password'
                                label='Password'
                                //helperText="Password del usuario"
                                value={values.password}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item xs>
                            {/*<Text
                                fullWidth
                                name="identificador"
                                label="Identificador"
                                helperText="Pin del identificador unico de usuario"
                                value={values.identificador}
                                onChange={handleChange}
                                editing={editing}
                            />*/}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <Typography variant='caption'>Firma</Typography>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{ marginTop: '20px', marginBottom: '20px' }}
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Document
                            name='firma'
                            readOnly={!editing}
                            width='300px'
                            height='200px'
                            value={values.firma}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default Detalle;
