import React, { useState } from 'react';

import { makeStyles, gridSpacing, Avatar } from '../../library';
import {
    Card,
    CardHeader,
    Grid,
    IconButton,
    Tooltip,
    Tab,
    Tabs,
    Typography
} from '../../library';

import { CloseIcon } from '../../icons';

import { Alert, Error } from '../../';
import * as helpers from '../../helpers';
import { gridColumnVisibilityModelSelector } from '@mui/x-data-grid';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 550,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 250
        }
    },
    avatar: {
        marginTop: 10,
        marginRight: 10
    }
}));

const TabPanel = props => {
    const { value, index, item, close, edit, refresh, ...rest } = props;
    const Container = item.container;

    return (
        <Grid item hidden={value !== index}>
            <Container
                readOnly={item.readOnly}
                item={item}
                close={close}
                edit={edit}
                refreshIndex={refresh}
                {...rest}
            />
        </Grid>
    );
};

const Elements = props => {
    const {
        title,
        items,
        single,
        Icon,
        selectedItem,
        selectItem,
        close,
        edit,
        editing,
        refresh,
        ...rest
    } = props;
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);

    const getLabel = (item, index) => {
        const max = 10;

        if (item.title.length > max)
            return `${item.title.substring(0, max)}...${index}`;

        return `${item.title} ${index}`;
    };

    const getPanels = items => {
        selectedItem === items.length &&
            selectedItem !== 0 &&
            selectItem(selectedItem - 1);

        return (
            <div className={classes.root}>
                <Tabs
                    value={selectedItem}
                    variant='scrollable'
                    scrollButtons='auto'
                    onChange={(event, newValue) => selectItem(newValue)}
                    indicatorColor='primary'
                    textColor='primary'
                >
                    {items.map((item, index) => (
                        <Tab
                            key={item.key}
                            id={index}
                            label={getLabel(item, index)}
                            wrapped
                        />
                    ))}
                </Tabs>
            </div>
        );
    };

    return (
        <Grid container spacing={gridSpacing} direction='column'>
            <Grid item>
                <Card raised={true}>
                    <CardHeader
                        style={{ padding: 10 }}
                        //title={<Typography variant="h6">{title}</Typography>}
                        title={getPanels(items)}
                        //subheader="Elementos abiertos"
                        //action={getPanels(items)}
                        action={
                            single && (
                                <Avatar className={classes.avatar}>
                                    {Icon ? (
                                        <Icon />
                                    ) : (
                                        helpers.getInitials(title)
                                    )}
                                </Avatar>
                            )
                        }
                        avatar={
                            <Tooltip title='Cerrar todos'>
                                <IconButton
                                    onClick={() =>
                                        editing ? setOpenDialog(true) : close()
                                    }
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    />
                </Card>
                <Alert
                    open={openDialog}
                    title='¿Hay cambios pendientes de aceptar. Esta seguro de cerrar?'
                    text={`Si "Acepta" perdera todos los cambios realizados desde la ultima vez que los guardo. Tenga en cuenta que esta acción es irreversible.`}
                    cancel={() => setOpenDialog(false)}
                    accept={() => {
                        setOpenDialog(false);
                        close();
                    }}
                />
            </Grid>
            {items.map((item, index) => (
                <TabPanel
                    key={item.key}
                    value={selectedItem}
                    index={index}
                    item={item}
                    close={close}
                    edit={edit}
                    refresh={refresh}
                    {...rest}
                />
            ))}
        </Grid>
    );
};

export default Elements;
