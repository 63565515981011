import React, { useState } from 'react';

import { Grid, Divider } from '@mui/material';

import {
    Select,
    DataGridPro as DataGrid,
    Text,
    Combo,
    Switch
} from 'core/components';

import * as hlprs from 'core/helpers';
import * as enums from 'core/enums';
import List from '../../../../../../store/List';
import * as icons from '../../../../../../core/icons';
import { Chip } from '../../../../../../core/components/library';
import DetailMencion from 'views/entorno/Menciones/Detalle';

const ComboVinos = List(Combo, 'options', 'lstVinos');
const ComboVariedades = List(Combo, 'options', 'lstVariedades');
const ComboEnvases = List(Combo, 'options', 'lstEnvases');
const ComboMenciones = List(Combo, 'options', 'lstMenciones');

const cols = (name, row, index, editing, handleChange, refresh) => [
    {
        key: 'variedad',
        title: 'Variedades',
        align: 'left',
        width: '80%',
        component: row && (
            <ComboVariedades
                fullWidth
                api='auxiliares/variedadesUva'
                name='variedad'
                nameKey='variedadid'
                variant='standard'
                Icon={icons.VarietyIcon}
                refresh={refresh}
                value={row.variedad}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option => `${option.descripcion}`}
                searchKeys={['descripcion']}
            />
        )
    },
    {
        key: 'porcentaje',
        title: '%',
        align: 'center',
        width: '20%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='porcentaje'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['porcentaje']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    }
];

const Caracteristicas = props => {
    const { values, editing, adding, handleChange, refresh } = props;

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboMenciones
                        fullWidth
                        api='menciones'
                        multiple
                        name='menciones'
                        label='Menciones'
                        Icon={icons.MentionIcon}
                        value={
                            values.menciones
                                ? values.menciones.map(item => item)
                                : []
                        }
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => option.id}
                        getItem={item => ({
                            id: item.id
                        })}
                        relacionado={{
                            maxWidth: 'xs',
                            Detail: DetailMencion,
                            resume: ['id'],
                            readOnly: false,
                            lite: true
                        }}
                        refresh={refresh}
                        searchKeys={['id']}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Grid item xs>
                            <Grid item xs>
                                <Divider
                                    style={{ margin: '12px 12px 12px 12px' }}
                                >
                                    <Chip size='small' label={'Composición'} />
                                </Divider>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <ComboVinos
                                fullWidth
                                api='auxiliares/variedadesvino/'
                                name='variedadVino'
                                nameKey='variedadVinoId'
                                label='Tipo Vino'
                                Icon={icons.WineIcon}
                                value={values.variedadVino}
                                onChange={handleChange}
                                editing={editing}
                                propertyValue='id'
                                getOptionLabel={option => option.descripcion}
                                searchKeys={['descripcion']}
                            />
                        </Grid>
                        <Grid item xs>
                            <Grid container direction='row' spacing={2}>
                                <Grid item xs>
                                    <DataGrid
                                        name='variedades'
                                        editing={editing}
                                        rows={
                                            values.variedades
                                                ? values.variedades
                                                : []
                                        }
                                        cols={(name, row, index) =>
                                            cols(
                                                name,
                                                row,
                                                index,
                                                editing,
                                                handleChange,
                                                refresh
                                            )
                                        }
                                        onAdd={name => {
                                            handleChange({}, name);
                                        }}
                                        onDelete={(name, index) =>
                                            handleChange(
                                                undefined,
                                                name,
                                                (index + 1) * -1
                                            )
                                        }
                                        minHeight={250}
                                        maxHeight={250}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Grid container direction='row' spacing={1}>
                                        <Grid item xs>
                                            <Text
                                                fullWidth
                                                name='añada'
                                                label='Añada'
                                                value={values.añada}
                                                onChange={handleChange}
                                                editing={editing}
                                                type={'entero'}
                                            />
                                        </Grid>
                                        {/*<Grid item>
                                            <Switch
                                                value={values.madera}
                                                onChange={handleChange}
                                                name='madera'
                                                label='Madera'
                                                editing={editing}
                                            />
                                        </Grid>*/}
                                        <Grid item xs>
                                            <Text
                                                fullWidth
                                                name='tiempoMadera'
                                                label='Meses Madera'
                                                value={values.tiempoMadera}
                                                onChange={handleChange}
                                                editing={editing}
                                                type={'entero'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Grid item xs>
                            <Divider style={{ margin: '12px 12px 12px 12px' }}>
                                <Chip size='small' label={'Embotellado'} />
                            </Divider>
                        </Grid>

                        <Grid item xs>
                            <Grid container direction='row' spacing={1}>
                                <Grid item xs={6}>
                                    <ComboEnvases
                                        fullWidth
                                        api='auxiliares/envases/'
                                        name='envase'
                                        nameKey='envaseId'
                                        label='Tipo Envase'
                                        Icon={icons.PackageIcon}
                                        value={values.envase}
                                        onChange={handleChange}
                                        editing={editing}
                                        propertyValue='id'
                                        getOptionLabel={option =>
                                            option.descripcion
                                        }
                                        searchKeys={['descripcion']}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        //name='volumen'
                                        label='Volumen (Litros)'
                                        value={
                                            values.envase
                                                ? values.envase.capacidad
                                                : 0
                                        }
                                        //onChange={handleChange}
                                        editing={false}
                                        type={'d.4'}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        helperText={
                                            values.regEmbotellado ===
                                                values?.explotacion
                                                    ?.regEmbotellado ||
                                            !values.regEmbotellado ||
                                            !values.explotacion
                                                ? undefined
                                                : `Centro: ${values?.explotacion?.regEmbotellado}`
                                        }
                                        name='regEmbotellado'
                                        label='Registro Embotellado'
                                        value={values.regEmbotellado}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction='row' spacing={1}>
                                <Grid item xs>
                                    <Switch
                                        aling={'center'}
                                        value={values.compartida}
                                        onChange={handleChange}
                                        name='compartida'
                                        label='Compartida'
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Switch
                                        aling={'center'}
                                        value={values.numeracionInsertada}
                                        onChange={handleChange}
                                        name='numeracionInsertada'
                                        label='Num. Insertada'
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Switch
                                        aling={'center'}
                                        value={values.envolvente}
                                        onChange={handleChange}
                                        name='envolvente'
                                        label='Etq. Envolvente'
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction='row' spacing={1}>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        name='alto'
                                        label='Alto Frontal (cm)'
                                        value={values.alto}
                                        onChange={handleChange}
                                        editing={editing}
                                        type={'d.3'}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        name='ancho'
                                        label='Ancho Frontal (cm)'
                                        value={values.ancho}
                                        onChange={handleChange}
                                        editing={editing}
                                        type={'d.3'}
                                    />
                                </Grid>

                                {!values.envolvente && (
                                    <>
                                        <Grid item xs>
                                            <Text
                                                fullWidth
                                                name='altoTrasera'
                                                label='Alto Trasera (cm)'
                                                value={values.altoTrasera}
                                                onChange={handleChange}
                                                editing={editing}
                                                type={'d.3'}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Text
                                                fullWidth
                                                name='anchoTrasera'
                                                label='Ancho Trasera (cm)'
                                                value={values.anchoTrasera}
                                                onChange={handleChange}
                                                editing={editing}
                                                type={'d.3'}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Caracteristicas;
