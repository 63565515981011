import React, { useEffect } from 'react';

import { Divider, Grid } from '@mui/material';

import {
    Text,
    Button,
    Select,
    Switch,
    Tabs,
    Documents,
    Combo,
    Document
} from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';
import List from '../../../../../../store/List';

import DetailEtiqueta from 'views/maestros/Etiquetas/Detalle';
import DetailMarca from '../../../../../maestros/Marcas/Detalle';
import { statesEntity } from 'core/enums';
import { Chip } from '../../../../../../core/components/library';
import { ENTITY_ETIQUETA_MARCA } from '../../../../../../store/entities/constants';
const ComboEtiquetas = List(Combo, 'options', 'lstetiquetas');

const getNewValue = values => {
    if (values.estado === 'Finalizado') return values.newValue;

    if (!values.formatoAfectado) return '';

    return JSON.stringify(values.formatoAfectado, null, 4).replaceAll(
        'null,',
        ''
    );
};

const getOldValue = values => {
    return JSON.stringify(values.oldValue, null, 4);
};

const Etiquetas = props => {
    const { values, editing, handleChange, refresh } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboEtiquetas
                        fullWidth
                        api='etiquetasmarca'
                        name='formatoAfectado'
                        nameKey='formatoAfectadoId'
                        label='Etiqueta Afectada'
                        relacionado={{
                            type: ENTITY_ETIQUETA_MARCA,
                            Detail: DetailEtiqueta,
                            resume: ['nombre'],
                            readOnly: false
                        }}
                        refresh={refresh}
                        Icon={icons.BrandsIcon}
                        value={values.formatoAfectado}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option =>
                            `${option.marca.nombre}-${option.nombre}-[${option.marca.bodega.nombre}]`
                        }
                        searchKeys={[
                            'nombre',
                            'marca.nombre',
                            'marca.bodega.nombre'
                        ]}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        fullWidth
                        name='estadoEtiqueta'
                        label='Estado'
                        value={values.estadoEtiqueta}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.filterValues(statesEntity, [
                            'Activo',
                            'Baja'
                        ])}
                    />
                </Grid>
            </Grid>
            <Divider>
                <Chip
                    sx={{ margin: '12px 12px 12px 12px' }}
                    label={'Datos de la versión'}
                />
            </Divider>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        editing={editing}
                        name='resumenVersion'
                        label='Descripcion'
                        value={values.resumenVersion}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        editing={editing}
                        name='destinoVersion'
                        label='Destino'
                        value={values.destinoVersion}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        editing={editing}
                        name='idiomaVersion'
                        label='Idioma'
                        value={values.idiomaVersion}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Divider>
                <Chip
                    sx={{ margin: '12px 12px 12px 12px' }}
                    label={'Nuevo diseño'}
                />
            </Divider>
            <Grid container spacing={12}>
                <Grid item xs>
                    <Grid
                        height={'100%'}
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Document
                            //objectFit={'cover'}
                            name='etiqueta'
                            readOnly={!editing}
                            width='200px'
                            height='200px'
                            value={values.etiqueta}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>

                <Grid item xs>
                    <Text
                        fullWidth
                        multiline
                        rows={10}
                        name='newValue'
                        label='Datos a registrar'
                        value={getNewValue(values)}
                        //onChange={handleChange}
                        //editing={false}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Etiquetas;
