import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from 'core/components/library';
import {
    DataGridPro as DataGrid,
    Literal,
    Switch,
    ViewEntity
} from 'core/components';
import DetailContacto from 'views/entorno/contactos/Detalle';
import { ENTITY_CONTACTO } from '../../../../../../store/entities/constants';
import * as constants from 'core/constants';
import * as helpers from 'core/helpers';
import * as IconsSelector from 'core/icons';
import * as enums from 'core/enums';

const getDatos = row => {
    let text = ''.concat(row.nombre ? row.nombre : '');

    if (row.tipo === constants.CONTACT_TYPE_DIRECCION)
        return text.concat(
            row.direccion ? ` - 🏠️ ${row.direccion}` : '',
            row.poblacion ? ` - 🏘 ${row.poblacion}` : '',
            row.provincia ? ` (${row.provincia})` : '',
            row.pais ? ` - 🌍 ${row.pais}` : ''
        );

    if (row.tipo === constants.CONTACT_TYPE_CONTACTO)
        return text.concat(
            row.tlf ? ` - 📞 ${row.tlf}` : '',
            row.email ? ` - 📨 ${row.email}` : ''
        );

    return text.concat(
        row.nif ? ` - 🪪 ${row.nif}` : '',
        row.tlf ? ` - 📞 ${row.tlf}` : '',
        row.email ? ` - 📨 ${row.email}` : ''
    );
};

const getValidez = row => {
    let text = 'Indefinida';

    if (row.tipo === constants.CONTACT_TYPE_AUTORIZADO) {
        if (!row.indefinido)
            if (row.hasta) text = helpers.getValueFormatted('date', row.hasta);
            else text = 'No definida';
    }

    return text;
};

const Icon = props => {
    const { type, DefaultIcon } = props;
    const item = enums.getItem(enums.contactType, type);

    if (item) return <item.icon fontSize={'small'} />;

    return <DefaultIcon fontSize={'small'} />;
};

const colsContactos = (row, entity) => [
    {
        key: 'activo',
        title: 'Activo',
        align: 'center',
        width: '2%',
        component: row && (
            <Switch value={row.activo} name='activo' editing={false} />
        )
    },
    {
        key: 'tipo',
        title: 'Tipo',
        align: 'left',
        width: '10%',
        component: row && (
            <Literal
                text={row['tipo']}
                color={enums.getItem(enums.contactType, row.tipo).color}
            >
                <Icon type={row.tipo} DefaultIcon={entity.icon} />
            </Literal>
        )
    },
    {
        key: 'cargo',
        title: 'Relación',
        align: 'left',
        width: '10%',
        component: row && row.cargo
    },
    {
        key: 'validez',
        title: 'Validez',
        align: 'center',
        width: '8%',
        component: row && getValidez(row)
    },
    {
        key: 'datos',
        title: 'Datos Contacto',
        align: 'left',
        width: '70%',
        component: row && getDatos(row)
    }
];

const Contactos = props => {
    const { values, editing, execAction, refresh } = props;
    const entityData = useSelector(state => state.entities[ENTITY_CONTACTO]);

    return (
        <Grid container spacing={3}>
            <Grid item xs>
                <DataGrid
                    //title='Centros'
                    name='contactos'
                    editing={editing}
                    rows={values.contactos ? values.contactos : []}
                    cols={(name, row, index) => colsContactos(row, entityData)}
                    onDelete={(name, index, row) => {
                        execAction(
                            {
                                api: entityData.api,
                                metodo: 'delete',
                                data: [row.id]
                            },
                            [() => refresh(false)]
                        );
                    }}
                    //maxHeight={456}
                >
                    <ViewEntity
                        key={ENTITY_CONTACTO}
                        refresh={refresh}
                        entity={entityData}
                        initValue={{ cliente: { id: values.id } }}
                    >
                        {DetailContacto}
                    </ViewEntity>
                </DataGrid>
            </Grid>
        </Grid>
    );
};

export default Contactos;
