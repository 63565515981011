import React, { useEffect, useState } from 'react';
import {
    Auth,
    Chip,
    Entity,
    Scrollbar,
    Table,
    TableBody,
    TableContainer,
    Paper,
    Divider
} from '../library';
import Head from './Head';
import Row from './Row';
import Foot from './Foot';
import { Alert } from '../index';
import * as helpers from '../helpers';

const DataGrid = props => {
    const {
        getId,
        name,
        editing,
        rows,
        cols,
        notAdd,
        onAdd,
        onDelete,
        onClick,
        maxHeight,
        maxWidth,
        minHeight,
        title,
        children,
        readOnly,
        allowSelect,
        changeSelectedItems,
        highlightRow
    } = props;

    const [id, setId] = useState(null);
    const [alert, setAlert] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [componentTopPosition, setComponentTopPosition] = useState('0px');
    const [componentHeight, setComponentHeight] = useState('0px');

    const updatePosition = () => {
        const element = document.getElementById('table');
        let positionInPixels = 0;
        let ajuste = 0; //24

        if (element) {
            positionInPixels = element.getBoundingClientRect().top;

            const dialog = document.getElementById('draggable-dialog-title');

            if (!dialog) {
                ajuste =
                    element.getBoundingClientRect().top -
                    element.parentElement.getBoundingClientRect().top;
            } else {
                ajuste = 80;
            }
        }

        setComponentTopPosition(`${positionInPixels + ajuste}px`);
    };

    useEffect(() => {
        updatePosition();
        window.addEventListener('resize', updatePosition);
        return () => window.removeEventListener('resize', updatePosition);
    }, []);

    const selectItem = item => {
        let newSelectedItems = [];

        if (allowSelect.toLowerCase() === 'multiple') {
            if (isSelected(item)) {
                const id = getId ? getId(item) : item['id'];
                newSelectedItems = selectedItems.filter(selected => {
                    const idSelected = getId ? getId(selected) : selected['id'];
                    return idSelected !== id;
                });
            } else newSelectedItems = selectedItems.concat(item);
        } else {
            newSelectedItems = [item];
        }
        setSelectedItems(newSelectedItems);
        if (changeSelectedItems) changeSelectedItems(newSelectedItems);
    };

    const isSelected = item => {
        const id = getId ? getId(item) : item['id'];
        return selectedItems.some(selected => {
            const idSelected = getId ? getId(selected) : selected['id'];
            return idSelected === id;
        });
    };

    const open = (key, id) => {
        setId({ key: key, id: id });
    };

    return (
        <>
            {title && (
                <Divider style={{ margin: '0px 0px 1px 0px' }}>
                    <Chip size='small' label={title} />
                </Divider>
            )}
            {/*<Scrollbar>*/}
            <TableContainer
                id={'table'}
                style={{
                    //border: '1px solid black',
                    maxWidth: maxWidth,
                    maxHeight: maxHeight
                        ? maxHeight
                        : `calc(100vh - ${componentTopPosition})`,
                    minHeight: minHeight
                }}
            >
                <Table size='small' stickyHeader={true}>
                    <Head
                        cols={cols()}
                        editing={editing}
                        notAdd={notAdd}
                        onOpen={
                            children &&
                            !readOnly &&
                            (onAdd ? onAdd : () => open(null, 'new'))
                        }
                        onAdd={
                            !children &&
                            onAdd &&
                            !readOnly &&
                            (() => onAdd(name))
                        }
                        onDelete={onDelete ? true : false}
                    />
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row
                                index={index}
                                key={getId ? getId(row) : row['id']}
                                cols={cols(name, row, index)}
                                editing={editing}
                                selected={allowSelect ? isSelected(row) : false}
                                highlightRow={
                                    highlightRow ? highlightRow(row) : false
                                }
                                onSelect={
                                    allowSelect
                                        ? () => selectItem(row)
                                        : undefined
                                }
                                onOpen={
                                    children
                                        ? onClick
                                            ? () => {
                                                  onClick(row, index, open);
                                              }
                                            : () =>
                                                  open(
                                                      null,
                                                      getId
                                                          ? getId(row)
                                                          : row['id']
                                                  )
                                        : undefined
                                }
                                onDelete={
                                    onDelete &&
                                    !readOnly &&
                                    (children
                                        ? () => setAlert({ name, index, row })
                                        : () => onDelete(name, index, row))
                                }
                            />
                        ))}
                    </TableBody>
                    <Foot
                        cols={cols()}
                        editing={editing}
                        onOpen={
                            children && !readOnly && (() => open(null, 'new'))
                        }
                        onAdd={
                            !children &&
                            onAdd &&
                            !readOnly &&
                            (() => onAdd(name))
                        }
                    />
                </Table>
            </TableContainer>
            <Alert
                open={alert !== null}
                title={'¿Esta seguro de eliminar el elemento?'}
                text={`Si "Acepta" borrará definitivamente el elemento. Tenga en cuenta que esta acción es irreversible.`}
                cancel={() => setAlert(null)}
                accept={() => {
                    onDelete(alert.name, alert.index, alert.row);
                    setAlert(null);
                }}
            />
            {id &&
                React.Children.map(children, (child, index) => {
                    if (id.key === child.key || (!id.key && index === 0)) {
                        return React.cloneElement(child, {
                            open: id.id ? true : false,
                            id: id.id,
                            onClose: () => setId(null)
                        });
                    }
                })}
            {/*</Scrollbar>*/}
        </>
    );
};

export default DataGrid;
