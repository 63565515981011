export const LAYOUT_MAIN = 'LAYOUT_MAIN';
export const LAYOUT_MINIMAL = 'LAYOUT_MINIMAL';
export const STATE_BORRRADOR = 'Borrador';
export const STATE_ACTIVO = 'Activo';
export const STATE_BAJA = 'Baja';
export const STATE_TRASPASADO = 'Traspasado';
export const STATE_SOLICITUD = 'Solicitud';
export const SCOPE_MIEMBRO = 'Miembro';
export const SCOPE_PRODUCCION = 'Produccion';
export const SCOPE_ELABORACION = 'Elaboracion';
export const SCOPE_ALMACENAMIENTO = 'Almacenamiento';
export const SCOPE_CRIANZA = 'Crianza';
export const SCOPE_EMBOTELLADO = 'Embotellado';
export const CONTACT_TYPE_REPRESENTANTE = 'Representante';
//export const CONTACT_TYPE_DECLARANTE = 'Declarante';
export const CONTACT_TYPE_AUTORIZADO = 'Autorizado';
export const CONTACT_TYPE_CONTACTO = 'Contacto';
export const CONTACT_TYPE_DIRECCION = 'Direccion';
export const LOCK_PARCEL_COSECHA_EN_VERDE = 'Cosecha en Verde';
export const LOCK_PARCEL_EXCESO_RENDIMIENTO = 'Exceso Rendimiento';
export const INCIDENCE_PARCEL_COSECHA_EN_VERDE = 'Cosecha en Verde';
export const INCIDENCE_PARCEL_EXCESO_RENDIMIENTO_CAMPO =
    'Exceso Rendimiento Campo';
export const INCIDENCE_PARCEL_EXCESO_RENDIMIENTO_DECLARADO =
    'Exceso Rendimiento Declarado';
export const INCIDENCE_PARCEL_NO_GRADO_MINIMO = 'No grado minimo';
export const STATE_EXECUTION_PENDIENTE = 'Pendiente';
export const STATE_EXECUTION_EN_CURSO = 'En Curso';
export const STATE_EXECUTION_FINALIZADO = 'Finalizado';
export const STATE_LIQUIDATION_POR_LIQUIDAR = 'Por Liquidar';
export const STATE_LIQUIDATION_NO_LIQUIDABLE = 'No Liquidable';
export const STATE_LIQUIDATION_LIQUIDADO = 'Liquidado';
export const STATE_RESOLUTION_POR_RESOLVER = 'Por Resolver';
export const STATE_RESOLUTION_EN_CURSO = 'En Curso';
export const STATE_RESOLUTION_RESUELTO = 'Resuelto';
export const STATE_RESOLUTION_RECHAZADO = 'Rechazado';
export const RESULT_RESOLUTION_FAVORABLE = 'Favorable';
export const RESULT_RESOLUTION_NO_FAVORABLE = 'No favorable';
export const RESULT_RESOLUTION_PENDIENTE = 'Pendiente';
export const USE_FORM_SOLICITUD = 'Solicitud';
export const USE_FORM_ACTA = 'Acta';
export const USE_FORM_RESOLUTION = 'Resolucion';
//export const USE_FORM_RESOLUTION_DESFAVORABLE = 'Resolucion Desfavorable';
export const STATE_TASK_PENDIENTE = 'Pendiente';
export const STATE_TASK_POR_HACER = 'Por Hacer';
export const STATE_TASK_EN_CURSO = 'En Curso';
export const STATE_TASK_FINALIZADA = 'Finalizada';
export const VALIDATION_TASK_POR_VALIDAR = 'Por Validar';
export const VALIDATION_TASK_VALIDADA = 'Validada';
export const VALIDATION_TASK_RECHAZADA = 'Rechazada';
export const REPRESENTATION_ROL_INSPECTOR = 'Inspector';
export const REPRESENTATION_ROL_FORMADOR = 'Formador';
export const REPRESENTATION_ROL_OBSERVADOR = 'Observador';
export const REPRESENTATION_ROL_AUDITOR = 'Auditor';
export const AUTH_DOCUMENT_AUTORIZACION = 'Autorizacion';
export const AUTH_DOCUMENT_DNI = 'DNI';
export const AUTH_DOCUMENT_PODERES = 'Poderes';
export const AUTH_DOCUMENT_ESCRITURA = 'Escritura';
export const AUTH_ACTION_TODO = 'Todo';
export const AUTH_ACTION_DECLARACIONES = 'Declaraciones';
export const AUTH_ACTION_SOLICITUDES = 'Solicitudes';
export const AUTH_ACTION_CONSULTAS = 'Consultas';
export const AUTH_ACTION_AUTORIZACIONES = 'Autorizaciones';
export const LOG_DATA = 'LOG_DATA';
export const LOG_STATE = 'LOG_STATE';
export const LINK_SIGPAC = 'https://sigpac.mapama.gob.es/fega/visor/';
export const TYPE_QUOTA_COMPLETA = 'Cuota completa';
export const TYPE_QUOTA_PARCIAL = 'Cuota parcial';
export const TYPE_QUOTA_YA_EMITIDA = 'Cuota ya emitida';
export const TYPE_REPRESENTATIVE_CLIENTE = 'Cliente';
export const TYPE_REPRESENTATIVE_SOCIO = 'Socio';
//export const BODEGAS = 'Bodegas';
//export const AUDITORIAS = 'Auditorias';
