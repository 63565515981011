import moment from 'moment';
import es from 'moment/locale/es';
import config from 'settings/environment';
import { DateTime } from 'luxon';
import {
    LIST_FAILED,
    LIST_SELECT_SOME,
    LIST_SUCCESS
} from '../store/List/constants';
import { AUTH_KEY } from '../store/Auth/constants';

let timeBoxId = 0;

export const getNumero = num => {
    if (num) {
        if (num.includes(',')) {
            return Number(num.replace(/\./g, '').replace(',', '.'));
        }
        return Number(num);
    }

    return 0;
};

export const capitalizeString = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getInitials = (name = '') => {
    if (!name) return '';

    return name
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map(v => v && v[0].toUpperCase())
        .join('');
};

export const getAvatar = (id, avatar) => {
    return `${config.rutaPerfiles}/${id}/${avatar}`;
};

// Compara un item con un valor segun un operador y un tipo
export const compare = (item, valor, op, type, flag) => {
    let resultado = false;

    const value = (valor, tipo) => {
        let resultado = false;

        switch (tipo.toLowerCase()) {
            case 'text':
                resultado = valor.toLowerCase();
                break;

            case 'number':
                resultado = parseFloat(valor.toString().replace(',', '.'));
                break;

            case 'date':
            case 'datetime-local':
            case 'month':
                resultado = Date.parse(valor);
                break;

            default:
                resultado = valor;
        }

        return resultado;
    };

    const compr = (item, valor, op, type, flag) => {
        let resultado = false;

        switch (op) {
            case '=':
                resultado = value(item, type) === value(valor, type);
                break;

            case '>=':
                resultado = value(item, type) >= value(valor, type);
                //console.log(item,'>=',valor)
                break;

            case '<=':
                resultado = value(item, type) <= value(valor, type);

                //console.log(item,'<=',valor)
                break;

            case '>':
                resultado = value(item, type) > value(valor, type);
                break;

            case '<':
                resultado = value(item, type) < value(valor, type);
                break;

            case '<>':
                if (type === 'text') {
                    if (flag && flag === '===')
                        resultado = value(item, type) !== value(valor, type);
                    else
                        resultado = !value(item, type).match(
                            value(valor, type)
                        );
                } else resultado = value(item, type) !== value(valor, type);
                break;

            case 'in':
                resultado = valor.indexOf(item) !== -1;

                break;

            case '!in':
                resultado = valor.indexOf(item) === -1;

                break;

            case 'between':
                if (type.toLowerCase() === 'text' && isNaN(Number(item))) {
                    try {
                        resultado = value(item, type).match(value(valor, type));
                    } catch (e) {
                        console.log('error');
                    }

                    break;
                }

                const operandos = getOperandos(valor, type);

                if (!operandos.desde && !operandos.hasta) {
                    if (operandos.value) {
                        if (type === 'text')
                            if (flag && flag === '===')
                                resultado =
                                    value(item, type) ===
                                    value(operandos.value, type);
                            else
                                resultado = value(item, type).match(
                                    value(operandos.value, type)
                                );
                        else {
                            resultado =
                                value(item, type) ===
                                value(operandos.value, type);
                        }
                    }
                } else {
                    let ok = true;

                    if (operandos.desde) {
                        ok =
                            ok &&
                            value(item, type) >= value(operandos.desde, type);
                    }

                    if (operandos.hasta && ok) {
                        ok =
                            ok &&
                            value(item, type) <= value(operandos.hasta, type);
                    }
                    resultado = ok;
                }
                break;

            default:
                if (type === 'text') {
                    if (flag && flag === '===')
                        resultado = value(item, type) === value(valor, type);
                    else
                        resultado = value(item, type).match(value(valor, type));
                } else {
                    resultado = value(item, type) === value(valor, type);
                }
        }

        return resultado;
    };

    if (item === null) {
        if (op === '<>' || op === '<=' || op === '<') resultado = true;

        // Esto lo incluyo para que si el valor a comparar es null lo tenga en cuenta como si fuera un OR
        if (op === '>=' || op === '>') resultado = true;

        if (op === '=' && type.toLowerCase() === 'checkbox' && !valor)
            resultado = true;
    } else {
        if (Array.isArray(item)) {
            if (flag === '===') {
                // Busco desde el punto de vista del valor del filtro y se tienen que cumplir todos los valores
                resultado = true;
                for (var j = 0; j < valor.length; j++) {
                    resultado =
                        resultado && compr(valor[j], item, op, type, flag);
                }
            } else {
                // Busco desde el punto de vista de los valores de campo y en cuanto se cumple uno lo doy por bueno
                for (var k = 0; k < item.length; k++) {
                    resultado = compr(item[k], valor, op, type, flag);
                    if (resultado) break;
                }
            }
        } else resultado = compr(item, valor, op, type, flag);
    }

    return resultado;
};

const getOperandos = (valor, type) => {
    const partes = valor.toString().split('*');

    // Aplico el tipo
    switch (type.toLowerCase()) {
        case 'text':
            break;

        case 'number':
            break;

        case 'date':
        case 'datetime-local':
        case 'month':
            const inicial = getOperandoFecha(partes[0]);

            if (inicial !== '') partes[0] = `${inicial} 00:00:00`;
            if (partes.length === 1) partes.push(`${inicial} 23:59:59`);
            else if (partes[1] !== '')
                partes[1] = `${getOperandoFecha(partes[1])} 23:59:59`;

            break;

        default:
            break;
    }

    return {
        value: partes.length === 1 ? partes[0].trim() : undefined,
        desde:
            partes.length === 1 || partes[0] === ''
                ? undefined
                : partes[0].trim(),
        hasta: partes[1] && partes[1] !== '' ? partes[1].trim() : undefined
    };
};

const getOperandoFecha = value => {
    const añoActual = new Date().getFullYear().toString();
    const mesActual = (new Date().getMonth() + 1).toString();
    let newFecha = '';

    if (value !== '') {
        const partes = value.split('/');

        const año = partes[2] ? partes[2].trim() : añoActual;
        const mes = partes[1] ? partes[1].trim() : mesActual;
        const dia = partes[0].trim();

        newFecha = `${
            año.length === 2 ? Number(año) + 2000 : año
        }-${mes}-${dia}`;
    }

    return newFecha;
};

export const pausa = intervalo => {
    var d = new Date();
    var begin = d.getTime();

    var a = 0;
    while (a === 0) {
        d = new Date();
        if (d.getTime() - begin > intervalo) {
            a = 1;
        }
    }
};

export const reloj = (id, setDate) => {
    if (id !== 0) return () => clearInterval(id);

    return setInterval(() => {
        setDate(new Date());
        //console.log('Tic')
    }, 1000);
};

export const timer = (id, intervalo, accion) => {
    if (id !== 0) {
        return clearInterval(id);
    }

    const newId = setInterval(() => {
        accion();
    }, intervalo);

    return newId;
};

export const contadorTemporal = tiempo => {
    if (tiempo < 0) return '000:00:00';

    if (tiempo === undefined || tiempo === null || tiempo === '') return null;

    // Obtengo las horas
    const horas = Math.trunc(tiempo / 3600);

    // obtengo los minutos
    const minutos = Math.trunc((tiempo - horas * 3600) / 60);

    // obtengo los segundos
    const segundos = tiempo - horas * 3600 - minutos * 60;

    return (
        horas.toString().padStart(3, '0') +
        ':' +
        minutos.toString().padStart(2, '0') +
        ':' +
        segundos.toString().padStart(2, '0')
    );
};

export const segundosContador = contador => {
    if (contador === undefined || contador === null || contador === '')
        return 0;

    const elementos = contador.split(':');

    let horas = 0;
    let minutos = 0;
    let segundos = 0;

    // Obtengo las horas
    elementos.length > 0 &&
        (horas = parseInt(elementos[0] === '' ? 0 : elementos[0]));

    // obtengo los minutos
    elementos.length > 1 &&
        (minutos = parseInt(elementos[1] === '' ? 0 : elementos[1]));

    // obtengo los segundos
    elementos.length > 2 &&
        (segundos = parseInt(elementos[2] === '' ? 0 : elementos[2]));

    return horas * 3600 + minutos * 60 + segundos;
};

export const getValue = (obj, property) => {
    const prop = Array.isArray(property) ? property[0] : property;
    const newProp = Array.isArray(property) ? property[1] : null;
    let newObj = null;

    if (Array.isArray(obj)) {
        newObj = [];
        obj.forEach(itemObj => {
            const value = getValueProperty(itemObj, prop);
            if (value !== null && !newObj.includes(value)) newObj.push(value);
        });
    } else {
        newObj = getValueProperty(obj, prop);
    }

    if (newProp !== null && newObj !== null) {
        return getValue(newObj, newProp);
    }

    return newObj;
};

const getValueProperty = (obj, property) => {
    let value = '';

    // Compruebo si son varias propiedades concatenadas con  '+'
    // Si encuentra [] lo de dentro es un literal que tienen que concatenar
    const properties = property.split('+');

    if (properties.length === 1) {
        // Lo separo en dos casos para evitar que me concatene valores y me los cambie de tipo a string
        value = obj[property];
    } else {
        properties.forEach(e => {
            if (e.startsWith('['))
                // Es un literal lo concateno
                value = value + e.substring(1, e.length - 1);
            else {
                value =
                    !isObject(obj[e]) && obj[e] !== null
                        ? value + obj[e]
                        : obj[e];
            }
        });
    }

    return value;
};

export const getValueFormatted = (type, value) => {
    if (typeof type === 'function') return type(value);

    let newValue = '';
    let tp = type === undefined ? 'text' : type.toLowerCase();
    let decimales = 0;

    if (tp.startsWith('d.')) {
        decimales = Number(tp.substring(2));
        tp = 'decimal';
    }

    switch (tp) {
        case 'text':
            newValue = value;
            break;

        case 'number':
            newValue = value;
            break;

        case 'decimal':
            newValue = getNumberFormatted(value, decimales);
            break;

        case 'date':
            newValue = value === null ? '' : moment(value).format('DD/MM/YYYY');
            break;

        case 'time':
            newValue = value === null ? '' : moment(value).format('HH:mm:ss');
            break;

        case 'datetime':
            newValue =
                value === null
                    ? ''
                    : moment(value).format('DD/MM/YYYY HH:mm:ss');
            break;
        case 'datetime-base':
            newValue =
                value === null ? '' : moment(value).format('DD/MM/YYYY HH:mm');
            break;

        case 'datetime-local':
            newValue =
                value === null
                    ? ''
                    : moment(value).format(moment.HTML5_FMT.DATETIME_LOCAL);
            break;

        case 'day':
            newValue =
                value === null ? '' : moment(value).format('dddd DD MMMM YYYY');
            break;

        case 'week':
            newValue =
                value === null
                    ? ''
                    : `${moment(value).format('WW')} [${moment(value)
                          .startOf('week')
                          .format('dddd DD')} - ${moment(value)
                          .endOf('week')
                          .format('dddd DD')}] ${moment(value).format('MMMM')}`;
            break;

        case 'month':
            newValue = value === null ? '' : moment(value).format('MMMM YYYY');
            break;

        case 'timecounter':
            newValue = contadorTemporal(value);
            break;

        default:
            newValue = value;
    }

    return newValue;
};

const esFecha = valor => valor instanceof Date;

export const replacer = (key, value) => {
    // Compruebo las fechas para devolver la hora local y no la UTC
    if (esFecha(value)) {
        return undefined;
    }

    // Cambio los nulos por
    if (value === null) return '';

    return value;
};

export const now = date => {
    if (!date) return DateTime.now().toISO();

    return DateTime.fromISO(
        `${date}T${DateTime.now().hour}:${DateTime.now().minute}:${
            DateTime.now().second
        }`
    ).toISO();
};

export const toDay = () => {
    //return DateTime.now().toFormat(DateTime.DATE_SHORT);
    return moment().format('YYYY-MM-DD');
};

export const sumaLista = (lista, campos, formato) => {
    const obj = Array.isArray(campos);
    let total = obj ? {} : 0;

    lista.forEach(registro => {
        if (obj)
            campos.forEach(campo => {
                const acumulado = total[campo] ? total[campo] : 0;
                const value = registro[campo];

                total = {
                    ...total,
                    [campo]: acumulado + value
                };
            });
        else total = total + registro[campos];
    });

    if (!formato) return total;

    if (obj) {
        Object.keys(total).map(
            resume =>
                (total = {
                    ...total,
                    [resume]: getValueFormatted(formato, total[resume])
                })
        );
        return total;
    } else return getValueFormatted(formato, total);
};

export const singular = word => {
    return word.slice(0, word.length - 1);
};

export const dateFormat = period => {
    switch (period) {
        case 'days':
            return 'yyyy-MM-DD';

        case 'weeks':
            return 'GGGG-[W]WW';

        case 'months':
            return 'YYYY-MM';

        case 'years':
            return 'YYYY';

        default:
            return '';
    }
};

export const horasExtra = item => {
    const total = 0;

    return contadorTemporal(total);
};

export const groupBy = (list, groups) => {
    if (groups.length === 0) return list;

    const reducer = (acc, curr) => {
        const valueKey = curr[groups[0].key];
        const valueData = groups[0].data ? curr[groups[0].data] : {};
        const index = acc.findIndex(
            item => item[groups[0].key].toLowerCase() === valueKey.toLowerCase()
        );

        Reflect.deleteProperty(curr, groups[0].key);
        groups[0].data && Reflect.deleteProperty(curr, groups[0].data);

        if (index === -1) {
            acc.push(
                groups[0].data
                    ? {
                          [groups[0].key]: valueKey,
                          [groups[0].data]: valueData,
                          [groups[0].details]: [curr]
                      }
                    : {
                          [groups[0].key]: valueKey,
                          [groups[0].details]: [curr]
                      }
            );
        } else acc[index][groups[0].details].push(curr);

        return acc;
    };

    const newList = list.reduce(reducer, []);

    newList.map(item => {
        groups[0].aggregates &&
            Object.assign(
                item,
                sumaLista(item[groups[0].details], groups[0].aggregates)
            );
        item[groups[0].details] = groupBy(
            item[groups[0].details],
            groups.slice(1)
        );

        return item;
    });

    return newList;
};

export const identText = (text, identation) => {
    const ident = '  '.repeat(identation);

    console.log(ident + text);
    return ident + text;
};

export const timeBox = (action, time) => {
    timeBoxId > 0 && clearTimeout(timeBoxId);

    timeBoxId = setTimeout(action, time);
};

export const clone = obj => {
    return JSON.parse(JSON.stringify(obj));
};

export const isObject = obj => {
    return (
        (typeof obj === 'object' || typeof obj === 'function') && obj !== null
    );
};

export const empty = obj => {
    if (isObject(obj)) return Object.keys(obj).length === 0;

    return true;
};

export const getPosition = (latitud, longitud) => {
    alert(`Ir a Latitud: ${latitud}, Longitud: ${longitud}`);
};

export const getValueControl = (control, name, value) => {
    switch (control) {
        case 'combo':
            return {
                field: name,
                value: {
                    selected: clone(value),
                    name: name,
                    nameKey: `${name}Id`,
                    value: value ? value.id : null
                }
            };

        case 'text':
            return {
                field: name,
                value: value
            };

        default:
            return value;
    }
};

export const getNumberFormatted = (value, max) => {
    let decimales = 0;
    try {
        decimales = max
            ? max
            : value.split('.')[1]
              ? value.split('.')[1].length
              : 0;
    } catch {
        return '0';
    }

    if (value.toString().substring(value.length - 1) !== '.') {
        const number = Number(value); //Number(value.replace(',', '.'));
        const newValue = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: decimales
        }).format(number);

        return newValue;
    }

    return value.replace('.', ',');
};

const paraAnalizar = term => {
    var wordQuery = Array.map(term.trim().split(/\W/), function (word) {
            return "name contains '" + word + "'";
        }).join(' and '),
        datos = {
            corpora: 'user',

            orderBy: 'createdTime desc',

            pageSize: 500,

            spaces: 'drive',

            fields: '*',

            q: 'trashed = false and ' + wordQuery
        };
};

export const apiGet = api => {
    const token = localStorage.getItem(AUTH_KEY);

    return fetch(`${config.servidor}:${config.puerto}/api/${api}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
    })
        .then(response => {
            return response.json();
        })
        .then(
            data => data,
            error => error
        );
};

export const getNoneImg = () => {
    return 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"%3E%3C/svg%3E';
};

export const getForms = (forms, tipo) => {
    let newForms = [];

    forms
        .filter(form => form.activo && form.uso === tipo)
        .forEach(item => {
            newForms.push(item.formulario);
        });

    return newForms;
};
