import React, { useState } from 'react';

import { Grid, Typography, Divider } from 'core/components/library';

import * as icons from 'core/icons';

import { Text, Combo } from 'core/components';
import List from '../../../../../../store/List';
import { now } from '../../../../../../core/helpers';
import DetailUsuario from 'views/usuarios/Detalle';

const ComboAsignados = List(Combo, 'options', 'asignados');
const ComboValidadores = List(Combo, 'options', 'validadores');
const ComboInformados = List(Combo, 'options', 'informados');

const Detalles = props => {
    const { values, editing, adding, initial, handleChange, refresh } = props;

    const estableceFiltro = name => {
        const lst = [];

        if (values.flujo) {
            const list = values.flujo[name] ? values.flujo[name] : [];

            list.map(rol =>
                rol.idRol !== null
                    ? lst.push(rol.idRol)
                    : lst.push(rol.idEquipo)
            );
        }

        return [
            [
                ['roles', 'id'],
                ['roles', 'idEquipo']
            ],
            lst
        ];
    };

    const validateEquipo = (changes, event) => {
        // Borro Flujo
        changes.push({
            field: 'flujo',
            value: {
                selected: null,
                name: 'flujo',
                nameKey: 'idFlujo',
                value: null
            }
        });

        // Borro usuarios
        changes.push({
            field: 'usuarios',
            value: {
                name: 'usuarios',
                value: []
            }
        });

        // Borro validadores
        changes.push({
            field: 'validadores',
            value: {
                name: 'validadores',
                value: []
            }
        });

        // Borro informados
        changes.push({
            field: 'informados',
            value: {
                name: 'informados',
                value: []
            }
        });

        handleChange(changes, event);
    };

    const validateFlujo = (changes, event) => {
        // Borro usuarios
        changes.push({
            field: 'usuarios',
            value: {
                name: 'usuarios',
                value: []
            }
        });

        // Borro validadores
        changes.push({
            field: 'validadores',
            value: {
                name: 'validadores',
                value: []
            }
        });

        // Borro informados
        changes.push({
            field: 'informados',
            value: {
                name: 'informados',
                value: []
            }
        });

        handleChange(changes, event);
    };

    const validateEstado = (changes, event) => {
        // Compruebo como esta la validacion
        if (
            values.validacion.toLowerCase() === 'rechazada' ||
            values.validacion.toLowerCase() === 'validada'
        )
            return;

        handleChange(changes, event);
    };

    const validateValidacion = (changes, event) => {
        // Compruebo como esta el estado
        if (values.estado.toLowerCase() !== 'finalizada') return;

        handleChange(changes, event);
    };

    const asignaTarea = item => {
        // Tengo que sacar el valor original de la entidad
        const temp = initial.asignados
            ? initial.asignados.find(element => element.usuarioId === item.id)
            : undefined;

        if (temp === undefined)
            return {
                usuarioId: item.id,
                fechaAsignacion: now(),
                usuario: item
            };

        return {
            usuarioId: item.id,
            fechaAsignacion: temp.fechaAsignacion,
            fechaActivacion: temp.fechaActivacion,
            fechaDesactivacion: temp.fechaDesactivacion,
            activa: temp.activa,
            usuario: item
        };
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Text
                    fullWidth
                    name='detalles'
                    label='Descripción'
                    //helperText="Descripción detallada"
                    value={values.detalles}
                    onChange={handleChange}
                    editing={editing}
                    multiline
                    rows={18}
                />
            </Grid>
            <Grid item xs>
                <Grid container direction='column' spacing={1}>
                    <Grid item>
                        <Typography variant='caption'>Asignado a</Typography>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <ComboAsignados
                            fullWidth
                            api='usuarios'
                            multiple
                            name='asignados'
                            label='Trabajadores'
                            Icon={icons.UserIcon}
                            value={
                                values.asignados
                                    ? values.asignados.map(item => {
                                          return {
                                              ...item.usuario,
                                              activa: item.activa
                                          };
                                      })
                                    : []
                            }
                            onChange={handleChange}
                            editing={editing}
                            //filtro={estableceFiltro("asignados")}
                            propertyValue='id'
                            getOptionLabel={option => option.nombre}
                            lockOption={option =>
                                option.activa === null ? false : option.activa
                            }
                            colorOptionLocked='green'
                            getItem={asignaTarea}
                            relacionado={{
                                Detail: DetailUsuario,
                                resume: ['nombre'],
                                readOnly: true
                            }}
                            refresh={refresh}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant='caption'>Validado por</Typography>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <ComboValidadores
                            fullWidth
                            api='usuarios'
                            multiple
                            name='validadores'
                            label='Validadores'
                            Icon={icons.UserIcon}
                            //helperText="Validadores de la tarea"
                            value={
                                values.validadores
                                    ? values.validadores.map(item => item)
                                    : []
                            }
                            onChange={handleChange}
                            editing={editing}
                            //filtro={estableceFiltro("validados")}
                            propertyValue='id'
                            getOptionLabel={option => option.nombre}
                            getItem={item => ({
                                id: item.id,
                                nombre: item.nombre
                            })}
                            relacionado={{
                                Detail: DetailUsuario,
                                resume: ['nombre'],
                                readOnly: true
                            }}
                            refresh={refresh}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant='caption'>Informado a</Typography>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <ComboInformados
                            fullWidth
                            api='usuarios'
                            multiple
                            name='informados'
                            label='Informados'
                            Icon={icons.UserIcon}
                            //helperText="Usuarios notificados por la tarea"
                            value={
                                values.informados
                                    ? values.informados.map(item => item)
                                    : []
                            }
                            onChange={handleChange}
                            editing={editing}
                            //filtro={estableceFiltro("informados")}
                            propertyValue='id'
                            getOptionLabel={option => option.nombre}
                            getItem={item => ({
                                id: item.id,
                                nombre: item.nombre
                            })}
                            relacionado={{
                                Detail: DetailUsuario,
                                resume: ['nombre'],
                                readOnly: true
                            }}
                            refresh={refresh}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Detalles;
