import { DEL_ENTITIES } from './actions';
import initialEntities from './initialEntities';

const initialState = initialEntities;

const delEntities = (state, action) => {
    return {};
};

const actions = {
    [DEL_ENTITIES]: delEntities
};

const entitiesReducer = (state = initialState, action) => {
    if (actions[action.type]) {
        return actions[action.type](state, action);
    }

    return state;
};

export default entitiesReducer;
