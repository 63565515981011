import React, { useEffect } from 'react';

import { Divider, Grid } from 'core/components/library';

import {
    Text,
    Select,
    Tabs,
    Documents,
    Tag,
    Switch,
    Combo,
    Emit
} from 'core/components';

import * as enums from 'core/enums';
import * as icons from 'core/icons';

import Detalles from './Tabs/TabDetalles';
import Parcelas from './Tabs/TabParcelas';
import Contactos from './Tabs/TabContactos';
import { statesEntity } from 'core/enums';
import List from '../../../../store/List';
import * as helpers from '../../../../core/helpers';

const ComboFormasJuridicas = List(Combo, 'options', 'lstformasjuridicas');
const reports = [
    {
        id: 'FichaViticola',
        title: 'Ficha Viticola activa del viticultor',
        resume: 'Ficha Viticola',
        filters: []
    },
    {
        id: 'Apoderamiento',
        title: 'Apoderamiento para la consulta de datos de forma telemática a traves de UVITI',
        resume: 'Apoderamiento',
        filters: []
    }
];

const Detalle = props => {
    const { values, editing, handleChange, refresh } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        required={true}
                        autoSelect
                        name='codigo'
                        label='Código'
                        value={values.codigo}
                        onChange={handleChange}
                        editing={editing}
                        //type='0.6'
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        name='nif'
                        label='NIF'
                        value={values.nif}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Select
                        fullWidth
                        name='censo'
                        label='Censo'
                        value={values.censo}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.censos}
                    />
                </Grid>
                <Grid item xs={3}>
                    <ComboFormasJuridicas
                        fullWidth
                        //margin='none'
                        //variant='standard'
                        label='Forma Jurídica'
                        api='auxiliares/formasjuridicas'
                        name='formaJuridica'
                        nameKey='formaJuridicaId'
                        refresh={refresh}
                        Icon={icons.FormaJuridicaIcon}
                        value={values.formaJuridica}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue='id'
                        getOptionLabel={option => `${option['descripcion']}`}
                    />
                </Grid>
                <Grid item xs>
                    <Select
                        fullWidth
                        name='tipoDedicacion'
                        label='Dedicación'
                        value={values.tipoDedicacion}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.tipoSocio}
                    />
                </Grid>
                <Grid item>
                    <Emit
                        disabled={false}
                        hidden={editing}
                        toolTipText={'Emitir'}
                        reports={reports}
                        values={values}
                    />
                </Grid>
                <Grid item xs>
                    <Tag
                        align={'center'}
                        size={'medium'}
                        sx={{
                            color: enums.getItem(
                                statesEntity,
                                values.estado,
                                'color'
                            )
                        }}
                        Icon={enums.getItem(
                            statesEntity,
                            values.estado,
                            'icon'
                        )}
                        value={values.estado}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='nombre'
                        label='Nombre / Razón Social'
                        value={values.nombre}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        name='nombreComercial'
                        label='Nombre Comercial'
                        value={values.nombreComercial}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Divider style={{ margin: '12px 12px 0px 12px' }} />
                        <Grid item>
                            <Text
                                fullWidth
                                multiline
                                rows={2}
                                name='direccion'
                                label='Dirección'
                                value={values.direccion}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Text
                                        fullWidth
                                        name='cp'
                                        label='Cód. Postal'
                                        value={values.cp}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        name='poblacion'
                                        label='Población'
                                        value={values.poblacion}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Text
                                fullWidth
                                name='provincia'
                                label='Provincia'
                                value={values.provincia}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Divider style={{ margin: '12px 12px 0px 12px' }} />
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        multiline
                                        rows={2}
                                        name='tlf'
                                        label='Tlfno.'
                                        value={values.tlf}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        fullWidth
                                        multiline
                                        rows={2}
                                        name='fax'
                                        label='Fax'
                                        value={values.fax}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Text
                                fullWidth
                                name='email'
                                label='@'
                                value={values.email}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item>
                            <Text
                                fullWidth
                                name='web'
                                label='web'
                                value={values.web}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Detalles',
                        icon: icons.DetailIcon,
                        component: <Detalles {...props} />
                    },
                    {
                        label: 'Ficha',
                        icon: icons.ParcelIcon,
                        component: <Parcelas {...props} />,
                        hide: false
                    },
                    {
                        label: 'Contactos',
                        icon: icons.ContactsIcon,
                        component: <Contactos {...props} />,
                        disabled: false
                    },
                    {
                        label: 'Documentos',
                        icon: icons.DocumentIcon,
                        component: (
                            <Documents
                                name='documentacion'
                                editing={editing}
                                onChange={handleChange}
                                rows={
                                    values.documentacion
                                        ? values.documentacion
                                        : []
                                }
                                //maxHeight={456}
                            />
                        ),
                        disabled: false
                    }
                ]}
            />
        </form>
    );
};

export default Detalle;
