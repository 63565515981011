import React from 'react';

import { makeStyles } from '../library';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Paper,
    IconButton,
    Tooltip,
    Typography,
    SimpleDraggable as Draggable
} from '../library';

import * as icons from '../icons';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-star',
        '& > *': {
            marginLeft: theme.spacing(1)
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[20],
        padding: theme.spacing(2, 4, 3)
    }
}));

const PaperComponent = props => {
    return (
        <Draggable
            handle='#draggable-dialog-title'
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper width={500} {...props} />
        </Draggable>
    );
};

const Modal = props => {
    const { open, close, title, children, ...rest } = props;

    const classes = useStyles();

    if (!open) return null;

    return (
        <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            {...rest}
        >
            <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
                <div className={classes.wrapper}>
                    <Tooltip title={'Cerrar'}>
                        <IconButton size='small' onClick={close}>
                            <icons.CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography variant='h6'>{title}</Typography>
                </div>
            </DialogTitle>
            <DialogContent className={classes.paper}>{children}</DialogContent>
        </Dialog>
    );
};

export default Modal;
