import React, { useState } from 'react';

import { makeStyles } from '../library';
import { Badge, CircularProgress, Fab, IconButton, Tooltip } from '../library';

import { SelectModal, Modal } from '../';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    wrapper: {
        position: 'relative'
    },
    icProgreso: {
        position: 'absolute',
        top: 7,
        left: 7,
        zIndex: 1,
        size: 33
    },
    fbProgreso: {
        position: 'absolute',
        top: 7,
        left: 7,
        zIndex: 1
    },
    acccion: {
        margin: theme.spacing(1)
    }
}));

const ButtonBase = props => {
    const { toolTipText, float, ...rest } = props;

    if (float)
        return (
            <Tooltip title={toolTipText}>
                <Fab {...rest} />
            </Tooltip>
        );

    return (
        <Tooltip title={toolTipText}>
            <IconButton {...rest} />
        </Tooltip>
    );
};

const Button = props => {
    const {
        className,
        hidden,
        toolTipText,
        disabled,
        counter,
        onClick,
        icon,
        busy,
        float,
        selector,
        title,
        relacionado,
        maxWidth,
        fullWidth,
        fullScreen,
        ...rest
    } = props;

    const classes = useStyles();

    const [openSelect, setOpenSelect] = useState(false);

    const [openRelacionado, setOpenRelacionado] = useState(false);

    const useSelector = () => {
        if (selector.list.length === 0) selector.action(selector.list[0].key);
        else setOpenSelect(true);
    };

    if (hidden) return null;

    return (
        <div className={classes.wrapper}>
            <ButtonBase
                {...rest}
                className={classes.acccion}
                float={float}
                toolTipText={toolTipText}
                size='small'
                onClick={
                    !selector
                        ? relacionado
                            ? () => setOpenRelacionado(true)
                            : onClick
                        : useSelector
                }
                disabled={disabled}
            >
                <Badge
                    color={Number.isInteger(counter) ? 'error' : 'default'}
                    badgeContent={counter}
                >
                    {icon}
                </Badge>
            </ButtonBase>

            {busy && (
                <CircularProgress
                    disableShrink
                    className={float ? classes.fbProgreso : classes.icProgreso}
                    size={float ? 43 : 33}
                />
            )}
            <SelectModal
                selector={selector}
                open={openSelect}
                close={() => setOpenSelect(false)}
            />
            <Modal
                open={openRelacionado}
                close={() => setOpenRelacionado(false)}
                title={title}
                maxWidth={maxWidth}
                fullWidth={fullWidth}
                fullScreen={fullScreen}
            >
                {relacionado}
            </Modal>
        </div>
    );
};

export default Button;
