import React from 'react';

import { Stack, Switch as SwitchBase, Typography, Tooltip } from '../library';
const Switch = props => {
    const {
        name,
        align,
        value,
        onChange,
        editing,
        hidden,
        label,
        labelPlacement,
        tooltipText,
        color
    } = props;

    if (hidden) return null;
    return (
        <Stack
            sx={{
                height: '100%',
                margin: '0px 10px 0px 10px'
            }}
            direction='row'
            alignItems='center'
            justifyContent={align ? align : 'start'}
        >
            {label && (labelPlacement === 'start' || !labelPlacement) && (
                <Typography>{label}</Typography>
            )}
            <Tooltip title={tooltipText}>
                <SwitchBase
                    sx={{ cursor: !editing ? 'default' : 'pointer' }}
                    name={name}
                    color={color}
                    checked={value}
                    onChange={
                        onChange &&
                        editing &&
                        (event =>
                            onChange([
                                {
                                    field: event.target.name,
                                    value: event.target.checked,
                                    event
                                }
                            ]))
                    }
                />
            </Tooltip>
            {label && labelPlacement === 'end' && (
                <Typography>{label}</Typography>
            )}
        </Stack>
    );
};

export default Switch;
