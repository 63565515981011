import { lazy } from 'react';
import Loadable from 'core/components/Loadable';

import { LAYOUT_MAIN, LAYOUT_MINIMAL } from '../core/constants';
import Bodegas from 'views/maestros/Bodegas';
import Informes from 'views/Pruebas';

import * as constants from 'store/entities/constants';

const Dashboard = Loadable(lazy(() => import('views/dashboard/Default')));
const Typography = Loadable(lazy(() => import('views/utilities/Typography')));
const Colors = Loadable(lazy(() => import('views/utilities/Color')));
const Shadows = Loadable(lazy(() => import('views/utilities/Shadow')));
const MaterialIcons = Loadable(
    lazy(() => import('views/utilities/MaterialIcons'))
);
const TablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const Sample = Loadable(lazy(() => import('views/sample-page')));
const NotFound = Loadable(lazy(() => import('entorno/notfound')));
const ReportView = Loadable(lazy(() => import('reports')));
const FormView = Loadable(lazy(() => import('reports/components/Report')));
//const Bodegas = Loadable(lazy(() => import('views/maestros/bodegas')));
const Etiquetado = Loadable(lazy(() => import('views/etiquetado')));
const Expediciones = Loadable(lazy(() => import('views/expediciones')));
const Normas = Loadable(lazy(() => import('views/normas')));
const Auditorias = Loadable(lazy(() => import('views/auditorias')));
const Tramites = Loadable(lazy(() => import('views/tramites/Tramites')));
const TiposTramite = Loadable(lazy(() => import('views/tramites/Tipos')));
const Proveedores = Loadable(lazy(() => import('views/compras/Proveedores')));
const Clientes = Loadable(lazy(() => import('views/ventas/Clientes')));
const Subcuentas = Loadable(
    lazy(() => import('views/contabilidad/subcuentas'))
);
const PartidasPresupuesto = Loadable(
    lazy(() => import('views/contabilidad/partidas'))
);
const PresupuestoContable = Loadable(
    lazy(() => import('views/contabilidad/presupuestos'))
);
const Gastos = Loadable(lazy(() => import('views/contabilidad/gastos')));
const Liquidaciones = Loadable(
    lazy(() => import('views/liquidaciones/Liquidaciones'))
);
const FormasPago = Loadable(lazy(() => import('views/tesoreria/formas-pago')));
const Bancos = Loadable(lazy(() => import('views/tesoreria/bancos')));
const Vencimientos = Loadable(
    lazy(() => import('views/tesoreria/vencimientos'))
);
const Diario = Loadable(lazy(() => import('views/contabilidad/diario')));
const MovimientosTesoreria = Loadable(
    lazy(() => import('views/tesoreria/movimientos'))
);
const Socios = Loadable(lazy(() => import('views/maestros/Socios')));
const Viticultores = Loadable(
    lazy(() => import('views/maestros/Viticultores'))
);
const Tablero = Loadable(lazy(() => import('views/seguimiento/tablero')));
const Tareas = Loadable(lazy(() => import('views/seguimiento/Tareas')));
const Usuarios = Loadable(lazy(() => import('views/usuarios')));
const Mod347 = Loadable(
    lazy(() => import('views/contabilidad/declaraciones/m347'))
);
const Explotaciones = Loadable(
    lazy(() => import('views/maestros/Explotaciones'))
);
const Parcelas = Loadable(lazy(() => import('views/maestros/Parcelas')));
const Cosecha = Loadable(lazy(() => import('views/campaña/Cosecha')));
const Marcas = Loadable(lazy(() => import('views/maestros/Marcas')));
const Etiquetas = Loadable(lazy(() => import('views/maestros/Etiquetas')));

export const privateRoutes = layouts => [
    {
        path: '/Report/:Report',
        element: <ReportView />
    },
    {
        path: '/form/:form',
        element: <FormView />
    },
    {
        path: '/',
        element: layouts[LAYOUT_MAIN],
        children: [
            {
                path: '/',
                element: <Dashboard />
            },
            {
                path: '/seguimiento/informes',
                element: <Informes />
            },
            {
                path: '/seguimiento/dashboard',
                element: <Dashboard />
            },
            {
                path: '/seguimiento/tablero',
                element: <Tablero />
            },
            {
                path: '/gestion/maestros/bodegas',
                element: <Bodegas />
            },
            {
                path: '/gestion/maestros/centros',
                element: <Explotaciones />
            },
            {
                path: '/gestion/maestros/marcas',
                element: <Marcas />
            },
            {
                path: '/gestion/maestros/etiquetasbodega',
                element: <Etiquetas />
            },
            {
                path: '/gestion/maestros/socios',
                element: <Socios />
            },
            {
                path: '/gestion/maestros/viticultores',
                element: <Viticultores />
            },
            {
                path: '/gestion/maestros/parcelas',
                element: <Parcelas />
            },
            {
                path: '/gestion/cosecha',
                element: <Cosecha />
            },
            {
                path: '/gestion/maestros/proveedores',
                element: <Proveedores />
            },
            {
                path: '/gestion/maestros/clientes',
                element: <Clientes />
            },
            {
                path: '/administracion/contabilidad/gastos',
                element: <Gastos />
            },
            {
                path: '/gestion/Liquidaciones/Liquidaciones',
                element: <Liquidaciones />
            },
            {
                path: '/administracion/tesoreria/formaspago',
                element: <FormasPago />
            },
            {
                path: '/administracion/tesoreria/bancos',
                element: <Bancos />
            },
            {
                path: '/gestion/maestros/viticultores',
                element: <NotFound />
            },
            {
                path: '/gestion/maestros/productos',
                element: <NotFound />
            },
            {
                path: '/gestion/maestros/usuarios',
                element: <Usuarios />
            },
            {
                path: '/gestion/tramites/definicion',
                element: <TiposTramite />
            },
            {
                path: '/gestion/tramites/tramites',
                element: <Tramites />
            },
            {
                path: '/gestion/control/normas',
                element: <Normas />
            },
            {
                path: '/gestion/control/auditorias',
                element: <Auditorias />
            },
            {
                path: '/gestion/control/inspecciones',
                element: <NotFound />
            },
            {
                path: '/gestion/control/catas',
                element: <NotFound />
            },
            {
                path: '/gestion/contraetiquetas/adquisiciones',
                element: <NotFound />
            },
            {
                path: '/gestion/contraetiquetas/expediciones',
                element: <Expediciones />
            },
            {
                path: '/gestion/contraetiquetas/inventario',
                element: <NotFound />
            },
            {
                path: '/seguimiento/tareas',
                element: <Tareas />
            },
            {
                path: '/gestion/stock',
                element: <NotFound />
            },
            {
                path: '/administracion/tramites',
                element: <NotFound />
            },
            {
                path: '/administracion/contabilidad/cuentas',
                element: <Subcuentas />
            },
            {
                path: '/administracion/contabilidad/partidas',
                element: <PartidasPresupuesto />
            },
            {
                path: '/administracion/contabilidad/presupuestos',
                element: <PresupuestoContable />
            },
            {
                path: '/administracion/contabilidad/asientos',
                element: <NotFound />
            },
            {
                path: '/administracion/contabilidad/gastos',
                element: <NotFound />
            },
            {
                path: '/administracion/contabilidad/diario',
                element: <Diario />
            },
            {
                path: '/administracion/tesoreria/vencimientos',
                element: <Vencimientos />
            },
            {
                path: '/administracion/tesoreria/movimientos',
                element: <MovimientosTesoreria />
            },
            {
                path: '/administracion/contabilidad/declaraciones/m347proveedores',
                element: <Mod347 tipo={constants.IMPUESTO_347_PROVEEDORES} />
            },
            {
                path: '/administracion/contabilidad/declaraciones/m347clientes',
                element: <Mod347 tipo={constants.IMPUESTO_347_CLIENTES} />
            }
        ]
    },
    { path: '*', element: <NotFound /> }
];
