import {
    ENTITY_INIT,
    ENTITY_LOCK,
    ENTITY_UNLOCK,
    ENTITY_SUCCESS,
    ENTITY_FAILED,
    ENTITY_EDIT,
    ENTITY_REFRESH,
    ENTITY_UPDATE,
    ENTITY_UNLOAD,
    ENTITY_UPDATING,
    ENTITY_REFRESHING,
    ENTITY_UNDO,
    ENTITY_CLEAR,
    ENTITY_ADDED,
    ENTITY_SAVED,
    ENTITY_DELETED
} from './constants.js';

const initEntity = (state, action) => {
    const { entidad, api } = action.payload;

    return {
        ...state,
        [entidad]: {
            api: api,
            isInit: true,
            isReady: false,
            isEditable: false,
            isUpdating: false,
            errors: null,
            initial: {},
            changes: {},
            entity: {}
        }
    };
};

const lockEntity = (state, action) => {
    const { entidad } = action.payload;

    return {
        ...state,
        [entidad]: {
            ...state[entidad],
            isEditable: false
        }
    };
};

const unlockEntity = (state, action) => {
    const { entidad } = action.payload;

    return {
        ...state,
        [entidad]: {
            ...state[entidad],
            isEditable: true
        }
    };
};

const successEntity = (state, action) => {
    const { entidad, data } = action.payload;
    const api = state[entidad].api;
    let editable = false;
    let newChanges = {};

    if (api.toLowerCase().indexOf('/new') !== -1) {
        editable = api.toLowerCase().indexOf('/new') !== -1;
        newChanges = data;
    }

    return {
        ...state,
        [entidad]: {
            ...state[entidad],
            isInit: false,
            isReady: true,
            isEditable: editable,
            isUpdating: false,
            errors: null,
            initial: data,
            changes: newChanges,
            entity: data
        }
    };
};

const editEntity = (state, action) => {
    const { entidad, data } = action.payload;

    return {
        ...state,
        [entidad]: {
            ...state[entidad],
            isEditable: data
        }
    };
};

const failedEntity = (state, action) => {
    const { entidad, error } = action.payload;

    return {
        ...state,
        [entidad]: {
            ...state[entidad],
            isInit: false,
            isReady: true,
            isUpdating: false,
            errors: error
        }
    };
};

const refreshingEntity = (state, action) => {
    const { entidad } = action.payload;

    return {
        ...state,
        [entidad]: {
            ...state[entidad],
            isReady: true,
            isRefreshing: true,
            errors: null
        }
    };
};

const refreshEntity = (state, action) => {
    const { entidad, data, api, onlyInitial } = action.payload;

    if (!state[entidad]) return state;

    const newApi = api === undefined ? state[entidad].api : api;
    const newEditable =
        newApi.toLowerCase().indexOf('/new') !== -1
            ? true
            : state[entidad].isEditable;
    const changes = state[entidad].changes;

    //if(state[entidad].isEditable) return state;

    let newInitial = data;
    let newEntity = { ...data, ...changes };

    if (newApi === state[entidad].api) {
        newInitial = { ...state[entidad].initial, ...data };
        if (onlyInitial) newEntity = { ...state[entidad].entity };
        else newEntity = { ...state[entidad].entity, ...newEntity };
    }

    return {
        ...state,
        [entidad]: {
            ...state[entidad],
            api: newApi,
            isEditable: newEditable,
            isRefreshing: false,
            entity: newEntity,
            initial: newInitial,
            errors: null
        }
    };
};

const updateEntity = (state, action) => {
    const { entidad, data } = action.payload;

    if (!state[entidad]) return state;

    if (!state[entidad].isEditable) return state;

    const newEntity = { ...state[entidad].entity, ...data };
    const newChanges = { ...state[entidad].changes, ...data };

    return {
        ...state,
        [entidad]: {
            ...state[entidad],
            entity: newEntity,
            changes: newChanges,
            isUpdating: false
        }
    };
};

const undoEntity = (state, action) => {
    const { entidad } = action.payload;

    const newEntity = { ...state[entidad].initial };

    return {
        ...state,
        [entidad]: {
            ...state[entidad],
            entity: newEntity,
            isEditable: false,
            changes: {}
        }
    };
};

const clearEntity = (state, action) => {
    const { entidad } = action.payload;

    return {
        ...state,
        [entidad]: {
            ...state[entidad],
            isReady: true,
            errors: null
        }
    };
};

const unloadEntity = (state, action) => {
    const { entidad } = action.payload;

    const newState = { ...state };

    Reflect.deleteProperty(newState, entidad);

    return newState;
};

const updatingEntity = (state, action) => {
    const { entidad } = action.payload;

    return {
        ...state,
        [entidad]: {
            ...state[entidad],
            //isEditable: false, Esto es importante para el refresco del componente element
            isUpdating: true,
            errors: null
        }
    };
};

const addedEntity = (state, action) => {
    const { entidad, data } = action.payload;
    const api = state[entidad].api;
    const newApi = api.slice(0, api.toLowerCase().indexOf('new')) + data.id;

    const newEntity = { ...state[entidad].entity, ...data };
    const newInitial = { ...newEntity };
    const newState = { ...state };

    return {
        ...newState,
        [entidad]: {
            api: newApi,
            isInit: false,
            isReady: true,
            isEditable: false, // Lo pongo a true para que haya modificacion continua hasta que salga especificamente
            isUpdating: false,
            errors: null,
            entity: newEntity,
            initial: newInitial,
            changes: {}
        }
    };
};

const savedEntity = (state, action) => {
    const { entidad, data } = action.payload;

    const newEntity = { ...state[entidad].entity, ...data };
    const newInitial = { ...newEntity };

    return {
        ...state,
        [entidad]: {
            ...state[entidad],
            isReady: true,
            isEditable: false, // Lo pongo a true para que haya modificacion continua hasta que salga especificamente
            isUpdating: false,
            errors: null,
            entity: newEntity,
            initial: newInitial,
            changes: {}
        }
    };
};

const deletedEntity = (state, action) => {
    const { entidad } = action.payload;

    const newState = { ...state };

    Reflect.deleteProperty(newState, entidad);

    return newState;
};

// Mapeo las acciones con su implementacion
const actions = {
    [ENTITY_INIT]: initEntity,
    [ENTITY_LOCK]: lockEntity,
    [ENTITY_UNLOCK]: unlockEntity,
    [ENTITY_SUCCESS]: successEntity,
    [ENTITY_FAILED]: failedEntity,
    [ENTITY_EDIT]: editEntity,
    [ENTITY_REFRESH]: refreshEntity,
    [ENTITY_UPDATE]: updateEntity,
    [ENTITY_UNDO]: undoEntity,
    [ENTITY_CLEAR]: clearEntity,
    [ENTITY_UNLOAD]: unloadEntity,
    [ENTITY_UPDATING]: updatingEntity,
    [ENTITY_REFRESHING]: refreshingEntity,
    [ENTITY_ADDED]: addedEntity,
    [ENTITY_SAVED]: savedEntity,
    [ENTITY_DELETED]: deletedEntity
};

// Defino el estado inicial
const initialState = {};

// El reducer recibe el state, en caso de no existir utiliza el initialState y la accion correspondiente
const reducer = (state = initialState, action) => {
    // Si esixte una acccion devuelvo la accion y el estado
    if (actions[action.type]) {
        return actions[action.type](state, action);
    }

    // Si no devuelvo solamente el estado
    return state;
};

export default reducer;

// Exporto los selectores que permiten acceder a una parte concreta del estado
export const get = (entidad, state) =>
    state[entidad] ? state[entidad].entity : {};
export const getInitial = (entidad, state) =>
    state[entidad] ? state[entidad].initial : {};
export const getApi = (entidad, state) =>
    state[entidad] ? state[entidad].api : '';
export const changes = (entidad, state) =>
    state[entidad] ? state[entidad].changes : {};
export const isInit = (entidad, state) =>
    state[entidad] && state[entidad].isInit;
export const isReady = (entidad, state) =>
    state[entidad] && state[entidad].isReady;
export const isEditable = (entidad, state) =>
    state[entidad] && state[entidad].isEditable;
export const isUpdating = (entidad, state) =>
    state[entidad] && state[entidad].isUpdating;
export const isRefreshing = (entidad, state) =>
    state[entidad] && state[entidad].isRefreshing;
export const hasErrors = (entidad, state) =>
    state[entidad] && state[entidad].errors;
