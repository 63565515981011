import React from 'react';
import { Stack, Typography } from '../library';

const Literal = props => {
    const { text, decorator, children, color, ...rest } = props;

    return (
        <Stack
            sx={{ color: color }}
            direction='row'
            alignItems='center'
            spacing={1}
        >
            {(!decorator || decorator === 'start') && children}
            <Typography {...rest}>{text}</Typography>
            {decorator && decorator === 'end' && children}
        </Stack>
    );
};

export default Literal;
