import React, { useEffect } from 'react';
import { Divider, Grid, Chip } from 'core/components/library';
import { Signature, Document, Combo, Switch, Select } from 'core/components';
import Representantes from '../../../../../../entorno/Representantes';
import { ENTITY_CONTACTO } from 'store/entities/constants';
import * as icons from '../../../../../../core/icons';
import * as helpers from 'core/helpers';
import List from 'store/List';
import DetailRepresentante from 'views/entorno/contactos/Detalle';
import { DateTime } from 'luxon';
import Declaracion from '../../../Declaracion';
import * as enums from '../../../../../../core/enums';

const ComboRepsSocio = List(Combo, 'options', 'lstrepssocio');
const ComboRepsCliente = List(Combo, 'options', 'lstreprescliente');

const getAutorizadoPara = values => {
    if (values.tipo.clase === 'Declaracion') return ['Declaraciones', 'Todo'];

    if (values.tipo.clase === 'Solicitud') return ['Solicitudes', 'Todo'];

    return ['Todo'];
};

const Firmas = props => {
    const { values, editing, handleChange, refresh } = props;

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <Grid container direction='column' spacing={1}>
                        {/*
                        <Grid item>
                            <Signature
                                toolTipText={`Firmar ${values.tipo.clase}`}
                                disabled={editing}
                                onClick={() => alert('firma')}
                            />
                        </Grid>
                        */}
                        <Grid item xs>
                            <Divider style={{ margin: '12px 12px 12px 12px' }}>
                                <Chip label={'INTERESADO / REPRESENTANTE'} />
                            </Divider>
                        </Grid>
                        <Grid item xs>
                            <Grid container alignItems={'center'} spacing={1}>
                                {/*<Grid item xs={!values.representaA ? 3 : null}>
                                    <Select
                                        fullWidth
                                        name='representaA'
                                        label='Representa a'
                                        value={values.representaA}
                                        onChange={handleChange}
                                        editing={editing}
                                        options={enums.typeRepresentative}
                                    />
                                </Grid>*/}

                                <Grid item xs>
                                    {values.socio ? (
                                        <ComboRepsSocio
                                            fullWidth
                                            api='contactos'
                                            name='representante'
                                            nameKey='representanteId'
                                            label='Representante Socio'
                                            relacionado={{
                                                type: ENTITY_CONTACTO,
                                                Detail: DetailRepresentante,
                                                resume: ['nombre'],
                                                readOnly: true
                                            }}
                                            refresh={refresh}
                                            Icon={icons.ContactsIcon}
                                            value={values.representante}
                                            onChange={handleChange}
                                            editing={editing}
                                            propertyValue='id'
                                            getOptionLabel={option =>
                                                `${option.nombre}-[${option.nif}]-[${option.cargo}]`
                                            }
                                            searchKeys={[
                                                'cargo',
                                                'nombre',
                                                'nif'
                                            ]}
                                            filters={[
                                                {
                                                    fields: [['socio', 'id']],
                                                    values: [values.socio.id],
                                                    operator: 'in',
                                                    type: 'text'
                                                },
                                                {
                                                    fields: ['tipo'],
                                                    values: [
                                                        'Autorizado',
                                                        'Representante'
                                                    ],
                                                    operator: 'in',
                                                    type: 'text'
                                                },
                                                {
                                                    fields: ['autorizado'],
                                                    values: getAutorizadoPara(
                                                        values
                                                    ),
                                                    operator: 'in',
                                                    type: 'text'
                                                },
                                                {
                                                    fields: ['activo'],
                                                    values: true,
                                                    operator: '',
                                                    type: 'boolean'
                                                },
                                                {
                                                    fields: ['hasta'],
                                                    values: DateTime.fromISO(
                                                        values.fecha
                                                    ).toISODate(),
                                                    operator: '>=',
                                                    type: 'date'
                                                }
                                            ]}
                                        />
                                    ) : (
                                        <ComboRepsCliente
                                            fullWidth
                                            api='contactos'
                                            name='representante'
                                            nameKey='representanteId'
                                            label='Representante Cliente'
                                            relacionado={{
                                                type: ENTITY_CONTACTO,
                                                Detail: DetailRepresentante,
                                                resume: ['nombre'],
                                                readOnly: true
                                            }}
                                            refresh={refresh}
                                            Icon={icons.ContactsIcon}
                                            value={values.representante}
                                            onChange={handleChange}
                                            editing={editing}
                                            propertyValue='id'
                                            getOptionLabel={option =>
                                                `${option.nombre}-[${option.nif}]-[${option.cargo}]`
                                            }
                                            searchKeys={[
                                                'cargo',
                                                'nombre',
                                                'nif'
                                            ]}
                                            filters={[
                                                {
                                                    fields: [['cliente', 'id']],
                                                    values: [values.cliente.id],
                                                    operator: 'in',
                                                    type: 'text'
                                                },
                                                {
                                                    fields: ['tipo'],
                                                    values: [
                                                        'Autorizado',
                                                        'Representante'
                                                    ],
                                                    operator: 'in',
                                                    type: 'text'
                                                },
                                                {
                                                    fields: ['autorizado'],
                                                    values: getAutorizadoPara(
                                                        values
                                                    ),
                                                    operator: 'in',
                                                    type: 'text'
                                                },
                                                {
                                                    fields: ['activo'],
                                                    values: true,
                                                    operator: '',
                                                    type: 'boolean'
                                                },
                                                {
                                                    fields: ['hasta'],
                                                    values: DateTime.fromISO(
                                                        values.fecha
                                                    ).toISODate(),
                                                    operator: '>=',
                                                    type: 'date'
                                                }
                                            ]}
                                        />
                                    )}
                                </Grid>

                                <Grid item>
                                    <Switch
                                        value={values.excluirFirma}
                                        onChange={handleChange}
                                        name='excluirFirma'
                                        label='No Imprimir firma'
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid
                                container
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Document
                                    //objectFit={'cover'}
                                    name='firma'
                                    readOnly={!editing || values.firmaActa}
                                    width='300px'
                                    height='200px'
                                    value={
                                        values.firmaActa
                                            ? values.actas &&
                                              values.actas.length > 0
                                                ? values.actas[
                                                      values.actas.length - 1
                                                  ].firma
                                                : null
                                            : values.firma
                                    }
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Representantes
                        name={'intervinientes'}
                        title={'REPRESENTANTES OPA'}
                        values={values}
                        editing={editing}
                        handleChange={handleChange}
                        refresh={refresh}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Firmas;
