export const ENTITY_INIT = 'ENTITY_INIT';
export const ENTITY_SUCCESS = 'ENTITY_SUCCESS';
export const ENTITY_FAILED = 'ENTITY_FAILED';
export const ENTITY_EDIT = 'ENTITY_EDIT';
export const ENTITY_REFRESH = 'ENTITY_REFRESH';
export const ENTITY_UPDATE = 'ENTITY_UPDATE';
export const ENTITY_UNDO = 'ENTITY_UNDO';
export const ENTITY_CLEAR = 'ENTITY_CLEAR';
export const ENTITY_UNLOAD = 'ENTITY_UNLOAD';
export const ENTITY_UPDATING = 'ENTITY_UPDATING';
export const ENTITY_REFRESHING = 'ENTITY_REFRESHING';
export const ENTITY_SAVED = 'ENTITY_SAVED';
export const ENTITY_ADDED = 'ENTITY_ADDED';
export const ENTITY_DELETED = 'ENTITY_DELETED';
export const ENTITY_LOCK = 'ENTITY_LOCK';
export const ENTITY_UNLOCK = 'ENTITY_UNLOCK';
