import seguimiento from './seguimiento';
import maestros from './maestros';
import secretaria from './secretaria';
import administracion from './administracion';
import gestion from './gestion';
import control from './control';
import utilities from './utilities';
import other from './other';
import { useSelector } from 'react-redux';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [seguimiento, maestros, gestion, administracion] //, other]
};

const applyPermissions = items => {
    return items;
};

export default applyPermissions(menuItems);
