import React from 'react';
import { Button } from '../index';

const openLinks = links => {
    if (links) {
        links.forEach(link => window.open(link, '_blank'));
    }
};

const Links = props => {
    const { hidden, toolTipText, disabled, icon, links } = props;

    return (
        <Button
            hidden={hidden}
            toolTipText={toolTipText}
            disabled={disabled}
            onClick={event => {
                openLinks(links);
            }}
            icon={icon}
            float={true}
        />
    );
};

export default Links;
