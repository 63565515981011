import React, { useEffect, useState } from 'react';

import { Grid, Divider, Chip } from 'core/components/library';

import {
    Select,
    DataGridPro as DataGrid,
    Text,
    Combo,
    Button
} from 'core/components';

import * as helpers from 'core/helpers';
import * as enums from 'core/enums';
import List from '../../../../../../store/List';
import DetailSubcuenta from 'views/contabilidad/subcuentas/Detalle';
import * as icons from '../../../../../../core/icons';

const ComboSubcuentas = List(Combo, 'options', 'lstSubcuentas');
const ComboContrapartidas = List(Combo, 'options', 'lstSubcuentas');

const cols = (name, row, index, editing, handleChange, refresh) => [
    {
        key: 'fecha',
        title: 'Fecha',
        align: 'left',
        width: '10%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                type={'date'}
                name='fecha'
                variant='standard'
                margin='none'
                value={row['fecha']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    },
    {
        key: 'subcuenta',
        title: 'Cuenta',
        align: 'left',
        width: '35%',
        component: row && (
            <ComboSubcuentas
                fullWidth
                margin='none'
                variant='standard'
                api='subcuentas/activas'
                name='subcuenta'
                nameKey='subcuentaid'
                relacionado={{
                    Detail: DetailSubcuenta,
                    resume: ['descripcion'],
                    readOnly: false
                }}
                refresh={refresh}
                Icon={icons.CuentasIcon}
                value={row.subcuenta}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option => `${option.id} ${option.descripcion} `}
            />
        )
    },
    {
        key: 'debe',
        title: 'Debe €',
        align: 'left',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='debe'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['debe']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    },
    {
        key: 'haber',
        title: 'Haber €',
        align: 'left',
        //width: '1%',
        component: row && (
            <Text
                fullWidth
                editing={editing}
                name='haber'
                variant='standard'
                margin='none'
                type='d.2'
                value={row['haber']}
                onFocus={event => event.target.select()}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
            />
        )
    },
    {
        key: 'contrapartida',
        title: 'Contrapartida',
        align: 'left',
        width: '35%',
        component: row && (
            <ComboContrapartidas
                fullWidth
                margin='none'
                variant='standard'
                api='subcuentas/activas'
                name='contrapartida'
                nameKey='contrapartidaid'
                relacionado={{
                    Detail: DetailSubcuenta,
                    resume: ['descripcion'],
                    readOnly: false
                }}
                refresh={refresh}
                Icon={icons.CuentasIcon}
                value={row.contrapartida}
                onChange={
                    handleChange &&
                    (values => handleChange(values, name, index))
                }
                editing={editing}
                propertyValue='id'
                getOptionLabel={option => `${option.id} ${option.descripcion} `}
            />
        )
    }
];

const Apuntes = props => {
    const {
        values,
        editing,
        adding,
        handleChange,
        refresh,
        reload,
        refreshing,
        execAction
    } = props;

    const [debe, setDebe] = useState(0);
    const [haber, setHaber] = useState(0);
    const [saldo, setSaldo] = useState(0);

    useEffect(() => {
        if (values.apuntes) {
            if (values.apuntes.length !== 0) actualizaTotales(values.apuntes);
        }
    }, [JSON.stringify(values.apuntes)]);

    const actualizaTotales = apuntes => {
        let debe = 0;
        let haber = 0;

        apuntes.forEach(apunte => {
            debe = debe + Number(apunte.debe);
            haber = haber + Number(apunte.haber);
        });

        setDebe(debe.toFixed(2));
        setHaber(haber.toFixed(2));
        setSaldo((debe - haber).toFixed(2));
    };

    const action = () => {
        const accion = {
            api: 'liquidaciones/asiento',
            metodo: 'post',
            data: { ...values }
        };
        execAction(accion, [reload]);
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs>
                    <DataGrid
                        name='apuntes'
                        editing={editing}
                        rows={values.apuntes ? values.apuntes : []}
                        cols={(name, row, index) =>
                            cols(
                                name,
                                row,
                                index,
                                editing,
                                handleChange,
                                refresh
                            )
                        }
                        onAdd={name =>
                            handleChange(
                                {
                                    apunte: values.apuntes
                                        ? values.apuntes.length + 1
                                        : 1,
                                    fecha: values.fecha,
                                    concepto: values.concepto,
                                    debe: '0.00',
                                    haber: '0.00'
                                },
                                name
                            )
                        }
                        onDelete={(name, index) =>
                            handleChange(undefined, name, (index + 1) * -1)
                        }
                        minHeight={250}
                        maxHeight={250}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Divider style={{ margin: '12px 12px 12px 12px' }}>
                        <Chip label={'TOTALES'} />
                    </Divider>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Button
                        toolTipText='Actualiza'
                        disabled={!editing}
                        onClick={event => action()}
                        counter={0}
                        busy={refreshing}
                        icon={<icons.RefreshIcon />}
                        float={true}
                    />
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='debe'
                        label='Debe €'
                        value={debe}
                        //onChange={handleChange}
                        onFocus={event => event.target.select()}
                        editing={false}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='haber'
                        label='Haber €'
                        value={haber}
                        //onChange={handleChange}
                        onFocus={event => event.target.select()}
                        editing={false}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        fullWidth
                        type='d.2'
                        name='saldo'
                        label='Saldo €'
                        value={saldo}
                        //onChange={handleChange}
                        onFocus={event => event.target.select()}
                        editing={false}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Apuntes;
