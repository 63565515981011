import React, { useEffect } from 'react';

import { Grid, Typography } from 'core/components/library';

import {
    DataGridPro as DataGrid,
    Switch,
    ViewEntity,
    Literal
} from 'core/components';

import * as helpers from 'core/helpers';
import * as icons from '../../../../../../core/icons';

import DetailLiquidacion from '../../../../../liquidaciones/Liquidaciones/Detalle';
import { useSelector } from 'react-redux';
import { ENTITY_LIQUIDACIONES } from '../../../../../../store/entities/constants';

const cols = (row, entityData) => [
    {
        key: 'liquidacion',
        title: 'Liquidacion',
        align: 'left',
        width: '10%',
        component: row && (
            <Literal text={row.liquidacion.codigo}>
                <icons.LiquidacionesIcon fontSize={'small'} />
            </Literal>
        )
    },
    {
        key: 'fecha',
        title: 'Fecha',
        align: 'center',
        width: '5%',
        component:
            row && helpers.getValueFormatted('date', row.liquidacion.fecha)
    },
    {
        key: 'tasa',
        title: 'Tasa',
        align: 'center',
        width: '5%',
        component: row && (
            <Switch
                align={'center'}
                value={row.tasa}
                name='tasa'
                editing={false}
            />
        )
    },
    {
        key: 'concepto',
        title: 'Concepto',
        align: 'left',
        width: '45%',
        component: row && row.descripcion
    },
    {
        key: 'medida',
        title: 'Und. Medida',
        align: 'left',
        width: '6%',
        component: row && (
            <Literal text={row.medida.descripcion}>
                <icons.MedicionIcon fontSize={'small'} />
            </Literal>
        )
    },
    {
        key: 'unidades',
        title: 'Unidades',
        align: 'right',
        width: '8%',
        component: row && helpers.getValueFormatted('d.4', row.unidades)
    },
    {
        key: 'precio',
        title: 'Precio €',
        align: 'right',
        width: '8%',
        component: row && helpers.getValueFormatted('d.4', row.precio)
    },
    {
        key: 'importe',
        title: 'Importe €',
        align: 'right',
        width: '8%',
        component: row && helpers.getValueFormatted('d.2', row.importe)
    },
    {
        key: 'pagado',
        title: '',
        align: 'center',
        width: '5%',
        component: row &&
            row.liquidacion &&
            Number(row.liquidacion.pendiente) === 0 && (
                <icons.PaidIcon style={{ color: 'darkgreen' }} />
            )
    }
];

const Liquidaciones = props => {
    const { values, editing, adding, handleChange, refresh } = props;

    const entityData = useSelector(
        state => state.entities[ENTITY_LIQUIDACIONES]
    );

    const getUnidades = () => {
        let valor = 0;
        if (values && values.liquidaciones) {
            values.liquidaciones.forEach(liquidacion => {
                valor = valor + Number(liquidacion.unidades);
            });
        }

        return helpers.getValueFormatted('d.4', valor);
    };

    const getImporte = () => {
        let valor = 0;
        if (values && values.liquidaciones) {
            values.liquidaciones.forEach(liquidacion => {
                valor = valor + Number(liquidacion.importe);
            });
        }

        return helpers.getValueFormatted('d.2', valor);
    };

    return (
        <>
            <Grid
                sx={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    backgroundColor: 'lightgray',
                    height: 25,
                    borderRadius: 1
                }}
                container
                justifyContent={'space-around'}
                alignItems={'center'}
            >
                <Grid item>
                    <Typography variant={'h5'}>
                        {`Unidades: ${getUnidades()}`}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant={'h5'}>
                        {`Importe: ${getImporte()}`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs>
                    <DataGrid
                        readOnly
                        getId={row => row.liquidacion.id}
                        name='liquidaciones'
                        editing={editing}
                        rows={values.liquidaciones ? values.liquidaciones : []}
                        cols={(name, row, index) => cols(row, entityData)}
                        //maxHeight={450}
                    >
                        <ViewEntity
                            readOnly
                            type={ENTITY_LIQUIDACIONES}
                            refresh={refresh}
                            entity={entityData}
                        >
                            {DetailLiquidacion}
                        </ViewEntity>
                    </DataGrid>
                </Grid>
            </Grid>
        </>
    );
};

export default Liquidaciones;
