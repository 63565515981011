import React from 'react';

import { makeStyles } from '../../../library';
import {
    Badge,
    Checkbox,
    TableCell,
    TableHead,
    TableRow
} from '../../../library';

import { MoreIcon, LockedIcon } from '../../../icons';

const useStyles = makeStyles(theme => ({
    actions: {
        width: '50px'
    },
    checkbox: {
        width: '50px',
        padding: 0,
        margin: 0
    }
}));

const Head = props => {
    const { disabled, columns, entities } = props;
    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                {!disabled && (
                    <TableCell width={'1%'} className={classes.actions}>
                        <MoreIcon size='small' color='secondary' />
                    </TableCell>
                )}

                <TableCell
                    style={{ zIndex: 10 }}
                    width={'1%'}
                    className={classes.checkbox}
                >
                    <Badge
                        color='error'
                        badgeContent={entities.itemsSelected}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                    >
                        <Checkbox
                            size={'small'}
                            checked={entities.allSelected}
                            indeterminate={
                                !entities.allSelected &&
                                entities.itemsSelected !== 0
                            }
                            color='primary'
                            onChange={event =>
                                entities.selectAll(event.target.checked)
                            }
                        />
                    </Badge>
                </TableCell>
                {columns.map(
                    (column, index) =>
                        column.column &&
                        column.column !== '' && (
                            <TableCell
                                align={column.align && column.align}
                                width={column.width}
                                key={index}
                            >
                                {column.title}
                            </TableCell>
                        )
                )}
            </TableRow>
        </TableHead>
    );
};

export default Head;
