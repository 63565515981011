import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { Grid, Typography, Divider } from '@mui/material';

import * as icons from 'core/icons';
import * as hlprs from 'core/helpers';
import * as enums from 'core/enums';

import { DataGrid, Text, Switch, Select, Combo } from 'core/components';
import DetailSubcuenta from '../../../../../contabilidad/subcuentas/Detalle';
import DetailFormaPago from '../../../../../tesoreria/formas-pago/Detalle';
import List from '../../../../../../store/List';
import * as helpers from '../../../../../../core/helpers';

const ComboSubcuentas = List(Combo, 'options', 'lstSubcuentas');
const ComboFormaPago = List(Combo, 'options', 'lstFormasPago');
const ComboFormasJuridicas = List(Combo, 'options', 'lstformasjuridicas');

const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const Detalles = props => {
    const {
        values,
        editing,
        adding,
        initial,
        handleChange,
        refresh,
        estableceFiltro
    } = props;
    const classes = useStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Grid container direction='column' spacing={1}>
                    <Grid item xs>
                        <Grid container xs direction='row' spacing={1}>
                            <Grid item xs>
                                <ComboFormasJuridicas
                                    fullWidth
                                    //margin='none'
                                    //variant='standard'
                                    label='Forma Jurídica'
                                    api='auxiliares/formasjuridicas'
                                    name='formaJuridica'
                                    nameKey='formaJuridicaId'
                                    refresh={refresh}
                                    Icon={icons.FormaJuridicaIcon}
                                    value={values.formaJuridica}
                                    onChange={handleChange}
                                    editing={editing}
                                    propertyValue='id'
                                    getOptionLabel={option =>
                                        `${option['descripcion']}`
                                    }
                                />
                            </Grid>
                            <Grid item xs>
                                <Text
                                    fullWidth
                                    name='acronimo'
                                    label='Acronimo'
                                    value={values.acronimo}
                                    onChange={handleChange}
                                    editing={editing}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <ComboSubcuentas
                            fullWidth
                            api='subcuentas/activas'
                            name='subcuenta'
                            nameKey='subcuentaid'
                            label='Cuenta Contable'
                            relacionado={{
                                maxWidth: 'md',
                                Detail: DetailSubcuenta,
                                resume: ['descripcion'],
                                readOnly: false,
                                dfault: {
                                    id: '4300' + values.codigo,
                                    descripcion: values.nombre
                                }
                            }}
                            refresh={refresh}
                            Icon={icons.CuentasIcon}
                            value={values.subcuenta}
                            onChange={handleChange}
                            editing={editing}
                            propertyValue='id'
                            getOptionLabel={option =>
                                `${option.id} ${option.descripcion}`
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <ComboFormaPago
                            fullWidth
                            api='formaspago'
                            name='formaPago'
                            nameKey='formaPagoid'
                            label='Forma de Pago habitual'
                            relacionado={{
                                maxWidth: 'md',
                                Detail: DetailFormaPago,
                                resume: ['descripcion'],
                                readOnly: false
                            }}
                            refresh={refresh}
                            Icon={icons.FormasPagoIcon}
                            value={values.formaPago}
                            onChange={handleChange}
                            editing={editing}
                            propertyValue='id'
                            getOptionLabel={option => `${option.descripcion}`}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Text
                    fullWidth
                    multiline
                    rows={10}
                    name='notas'
                    label='Notas'
                    value={values.notas}
                    onChange={handleChange}
                    editing={editing}
                />
            </Grid>
        </Grid>

        /* <Grid container spacing={1}>
            <Grid item xs>
                <Grid container direction='column' spacing={1}>
                    <Grid item>
                        <Select
                            fullWidth
                          name='tipoEmpresa'
                          label='Tipo'
                          value={values.tipoEmpresa}
                          onChange={handleChange}
                          editing={editing}
                          options={enums.tiposEmpresa}
                        />
                    </Grid>
                    <Grid item>
                        <Select
                            fullWidth
                          name='sistemaExplotacion'
                          label='Sistema Explotación'
                          value={values.sistemaExplotacion}
                          onChange={handleChange}
                          editing={editing}
                          options={enums.sistemasExplotacion}
                        />
                    </Grid>
                    <Grid item>
                        <Text
                            fullWidth
                          name='regIndustria'
                          label='Registro Industria'
                          value={values.regIndustria}
                          onChange={handleChange}
                          editing={editing}
                        />
                    </Grid>
                    <Grid item>
                        <Text
                            fullWidth
                          name='regEmbotellado'
                          label='Registro Embotellado'
                          value={values.regEmbotellado}
                          onChange={handleChange}
                          editing={editing}
                        />
                    </Grid>
                    <Grid item>
                        <Text
                            fullWidth
                          name='regSanitario'
                          label='Registro Sanitario'
                          value={values.regSanitario}
                          onChange={handleChange}
                        editing={editing}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>*/
    );
};

export default Detalles;
