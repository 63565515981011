import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';

import * as icons from 'core/icons';

import { Combo } from 'core/components';
import List from 'store/List';
import DetailContacto from 'views/entorno/contactos/Detalle';
import DetailCentro from 'views/entorno/centros/Detalle';

const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const ComboContactos = List(Combo, 'options', 'lstContactos');
const ComboCentros = List(Combo, 'options', 'lstCentros');

const Recursos = props => {
    const { values, editing, adding, handleChange, refresh } = props;
    const classes = useStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <ComboContactos
                    fullWidth
                    //rows={10}
                    api='contactos'
                    multiple
                    name='contactos'
                    label='Contactos'
                    Icon={icons.ContactIcon}
                    value={
                        values.contactos
                            ? values.contactos.map(item => item)
                            : []
                    }
                    onChange={handleChange}
                    editing={editing}
                    //filtro={estableceFiltro("parcelas")}
                    propertyValue='id'
                    getOptionLabel={option =>
                        `${option.nombre}-[${option.cargo}]${
                            option.declarante ? '-[Declarante]' : ''
                        }`
                    }
                    getItem={item => ({
                        id: item.id,
                        nombre: item.nombre
                    })}
                    relacionado={{
                        maxWidth: 'md',
                        Detail: DetailContacto,
                        resume: ['nombre'],
                        readOnly: false
                    }}
                    refresh={refresh}
                />
            </Grid>
            <Grid item xs>
                <ComboCentros
                    fullWidth
                    api='centros'
                    multiple
                    //rows={10}
                    name='centros'
                    label='Centros'
                    Icon={icons.SitesIcon}
                    value={
                        values.centros ? values.centros.map(item => item) : []
                    }
                    onChange={handleChange}
                    editing={editing}
                    //filtro={estableceFiltro("parcelas")}
                    propertyValue='id'
                    getOptionLabel={option => `${option.nombre}`}
                    getItem={item => ({
                        id: item.id,
                        nombre: item.nombre
                    })}
                    relacionado={{
                        maxWidth: 'md',
                        Detail: DetailCentro,
                        resume: ['nombre'],
                        readOnly: false
                    }}
                    refresh={refresh}
                />
            </Grid>
        </Grid>
    );
};

export default Recursos;
