import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from 'core/components/library';
import {
    DataGridPro as DataGrid,
    Literal,
    Switch,
    Tag,
    ViewEntity
} from 'core/components';
import DetailMarca from '../../../../Marcas/Detalle';
import { ENTITY_MARCA } from '../../../../../../store/entities/constants';
import * as constants from 'core/constants';
import * as IconsSelector from 'core/icons';
import * as enums from 'core/enums';
import { statesEntity } from 'core/enums';

const colsContactos = (row, entity) => [
    {
        key: 'tipo',
        title: 'Tipo',
        align: 'left',
        width: '30%',
        component: row && (
            <Literal text={row.nombre}>
                <IconsSelector.BrandsIcon fontSize={'small'} />
            </Literal>
        )
    },
    {
        key: 'notas',
        title: 'Notas',
        align: 'left',
        width: '55%',
        component: row && row.notas
    },
    {
        key: 'estado',
        title: 'Estado',
        align: 'center',
        width: '15%',
        component: row && (
            <Tag
                align={'center'}
                size={'small'}
                sx={{
                    color: enums.getItem(statesEntity, row.estado, 'color')
                }}
                Icon={enums.getItem(statesEntity, row.estado, 'icon')}
                value={row.estado}
            />
        )
    }
];

const Contactos = props => {
    const { values, editing, execAction, refresh } = props;
    const entityData = useSelector(state => state.entities[ENTITY_MARCA]);

    return (
        <Grid container spacing={3}>
            <Grid item xs>
                <DataGrid
                    name='marcas'
                    editing={editing}
                    rows={values.marcas ? values.marcas : []}
                    cols={(name, row, index) => colsContactos(row, entityData)}
                    onDelete={(name, index, row) => {
                        execAction(
                            {
                                api: entityData.api,
                                metodo: 'delete',
                                data: [row.id]
                            },
                            [() => refresh(false)]
                        );
                    }}
                    maxHeight={456}
                >
                    <ViewEntity
                        maxWidth={'lg'}
                        key={ENTITY_MARCA}
                        refresh={refresh}
                        entity={entityData}
                        initValue={{ bodega: { id: values.id } }}
                    >
                        {DetailMarca}
                    </ViewEntity>
                </DataGrid>
            </Grid>
        </Grid>
    );
};

export default Contactos;
