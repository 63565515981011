import { useTheme } from '../library';

import { Fab, IconButton, Tooltip } from '../library';

import { IconSettings as IconDefault } from '@tabler/icons';

const FloatAction = props => {
    const { handleAction, title, Icon } = props;

    const theme = useTheme();

    return (
        <div style={{ position: 'relative' }}>
            <Tooltip title={title}>
                <Fab
                    component='div'
                    onClick={handleAction}
                    size='medium'
                    variant='circular'
                    color='primary'
                    sx={{
                        borderRadius: 0,
                        borderTopLeftRadius: '50%',
                        borderBottomLeftRadius: '50%',
                        borderTopRightRadius: '50%',
                        borderBottomRightRadius: '4px',
                        top: '0',
                        position: 'absolute',
                        left: 0,
                        zIndex: theme.zIndex.appBar
                    }}
                >
                    <IconButton color='inherit' size='large' disableRipple>
                        {Icon ? <Icon /> : <IconDefault />}
                    </IconButton>
                </Fab>
            </Tooltip>
        </div>
    );
};
export default FloatAction;
